import React from 'react'

const Input = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M8 12V8h16v16H8v-4M8 16h11"/>
      <path d="M16 12l4 4-4 4"/>
    </g>
  </svg>
)

export default Input
