import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Add from './Add'
import Inputs from './Inputs'
import Buttons from './Buttons'
import {
  addCandidate,
  createCandidates,
  removeCandidate,
  unSetCandidates,
  updateCandidateField
} from 'actions/candidateAction'
import { addModal, replaceModal } from 'actions/uiAction'
import { createLabel, listLabels } from 'fetchs/labelFetch'
import { getCandidates } from 'reducers/candidatesReducer'
import { getLabels } from 'reducers/labelsReducer'

class Form extends Component {
  constructor (props) {
    super(props)

    this.state = {
      idCounter: 0,
    }

    this.props.onUnSetCandidates()
    this.props.onListLabels()
  }

  componentDidMount () {
    this.handleAddCandidate()
  }

  handleTextChange = (event, candidateId, itemName) => {
    this.props.onUpdateCandidate(candidateId, itemName, event.target.value)
  }

  handleLabelChange = (newValue, candidateId) => {
    const labels = []
    newValue.forEach((label) => {
      if (label.__isNew__) {
        this.props.onCreateLabel(label.value)
      }
      labels.push(label.value)
    })
    this.props.onUpdateCandidate(candidateId, 'labels', labels)
  }

  handleAddCandidate = () => {

    // brute force clear candidates from previous input
    if (this.props.candidates && this.props.candidates.length > 0) {
      Object.values(this.props.candidates).forEach((candidate) => {
        if (candidate.status !== 'Pending') {
          this.props.onRemoveCandidate(candidate)
        }
      })
    }

    const idCounter = this.state.idCounter + 1
    const candidate = {
      id: idCounter,
      status: 'Pending',
      firstName: '',
      lastName: '',
      email: '',
      labels: []
    }
    this.props.onAddCandidate(candidate)
    this.setState({ idCounter })
  }

  handleDeleteCandidate = (candidate) => {
    this.props.onRemoveCandidate(candidate)
  }

  handleConfirm = (event) => {
    event.preventDefault()
    this.props.onCreateCandidates()
    if(!this.props.newCandidates){
      this.props.onAddModal('Individual Input')
    }
  }

  render () {
    const { candidates, labels } = this.props

    const inputs = []
    Object.values(candidates).forEach((candidate) => {
      if (candidate.status === 'Pending') {
        inputs.push(<Inputs key={candidate.id} labels={labels} candidate={candidate}
                            onTextChange={this.handleTextChange}
                            onLabelChange={this.handleLabelChange}
                            onDeleteCandidate={this.handleDeleteCandidate}/>)
      }
    })

    return (
      <form className="form" onSubmit={this.handleConfirm}>
        {inputs}
        <Add onAddCandidate={this.handleAddCandidate}/>
        <Buttons/>
      </form>
    )
  }
}

Form.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object),
  candidates: PropTypes.arrayOf(PropTypes.object),
  onCreateCandidates: PropTypes.func.isRequired,
  onListLabels: PropTypes.func.isRequired,
  onUpdateCandidate: PropTypes.func.isRequired,
  onAddCandidate: PropTypes.func.isRequired,
  onRemoveCandidate: PropTypes.func.isRequired,
  onUnSetCandidates: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onCreateLabel: PropTypes.func.isRequired,
  onNewCandidates: PropTypes.func,
}

const mapStateToPropsSelector = createStructuredSelector({
  labels: getLabels,
  candidates: getCandidates
})

const mapDispatchToProps = (dispatch) => ({
  onCreateCandidates: () => {
    dispatch(createCandidates())
  },
  onListLabels: () => {
    dispatch(listLabels())
  },
  onUpdateCandidate: (candidateId, inputId, value) => {
    dispatch(updateCandidateField(candidateId, inputId, value))
  },
  onAddCandidate: (candidate) => {
    dispatch(addCandidate(candidate))
  },
  onRemoveCandidate: (candidate) => {
    dispatch(removeCandidate(candidate))
  },
  onUnSetCandidates: () => {
    dispatch(unSetCandidates())
  },
  onAddModal: (modal) => {
    dispatch(replaceModal(modal))
  },
  onCreateLabel: (label) => {
    dispatch(createLabel(label))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Form)
