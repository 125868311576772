import React from 'react'
import PropTypes from 'prop-types'
import AssignCredit from './AssignCredit'
// import AssignCreditSingle from './AssignCreditSingle'
import SelectedAssessment from './SelectedAssessment'
import ChevronRight from 'assets/svg/components/ChevronRight'

const IssueSpecificCredit = ({ selectedAssessments, assessmentSelect, assessmentSpecificCredits, handleAssignCredit, handleChangeCredit, handleAssignAssessment, handleUnAssignAssessment,transferringCredits }) => (
  <div className="padding-top-md text-left separator">
    <div className="grid">
      <div className="grid__item w-6">
        <label className={'label'} htmlFor="firstName"><b
          className="colour-black">Type Specific Credit Assessments:</b> </label>
      </div>
      <div className="grid__item w-12">
        {Object.values(selectedAssessments).map((assessment) => (
            <div key={assessment.id} className="grid">
              <SelectedAssessment key={assessment.id} assessment={assessment} handleUnAssignAssessment={() => handleUnAssignAssessment(assessment)}/>
              <AssignCredit currentCredit={validateCredit(assessmentSpecificCredits, assessment.assessment.name)}
                            handleAssignCredit={() => handleAssignCredit(assessment.assessment.name)}
                            handleChangeCredit={(event) => handleChangeCredit(event, assessment.assessment.name)}
                            transferringCredits={transferringCredits}/>
            </div>
        ))}
      </div>
      <div className="grid__item w-6">
        {assessmentSelect}
      </div>
      <div className="grid__item w-6">
        <button onClick={handleAssignAssessment} className="button button--stretch button--active w-5">
          <span className="padding-right-sm">Assign assessment</span>
          <i className="icon"><ChevronRight/></i>
        </button>
      </div>
    </div>
  </div>
)

function validateCredit (assessmentSpecificCredits, assessmentName) {
  if (assessmentName in  assessmentSpecificCredits) {
    return assessmentSpecificCredits[assessmentName]
  }
  return 0
}

IssueSpecificCredit.propTypes = {
  selectedAssessments: PropTypes.array.isRequired,
  // input: PropTypes.oneOfType([
  //   PropTypes.object,
  //   PropTypes.array
  // ]),
}

export default IssueSpecificCredit

