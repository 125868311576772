import React from 'react'
import PropTypes from 'prop-types'

const BalanceSharedCredit = ({ shared }) => (
  <div>
    {shared.toString()}
  </div>
)

BalanceSharedCredit.propTypes = {
  shared: PropTypes.string,
}

export default BalanceSharedCredit
