import { receiveLeadershipAssessment } from 'actions/leadershipAssessmentAction'

export const leadershipAssessmentError = (errorCode, leadershipAssessment) => {
    return function (dispatch) {
        switch (errorCode) {
            case 4009002:
                // 'No credit available. There were not enough shared tokens available to complete this request. Please contact your administrator.'
                dispatch(receiveLeadershipAssessment('No credit', leadershipAssessment))
                break
            case 4009003:
                // 'No credit available. There were not enough basic tokens available to complete this request. Please contact your administrator.'
                dispatch(receiveLeadershipAssessment('No credit', leadershipAssessment))
                break
            case 409:
                // 'Similar assessment for given candidate already exists.'
                dispatch(receiveLeadershipAssessment('Duplicate', leadershipAssessment))
                break
            case 4009001:
                // 'Assessment has been disabled. Please contact your administrator.'
                dispatch(receiveLeadershipAssessment('Assessment Disabled', leadershipAssessment))
                break
            default:
        }
    }
}
