import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/Form/Text'
import Field from './Field'
import ChevronRight from 'assets/svg/components/ChevronRight'

const UserInfo = ({ user, onTextChange }) => (
    <div className="card card--stretch">
        <div className="grid">
            <Field required={true} label={'First name'} input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.firstName}
                                                placeholder={'First Name'}
                                                itemName={'firstName'}/>}/>
            <Field required={true}
                   label={'Last name'} input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.lastName}
                                placeholder={'Last Name'}
                                itemName={'lastName'}/>}/>
            <Field required={true}
                   label={'Email'} input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.primaryEmail}
                                placeholder={'Email'}
                                itemName={'primaryEmail'}/>}/>
            <Field input={<button type={'submit'} className="button button--stretch button--active">
                <span className="padding-right-sm">Save</span>
                <i className="icon"><ChevronRight/></i>
            </button>}/>
        </div>
    </div>
)

UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
    onTextChange: PropTypes.func.isRequired,
}

export default UserInfo
