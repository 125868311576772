import React, { Component } from 'react'
import AddSingle from 'assets/svg/components/AddSingle'

class UploadCandidates extends Component {
    render () {
        return (
            <div className="padding-top-xsm">
                <h5>Create Team Name (optional)</h5>
                <div className="input-group input-group--flip">
                    <input className="input-search" type="text" placeholder="Team name"/>
                    <button className="input-button"><AddSingle/></button>
                </div>
            </div>
        )
    }
}

export default UploadCandidates
