import React from 'react'
import PropTypes from 'prop-types'
import ChevronRight from 'assets/svg/components/ChevronRight'
import Loader from 'react-loader-spinner'

const AssignCredit = ({ currentCredit, handleChangeCredit, handleAssignCredit, transferringCredits }) => (
  <div className="grid__item w-6">
    <input className="input margin-right-md w-5" type="number" value={currentCredit} onChange={(event) => handleChangeCredit(event)}/>
    {transferringCredits &&
    <div className="button button--stretch button--info w-5">
      Assigning credit
      <Loader
        type="ThreeDots"
        color="#005a6d"
        height="20"
        width="20"
      />
    </div>
    }
    {!transferringCredits &&
    <button onClick={handleAssignCredit} className="button button--stretch button--active w-5">
      <span>Assign Credit</span>
      <i className="icon"><ChevronRight/></i>
    </button>
    }
  </div>
)

AssignCredit.propTypes = {
  // currentCredit: PropTypes.number.isRequired,
  handleChangeCredit: PropTypes.func.isRequired,
}

export default AssignCredit
