import React from 'react'
import Logout from 'assets/svg/components/Logout'
import SidebarItem from './Item'
import Help from 'assets/svg/components/Help'
import Settings from 'assets/svg/components/Settings'
import Privacy from 'assets/svg/components/Privacy'
import {checkPermissions} from 'actions/authenticateAction'

const GroupBottom = ({ onLogOut }) => (
  <div className="sidebar__group border-bottom">
    <h5 className="sidebar__title margin-bottom-xsm">Settings</h5>
    <div>
      <ul className="list-bare">
        {checkPermissions('USER_VIEW') &&
        <SidebarItem name={'Users'} link={`users`} icon={<Settings/>}/>
        }
        <SidebarItem name={'Account'} link={`account`} icon={<Settings/>}/>
        <SidebarItem name={'Site'} link={`site`} icon={<Settings/>}/>
        <SidebarItem targetBlank name={'Help'} link={`default/user/help`} icon={<Help/>} dual={true}/>
        {/*<SidebarItem name={'Settings'} link={`/settings`} icon={<Settings/>}/>*/}
        <SidebarItem name={'Privacy'} link={`default/user/privacy`} icon={<Privacy/>} dual={true}/>
        <li className="padding-top-sm padding-bottom-xsm">
          <button className="button white" type="link"
            onClick={() => onLogOut()}>
            <i className="icon"><Logout/></i>
            <span className="padding-left-xsm">Logout</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
)

export default GroupBottom
