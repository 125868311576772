import store from 'store'
import { getAuth } from 'reducers/authReducer'
import { listUserOrganisations } from 'fetchs/userOrganisationFetch'
import { readUser } from 'fetchs/userFetch'
import { readOrganisation } from 'fetchs/organisationFetch'
import { removeAlert } from 'actions/uiAction'

const jwt_decode = require('jwt-decode')
export const ADD_AUTH = 'ADD_AUTH'
export const REMOVE_AUTH = 'REMOVE_AUTH'

export function applyToken (token, namespace, branding = false) {
  return function (dispatch) {
    const decodedToken = jwt_decode(token)

    const auth = {
      user: decodedToken.username,
      permissions: decodedToken.sps,
      tokenExp: decodedToken.exp,
      organisation: namespace,
      jwtToken: token,
      branding: branding
    }

    localStorage.setItem('auth', JSON.stringify(auth))
    dispatch(addAuth(auth))
    dispatch(removeAlert())

    if (!namespace) {
      dispatch(listUserOrganisations(auth))
    } else {
      dispatch(readUser(decodedToken.username))
      dispatch(readOrganisation(namespace))
    }
  }
}

export function needsRefresh (timeLimit) {
  const auth = JSON.parse(localStorage.getItem('auth'))
  if (auth) {
    const now = new Date()
    const seconds = 1000
    const minute = seconds * 60
    const nowPlusLimit = new Date(now.getTime() + (timeLimit * minute))
    const expiryTime = new Date(auth.tokenExp * 1000)

    if (expiryTime < nowPlusLimit) {
      return true
    }
  }
}

export function checkPermissions (permission) {
  const auth = getAuth(store.getState())
  return (Object.values(auth.permissions).includes(permission))
}

export function removeToken () {
  return function (dispatch) {
    localStorage.removeItem('auth')
    dispatch(removeAuth())
  }
}

// ACTION CREATORS
export function removeAuth () {
  return {
    type: REMOVE_AUTH
  }
}

export function addAuth (auth) {
  return {
    type: ADD_AUTH,
    auth
  }
}

