import { SET_CANDIDATE_SELECTED, UNSET_CANDIDATE_SELECTED } from 'actions/candidateSelectedAction'

const initialState = false
const candidateSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANDIDATE_SELECTED:
      return action.candidate
    case UNSET_CANDIDATE_SELECTED:
      return action.candidate
    default:
      return state
  }
}

export default candidateSelected

export const getCandidateSelected = state => state.candidateSelected

