import React from 'react'
import PropTypes from 'prop-types'

const Select = ({ parentId, selectedItem, onItemChange, itemOptions, itemName, required = false }) => (
  <select className="select" value={checkSelectedItem(selectedItem)} onChange={(event) => onItemChange(event, parentId)} required={required}>
    <option value="">Select {itemName}...</option>
    {itemOptions}
  </select>
)

function checkSelectedItem (selectedItem) {
  if (!selectedItem) {
    return ''
  }
  return selectedItem
}

Select.propTypes = {
  onItemChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  itemOptions: PropTypes.array,
  itemName: PropTypes.string,
}

export default Select
