import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { listUserOrganisations } from 'fetchs/userOrganisationFetch'
import { getUserOrganisationBrand } from 'actions/userOrganisationAction'
import Select from 'components/Form/Select'
import SelectOption from 'components/Form/SelectOption'
import ChevronRight from 'assets/svg/components/ChevronRight'

function compare( a, b ) {
  if ( a.namespace < b.namespace ){
    return -1;
  }
  if ( a.namespace > b.namespace ){
    return 1;
  }
  return 0;
}

class UserOrganisationsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedOrganisation: '',
      selectedNamespace: ''
    }

    if (!this.props.userOrganisations) {
      this.props.onListUserOrganisations()
    }
  }

  handleClickOrganisation = () => {
    this.props.onGetUserOrganisationBrand(this.state.selectedNamespace)
  }

  handleOrganisationChange = (event) => {
    const selectedNamespace = this.props.userOrganisations.find((organisation) => {
      return organisation.name === event.target.value
    })

    this.setState({ selectedOrganisation: event.target.value, selectedNamespace: selectedNamespace.namespace })
  }

  render () {
    const { userOrganisations, userOrganisation, auth } = this.props
    const { selectedOrganisation, selectedNamespace } = this.state
    let redirect = null

    if (selectedNamespace === auth.organisation) {
      redirect = <Redirect to={`/${userOrganisation.branding.name}/send`}/>
    }
    if (userOrganisations) userOrganisations.sort( compare );
    const organisationOptions = []
    Object.values(userOrganisations).forEach((organisation) => {
      organisationOptions.push(<SelectOption key={organisation.id} value={organisation.name}
                                             title={organisation.name}/>)
    })

    return (
        <div className="form">
          {redirect}
          <h2 className="form__head">Select Organisation</h2>
          <Select parentId={''} onItemChange={this.handleOrganisationChange} selectedItem={selectedOrganisation}
                  itemOptions={organisationOptions} itemName={'Organisation'}/>
          <div className="padding-top-md padding-bottom-sm text-left">
            <a className="colour-active" href={`/default/user/login`}><b>Back to Login</b></a>
          </div>
          <div>
            <button onClick={() => this.handleClickOrganisation()}
                    className="button button--stretch button--active">
              <span>Go</span>
              <i className="icon"><ChevronRight/></i>
            </button>
          </div>
        </div>
    )
  }
}

UserOrganisationsPage.propTypes = {
  userOrganisations: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  auth: PropTypes.object.isRequired,
  onListUserOrganisations: PropTypes.func.isRequired,
  onGetUserOrganisationBrand: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  onListUserOrganisations: () => {
    dispatch(listUserOrganisations())
  },
  onGetUserOrganisationBrand: (organisation) => {
    dispatch(getUserOrganisationBrand(organisation, true))
  },
})

export default connect(null, mapDispatchToProps)(UserOrganisationsPage)
