import React from 'react'
import PropTypes from 'prop-types'

const UserAssessmentExpiredPage = ({ message }) => (
    <div className="form">
        <h2 className="form__head">{message}</h2>
        <p>Your assessment has now expired. If you would like to complete it, please contact the organisation that sent you the assessment.</p>
    </div>
)

UserAssessmentExpiredPage.propTypes = {
    message: PropTypes.string.isRequired,
}

export default UserAssessmentExpiredPage
