import React from 'react'
import ChevronRight from 'assets/svg/components/ChevronRight'
import PropTypes from 'prop-types'

const ButtonGroup = ({ handleGoClick }) => (
    <div className="text-right">
        <button className="button button--stretch button--active margin-bottom-sm" onClick={handleGoClick}>
            <span className="padding-right-sm">Go</span>
            <i className="icon"><ChevronRight/></i>
        </button>
    </div>
)

ButtonGroup.propTypes = {
    handleGoClick: PropTypes.func.isRequired,
}

export default ButtonGroup
