import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PropTypes from 'prop-types'
import CandidatesAssessmentsPage from '../CandidateAssessmentPage'
import AssessmentsPage from '../AssessmentPage'
import Sidebar from '../Sidebar'
import Modal from 'containers/Modal'
import Menu from 'containers/Menu'
import CandidatesPage from 'containers/CandidatePage'
import Assessments from 'containers/TeamReportPage'
import LeadershipAssessments from 'containers/LeadershipAssessmentPage'
import OrganisationsPage from 'containers/OrganisationsPage'
import LabelsPage from 'containers/LabelsPage'
import InputPage from 'containers/InputPage'
import NotFoundPage from 'containers/NotFoundPage'
import UserPage from 'containers/UserPage'
import privacy from 'containers/templates/privacy'
import { getModal } from 'reducers/modalReducer'
import { checkPermissions } from 'actions/authenticateAction'
import SitePage from 'containers/SitePage'
import AccountPage from 'containers/AccountPage'

class AppView extends Component {
  render () {
    const { modal, branding } = this.props

    return (
      <section>
        {modal.length > 0 &&
        <Modal/>
        }
        <main className="main">
          <Menu/>
          <div
            className={'main__column main__column--primary'}>
            <Route component={Sidebar}/>
          </div>
          <div className="main__column main__column--secondary">
            <Switch>
              <Route exact path={`/${branding}/send`} component={AssessmentsPage}/>
              <Route exact path={`/${branding}/individuals`} component={CandidatesPage}/>
              <Route exact path={`/${branding}/teams`} component={Assessments}/>
              <Route exact path={`/${branding}/360`} component={LeadershipAssessments}/>
              <Route exact path={`/${branding}/assessments`} component={CandidatesAssessmentsPage}/>
              <Route exact path={`/${branding}/organisations`} component={OrganisationsPage}/>
              <Route exact path={`/${branding}/labels`} component={LabelsPage}/>
              <Route exact path={`/${branding}/site`} component={SitePage}/>
              <Route exact path={`/${branding}/account`} component={AccountPage}/>
              <Route exact path={`/${branding}/privacy`} component={privacy}/>
              <Route exact path={`/${branding}/input`} component={InputPage}/>
              {checkPermissions('USER_VIEW') &&
              <Route path={`/${branding}/users`} component={UserPage}/>
              }
              <Route path={`/${branding}/*`} component={NotFoundPage}/>
            </Switch>
          </div>
        </main>
      </section>
    )
  }
}

AppView.propTypes = {
  modal: PropTypes.array.isRequired,
  branding: PropTypes.string.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  modal: getModal
})

export default connect(mapStateToPropsSelector, null)(AppView)
