import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCandidatesAssessments } from 'reducers/candidatesAssessmentsReducer'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import { createStructuredSelector } from 'reselect'

class TableCandidatesAssessmentsStatus extends Component {
  render () {
    const { candidatesAssessments } = this.props

    const headers = []
    let key = 0
    headers.push(<Header key={++key} header='First'/>)
    headers.push(<Header key={++key} header='Last'/>)
    headers.push(<Header key={++key} header='Email'/>)
    headers.push(<Header key={++key} header='Assessment'/>)
    headers.push(<Header key={++key} header='Status'/>)

    let tableBody = <NoResults colSpan={5}/>
    const body = []

    if (candidatesAssessments.length) {
      candidatesAssessments.forEach((candidateAssessment) => {
        const row = []
        row.push(<CellText header={'First'} value={candidateAssessment.candidate.firstName} key={++key}/>)
        row.push(<CellText header={'Last'} value={candidateAssessment.candidate.lastName} key={++key}/>)
        row.push(<CellText header={'Email'} value={candidateAssessment.candidate.email} key={++key}/>)
        row.push(<CellText header={'Assessment'} value={candidateAssessment.assessment.assessment.name} key={++key}/>)
        row.push(<CellText header={'Status'} value={candidateAssessment.status} key={++key}/>)
        body.push(<Row key={++key} row={row}/>)
      })
      tableBody = (<tbody>{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

TableCandidatesAssessmentsStatus.propTypes = {
  candidatesAssessments: PropTypes.array.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  candidatesAssessments: getCandidatesAssessments,
})

export default connect(mapStateToPropsSelector, null)(TableCandidatesAssessmentsStatus)
