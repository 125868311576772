import React from 'react'

const Logout = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M24 24H8V8h16"/>
      <path d="M20 12l4 4-4 4M12 16h11"/>
    </g>
  </svg>
)

export default Logout
