import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getLeadershipsAssessments } from 'reducers/leadershipsAssessmentsReducer'
import { createLeadershipAssessment } from 'fetchs/leadershipAssessmentFetch'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import {formatLabels} from 'actions/labelAction'
import Moment from 'react-moment';

import { createStructuredSelector } from 'reselect'

class Table extends Component {
    render () {
        const { leadershipAssessments, onClickLeadershipAssessment } = this.props

        const headers = []
        let key = 0
        headers.push(<Header key={++key} header='First'/>)
        headers.push(<Header key={++key} header='Last'/>)
        headers.push(<Header key={++key} header='Assessment'/>)
        // headers.push(<Header key={++key} header='Label'/>)
        // headers.push(<Header key={++key} header='Status'/>)
        headers.push(<Header key={++key} header='Invited'/>)
        // headers.push(<Header key={++key} header='Expires'/>)

        let tableBody = <NoResults colSpan={6}/>
        const body = []

        if (leadershipAssessments.length) {
            leadershipAssessments.forEach((leadershipAssessment) => {

                let full = '';
                if (leadershipAssessment.createdAt) {
                    full = <Moment format="DD/MM/YYYY" date={leadershipAssessment.createdAt.date} />
                }
                const fullDate = full

                let expires = '';
                if (leadershipAssessment.expiresAt) {
                    expires = <Moment format="DD/MM/YYYY" date={leadershipAssessment.expiresAt.date} />
                }
                const expiresAt = expires;


                let status = 'Sent'
                if (leadershipAssessment.deletedAt) {
                    status = 'Deleted'
                } else if (leadershipAssessment.cancelledAt) {
                    status = 'Cancelled'
                } else if (leadershipAssessment.completedAt) {
                    status = 'Completed'
                } else if (leadershipAssessment.startedAt) {
                    status = 'Started'
                }

                const row = []
                row.push(<CellText header={'First'} value={leadershipAssessment.candidate.firstName} key={++key}/>)
                row.push(<CellText header={'Last'} value={leadershipAssessment.candidate.lastName} key={++key}/>)
                row.push(<CellText header={'Assessment'} value={leadershipAssessment.assessment.name} key={++key}/>)
                // row.push(<CellText header={'Label'} value={formatLabels(leadershipAssessment.candidate.labels)} key={++key}/>)
                // row.push(<CellText header={'Status'} value={leadershipAssessment.status} key={++key}/>)
                row.push(<CellText header={'Invited'} value={fullDate} key={++key}/>)
                // row.push(<CellText header={'Expires'} value={expiresAt} key={++key}/>)
                body.push(<Row onClick={() => onClickLeadershipAssessment(leadershipAssessment)} key={++key} row={row}/>)
            })
            tableBody = (<tbody>{body}</tbody>)
        }

        return (
            <div className="padding-top-md">
                <table className="table">
                    <Head headers={headers}/>
                    {tableBody}
                </table>
            </div>
        )
    }
}

Table.propTypes = {
    leadershipsAssessments: PropTypes.arrayOf(PropTypes.object),
    onCreateLeadershipAssessment: PropTypes.func.isRequired,
    onClickLeadershipAssessment: PropTypes.func.isRequired
}

const mapStateToPropsSelector = createStructuredSelector({
    leadershipsAssessments: getLeadershipsAssessments
})

const mapDispatchToProps = (dispatch) => ({
    onCreateLeadershipAssessment: (candidateAssessment) => {
        dispatch(createLeadershipAssessment(candidateAssessment))
    },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Table)
