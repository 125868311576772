import { createCandidate, updateCandidate, anonymiseCandidate } from 'fetchs/candidateFetch'
import { getCandidates } from 'reducers/candidatesReducer'
import { getLabels } from 'reducers/labelsReducer'

export const UNSET_LEADER = 'UNSET_CANDIDATES'
export const ADD_LEADER = 'ADD_CANDIDATE'

export function addLeader (leader) {
    return {
        type: ADD_LEADER,
        leader
    }
}

export function unSetLeader () {
    return {
        type: UNSET_LEADER,
    }
}
