import React from 'react'
import PropTypes from 'prop-types'

const BalanceAssessmentCredit = ({ assessmentSpecific }) => (
  <div>
    {
      Object.entries(assessmentSpecific).map((credit, index) =>
        <div key={index}>
          <div>{credit[0].toString()}</div>
          <div>{credit[1].toString()}</div>
        </div>
      )
    }
  </div>
)

BalanceAssessmentCredit.propTypes = {
  assessmentSpecific: PropTypes.object,
}

export default BalanceAssessmentCredit
