import React from 'react'

const AddSingle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.9" height="17.9">
    <g fill="none" stroke="#005A6D" strokeWidth="1.297" strokeMiterlimit="10">
      <path d="M1.1 9h15.6M8.8 16.7V1.1"/>
    </g>
  </svg>
)

export default AddSingle
