import React from 'react'
import PropTypes from 'prop-types'

const Select = ({ selectedItem, onItemChange, itemOptions, itemName }) => (
  <div className="grid__item w-2-md">
    <div className="input-group">
      <select className="select" value={selectedItem} onChange={onItemChange}>
        <option value="">Select {itemName}...</option>
        {itemOptions}
      </select>
    </div>
  </div>
)

Select.propTypes = {
  onItemChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.string,
  itemOptions: PropTypes.array,
  itemName: PropTypes.string,
}

export default Select
