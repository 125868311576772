import { SET_USERS } from 'actions/userAction'

const initialState = []
const users = (state = initialState, action) => {
  // let newState = Object.assign([], state)
  switch (action.type) {
    case SET_USERS:
      return action.users
    // case UNSET_USERS:
    //   return initialState
    // case ADD_USER:
    //   newState.push(action.user)
    //   return newState
    // case UPDATE_USER:
    //   return newState.map(function (user) {
    //     if (user.id === action.user.id) {
    //       user = action.user
    //       user['status'] = action.status
    //     }
    //     return user
    //   })
    // case UPDATE_USER_FIELD:
    //   return newState.map(function (user) {
    //     if (user.id === action.userId) {
    //       user[action.inputId] = action.value
    //     }
    //     return user
    //   })
    // case REMOVE_USER:
    //   return newState.filter(obj => {
    //     return obj.id !== action.user.id
    //   })
    default:
      return state
  }
}

export default users

export const getUsers = state => state.users
