import { setUserSelected } from 'actions/userSelectedAction'
import { createUserPermission, deleteUserPermission } from 'fetchs/userPermissionFetch'
import { updateUserInfoAPI, updateUserPasswordAPI, readUser, updateUserPreferencesAPI, activeateOrDeactivateUserFetch } from 'fetchs/userFetch'
import { getAuth } from 'reducers/authReducer'

export const SET_USERS = 'SET_USERS'
export const SET_USER = 'SET_USER'
export const ADD_USER_ORGANISATIONS = 'ADD_USER_ORGANISATIONS'
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES'


export function updateUserInfo (user) {
  return function (dispatch) {
    dispatch(updateUserInfoAPI(user))
  }
}

export function updateUserPassword (user) {
  return function (dispatch) {
    dispatch(updateUserPasswordAPI(user))
  }
}

export function updateUserWithRole (oldUser, user) {
  return function (dispatch) {
    if (oldUser.role.id && oldUser.role.id !== user.role.id) {
      dispatch(deleteUserPermission(oldUser))
      dispatch(createUserPermission(user))
    }
    dispatch(setUserSelected(user))
  }
}

export function readAuthUser () {
  return function (dispatch, getState) {
    const auth = getAuth(getState())
    dispatch(readUser(auth.user))
  }
}

export function updateUserPreferences (preferenceData) {
  return function (dispatch) {
    dispatch(updateUserPreferencesAPI(preferenceData))
  }
}

export function receiveUsers (users) {
  return {
    type: SET_USERS,
    users
  }
}
export function receiveUserOrganisations (organisations) {
  return {
    type: ADD_USER_ORGANISATIONS,
    organisations
  }
}

export function setUser (user) {
  return {
    type: SET_USER,
    user
  }
}

export function activeateOrDeactivateUser (user) {
  return function (dispatch) {
    dispatch(activeateOrDeactivateUserFetch(user));
  }
}
