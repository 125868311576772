import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateUserPassword } from 'fetchs/userPasswordFetch'
import { addAlertExpiry } from 'actions/uiAction'
import Form from './Form'
import Confirmation from '../UserPasswordUpdatePage/Confirmation'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getConfirmation } from 'reducers/confirmationReducer'

class PasswordUpdatePage extends Component {
  onSubmitForm = (event) => {
    event.preventDefault()
    const username = this.props.match.params.username
    const token = this.props.match.params.token
    const passwordNew = event.target['password-new'].value
    const passwordConfirm = event.target['password-confirm'].value
    this.props.onUpdateUserPassword(username, token, passwordNew, passwordConfirm)
  }

  render () {
    const { confirmation, branding } = this.props

    if (confirmation === 'passwordUpdate') {
      return (<Confirmation branding={branding}/>)
    } else {
      return (<Form onSubmitForm={this.onSubmitForm} branding={branding}/>)
    }
  }
}

PasswordUpdatePage.propTypes = {
  branding: PropTypes.string.isRequired,
  confirmation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onUpdateUserPassword: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  confirmation: getConfirmation
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateUserPassword: (username, passwordToken, password, passwordConfirm) => {
    dispatch(updateUserPassword(username, passwordToken, password, passwordConfirm))
  },
  onAddAlertExpiry: (alertType, message) => {
    dispatch(addAlertExpiry(alertType, message))
  }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(PasswordUpdatePage)
