import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCandidatesAssessments } from 'reducers/candidatesAssessmentsReducer'
import { createCandidateAssessment } from 'fetchs/candidateAssessmentFetch'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import {formatLabels} from 'actions/labelAction'
import Moment from 'react-moment';

import { createStructuredSelector } from 'reselect'

class Table extends Component {
    render () {
        const { candidatesAssessments } = this.props

        const headers = []
        let key = 0
        headers.push(<Header key={++key} header='First'/>)
        headers.push(<Header key={++key} header='Last'/>)
        headers.push(<Header key={++key} header='Completed'/>)

        let tableBody = <tbody>
        <tr className="table__group">
            <td/>
            <td colSpan="3">
                Loading candidate assessments
            </td>
        </tr>
        </tbody>
        const body = []

        if (candidatesAssessments) {
            if (candidatesAssessments.length) {
                candidatesAssessments.forEach((candidateAssessment) => {

                    const completedAt = <Moment format="DD/MM/YYYY" date={candidateAssessment.completedAt.date} />

                    const row = []
                    row.push(<CellText header={'First'} value={candidateAssessment.candidate.firstName} key={++key}/>)
                    row.push(<CellText header={'Last'} value={candidateAssessment.candidate.lastName} key={++key}/>)
                    row.push(<CellText header={'Completed'} value={completedAt} key={++key}/>)
                    body.push(<Row key={++key} row={row}/>)
                })
                tableBody = (<tbody>{body}</tbody>)
            }
            else {
                tableBody = <NoResults colSpan={3}/>
            }

        }

        return (
            <div className="padding-top-md">
                <table className="table">
                    <Head headers={headers}/>
                    {tableBody}
                </table>
            </div>
        )
    }
}

Table.propTypes = {
    candidatesAssessments: PropTypes.arrayOf(PropTypes.object),
}

export default connect()(Table)
