import { ADD_MODAL, REMOVE_CURRENT_MODAL, REMOVE_MODAL, REPLACE_MODAL } from '../actions/uiAction'

const initialState = []

const modal = (state = initialState, action) => {
  const newState = [...state]
  switch (action.type) {
    case ADD_MODAL:
      newState.push(action.modal)
      return newState
    case REMOVE_MODAL:
      return initialState
    case REMOVE_CURRENT_MODAL:
      newState.pop()
      return newState
    case REPLACE_MODAL:
      newState.pop()
      newState.push(action.modal)
      return newState
    default:
      return state
  }
}

export default modal

export const getModal = state => state.modal

