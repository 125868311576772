import { getLeader } from 'reducers/leaderReducer'
import {ADD_CANDIDATES_SELECTED} from "./candidateSelectedAction";

export const SET_LEADER_SELECTED = 'SET_LEADER_SELECTED'
export const UNSET_LEADER_SELECTED = 'UNSET_LEADER_SELECTED'

export const ADD_LEADER_SELECTED = 'ADD_LEADER_SELECTED'

export function setLeaderSelected (candidate) {
    return {
        type: SET_LEADER_SELECTED,
        candidate
    }
}

export function unSetLeaderSelected () {
    return {
        type: UNSET_LEADER_SELECTED,
    }
}

export function addLeaderSelected (candidate) {
    return {
        type: ADD_LEADER_SELECTED,
        candidate
    }
}
