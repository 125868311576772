import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createLeadershipAssessment } from 'fetchs/leadershipAssessmentFetch'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import TableReviewersConfirm from 'containers/Table/ReviewersConfirm'
import Options from './Options'
import { createStructuredSelector } from 'reselect'
import {getAssessmentsBySelected, getOrganisationAssessments} from 'reducers/organisationAssessmentsReducer'
import leaderSelected, { getLeaderSelected } from 'reducers/leaderSelectedReducer'
import Field from "../../InputPage/Form/Field";
import DateWrapper from 'components/Form/DateWrapper'
import Head from "../../../components/Table/Head";
import {getReviewers} from "../../../reducers/reviewersReducer";
import {removeModal} from "actions/uiAction";

class LeadershipAssessmentsConfirm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expiresAt: ''
        }
    }

    handleConfirm = () => {

        let orgAssessment = null;

        const { organisationAssessments } = this.props;

        // we need to remove the assessments that have a token type of team, as these are only needed for the team reports
        Object.keys(organisationAssessments).forEach(function(key) {
            let organisationAssessment = organisationAssessments[key];

            if (organisationAssessment.assessment.slug !== 'leadershipflow360') {
                orgAssessment = organisationAssessment
            }
        });

        this.props.onCreateLeadershipAssessment(orgAssessment, this.props.leaderSelected, this.props.reviewers)
        // this.props.onAddModal('Individual Assessments')
    }

    // handleDateChange = (date) => {
    //     if (date) {
    //         let dd = String(date.getDate()).padStart(2, '0');
    //         let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    //         let yyyy = date.getFullYear();
    //         let formattedDate = yyyy + '-' + mm + '-' + dd + ' 23:59:00';
    //         this.setState({ expiresAt: formattedDate })
    //     }
    //     else {
    //         this.setState({ expiresAt: '' })
    //     }
    // }

    render () {
        const { assessmentsBySelected } = this.props

        return (
            <section>
                {/*<div className="padding-top-md">*/}
                {/*    {assessmentsBySelected.length > 0 ? (*/}
                {/*        <CardGroup*/}
                {/*            cards={assessmentsBySelected}*/}
                {/*            noSelectionRequired*/}
                {/*        />*/}
                {/*    ) : (*/}
                {/*        <NoResults/>*/}
                {/*    )}*/}
                {/*</div>*/}
                <h2>Leader</h2>
                <div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td><strong>{ this.props.leaderSelected.firstName }</strong></td>
                                <td><strong>{ this.props.leaderSelected.lastName }</strong></td>
                                <td><strong>{ this.props.leaderSelected.email }</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <TableReviewersConfirm/>
                <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', 'alignItems': 'flex-end'}}>
                    {/*<Field input={<DateWrapper onItemChange={this.handleDateChange}*/}
                    {/*                           placeholder={'Expires on'}*/}
                    {/*                           itemValue={this.state.expiresAt}*/}
                    {/*/>}/>*/}
                    <Options title={'Confirm'} onClick={this.handleConfirm}/>
                </div>

            </section>
        )
    }
}

LeadershipAssessmentsConfirm.propTypes = {
    assessmentsBySelected: PropTypes.arrayOf(PropTypes.object),
    onCreateLeadershipAssessment: PropTypes.func.isRequired,
    organisationAssessments: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToPropsSelector = createStructuredSelector({
    organisationAssessments: getOrganisationAssessments,
    assessmentsBySelected: getAssessmentsBySelected,
    leaderSelected: getLeaderSelected,
    reviewers: getReviewers
})

const mapDispatchToProps = (dispatch) => ({
    onCreateLeadershipAssessment: (organisationAssessment, leader, reviewers) => {
        dispatch(createLeadershipAssessment(organisationAssessment, leader, reviewers))
    }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(LeadershipAssessmentsConfirm)
