import React from 'react'

const Note = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <path fill="none" stroke="#B1B2B4" strokeWidth="1.969" strokeMiterlimit="10" d="M24 5.1H7v21.8h17V5.1H7"/>
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeMiterlimit="10">
      <path d="M10.9 12h9.2M10.9 16h9.2M10.9 20h9.2"/>
    </g>
  </svg>
)

export default Note
