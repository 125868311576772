export const SET_ORGANISATIONS = 'SET_ORGANISATIONS'


export function receiveOrganisations (organisations) {
  return {
    type: SET_ORGANISATIONS,
    organisations
  }
}

// export const SET_ORGANISATION = 'SET_ORGANISATION'
// export function receiveOrganisation (organisation) {
//   return {
//     type: SET_ORGANISATION,
//     organisation
//   }
// }
