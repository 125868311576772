import { setCandidatesAssessments, unSetCandidatesAssessments } from 'actions/candidateAssessmentAction'
import { setCandidatesAssessmentsSelected } from 'actions/candidatesAssessmentsSelectedAction'
import { setTeamReport } from 'actions/teamReportAction'
import { setTeamReportsSaved } from '../actions/teamReportsSavedAction'
import { asyncFetch } from './apiFetch'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { getCandidatesAssessmentsSelected } from 'reducers/candidatesAssessmentsSelectedReducer';
import { receivePages, unSetPages } from 'actions/uiAction'
import { getPages } from 'reducers/pagesReducer'

// LIST
export function listOrganisationCandidatesAssessments (reset, page, searchFilter = null, labelFilter = null, statusFilter = null, invitedFilter = null) {
  return function (dispatch, getState) {
    dispatch(unSetPages())
    if(reset){
      dispatch(unSetCandidatesAssessments())
    }

    const userOrganisation = getUserOrganisation(getState())

    let searchFilterUrl = ''
    if (searchFilter) {
      searchFilterUrl = `&filters[]=or-candidate.firstName-inci-value-${searchFilter}`
      searchFilterUrl += `&filters[]=or-candidate.lastName-inci-value-${searchFilter}`
      searchFilterUrl += `&filters[]=or-assessment.name-inci-value-${searchFilter}`
    }

    let labelFilterUrl = ''
    if (labelFilter) {
      labelFilterUrl = `&filters[]=and-label.name-inci-value-${labelFilter}`
    }

    let invitedFilterUrl = ''
    if (invitedFilter) {
      const end = new Date(invitedFilter)
      end.setDate(end.getDate() + 1)
      invitedFilterUrl = `&filters[]=and-candidateAssessment.createdAt-gt-value-${invitedFilter.toISOString()}`
      invitedFilterUrl += `&filters[]=and-candidateAssessment.createdAt-lt-value-${end.toISOString()}`
    }

    let statusFilterUrl = ''
    switch (statusFilter) {
      case 'Sent':
        statusFilterUrl += '&filters[]=and-candidateAssessment.startedAt-bool-value-false'
        statusFilterUrl += '&filters[]=and-candidateAssessment.deletedAt-bool-value-false'
        statusFilterUrl += '&filters[]=and-candidateAssessment.cancelledAt-bool-value-false'
        break
      case 'Deleted':
        statusFilterUrl += '&filters[]=and-candidateAssessment.deletedAt-bool-value-true'
        break
      case 'Cancelled':
        statusFilterUrl += '&filters[]=and-candidateAssessment.cancelledAt-bool-value-true'
        break
      case 'Completed':
        statusFilterUrl += '&filters[]=and-candidateAssessment.completedAt-bool-value-true'
        statusFilterUrl += '&filters[]=and-candidateAssessment.deletedAt-bool-value-false'
        statusFilterUrl += '&filters[]=and-candidateAssessment.cancelledAt-bool-value-false'
        break
      case 'Started':
        statusFilterUrl += '&filters[]=and-candidateAssessment.startedAt-bool-value-true'
        statusFilterUrl += '&filters[]=and-candidateAssessment.completedAt-bool-value-false'
        statusFilterUrl += '&filters[]=and-candidateAssessment.deletedAt-bool-value-false'
        statusFilterUrl += '&filters[]=and-candidateAssessment.cancelledAt-bool-value-false'
        break
      default:
        break
    }

    const pages = getPages(getState())
    const offset = (page - 1) * pages.limit
    const paginationFilterUrl =  `&limit=${pages.limit}&offset=${offset}&count=true`

    return asyncFetch(dispatch, 'GET', `organisations/${userOrganisation.namespace}/candidate-assessments?${searchFilterUrl}${labelFilterUrl}${statusFilterUrl}${invitedFilterUrl}${paginationFilterUrl}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(candidateAssessmentError(response.customErrorCode))
          } else {
            dispatch(setCandidatesAssessments(response.json))
            dispatch(receivePages(response.resultsTotal, page))
          }
        })
  }
}

// LIST
export function listOrganisationCandidatesAssessmentsByAssessment (reset, page, assessment, searchFilter = null, labelFilter = null) {
  return function (dispatch, getState) {
    dispatch(unSetPages())
    if(reset){
      dispatch(unSetCandidatesAssessments())
    }

    const userOrganisation = getUserOrganisation(getState())

    let searchFilterUrl = ''
    if (searchFilter) {
      searchFilterUrl = `&filters[]=or-candidate.firstName-inci-value-${searchFilter}`
      searchFilterUrl += `&filters[]=or-candidate.lastName-inci-value-${searchFilter}`
      searchFilterUrl += `&filters[]=or-assessment.name-inci-value-${searchFilter}`
    }

    let labelFilterUrl = ''
    if (labelFilter) {
      labelFilterUrl = `&filters[]=and-label.name-inci-value-${labelFilter}`
    }

    let statusFilterUrl = ''
    statusFilterUrl += '&filters[]=and-candidateAssessment.completedAt-bool-value-true'
    statusFilterUrl += '&filters[]=and-candidateAssessment.deletedAt-bool-value-false'
    statusFilterUrl += '&filters[]=and-candidateAssessment.cancelledAt-bool-value-false'

    // there is a bug in filtering in-value which requires a non existed value (99) to be the first array option as it is ignored
    let assessmentFilterUrl = '&filters[]=and-assessment.id-in-value-[99,' + assessment + ',' + process.env.REACT_APP_FLOWPROFILER_ID + ']'

    // if not flowprofiler then include these too
    // if (parseInt(assessment) !== parseInt(process.env.REACT_APP_FLOWPROFILER_ID)) {
    //   assessmentFilterUrl += `&filters[]=or-assessment.id-eq-value-${process.env.REACT_APP_FLOWPROFILER_ID}`
    // }

    const pages = getPages(getState())
    const offset = (page - 1) * pages.limit
    const paginationFilterUrl =  `&limit=${pages.limit}&offset=${offset}&count=true`

    return asyncFetch(dispatch, 'GET', `organisations/${userOrganisation.namespace}/candidate-assessments?${searchFilterUrl}${labelFilterUrl}${statusFilterUrl}${assessmentFilterUrl}${paginationFilterUrl}`)
        .then(
            (response) => {
              if (response && response.customErrorCode) {
                // dispatch(candidateAssessmentError(response.customErrorCode))
              } else {
                dispatch(setCandidatesAssessments(response.json))
                dispatch(receivePages(response.resultsTotal, page))
              }
            })
  }
}

export function createTeamReport (teamName, reportType, assessment, anonymise) {
  return function (dispatch, getState) {

    const userOrganisation = getUserOrganisation(getState())
    const candidateAssessments = getCandidatesAssessmentsSelected(getState())

    let data = {
      'teamName': teamName,
      'reportType': reportType,
      'assessment': assessment,
      'anonymise': !!anonymise,
      'candidateAssessments': candidateAssessments
    }

    return asyncFetch(dispatch, 'POST', `organisations/${userOrganisation.namespace}/team-report`, data)
        .then(
            (response) => {
              if (response && response.customErrorCode) {
                // dispatch(candidateAssessmentError(response.customErrorCode))
              } else {
                console.log(response)
                dispatch(setTeamReport(response))
                dispatch(listTeamReportsSaved())
              }
            })
  }
}

export function updateTeamReport (teamReport, teamName, anonymise) {
  return function (dispatch, getState) {

    dispatch(setTeamReport(null))

    const userOrganisation = getUserOrganisation(getState())
    const candidateAssessments = getCandidatesAssessmentsSelected(getState())

    let data = {
      'teamName': teamName,
      'anonymise': !!anonymise,
      'candidateAssessments': candidateAssessments
    }

    return asyncFetch(dispatch, 'PATCH', `organisations/${userOrganisation.namespace}/team-report/${teamReport.id}`, data)
        .then(
            (response) => {
              if (response && response.customErrorCode) {
                // dispatch(candidateAssessmentError(response.customErrorCode))
              } else {
                console.log(response)
                dispatch(setTeamReport(response))
                dispatch(listTeamReportsSaved())
              }
            })
  }
}

export function listTeamReportsSaved () {

  return function (dispatch, getState) {

    const userOrganisation = getUserOrganisation(getState())

    return asyncFetch(dispatch, 'GET', `organisations/${userOrganisation.namespace}/team-reports`)
        .then(
            (response) => {
              if (response && response.customErrorCode) {
                // dispatch(candidateAssessmentError(response.customErrorCode))
              } else {
                console.log(response)
                dispatch(setTeamReportsSaved(response.json))
              }
            })
  }
}

export function readTeamReport (teamReport) {

  return function (dispatch, getState) {

    const userOrganisation = getUserOrganisation(getState())

    return asyncFetch(dispatch, 'GET', `organisations/${userOrganisation.namespace}/team-report/${teamReport.id}`)
        .then(
            (response) => {
              console.log(response)
              if (response && response.customErrorCode) {
                // dispatch(candidateAssessmentError(response.customErrorCode))
              } else {
                console.log(response.candidateAssessments)
                dispatch(setCandidatesAssessmentsSelected(response.candidateAssessments))
                dispatch(setTeamReport(response))
              }
            })
  }
}
