import { asyncFetch } from './apiFetch'
import { receiveUsers, setUser } from 'actions/userAction'
import { createUserPermission } from 'fetchs/userPermissionFetch'
import { addAlertExpiry } from 'actions/uiAction'
import { getPages } from 'reducers/pagesReducer'
import { setUserSelected } from 'actions/userSelectedAction'

// CREATE
export function createUser (user) {
  return function (dispatch) {
    const password = Math.random().toString(36).slice(-8)
    const email = user.primaryEmail.trim()
    const data = {
        'email': email,
        'username': email,
        'password': password,
        'brandName': user.brandName,
        'fromEmail': user.fromEmail,
        'fromName': user.fromName
    }

    // conditionally add so we don't pass empty strings
    if (user.role !== '') {
        data['role'] = user.role
    }

    if (user.firstName !== '') {
      data['firstName'] = user.firstName
    }

    if (user.lastName !== '') {
      data['lastName'] = user.lastName
    }

    return asyncFetch(dispatch, 'POST', `users`, data)
      .then(
        (response) => {
            if (response && response.message) {
              dispatch(addAlertExpiry('warning', response.message, false))
            } else if(response) {
            dispatch(addAlertExpiry('success', `User created and email sent`, false))
            dispatch(createUserPermission(user))
            } else {
              dispatch(addAlertExpiry('warning', `Network error`, false))
            }
        })
  }
}

// READ
export function readUser (user) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'GET', `users/${user}`)
      .then(
        (response) => {
            if (response && response.message) {
                dispatch(addAlertExpiry('warning', response.message, false))
            } else if(response) {
                dispatch(setUser(response))
            } else {
                dispatch(addAlertExpiry('warning', `Network error`, false))
            }
        })
  }
}

// UPDATE
export function updateUser (user) {
  return function (dispatch) {
    const data = {
      // 'firstName': user.firstName,
      // 'lastName': user.lastName,
      // 'email': user.primaryEmail,
      'isActive': user.status === 'Activated' ? 1 : 0,
    }
    return asyncFetch(dispatch, 'PATCH', `users/${user.id}`, data)
      .then(
        (response) => {
            if (response && response.message) {
                dispatch(addAlertExpiry('warning', response.message, false))
            }
        })
  }
}

export function updateUserInfoAPI (user) {
    return function (dispatch) {
        const data = {
            'firstName': user.firstName,
            'lastName': user.lastName,
            'email': user.primaryEmail,
        }
        return asyncFetch(dispatch, 'PATCH', `user`, data)
            .then(
                (response) => {
                    if (response && response.message) {
                        dispatch(addAlertExpiry('warning', response.message, false))
                    } else {
                        dispatch(addAlertExpiry('success', `Personal information updated`, false))
                        dispatch(setUser(response))
                    }
                })
    }
}

export function updateUserPasswordAPI (user) {
    return function (dispatch) {
        const data = {
            'password': user.password,
            'passwordConfirm': user.passwordConfirm,
        }
        return asyncFetch(dispatch, 'PATCH', `user/password`, data)
            .then(
                (response) => {
                    if (response && response.message) {
                        dispatch(addAlertExpiry('warning', response.message, false))
                    } else {
                        dispatch(addAlertExpiry('success', `Password updated`, false))
                        dispatch(setUser(response))
                    }
                })
    }
}

export function updateUserPreferencesAPI (preferenceData) {
  return function (dispatch) {
    const data = {
      preferences: preferenceData,
    }

    return asyncFetch(dispatch, 'PATCH', 'user', data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(createUserError(response.customErrorCode, user))
          } else {
            dispatch(setUser(response))
          }
        })
  }
}

export function activeateOrDeactivateUserFetch (user) {
  return function (dispatch) {
    const slug = user.status === 'Deactivated' ? 'activate' : 'deactivate';
    const status = user.status === 'Deactivated' ? 'Activated' : 'Deactivated';
    return asyncFetch(dispatch, 'PATCH', `users/${user.username}/${slug}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(createUserError(response.customErrorCode, user))
          } else {
            dispatch(listUsers(1));
            user = {...user, status };
            dispatch(setUserSelected(user))
          }
        })
  }
}

export function updateUserLogo (assetData) {
  return function (dispatch) {
    const data = {
      'bgImage': assetData.id
    }
    return asyncFetch(dispatch, 'PATCH', `user`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(createUserError(response.customErrorCode, user))
          } else {
            dispatch(addAlertExpiry('success', 'Background image updated successfully'))
            window.location.reload()
          }
        })
  }
}

export function updateUserStatus (user) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'PATCH', `users/${user.username}/${user.status === 'Activated' ? 'activate' : 'deactivate'}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(createUserError(response.customErrorCode, user))
          }
        })
  }
}

// DELETE

// LIST
export function listUsers (page = 1) {
  return function (dispatch, getState) {

    const deletedAtFilter = '&filters[]=and-user.deletedAt-bool-value-false'
    const { namespace } = getState().userOrganisation;
    const pages = getPages(getState())
    const offset = (page - 1) * pages.limit
    const paginationFilterUrl =  `&limit=${pages.limit}&offset=${offset}&count=true`

    return asyncFetch(dispatch, 'GET', `organisations/${namespace}/users?${deletedAtFilter}${paginationFilterUrl}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // assessmentsError(dispatch, response.customErrorCode)
          } else {
            dispatch(receiveUsers(response.json))
          }
        })
  }
}
