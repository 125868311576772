import fetch from 'isomorphic-fetch'
import { addAlertExpiry } from 'actions/uiAction'
import { updateAuthentication } from 'fetchs/authenticationFetch'
import { needsRefresh, removeToken } from 'actions/authenticateAction'

const apiFetch = (path, method = 'get', data = {}) => {
    const url = process.env.REACT_APP_API_URL + path
    const options = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }

    // add post data
    if (data) {
      options.body = JSON.stringify(data)
    }

    //add token as header
    const auth = JSON.parse(localStorage.getItem('auth'))

    if (auth) {
      options.headers['Authorization'] = `Bearer ${auth.jwtToken}`
    }

    return fetch(url, options)
      .then(response => response)
      .catch(function (error) {
        console.log(error)
      })
  }

  // ajax get method
  export const get = path => apiFetch(path, 'GET', null)

  // ajax post method
  export const post = (path, data) => apiFetch(path, 'POST', data)

  // ajax delete method
  export const remove = (path, data) => apiFetch(path, 'DELETE', data)

  // ajax patch method
  export const patch = (path, data) => apiFetch(path, 'PATCH', data)

  export async function asyncFetch (dispatch, type, path, data) {
    try {
      let fetchRequest = false
      switch (type) {
        case 'GET':
          fetchRequest = get(path, data)
          break
        case 'POST':
          fetchRequest = post(path, data)
          break
        case 'DELETE':
          fetchRequest = remove(path, data)
          break
        case 'PATCH':
          fetchRequest = patch(path, data)
          break
        default:
      }

      const response = await fetchRequest

      if (path !== 'token/refresh' && needsRefresh(5)) {
        dispatch(updateAuthentication())
      }

      let json = false
      if(response.status !== 204){
        json = await response.json()
      }

      const resultsTotal = response.headers.get('X-Results-Total')
      if(resultsTotal){
        return {
          json,
          resultsTotal
        }
      }

      let customErrorCode = false
      if (json && json.code) {
        customErrorCode = json.code
      }

      let message = ''
      if (json && json.message) {
        message = json.message
      }

      switch (customErrorCode) {
        case 401:
          // Not authorised
          dispatch(removeToken())
          dispatch(addAlertExpiry('warning', 'Not authorised'))
          break
        case 4030001:
          // Malformed user token. User token could not be parsed
          dispatch(removeToken())
          // if(path !== 'token/refresh') {
          //   dispatch(addAlertExpiry('warning', 'Not authorised'))
          // }
          // change to more user friendly message
          message = 'Not authorised'
          break
        default:
      }

      if (customErrorCode) {
        return { customErrorCode, message }
      } else {
        return json
      }

    } catch (error) {
      console.log(error)
    }
  }
