// CREATE
import axios from 'axios'
import { addAlertExpiry } from 'actions/uiAction'
import { updateUserLogo } from 'fetchs/userFetch'
import { updateOrganisationLogo } from 'fetchs/organisationBrandFetch'

export function createAsset (type, file) {
  return function (dispatch) {
    const data = new FormData()
    data.append('file', file)
    const auth = JSON.parse(localStorage.getItem('auth'))

    let responseMethod = updateUserLogo
    if(type === 'logo'){
      responseMethod = updateOrganisationLogo
    }

    axios.post(process.env.REACT_APP_API_URL + 'assets', data, {
      headers: { 'Authorization': `Bearer ${auth.jwtToken}` }
    }).then(function(response) {
      dispatch(responseMethod(response.data))
    }).catch(function(error) {
      dispatch(addAlertExpiry('warning', 'Error uploading image'))
    });
  }
}
// READ

// UPDATE

// DELETE

// LIST






