import { receiveCandidate } from 'actions/candidateAction'

export const createCandidateError = (errorCode, candidate) => {
  return function (dispatch) {
    switch (errorCode) {
      case 400:
        dispatch(receiveCandidate('Failed', candidate))
        break
      default:
    }
  }
}

export const updateCandidateError = (errorCode, candidate) => {
  return function (dispatch) {
    switch (errorCode) {
      case 400:
        dispatch(receiveCandidate('Failed', candidate))
        break
      default:
    }
  }
}


