import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { createStructuredSelector } from 'reselect'
import { getModal } from 'reducers/modalReducer'
import { getAssessmentsBySelected } from 'reducers/organisationAssessmentsReducer'
import { getUserOrganisation } from '../../../reducers/userOrganisationReducer'
import { getOrganisationAssessments } from '../../../reducers/organisationAssessmentsReducer'
import {getLeaderSelected} from "../../../reducers/leaderSelectedReducer";
import Table from "../LeadershipAssessmentStatus/Table";
import ChevronRight from "../../../assets/svg/components/ChevronRight";
import TableReviewersConfirm from "../../Table/ReviewersConfirm";
import Field from "../../InputPage/Form/Field";
import DateWrapper from "../../../components/Form/DateWrapper";
import Options from "../LeadershipAssessmentConfirm/Options";
import {getTeamReport} from "../../../reducers/teamReportReducer";
import {getLeadershipReport} from "../../../reducers/leadershipReportReducer"
import {
    createLeadershipAssessment,
    reminderLeadershipAssessmentReview,
    reminderLeadershipAssessmentReviews
} from "../../../fetchs/leadershipAssessmentFetch";
import {replaceModal} from "../../../actions/uiAction";

class LeadershipAssessmentStatus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            copySuccess: false,
        }
    }

    componentDidMount () {
        const {
            leadershipAssessment
        } = this.props;

        console.log(leadershipAssessment)
    }

    handleConfirm = () => {
        if (this.props.candidatesSelected.length) {
            this.props.onAddModal('Leadership Assessment Confirm')
        } else {
            // this.props.onAddAlertExpiry('Please Select a Candidate')
        }
    }

    handleResendReview = (review) => {
        this.props.onResendReview(review)
    }

    handleResendAllReviews = () => {
        this.props.onResendAllReviews(this.props.leadershipAssessment)
    }

    copyLinkToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")
        this.setState({copySuccess: true})
    }

    handleEdit = () => {
        this.props.onAddModal('Edit Leadership Assessment')
    }

    render() {

        const { leadershipAssessment } = this.props

        console.log(this.props)

        return (
            <section>
                {leadershipAssessment ?
                    <div>
                        <h2>Leader: { this.props.leadershipAssessment.candidate.firstName } { this.props.leadershipAssessment.candidate.lastName }</h2>

                        <h2>Reviewers</h2>

                        <Table reviewers={leadershipAssessment.reviewers}
                               onResendReview={(event,review) => this.handleResendReview(event, review)}
                               onResendAllReviews={(event) => this.handleResendAllReviews(event)}
                               modal={true}/>

                        <div className="grid--between grid--middle full-width">

                            <button type={'submit'} className="button button--stretch button--active"
                                    onClick={this.handleEdit}>
                                <span className="padding-right-sm">Edit</span>
                            </button>

                            {leadershipAssessment.status === 'Completed' &&
                                <div style={{display: 'flex'}}>

                                    <button style={{'height':'48px'}} className={this.state.copySuccess ? "button button--stretch button--info margin-left-md" : "button button--stretch button--active margin-left-md"}
                                            onClick={this.copyLinkToClipboard}>
                                        <span className="padding-right-sm">Copy Report Link</span>
                                        <i className="icon"><ChevronRight/></i>
                                    </button>
                                    <textarea
                                        readOnly
                                        style={{position: 'absolute', left: '-9999px', zIndex: '-9999'}}
                                        ref={(textarea) => this.textArea = textarea}
                                        value={`${process.env.REACT_APP_API_URL}leaderships/three-sixty-report/${leadershipAssessment.id}`}
                                    />

                                    <a
                                        className="button button--stretch button--active"
                                        href={`${process.env.REACT_APP_API_URL}leaderships/three-sixty-report/${leadershipAssessment.id}`}
                                        target={'_blank'}
                                    >
                                        <span className="padding-right-sm">Download Report</span>
                                    </a>

                                </div>
                            }

                        </div>


                    </div>
                    : <p>Loading data...</p>
                }


            </section>
        )
    }
}

LeadershipAssessmentStatus.propTypes = {
    modal: PropTypes.array.isRequired,
    assessmentsBySelected: PropTypes.arrayOf(PropTypes.object),
    leaderSelected: PropTypes.object,
    onAddModal: PropTypes.func.isRequired,
    onResendReview: PropTypes.func.isRequired,
    onResendAllReviews: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
    onResendReview: (review) => {
        dispatch(reminderLeadershipAssessmentReview(review))
    },
    onResendAllReviews: (leadershipAssessment) => {
        dispatch(reminderLeadershipAssessmentReviews(leadershipAssessment))
    },
    onAddModal: (modal) => {
        dispatch(replaceModal(modal))
    }
})

const mapStateToPropsSelector = createStructuredSelector({
    modal: getModal,
    assessmentsBySelected: getAssessmentsBySelected,
    userOrganisation: getUserOrganisation,
    organisationAssessments: getOrganisationAssessments,
    leadershipAssessment: getLeadershipReport
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(LeadershipAssessmentStatus)
