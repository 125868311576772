import React from 'react'
import PropTypes from 'prop-types'

const Head = ({ headers }) => (
  <thead className="show-md">
  <tr>
    {headers}
  </tr>
  </thead>
)

Head.propTypes = {
  headers: PropTypes.array.isRequired,
}

export default Head
