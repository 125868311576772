import { SET_TEAM_REPORTS_SAVED } from 'actions/teamReportsSavedAction'

const initialState = null

const teamReportsSaved = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEAM_REPORTS_SAVED:
            return action.teamReports
        default:
            return state
    }
}

export default teamReportsSaved

export const getTeamReportsSaved = state => state.teamReportsSaved
