import { asyncFetch } from './apiFetch'
// import { removeOrganisationCredits } from 'actions/organisationSelectedAction'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'

// CREATE

// READ
export function readOrganisationCredit (organisation = false, successMethod) {
  return function (dispatch, getState) {
    // TODO: Move this to action? Do not want it to be called by transfer tokens
    // dispatch(removeOrganisationCredits())

    let userOrganisation = getUserOrganisation(getState())
    if (organisation) {
      userOrganisation = organisation
    }

    return asyncFetch(dispatch, 'GET', `organisations/${userOrganisation.namespace}/credits`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // organisationAssessmentsError(dispatch, response.customErrorCode)
          } else {
            dispatch(successMethod(response))
          }
        })
  }
}

// UPDATE

// DELETE

// LIST

