import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import TableCandidatesConfirm from 'containers/Table/CandidatesConfirm'
import Options from './Options'
import { createStructuredSelector } from 'reselect'
import { getOrganisationAssessments } from 'reducers/organisationAssessmentsReducer'
import { getAssessmentsSelected } from 'reducers/assessmentsSelectedReducer'
import { listOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import { setAssessmentsSelected } from 'actions/assessmentAction'
import { updateCandidatesAssessments } from 'actions/candidateAssessmentAction'
import { addAlertExpiry } from 'actions/uiAction'

class ModalCandidatesPageMultiple extends Component {
  constructor (props) {
    super(props)
    this.props.onListOrganisationAssessments()
  }

  handleAddAssessment = (assessmentItem) => {
    const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
    assessmentsSelected[assessmentItem.id] = true
    this.props.onSetAssessmentsSelected(assessmentsSelected)
  }

  handleRemoveAssessment = (assessmentItem) => {
    const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
    delete assessmentsSelected[assessmentItem.id]
    this.props.onSetAssessmentsSelected(assessmentsSelected)
  }

  handleConfirm = () => {
    if (Object.keys(this.props.assessmentsSelected).length) {
      this.props.onUpdateCandidatesAssessments()
      this.props.onAddModal('Individual Assessments')
    } else {
      this.props.onAddAlertExpiry('Please Select an Assessment')
    }
  }

  render () {
    const { organisationAssessments, assessmentsSelected } = this.props

    return (
      <section>
        <TableCandidatesConfirm/>
        <div className="padding-top-md">
          {organisationAssessments.length > 0 ? (
            <CardGroup
              cards={organisationAssessments}
              assessmentsSelected={assessmentsSelected}
              handleRemoveAssessment={this.handleRemoveAssessment}
              handleAddAssessment={this.handleAddAssessment}
            />                
          ) : (
            <NoResults/>
          )}
        </div>
        <Options handleConfirm={this.handleConfirm}/>
      </section>
    )
  }
}

ModalCandidatesPageMultiple.propTypes = {
  organisationAssessments: PropTypes.arrayOf(PropTypes.object),
  assessmentsSelected: PropTypes.object.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onListOrganisationAssessments: PropTypes.func.isRequired,
  onSetAssessmentsSelected: PropTypes.func.isRequired,
  onUpdateCandidatesAssessments: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  organisationAssessments: getOrganisationAssessments,
  assessmentsSelected: getAssessmentsSelected,
})

const mapDispatchToProps = (dispatch) => ({
  onListOrganisationAssessments: () => {
    dispatch(listOrganisationAssessments())
  },
  onSetAssessmentsSelected: (assessments) => {
    dispatch(setAssessmentsSelected(assessments))
  },
  onUpdateCandidatesAssessments: () => {
    dispatch(updateCandidatesAssessments())
  },
  onAddAlertExpiry: (message) => {
    dispatch(addAlertExpiry('warning', message))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalCandidatesPageMultiple)
