import React from 'react'
import ChevronRight from 'assets/svg/components/ChevronRight'

const Go = ({ handleConfirm }) => (
  <button onClick={handleConfirm} className="button button--stretch button--active">
    <span className="padding-right-sm">Go</span>
    <i className="icon"><ChevronRight/></i>
  </button>
)

export default Go
