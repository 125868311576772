import React from 'react'
import ChevronRight from 'assets/svg/components/ChevronRight'

const Save = () => (
  <button type={'submit'} className="button button--stretch button--active">
    <span className="padding-right-sm">Save</span>
    <i className="icon"><ChevronRight/></i>
  </button>
)

export default Save
