import React, { Component } from 'react'
import Search from 'components/Filter/Search'
import Select from 'components/Filter/Select'
import Reset from 'components/Filter/Reset'
import SelectOption from 'components/Filter/SelectOption'
import PropTypes from 'prop-types'

class Filter extends Component {
    render () {
        const { labels, searchValue, selectedLabel, onSearchChange, onLabelChange, onReset } = this.props

        const labelOptions = []
        Object.values(labels).forEach((label) => {
            labelOptions.push(<SelectOption key={label.id} value={label.name} title={label.name}/>)
        })

        return (
            <div className="grid--between grid--middle full-width">
                <Search onSearchChange={onSearchChange} searchValue={searchValue}/>
                <Select onItemChange={onLabelChange} selectedItem={selectedLabel} itemOptions={labelOptions}
                        itemName={'Label'}/>
                <Reset onReset={onReset}/>
            </div>
        )
    }
}

Filter.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.object),
    searchValue: PropTypes.string.isRequired,
    selectedLabel: PropTypes.string.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onLabelChange: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
}

export default Filter
