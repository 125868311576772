import React from 'react'

const Privacy = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M10 14h12v10H10z"/>
      <circle cx="16" cy="19" r="1"/>
      <path d="M13 14v-3c0-1.7 1.3-3 3-3s3 1.3 3 3v3"/>
    </g>
  </svg>
)

export default Privacy
