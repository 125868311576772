import React from 'react'
import ChevronRight from 'assets/svg/components/ChevronRight'

const Remove = ({onRemove}) => (
  <button type="button" onClick={onRemove} className="button button--stretch button--error">
    <span className="padding-right-sm">Remove</span>
    <i className="icon"><ChevronRight/></i>
  </button>
)

export default Remove
