import { applyMiddleware, compose, createStore } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history';
import rootReducer from './reducers'

export const history = createBrowserHistory();

const getAuth = () => {
  try {
    return JSON.parse(localStorage.getItem('auth')) || false
  } catch (err) {
    return false
  }
}

const getUserOrganisation = () => {
  try {
    return JSON.parse(localStorage.getItem('userOrganisation')) || {}
  } catch (err) {
    return false
  }
}

let initialState = { auth: getAuth(), userOrganisation: getUserOrganisation() }

const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('reducers', () => {
    const nextRootReducer = require('reducers')
    store.replaceReducer(nextRootReducer)
  })
}

export default store
