import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ isChecked, onChange }) => (
    <input type='checkbox' checked={isChecked} onChange={onChange}/>
)

Checkbox.propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func.isRequired
}

export default Checkbox
