import { ADD_AUTH, REMOVE_AUTH } from '../actions/authenticateAction'

const initialState = false

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AUTH:
      return action.auth
    case REMOVE_AUTH:
      return initialState
    default:
      return state
  }
}

export default auth

export const getAuth = state => state.auth
