import { addAlertExpiry } from 'actions/uiAction'
// CREATE
export const updateUserPasswordError = (errorCode) => {
  return function (dispatch) {
    switch (errorCode) {
      case 4040021:
        // No primary email
        dispatch(addAlertExpiry('warning', 'There is no email associated with this username'))
        break
      case 4040001:
        // User not found. Supplied username or email address is invalid
        dispatch(addAlertExpiry('warning', 'Supplied username or email address is incorrect'))
        break
      case 4290001:
        // API rate limit exceeded for this call
        dispatch(addAlertExpiry('warning', 'Too many attempts, please try again in 24 hours'))
        break
      default:
    }
  }
}

// READ

// UPDATE
export const updatePasswordError = (response) => {
  return function (dispatch) {
    switch (response.customErrorCode) {
      case 4040021:
        // No primary email
        dispatch(addAlertExpiry('warning', 'There is no email associated with this username'))
        break
      case 4040001:
        // User not found. Supplied username or email address is invalid
        dispatch(addAlertExpiry('warning', 'Supplied username or email address is incorrect'))
        break
      case 4000001:
        // API rate limit exceeded for this call
        dispatch(addAlertExpiry('warning', 'Supplied username or email address is incorrect'))
        break
      case 4009101:
        dispatch(addAlertExpiry('warning', 'Password does not meet complexity requirements. Check it is a minimum length of 8, mixed case, includes at least 1 number and 1 special character: . , ! @ £ $ % ^ & * ( ) _ - + = ? / \''))
        break
      case 4009102:
        dispatch(addAlertExpiry('warning', 'Password does not meet complexity requirements. Check it is a minimum length of 8, mixed case, includes at least 1 number and 1 special character: . , ! @ £ $ % ^ & * ( ) _ - + = ? / \''))
        break
      case 4009103:
        dispatch(addAlertExpiry('warning', 'Password does not meet complexity requirements. Check it is a minimum length of 8, mixed case, includes at least 1 number and 1 special character: . , ! @ £ $ % ^ & * ( ) _ - + = ? / \''))
        break
      case 4009104:
        dispatch(addAlertExpiry('warning', 'Password does not meet complexity requirements. Check it is a minimum length of 8, mixed case, includes at least 1 number and 1 special character: . , ! @ £ $ % ^ & * ( ) _ - + = ? / \''))
        break
      case 4009105:
        dispatch(addAlertExpiry('warning', 'Password does not meet complexity requirements. Check it is a minimum length of 8, mixed case, includes at least 1 number and 1 special character: . , ! @ £ $ % ^ & * ( ) _ - + = ? / \''))
        break
      case 4009106:
        dispatch(addAlertExpiry('warning', 'Password failed validation because value must include at least one the special characters: . , ! @ £ $ % ^ & * ( ) _ - + = ? / \''))
        break
      case 4009111:
        dispatch(addAlertExpiry('warning', 'Your passwords do not match. Please re-enter your password.'))
        break
      default:
    }
  }
}

// DELETE

// LIST
