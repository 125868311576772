import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Field from './Field'

class Assessment extends Component {

  constructor(props) {
    super(props)

    this.state = {
      copySuccess: false
    }
  }

  copyLinkToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }

  render () {
    const { candidateAssessment } = this.props

    let status = 'Sent'
    if (candidateAssessment.deletedAt) {
      status = 'Deleted'
    } else if (candidateAssessment.cancelledAt) {
      status = 'Cancelled'
    } else if (candidateAssessment.completedAt) {
      status = 'Completed'
    } else if (candidateAssessment.startedAt) {
      status = 'Started'
    }

    return (
      <div className="padding-top-md text-left">
        <div className="grid">
          <Field itemLabel={'Name'}
                 input={candidateAssessment.assessment.name}/>
          <Field itemLabel={'Status'}
                 input={status}/>
            {status !== 'Completed' &&
            <div className="grid__item w-12-md">
                <div className="grid grid--middle">
                    <div className="grid__item w-12">
                        <label className="label" htmlFor="firstName">
                            <span className="colour-black">Link: </span>
                        </label>
                        <span className=""> {candidateAssessment.assessment.link}</span>
                        <textarea
                            style={{position: 'absolute', left: '-9999px', zIndex: '-9999'}}
                            ref={(textarea) => this.textArea = textarea}
                            value={candidateAssessment.assessment.link}
                        />

                        {
                            this.state.copySuccess ?
                                <a onClick={this.copyLinkToClipboard} className="copy"
                                   style={{marginTop: '24px', marginLeft: '20px', color: 'green'}}>
                                    Copy</a> : <a onClick={this.copyLinkToClipboard} class="copy"
                                                  style={{marginTop: '24px', marginLeft: '20px', color: '#cb561f'}}>
                                    Copy</a>
                        }
                    </div>
                </div>
            </div>
            }

        </div>

      </div>
    )
  }
}

Assessment.propTypes = {
  candidateAssessment: PropTypes.object.isRequired,
}

export default Assessment
