import React, { PureComponent } from 'react';
import arrayMove from 'array-move';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import Card from 'components/CardGroup/Card';

const SortableItem = sortableElement(({ assessmentItem, active, handleAddAssessment, handleRemoveAssessment }) => {
  return <Card
    assessmentItem={assessmentItem}
    active={active}
    modifierClass={assessmentItem.assessment.name.replace('®', '')}
    sortable
    handleClick={() => {
      active ? handleRemoveAssessment(assessmentItem) : handleAddAssessment(assessmentItem)
    }}               
  />
});

const SortableContainer = sortableContainer(({children}) => {
  return <div>{children}</div>;
});

class CardGroup extends PureComponent {
  constructor(props) {
    super(props);

    let newCardOrder = [];
    if (props.tilePreference) {
      props.tilePreference.forEach(cardId => {

        // check if card id in preferences exists (might have been deleted from DB)
        if (props.cards.filter(c => c.id === cardId)[0]) {
          newCardOrder.push(props.cards.filter(c => c.id === cardId)[0]);
        }
      });

      // if no ids match use existing order
      if (newCardOrder.length === 0) {
        newCardOrder = props.cards;
      }
    } else {
      newCardOrder = props.cards;
    }
    this.state = {
      cards: newCardOrder
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { cards } = this.state;
    const newOrder = arrayMove(cards, oldIndex, newIndex);
    this.setState({
      cards: newOrder,
    });
    const newPreferenceObject = { tiles: newOrder.map(item => item.id) };
    this.props.updatedTilePreferences(newPreferenceObject);
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.isSortable && ((nextProps.cards.length !== this.props.cards.length) ||
      (nextProps.tilePreference !== this.props.tilePreference))      
    ) {
      let newCardOrder = [];
      if (nextProps.tilePreference) {
        nextProps.tilePreference.forEach(cardId => {
          newCardOrder.push(nextProps.cards.filter(c => c.id === cardId)[0]);
        });
      } else {
        newCardOrder = nextProps.cards;
      }      
      this.setState({
        cards: newCardOrder
      });
    }
  }

  render() {
    const { cards } = this.state;
    const {
      assessmentsSelected,
      handleRemoveAssessment,
      handleAddAssessment,
      isSortable,
      noSelectionRequired
    } = this.props;

    return (
      <div>
        <div className="padding-bottom-md">
          <div className="card-group">
            {isSortable && cards.length > 0 ? (
              <SortableContainer onSortEnd={this.onSortEnd} axis="xy" distance={1}>
                {cards.map((assessmentItem, index) => (
                  <SortableItem
                    key={assessmentItem.id}
                    index={index}
                    assessmentItem={assessmentItem}
                    active={assessmentItem.id in assessmentsSelected ? 'active' : ''}
                    handleRemoveAssessment={handleRemoveAssessment}
                    handleAddAssessment={handleAddAssessment}
                  />
                ))}
              </SortableContainer>  
            ) : (
              this.props.cards.map(assessmentItem => {
                let active = false;
                if (assessmentsSelected) {
                  active = assessmentItem.id in assessmentsSelected ? 'active' : '';
                }
                return (
                  <Card
                    key={assessmentItem.id}
                    assessmentItem={assessmentItem}
                    active={active}
                    modifierClass={assessmentItem.assessment.name.replace('®', '')}
                    handleClick={() => {
                      if (noSelectionRequired) return;
                      active ? handleRemoveAssessment(assessmentItem) : handleAddAssessment(assessmentItem)
                    }}               
                  />                  
                )})
            )}
          </div>
        </div>
      </div>
    )
  }
}

CardGroup.defaultProps = {
  isSortable: false,
  assessmentsSelected: null,
  noSelectionRequired: false
};


export default CardGroup
