import React, { Component } from 'react'
import Table from './Table'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { listOrganisationDescendants, readOrganisation } from 'fetchs/organisationFetch'
import { addModal } from 'actions/uiAction'
import { addBackground, removeBackground } from 'actions/backgroundAction'
import { setOrganisationSelectedWithCreditsBranding, } from 'actions/organisationSelectedAction'
import { getOrganisations } from 'reducers/organisationsReducer'
import SelectOption from 'components/Filter/SelectOption'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import Pagination from 'components/Pagination'
import { listOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import NewOrganisation from './NewOrganisation'
import { createOrganisation } from 'fetchs/organisationFetch'

class OrganisationsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      organisation: {
        name: '',
        branding: '',
        type: ''
      },
    }
  }

  handleClickOrganisation = (organisation) => {
    this.props.onSetOrganisationSelected(organisation)
    this.props.onAddModal('Organisation')
  }

  componentDidMount () {
    this.props.onListOrganisations(1)
    this.props.onReadOrganisation(this.props.userOrganisation.namespace)
    this.props.onAddBackground()
    this.props.onListOrganisationAssessments()
  }

  componentWillUnmount () {
    this.props.onRemoveBackground()
  }

  handlePageChange = (page) => {
    this.props.onListOrganisations(page, this.state.searchValue, this.state.selectedType)
  }

  handleTextChange = (event, organisationId, field) => {
    const organisation = { ...this.state.organisation }
    organisation[field] = event.target.value

    this.setState({ organisation })
  }

  handleTypeChange = (event) => {
    const organisation = { ...this.state.organisation }
    organisation.type = event.target.value

    this.setState({ organisation })
  }

  handleConfirm = (event) => {
    event.preventDefault()
    this.props.onCreateOrganisation(this.state.organisation)
  }

  render () {
    const { organisations } = this.props
    const { organisation } = this.state
    const typeOptions = []

    let orgType = this.props.userOrganisation.organisation.type;

    if (orgType === 'owner') {
      typeOptions.push(<SelectOption key={1} value={'owner'} title={'Owner'}/>)
    }
    if (['owner'].includes(orgType)) {
      typeOptions.push(<SelectOption key={2} value={'partner'} title={'Partner'}/>)
    }
    if (['owner', 'partner'].includes(orgType)) {
      typeOptions.push(<SelectOption key={3} value={'subpartner'} title={'Sub-Partner'}/>)
    }
    if (['owner', 'partner', 'subpartner'].includes(orgType)) {
      typeOptions.push(<SelectOption key={4} value={'client'} title={'Client'}/>)
    }

    return (
      <section>
        <h2>Organisations</h2>
        <form className="form" onSubmit={this.handleConfirm}>
          <NewOrganisation organisation={organisation} typeOptions={typeOptions}
                   onTextChange={this.handleTextChange} onTypeChange={this.handleTypeChange}
                   handleConfirm={this.handleConfirm}
          />
        </form>
        <Table organisations={organisations}
               onClickOrganisation={this.handleClickOrganisation}/>
        <Pagination onPageChange={this.handlePageChange}/>
      </section>
    )
  }
}

OrganisationsPage.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  userOrganisation: PropTypes.object.isRequired,
  onListOrganisations: PropTypes.func.isRequired,
  onReadOrganisation: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onAddBackground: PropTypes.func.isRequired,
  onRemoveBackground: PropTypes.func.isRequired,
  onSetOrganisationSelected: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  organisations: getOrganisations,
  userOrganisation: getUserOrganisation,
})

const mapDispatchToProps = (dispatch) => ({
  onListOrganisations: (page, searchFilter, typeFilter) => {
    dispatch(listOrganisationDescendants(page, searchFilter, typeFilter))
  },
  onListOrganisationAssessments: () => {
    dispatch(listOrganisationAssessments())
  },
  onCreateOrganisation: (organisation) => {
    dispatch(createOrganisation(organisation))
  },
  onReadOrganisation: (organisation) => {
    dispatch(readOrganisation(organisation))
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
  onAddBackground: () => {
    dispatch(addBackground())
  },
  onRemoveBackground: () => {
    dispatch(removeBackground())
  },
  onSetOrganisationSelected: (organisation) => {
    dispatch(setOrganisationSelectedWithCreditsBranding(organisation))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(OrganisationsPage)
