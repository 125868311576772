import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import Options from './Options'
import User from './User'
import Cta from '../../../components/Cta/Cta';

import { updateUserWithRole, activeateOrDeactivateUser } from 'actions/userAction'
import { getRoles } from 'reducers/rolesReducer'
import { getLabels } from 'reducers/labelsReducer'
import { getUserSelected } from 'reducers/userSelectedReducer'
import SelectOption from 'components/Form/SelectOption'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { getUser } from 'reducers/userReducer'

class ModalUsersPageSingle extends Component {
  constructor (props) {
    super(props)

    const user = this.props.userSelected
    const organisationName = this.props.userOrganisation.namespace

    user.role = null
    if (user.groups[organisationName] && user.groups[organisationName][0]) {
      user.role = user.groups[organisationName][0]
    }

    user.status = 'Deactivated'
    if (user.isActive) {
      user.status = 'Activated'
    }

    this.state = {
      user
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userSelected.status !== this.props.userSelected.status) {
      const { user } = this.state;
      const status = user.status === 'Deactivated' ? 'Activated' : 'Deactivated';
      const clonedUser = Object.assign({}, user, { status }); 
      this.setState({
        user: clonedUser
      })
    }
  }

  handleUpdateUser = (event) => {
    event.preventDefault()
    this.props.onUpdateUser(this.props.userSelected, this.state.user)
  }

  handleTextChange = (event, userId, field) => {
    const user = { ...this.state.user }
    user[field] = event.target.value

    this.setState({ user })
  }

  toggleUserActivation = () => {
    this.props.onActiveateOrDeactivateUser(this.state.user);
  };

  handleRoleChange = (event) => {
    const user = { ...this.state.user }

    user.role = this.props.roles.find(function (role) {
      return role.id === Number(event.target.value)
    })

    this.setState({ user })
  }

  handleStatusChange = (event) => {
    const user = { ...this.state.user }

    user.status = this.props.roles.find(function (role) {
      return role.id === Number(event.target.value)
    })

    this.setState({ user })
  }

  render () {
    const { roles, userOrganisation: { namespace} } = this.props
    const { user } = this.state

    const roleOptions = []
    Object.values(roles).forEach((role) => {
      roleOptions.push(<SelectOption key={role.id} value={role.id} title={role.name}/>)
    })

    const statusOptions = []
    statusOptions.push(<SelectOption key={1} value={'Activated'} title={'Activated'}/>)
    statusOptions.push(<SelectOption key={2} value={'Deactivated'} title={'Deactivated'}/>)

    const canDeleteUser =
      this.props.user.groups[namespace]
        .filter(role =>
          role.name === 'Super Admin' ||
          role.name === 'Partner Admin' ||
          role.name === 'Sub-Partner Admin' ||
          role.name === 'Client Admin'
        )
        .length > 0;

    return (
      <section>
        {Object.keys(user).length &&
        <form className="form" onSubmit={this.handleUpdateUser}>
          <User user={user} onTextChange={this.handleTextChange} onRoleChange={this.handleRoleChange}
                onStatusChange={this.handleStatusChange}
                roleOptions={roleOptions} statusOptions={statusOptions}/>
        </form>
        }
        {canDeleteUser && (
          <div className="grid__item w-12">
            <div className="padding-top-sm">
              <Cta text={`${user.status === 'Deactivated' ? 'Activate' : 'Deactivate'} user`} onClick={this.toggleUserActivation} />
            </div>
          </div>
        )}
        <Options handleConfirm={this.handleUpdateUser}/>
      </section>
    )
  }
}

ModalUsersPageSingle.propTypes = {
  userSelected: PropTypes.object.isRequired,
  roles: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.arrayOf(PropTypes.object),
  userOrganisation: PropTypes.object.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  userSelected: getUserSelected,
  roles: getRoles,
  labels: getLabels,
  userOrganisation: getUserOrganisation,
  user: getUser
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateUser: (oldUser, user) => {
    dispatch(updateUserWithRole(oldUser, user))
  },
  onActiveateOrDeactivateUser: user => {
    dispatch(activeateOrDeactivateUser(user))
  }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalUsersPageSingle)
