import { asyncFetch } from './apiFetch'
import { receiveLabels } from 'actions/labelAction'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { getPages } from 'reducers/pagesReducer'
import { addAlertExpiry, receivePages } from 'actions/uiAction'
// import { updateLabelError } from 'errors/labelError'
import { receiveLabel } from 'actions/labelAction'

// CREATE
export function createLabel (label) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())
    const data = {
      'name': label,
      'organisation': userOrganisation.namespace
    }
    return asyncFetch(dispatch, 'POST', 'labels', data)
      .then(
        (json) => {
          if (json) {
            dispatch(listLabels())
          }
        })
  }
}

export function updateLabel (label) {
  return function (dispatch) {
    const data = {
      'name': label.name,
    }

    return asyncFetch(dispatch, 'PATCH', `labels/${label.id}`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(updateLabelError(response.customErrorCode, label))
          } else {
            dispatch(receiveLabel(label))
            dispatch(addAlertExpiry('success', 'Label details updated successfully'))
          }
        })
  }
}

// READ

// UPDATE

// DELETE
export function deleteLabel (label) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'DELETE', `labels/${label.id}`)
      .then(
        (json) => {
          if (json) {
            dispatch(listLabels())
            dispatch(addAlertExpiry('success', 'Label removed successfully'))
          }
        })
  }
}

// LIST
export function listLabels (page, searchFilter = null) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())
    const organisationFilter = `&filters[]=and-organisation.namespace-eq-value-${userOrganisation.namespace}`

    let searchFilterUrl = ''
    if (searchFilter) {
      searchFilterUrl = `&filters[]=or-label.name-inci-value-${searchFilter}`
    }

    let paginationFilterUrl = ''
    if(page){
      const pages = getPages(getState())
      const offset = (page - 1) * pages.limit
      paginationFilterUrl =  `&limit=${pages.limit}&offset=${offset}&count=true`
    }

    return asyncFetch(dispatch, 'GET', `labels?${organisationFilter}${searchFilterUrl}${paginationFilterUrl}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // organisationAssessmentsError(dispatch, response.customErrorCode)
          } else {
            dispatch(receiveLabels(response.json))
            if(page){
              dispatch(receivePages(response.resultsTotal, page))
            }
          }
        })
  }
}
