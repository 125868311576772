import React from 'react'

const Manage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M16 8c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
      <circle cx="16" cy="14" r="2"/>
    </g>
    <defs>
      <filter id="a" filterUnits="userSpaceOnUse" x="7" y="18" width="18" height="18">
        <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"/>
      </filter>
    </defs>
    <mask maskUnits="userSpaceOnUse" x="7" y="18" width="18" height="18" id="b">
      <circle fill="#FFF" cx="16" cy="16" r="8" filter="url(#a)"/>
    </mask>
    <circle mask="url(#b)" fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
            cx="16" cy="27" r="8"/>
    <circle fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" cx="16" cy="14"
            r="2"/>
  </svg>
)

export default Manage
