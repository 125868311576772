import {RECEIVE_PAGES, UNSET_PAGES} from 'actions/uiAction'

const initialState = {
  limit: 20,
  total: 0,
  current: 0,
  range: 5
}


const pages = (state = initialState, action) => {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case RECEIVE_PAGES:
      newState.total = action.total
      newState.current = action.page
      return newState
    case UNSET_PAGES:
      return initialState
    default:
      return state
  }
}

export default pages

export const getPages = state => state.pages
