import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Logo from 'containers/SitePage/Logo'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { createAsset } from 'fetchs/assetsFetch'
import { getAuth } from 'reducers/authReducer'

class SitesPage extends Component {
  constructor (props) {
    super(props)
    let logoImage = null
    if(this.props.userOrganisation.branding.logo){
      logoImage = this.props.userOrganisation.branding.logo.assetUrl
    }
    this.state = {
      logoFile: null,
      logoImage,
      backgroundFile: null,
      backgroundImage: null
    }
  }

  handleSaveImage = (type, event) => {
    event.preventDefault();
    let file = this.state.backgroundFile
    if(type === 'logo'){
      file = this.state.logoFile
    }
    this.props.onCreateAsset(type, file)
  }

  handleImageChange = (type, event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      if(type === 'logo'){
        this.setState({ logoFile: file, logoImage: reader.result })
      } else {
        this.setState({ backgroundFile: file, backgroundImage: reader.result })
      }
    }

    reader.readAsDataURL(file)
  }

  render () {
    const { logoImage } = this.state

    let logoImageUrl = 'https://via.placeholder.com/150'
    if(logoImage){
      logoImageUrl = logoImage
    }

    // let backgroundImageUrl = 'https://via.placeholder.com/150'
    // if(backgroundImage){
    //   backgroundImageUrl = backgroundImage
    // }

    return (
      <div>
        <Logo logoImageUrl={logoImageUrl} onSaveImage={this.handleSaveImage} onImageChange={this.handleImageChange}/>
        <div className="border margin-top-md margin-bottom-md"></div>
        {/*<BackgroundImage backgroundImageUrl={backgroundImageUrl} onSaveImage={this.handleSaveImage} onImageChange={this.handleImageChange}/>*/}
      </div>
    )
  }
}

SitesPage.propTypes = {

}

const mapStateToPropsSelector = createStructuredSelector({
  userOrganisation: getUserOrganisation,
  auth: getAuth,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateAsset: (type, file) => {
    dispatch(createAsset(type, file))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(SitesPage)
