export const SET_USER_SELECTED = 'SET_USER_SELECTED'

export function setUserSelected (user) {
  return {
    type: SET_USER_SELECTED,
    user
  }
}

// export const REMOVE_USER_SELECTED = 'REMOVE_USER_SELECTED'
// export const ADD_USER_SELECTED = 'ADD_USER_SELECTED'
// export const UNSET_USER_SELECTED = 'UNSET_USER_SELECTED'
// export function unSetUsersSelected () {
//   return {
//     type: UNSET_USER_SELECTED,
//   }
// }
