import React from 'react'

const Send = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M8 8l16 8-16 8 2-6 2-2-2-2zM12 16h3"/>
    </g>
  </svg>
)

export default Send
