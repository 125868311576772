import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { createStructuredSelector } from 'reselect'
import { getModal } from 'reducers/modalReducer'
import Table from './Table'
import {getCandidatesAssessmentsSelected} from "../../../reducers/candidatesAssessmentsSelectedReducer";
import {getTeamReport} from "../../../reducers/teamReportReducer";
import Field from "../TeamReportsPage/Field";
import ChevronDown from 'assets/svg/components/ChevronDown';
import ChevronRight from 'assets/svg/components/ChevronRight';
import {setCandidatesAssessments} from "../../../actions/candidateAssessmentAction";
import {
    listOrganisationCandidatesAssessmentsByAssessment,
    readTeamReport
} from "../../../fetchs/organisationCandidateAssessmentFetch";
import { replaceModal } from 'actions/uiAction'

class ModalTeamReportsDownload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            copySuccess: false,
        }
    }

    componentDidMount () {
        const {
            teamReport
        } = this.props;

        console.log(teamReport)

        if (teamReport && teamReport.assessment) {
            this.props.onSetCandidatesAssessments()
            //this.props.onClearCandidatesAssessmentsSelected()
            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, teamReport.assessment.id, '', '')

            // gets related candidateAssessments and saves to candidateAssessmentsSelected
            this.props.onReadTeamReport(teamReport)
        }
    }

    handleEdit = () => {
        this.props.onAddModal('Edit Team Report')
    }

    copyLinkToClipboard = () => {
        const el = this.textArea
        el.select()
        document.execCommand("copy")
        this.setState({copySuccess: true})
    }

    render() {

        const { teamReport } = this.props

        return (
            <section>
                {teamReport ?
                    <div>
                        <h2>{teamReport.name} {teamReport.anonymise &&
                        <span>(anonymised)</span>
                        }</h2>
                        <Table candidatesAssessments={teamReport.candidateAssessments}
                            modal={true}/>

                        <div className="grid--between grid--middle full-width">

                            <button type={'submit'} className="button button--stretch button--active"
                                    onClick={this.handleEdit}>
                                <span className="padding-right-sm">Edit</span>
                            </button>

                            <div style={{display: 'flex'}}>

                                <button style={{'height':'48px'}} className={this.state.copySuccess ? "button button--stretch button--info margin-left-md" : "button button--stretch button--active margin-left-md"}
                                        onClick={this.copyLinkToClipboard}>
                                    <span className="padding-right-sm">Copy Report Link</span>
                                    <i className="icon"><ChevronRight/></i>
                                </button>
                                <textarea
                                    readOnly
                                    style={{position: 'absolute', left: '-9999px', zIndex: '-9999'}}
                                    ref={(textarea) => this.textArea = textarea}
                                    value={`${process.env.REACT_APP_API_URL}reports/team/${teamReport.id}`}
                                />

                                <a
                                    className="button button--stretch button--active"
                                    href={`${process.env.REACT_APP_API_URL}reports/team/${teamReport.id}`}
                                    target={'_blank'}
                                >
                                    <span className="padding-right-sm">Download Report</span>
                                </a>

                            </div>



                        </div>
                    </div>
                    : <p>Loading data...</p>
                }


            </section>
        )
    }
}

ModalTeamReportsDownload.propTypes = {
    modal: PropTypes.array.isRequired,
    candidatesAssessmentsSelected: PropTypes.array.isRequired,
    onSetCandidatesAssessments: PropTypes.func.isRequired,
    onReadTeamReport: PropTypes.func.isRequired,
    onListOrganisationCandidatesAssessmentsByAssessment: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
    onSetCandidatesAssessments: () => {
        dispatch(setCandidatesAssessments([]))
    },
    onReadTeamReport: (teamReport) => {
        dispatch(readTeamReport(teamReport))
    },
    onListOrganisationCandidatesAssessmentsByAssessment: (reset, page, assessment, searchFilter, labelFilter) => {
        dispatch(listOrganisationCandidatesAssessmentsByAssessment(reset, page, assessment, searchFilter, labelFilter))
    },
    onAddModal: (modal) => {
        dispatch(replaceModal(modal))
    },
})

const mapStateToPropsSelector = createStructuredSelector({
    modal: getModal,
    candidatesAssessmentsSelected: getCandidatesAssessmentsSelected,
    teamReport: getTeamReport
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalTeamReportsDownload)
