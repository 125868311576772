import React, { Component } from 'react'
import Inputs from "./Inputs";
import Add from "./Add";
import Buttons from "../../../InputPage/Form/Buttons";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {createStructuredSelector} from "reselect";
import {getLabels} from "../../../../reducers/labelsReducer";
import {getReviewers} from "../../../../reducers/reviewersReducer";
import {
    addReviewer,
    removeReviewer
} from "../../../../actions/reviewerAction";
import {updateReviewerField} from "../../../../actions/reviewerAction";
import {createLeadershipAssessment} from "../../../../fetchs/leadershipAssessmentFetch";
import {replaceModal} from "../../../../actions/uiAction";
class Form extends Component {

    constructor (props) {
        super(props)

        this.state = {
            idCounter: 0,
        }

    }

    componentDidMount () {
        if (this.props.reviewers && this.props.reviewers.length > 0) {
            Object.values(this.props.reviewers).forEach((reviewer) => {
                this.props.onRemoveReviewer(reviewer)
            })
        }

        let idCounter = this.state.idCounter + 1

        let reviewer = {
            id: idCounter,
            status: 'Pending',
            firstName: '',
            lastName: '',
            email: '',
            relationship: ''
        }
        this.props.onAddReviewer(reviewer)
        this.setState({ idCounter })

        idCounter++
        reviewer = {
            id: idCounter,
            status: 'Pending',
            firstName: '',
            lastName: '',
            email: '',
            relationship: ''
        }
        this.props.onAddReviewer(reviewer)
        this.setState({ idCounter })

        idCounter++
        reviewer = {
            id: idCounter,
            status: 'Pending',
            firstName: '',
            lastName: '',
            email: '',
            relationship: ''
        }
        this.props.onAddReviewer(reviewer)
        this.setState({ idCounter })


    }

    handleTextChange = (event, reviewerId, itemName) => {
        this.props.onUpdateReviewer(reviewerId, itemName, event.target.value)
    }

    handleRelationshipChange = (event, reviewer) => {
        this.props.onUpdateReviewer(reviewer.id, 'relationship', event.target.value)
    }

    handleAddReviewer = () => {

        // brute force clear reviewers from previous input
        if (this.props.reviewers && this.props.reviewers.length > 0) {
            Object.values(this.props.reviewers).forEach((reviewer) => {
                if (reviewer.status !== 'Pending') {
                    this.props.onRemoveReviewer(reviewer)
                }
            })
        }

        const idCounter = this.state.idCounter + 1

        const reviewer = {
            id: idCounter,
            status: 'Pending',
            firstName: '',
            lastName: '',
            email: '',
            relationship: ''
        }
        this.props.onAddReviewer(reviewer)
        this.setState({ idCounter })
    }

    handleDeleteReviewer = (reviewer) => {
        this.props.onRemoveReviewer(reviewer)
    }

    handleConfirm = (event) => {
        event.preventDefault()
        this.props.onAddModal('Confirm')
        // if(!this.props.newReviewers){
        //     this.props.onAddModal('Individual Input')
        // }
    }
    
    render () {
        const { reviewers } = this.props
        console.log(reviewers.length)
        const inputs = []
        if (reviewers.length > 0) {
            Object.values(reviewers).forEach((reviewer) => {

                if (reviewer && reviewer.status === 'Pending') {
                    inputs.push(<Inputs key={reviewer.id} reviewer={reviewer}
                                        onTextChange={this.handleTextChange}
                                        onRelationshipChange={this.handleRelationshipChange}
                                        onDeleteReviewer={this.handleDeleteReviewer}/>)
                }
            })
        }


        console.log(inputs)

        return (
            <form className="form" onSubmit={this.handleConfirm}>
                {inputs}
                <Add onAddReviewer={this.handleAddReviewer}/>
                <Buttons/>
            </form>
        )
    }
}

Form.propTypes = {
    reviewers: PropTypes.arrayOf(PropTypes.object),
    onAddReviewer: PropTypes.func.isRequired,
    onRemoveReviewer: PropTypes.func.isRequired,
    onUpdateReviewer: PropTypes.func.isRequired
}

const mapStateToPropsSelector = createStructuredSelector({
    labels: getLabels,
    reviewers: getReviewers
})

const mapDispatchToProps = (dispatch) => ({
    onAddReviewer: (reviewer) => {
        dispatch(addReviewer(reviewer))
    },
    onRemoveReviewer: (reviewer) => {
        console.log(reviewer)
        dispatch(removeReviewer(reviewer))
    },
    onUpdateReviewer: (reviewerId, inputId, value) => {
        dispatch(updateReviewerField(reviewerId, inputId, value))
    },
    onAddModal: (modal) => {
        dispatch(replaceModal(modal))
    }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Form)
