import React from 'react';
import { object, string, func } from 'prop-types';

const DropdownItem = ({ option, labelSelector, handleChangeOption }) => {
  function handleChange() {
    handleChangeOption(option.id)
  }
  return (
    <div className="dropdown__item">
      <button onClick={handleChange}>{option[labelSelector]}</button>
    </div>
  );
}

DropdownItem.propTypes = {
  option: object.isRequired,
  labelSelector: string.isRequired,
  handleChangeOption: func.isRequired
};

export default DropdownItem;