import React, { Component } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginView from '../Views/LoginView'
import AppView from '../Views/AppView'
import CrossCircle from 'assets/svg/components/CrossCircle'
import '../../App.scss'
import { updateAuthentication } from 'fetchs/authenticationFetch'
import { needsRefresh, removeToken } from 'actions/authenticateAction'
import { getUserOrganisationBrand } from 'actions/userOrganisationAction'
import { addAlertExpiry, removeAlert } from 'actions/uiAction'
import { createStructuredSelector } from 'reselect'
import { getBackground } from 'reducers/backgroundReducer'
import { getAlert } from 'reducers/alertReducer'
import { getAuth } from 'reducers/authReducer'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { getUserOrganisations } from 'reducers/userOrganisationsReducer'
import UserOrganisationsPages from 'containers/UserOrganisationsPage'
import PropTypes from 'prop-types'
import PrivacyPage from 'containers/UserPrivacyPage'
import DualView from 'containers/Views/DualView'
import { readAuthUser } from 'actions/userAction';
import Help from 'containers/templates/help';

class App extends Component {
  constructor (props) {
    super(props)

    const pathArray = this.props.location.pathname.split('/')
    let organisation = pathArray[1]
    if (!organisation || organisation === 'user') {
      organisation = 'default'
    }

    this.props.onGetUserOrganisationBrand(organisation)

    this.state = {
      updateAuthenticationConfirm: false,
    }
  }

  onUpdateAuthentication = () => {
    this.setState({ updateAuthenticationConfirm: false })
    this.props.onUpdateAuthentication()
  }

  componentDidMount () {
    const second = 1000
    const minute = second * 60
    setInterval(() => {
      if (needsRefresh(2)) {
        this.setState({ updateAuthenticationConfirm: true })
      }
    }, minute)

    if (localStorage.getItem('auth')) {
      this.props.onReadAuthUser();
    }
  }

  componentDidUpdate (prevProps, prevState, snapShot) {
    const second = 1000
    if (this.props.alert.expiresAt) {
      setTimeout(() => {
        const date = new Date()
        const timestamp = date.getTime()
        if (timestamp < (this.props.alert.expiresAt + 30 * second)) {
          this.props.onRemoveAlert()
        }
      }, 30 * second)
    }
  }

  render () {
    const { alert, onRemoveAlert, onLogOut, background, auth, userOrganisation, userOrganisations } = this.props
    const { updateAuthenticationConfirm } = this.state
    let branding = userOrganisation.namespace
    if(userOrganisation.branding.name){
      branding = userOrganisation.branding.name
    }



    let userCss = null
    if(auth.user){
      const username = encodeURIComponent(auth.user)
      userCss = (<link rel="stylesheet" href={`${process.env.REACT_APP_API_URL}branding/css/${username}/user.css`}/>)
    }

    const brandCss = <link rel="stylesheet" href={`${process.env.REACT_APP_API_URL}branding/css/${branding}/branding.css`}/>

    let alertBox = null
    switch (alert.type) {
      case 'danger':
        alertBox = (<div className="alert alert--error" role="alert">
          {alert.message}
          <span onClick={onLogOut}>please login again</span>
          <div className="alert__close" onClick={onRemoveAlert}>
            <i className="icon">
              <CrossCircle/>
            </i>
          </div>
        </div>)
        break
      case 'warning':
        alertBox = (<div className="alert alert--warning" role="alert">
          {alert.message}
          <div className="alert__close" onClick={onRemoveAlert}>
            <i className="icon">
              <CrossCircle/>
            </i>
          </div>
        </div>)
        break
      case 'success':
        alertBox = (<div className="alert alert--success" role="alert">
          {alert.message}
          <div className="alert__close" onClick={onRemoveAlert}>
            <i className="icon">
              <CrossCircle/>
            </i>
          </div>
        </div>)
        break
      default:
        alertBox = null
    }

    return (
      <div className={background ? 'background-secondary' : ''}>
        {alertBox}
        {updateAuthenticationConfirm &&
        <div onClick={this.onUpdateAuthentication} className="alert alert--warning" role="alert">
          Please click this box if you are still active to avoid session timeout
          <div className="alert__close" onClick={onRemoveAlert}>
            <i className="icon">
              <CrossCircle/>
            </i>
          </div>
        </div>
        }
        <Switch>
          <Route exact path={`/`} render={() => (
            <Redirect to={`/${branding}/user/login`}/>
          )}/>
          <Route exact path={`/user/*`} render={() => (
            <Redirect to={`/${branding}/user/login`}/>
          )}/>
          <Route exact path={`/default/user/privacy`} render={(props) => (
            <PrivacyPage {...props}/>
          )}/>
          <Route path={`/default/user/help`} render={(props) => (
            <Help {...props}/>
          )}/>          
          <Route exact path={`/default/user/organisations`} render={(props) => (
            <DualView content={<UserOrganisationsPages {...props} userOrganisations={userOrganisations} userOrganisation={userOrganisation} auth={auth}/>}/>
          )}/>
          <Route path={`/${branding}/user`} render={(props) => (
            !auth || !auth.organisation || branding === 'default' ? (
              <LoginView {...props} branding={branding} userOrganisations={userOrganisations} auth={auth}/>
            ) : (
              <Redirect to={`/${branding}/send`}/>
            )
          )}/>
          <Route path={`/${branding}/`} render={(props) => (
            auth && branding === auth.branding ? (
              <AppView {...props} branding={branding}/>
            ) : (
              <Redirect to={`/${branding}/user/login`}/>
            )
          )}/>
        </Switch>
        {userCss}
        {brandCss}
      </div>
    )
  }
}

App.propTypes = {
  auth: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  alert: PropTypes.object.isRequired,
  background: PropTypes.bool.isRequired,
  userOrganisation: PropTypes.object.isRequired,
  userOrganisations: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  onUpdateAuthentication: PropTypes.func.isRequired,
  onRemoveAlert: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
  onLogOut: PropTypes.func.isRequired,
  onGetUserOrganisationBrand: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  auth: getAuth,
  alert: getAlert,
  background: getBackground,
  userOrganisation: getUserOrganisation,
  userOrganisations: getUserOrganisations
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateAuthentication: () => {
    dispatch(updateAuthentication())
  },
  onReadAuthUser: () => {
    dispatch(readAuthUser())
  },
  onRemoveAlert: () => {
    dispatch(removeAlert())
  },
  onAddAlertExpiry: (alert, message) => {
    dispatch(addAlertExpiry(alert, message))
  },
  onLogOut: () => {
    dispatch(removeToken())
  },
  onGetUserOrganisationBrand: (organisation) => {
    dispatch(getUserOrganisationBrand(organisation))
  },
})

export default withRouter(connect(mapStateToPropsSelector, mapDispatchToProps)(App))
