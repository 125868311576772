import React from 'react'
// import ChevronDown from 'assets/svg/components/ChevronDown'
// import ChevronUp from 'assets/svg/components/ChevronUp'
import ChevronRight from 'assets/svg/components/ChevronRight'

const Buttons = () => (
  <div className="text-right">
    {/*<button className="button button--flex-inline button--info margin-bottom-sm">*/}
    {/*<span className="padding-right-sm">CSV Template</span>*/}
    {/*<i className="icon"><ChevronDown/></i>*/}
    {/*</button>*/}
    {/*<button className="button button--flex-inline button--info margin-bottom-sm margin-left-sm">*/}
    {/*<span className="padding-right-sm">Upload CSV</span>*/}
    {/*<i className="icon"><ChevronUp/></i>*/}
    {/*</button>*/}
    <button type={'submit'} className="button button--stretch button--active margin-left-sm">
      <span className="padding-right-sm">Save</span>
      <i className="icon"><ChevronRight/></i>
    </button>
  </div>
)

export default Buttons
