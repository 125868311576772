import React from 'react'
import { Link } from 'react-router-dom'
import ChevronRight from 'assets/svg/components/ChevronRight'
import PropTypes from 'prop-types'

const Form = ({ onSubmitForm, branding }) => (
  <form className="form" onSubmit={onSubmitForm}>
    <h2 className="form__head">Update Password</h2>
    <div className="well">
      Password requirements: minimum length of 8, mixed case, includes at least 1 number and 1 special character: . , !
      @ £ $ % ^ & * ( ) _ - + = ? / \
    </div>
    <div className="form__group">
      <div className="grid grid--flush grid--between">
        <div className="padding-right-sm">
          <label className="label label--bare" htmlFor="password-new">New Password</label>
        </div>
      </div>
      <div className="padding-top-xsm">
        <input autoComplete="new-password" type="password" className="input" name="password-new" id="password-new" required/>
      </div>
    </div>
    <div className="form__group">
      <div className="grid grid--flush grid--between">
        <div className="padding-right-sm">
          <label className="label label--bare" htmlFor="password-confirm">Confirm Password</label>
        </div>
      </div>
      <div className="padding-top-xsm">
        <input autoComplete="new-password" type="password" className="input" name="password-confirm" id="password-confirm" required/>
      </div>
    </div>
    <div className="form__group">
      <div className="grid grid--flush grid--between">
        <Link className="colour-active" to={`/${branding}/user/login`}><b>Back to Login</b></Link>
      </div>
    </div>
    <button className="button button--stretch button--active">
      <span>Go</span>
      <i className="icon"><ChevronRight/></i>
    </button>
  </form>
)

Form.propTypes = {
  branding: PropTypes.string.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
}

export default Form
