export const SET_CANDIDATE_ASSESSMENT_SELECTED = 'SET_CANDIDATE_ASSESSMENT_SELECTED'


export function setCandidateAssessmentSelected (candidateAssessment) {
  return {
    type: SET_CANDIDATE_ASSESSMENT_SELECTED,
    candidateAssessment
  }
}

// export const UNSET_CANDIDATE_ASSESSMENT_SELECTED = 'UNSET_CANDIDATE_ASSESSMENT_SELECTED'
// export function unSetCandidateAssessmentSelected () {
//   return {
//     type: UNSET_CANDIDATE_ASSESSMENT_SELECTED,
//   }
// }
