import React, { PureComponent } from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom'

class HelpTopicItem extends PureComponent {
  render() {
    const { fields } = this.props;

    return (
      <div className="help-topic-item">
        <Link to={`/default/user/help/${fields.slug.fields.slug}`}>{fields.title} <strong>({fields.page.length})</strong></Link>
      </div>
    );
  }
}

HelpTopicItem.propTypes = {
  fields: object.isRequired
};

export default HelpTopicItem;