export const ADD_BACKGROUND = 'ADD_BACKGROUND'
export const REMOVE_BACKGROUND = 'REMOVE_BACKGROUND'

export function addBackground () {
  return {
    type: ADD_BACKGROUND
  }
}

export function removeBackground () {
  return {
    type: REMOVE_BACKGROUND
  }
}
