import React, { PureComponent } from 'react';
import fetchContent from '../../../../fetchs/contentfulFetch';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

class HelpPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      pageContent: undefined
    }
  }
  componentWillMount() {
    const { pathname } = this.props.location;
    const pathnameSplit = pathname.split('/');
    const pageSlug = pathnameSplit[pathnameSplit.length - 2];

    fetchContent({
      content_type: 'page',
      include: 10,
      'fields.slug[match]': pageSlug,      
    })
      .then(resp => {  
        this.setState({
          pageContent: resp.items[0].fields
        });
      })
      .catch(() => {
        this.setState({
          contentfulAPIerror: true
        });
      })
  }  
  render() {
    const { pageContent } = this.state;

    return (
      <div className="help__page">
        {pageContent && (
          <React.Fragment>
            <h1>{pageContent.title}</h1>
            {pageContent.pageModules.map(item => {
              switch(item.sys.contentType.sys.id) {
                case "richContentBlock" :
                  return documentToReactComponents(item.fields.content);
                default:
                  return <span />;                
              }
            })}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default HelpPage;
