import React from 'react'
import PropTypes from 'prop-types'
import CrossCircle from 'assets/svg/components/CrossCircle'

const SelectedAssessment = ({ assessment, handleUnAssignAssessment }) => (
  <div className="grid__item w-6">
    <span onClick={handleUnAssignAssessment}><CrossCircle /></span><label> Assigned Assessment: {assessment.assessment.name}</label>
  </div>
)

SelectedAssessment.propTypes = {
  assessment: PropTypes.object.isRequired,
  handleUnAssignAssessment: PropTypes.func.isRequired,
}

export default SelectedAssessment
