import {
  ADD_ORGANISATION_ASSESSMENTS,
  ADD_ORGANISATION_CREDITS,
  SET_ORGANISATION_SELECTED,
  ADD_ORGANISATION_BRANDING,
  ORGANISATION_UPDATED_CREDITS
} from 'actions/organisationSelectedAction'
import { createSelector } from 'reselect'

const initialState = {
  organisation: {},
  assessments: [],
  credits: false,
  branding:  {},
  updatedCredits: false
}
const organisationSelected = (state = initialState, action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case SET_ORGANISATION_SELECTED:
      return {
        organisation: action.organisation,
        assessments: [],
        credits: false,
        branding:  {},
        updatedCredits: false
      }
    case ADD_ORGANISATION_ASSESSMENTS:
      newState['assessments'] = action.assessments
      return newState
    case ADD_ORGANISATION_CREDITS:
      newState['credits'] = action.credits
      newState['updatedCredits'] = true
      return newState
    case ADD_ORGANISATION_BRANDING:
      newState['branding'] = action.branding
      return newState
    case ORGANISATION_UPDATED_CREDITS:
      newState['updatedCredits'] = false
      return newState
    default:
      return state
  }
}

export default organisationSelected

export const getOrganisationSelected = state => state.organisationSelected

export const getAssessmentsBySpecificToken = createSelector(
  getOrganisationSelected,
  (organisationSelected) => {
    return organisationSelected.assessments.filter(function (assessment) {
      return assessment.tokenType === 'specific'
    })
  }
)

export const getAssessmentsBySharedToken = createSelector(
  getOrganisationSelected,
  (organisationSelected) => {
    return organisationSelected.assessments.filter(function (assessment) {
      return assessment.tokenType === 'shared'
    })
  }
)

export const getAssessmentsByUnlimitedToken = createSelector(
  getOrganisationSelected,
  (organisationSelected) => {
    console.log(organisationSelected)
    return organisationSelected.assessments.filter(function (assessment) {
      return assessment.tokenType === 'unlimited'
    })
  }
)

