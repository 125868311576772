import React from 'react'
import PropTypes from 'prop-types'
import Field from './Field'
import {formatLabels} from 'actions/labelAction'

const Candidate = ({ candidate }) => (
  <div className="padding-top-md text-left">
    <div className="grid">
      <Field itemLabel={'First Name'}
             input={candidate.firstName}/>
      <Field itemLabel={'Last Name'}
             input={candidate.lastName}/>
      <Field itemLabel={'Email'}
             input={candidate.email}/>
      <Field itemLabel={'Labels'}
             input={formatLabels(candidate.labels)}/>
    </div>
  </div>
)

Candidate.propTypes = {
  candidate: PropTypes.object.isRequired,
}

export default Candidate
