import React, { Component } from 'react'
import Search from 'components/Filter/Search'
import Reset from 'components/Filter/Reset'
import PropTypes from 'prop-types'

class Filter extends Component {
  render () {
    const { searchValue, onSearchChange, onReset } = this.props

    return (
      <div className="grid--between grid--middle full-width">
        <Search onSearchChange={onSearchChange} searchValue={searchValue}/>
        <Reset onReset={onReset}/>
      </div>
    )
  }
}

Filter.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
}

export default Filter
