export const START_DATE = 'START_DATE'
export const CURRENT_DATE = 'CURRENT_DATE'

export function startDate (startDate) {
  return {
    type: START_DATE,
    startDate
  }
}

export function currentDate (date) {
  return {
    type: CURRENT_DATE,
    date
  }
}
