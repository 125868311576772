import React from 'react'

const Tick = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31.3" height="31.3">
    <path fill="#005A6D" d="M15.7 30.7c-8.3 0-15-6.8-15-15 0-8.3 6.8-15 15-15 8.3 0 15 6.8 15 15s-6.8 15-15 15z"/>
    <path fill="#FFF" d="M20.6 11l-7.2 7.2-2.6-2.6-1.2 1.2 2.6 2.6 1.2 1.3 8.5-8.4z"/>
  </svg>
)

export default Tick
