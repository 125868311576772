import { receiveCandidateAssessment, setCandidatesAssessments } from 'actions/candidateAssessmentAction'
import { candidateAssessmentError } from 'errors/candidateAssessmentError'
import { asyncFetch } from './apiFetch'
import { addAlertExpiry } from 'actions/uiAction'

// CREATE
export function createCandidateAssessment (candidateAssessment, expiresAt = null) {
  return function (dispatch) {

      let data = {
          'o_assessment': candidateAssessment.assessment.id
      }
      if (expiresAt && expiresAt != '') {
          data = {
            'o_assessment': candidateAssessment.assessment.id,
            'expiresAt': expiresAt
        }
      }

    return asyncFetch(dispatch, 'POST', `candidates/${candidateAssessment.candidate.id}/assessments`, data).then(
      (response) => {
        if (response && response.customErrorCode) {
          dispatch(candidateAssessmentError(response.customErrorCode, candidateAssessment))
        } else {
          dispatch(receiveCandidateAssessment('Sent', candidateAssessment))
        }
      })
  }
}

export function reminderCandidateAssessment (candidateAssessment, expiresAt = null) {
  return function (dispatch) {

      let data = {}
      if (expiresAt && expiresAt != '') {
          data = {
              'expiresAt': expiresAt
          }
      }

    return asyncFetch(dispatch, 'POST', `candidates/${candidateAssessment.candidate.id}/assessments/${candidateAssessment.id}/reminders`, data).then(
      (response) => {
          console.log(response)
          if (response && response.customErrorCode === 403) {
              dispatch(addAlertExpiry('warning', response.message))
          }
        else if (response && response.customErrorCode) {
          // dispatch(candidateAssessmentError(response.customErrorCode, response))
        } else {
          dispatch(addAlertExpiry('success', 'Reminder Sent'))
          // dispatch(setCandidatesAssessments(response))
        }
      })
  }
}

// READ
export function readCandidateAssessment (candidate) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'GET', `candidates/${candidate.id}/assessments`).then(
      (response) => {
        if (response && response.customErrorCode) {
          // dispatch(candidateAssessmentError(response.customErrorCode, response))
        } else {
          dispatch(setCandidatesAssessments(response.json))
        }
      })
  }
}

// UPDATE

// DELETE

// LIST
