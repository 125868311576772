import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { createAsset } from 'fetchs/assetsFetch'
import { updateUserInfo, updateUserPassword, readAuthUser } from 'actions/userAction'
import { getUser } from 'reducers/userReducer'
import UserInfo from "../AccountPage/UserInfo";
import UserPassword from "../AccountPage/UserPassword";

class SitesPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      backgroundFile: null,
      backgroundImage: 'https://via.placeholder.com/150',
      imageIsUpdate: false,
      user: {
        firstName: '',
        lastName: '',
        primaryEmail: '',
        password: '',
        passwordConfirm: '',
      },
    }
    this.props.onReadUser()
  }

  // static getDerivedStateFromProps(nextProps, prevState){
  //   if(nextProps.user!==prevState.user){
  //     console.log('diff')
  //     return { user: nextProps.user};
  //   }
  //   else return null;
  // }

  componentWillMount = async () => {
    let user = await this.props.user;
    this.setState({ user: user })
  }

  handleConfirm = (event) => {
    event.preventDefault()
    this.props.onUpdateUserInfo(this.state.user)
  }

  handleConfirmPassword = (event) => {
    event.preventDefault()
    this.props.onUpdateUserPassword(this.state.user)
  }

  handleTextChange = (event, userId, field) => {
    const user = { ...this.state.user }
    user[field] = event.target.value

    this.setState({ user })
  }

  handleSaveImage = (type, event) => {
    event.preventDefault();
    let file = this.state.backgroundFile
    this.props.onCreateAsset(type, file)
  }

  handleImageChange = (type, event) => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({ backgroundFile: file, backgroundImage: reader.result, imageIsUpdate: true })
    }

    reader.readAsDataURL(file)
  }

  renderForm() {

    return (
        <UserInfo user={this.state.user}
                  onTextChange={this.handleTextChange}
                  handleConfirm={this.handleConfirm}
        />
    )
  }

  renderPasswordForm() {
    return (
        <UserPassword user={this.state.user}
                      onTextChange={this.handleTextChange}
                      handleConfirm={this.handleConfirm}
        />
    )
  }

  render () {
    return (
      <div>
        <section>
          <h2>Update personal information</h2>
          <form className="form" onSubmit={this.handleConfirm}>
            {this.renderForm()}
          </form>
          <h2>Update password</h2>
          <form className="form" onSubmit={this.handleConfirmPassword}>
            {this.renderPasswordForm()}
          </form>
        </section>
      </div>
    )
  }
}

SitesPage.propTypes = {

}

const mapStateToPropsSelector = createStructuredSelector({
  userOrganisation: getUserOrganisation,
  user: getUser
})

const mapDispatchToProps = (dispatch) => ({
  onCreateAsset: (type, file) => {
    dispatch(createAsset(type, file))
  },
  onReadUser: () => {
    dispatch(readAuthUser())
  },
  onUpdateUserInfo: (user) => {
    dispatch(updateUserInfo(user))
  },
  onUpdateUserPassword: (user) => {
    dispatch(updateUserPassword(user))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(SitesPage)
