import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCandidatesSelected } from 'reducers/candidatesSelectedReducer'
import { getCandidates } from 'reducers/candidatesReducer'
import { removeCandidatesSelected, setCandidateSelected } from 'actions/candidateSelectedAction'
import { addModal } from 'actions/uiAction'
import CellLink from 'components/Table/CellLink'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import {formatLabels} from 'actions/labelAction'

class TableCandidatesConfirm extends Component {
  handleRemoveCandidate = (candidate) => {
    this.props.onRemoveCandidatesSelected(candidate)
  }

  handleClickCandidate = (candidate) => {
    this.props.onSetCandidateSelected(candidate)
    this.props.onAddModal('Individual')
  }

  render () {
    const { candidatesSelected } = this.props

    let candidateList = candidatesSelected

    const headers = []
    let key = 0
    headers.push(<Header key={++key} header='First'/>)
    headers.push(<Header key={++key} header='Last'/>)
    headers.push(<Header key={++key} header='Email'/>)
    headers.push(<Header key={++key} header='Label'/>)
    headers.push(<Header key={++key} header='Remove'/>)

    let tableBody = <NoResults colSpan={5}/>
    const body = []

    if (Object.values(candidateList).length) {
      Object.values(candidateList).forEach((candidate) => {

        const row = []
        row.push(<CellText header={'First'} value={candidate.firstName} key={++key}/>)
        row.push(<CellText header={'Last'} value={candidate.lastName} key={++key}/>)
        row.push(<CellText header={'Email'} value={candidate.email} key={++key}/>)
        row.push(<CellText header={'Label'} value={formatLabels(candidate.labels)} key={++key}/>)
        if (candidatesSelected.length) {
          row.push(<CellLink header={'Remove'} onClick={() => this.handleRemoveCandidate(candidate)} value={'Remove'}
                             key={++key}/>)
        }
        body.push(<Row key={++key} row={row}/>)
      })
      tableBody = (<tbody>{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

TableCandidatesConfirm.propTypes = {
  candidatesSelected: PropTypes.arrayOf(PropTypes.object),
  candidates: PropTypes.arrayOf(PropTypes.object),
  onRemoveCandidatesSelected: PropTypes.func.isRequired,
  onSetCandidateSelected: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  candidatesSelected: getCandidatesSelected,
  candidates: getCandidates,
})

const mapDispatchToProps = (dispatch) => ({
  onRemoveCandidatesSelected: (candidate) => {
    dispatch(removeCandidatesSelected(candidate))
  },
  onSetCandidateSelected: (candidate) => {
    dispatch(setCandidateSelected(candidate))
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(TableCandidatesConfirm)
