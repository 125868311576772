import {
    SET_LEADERSHIP_ASSESSMENT_STATUS,
    SET_LEADERSHIPS_ASSESSMENTS,
    UNSET_LEADERSHIPS_ASSESSMENTS
} from 'actions/leadershipAssessmentAction'
import { getOrganisationAssessments, getAssessmentsBySelected } from './organisationAssessmentsReducer'
import { getCandidatesSelected } from './candidatesSelectedReducer'
import { getCandidateSelected } from './candidateSelectedReducer'
import { createSelector } from 'reselect'

const initialState = []

const leadershipsAssessments = (state = initialState, action) => {
    const leadershipsAssessments = Object.assign([], state)
    switch (action.type) {
        case SET_LEADERSHIPS_ASSESSMENTS:
            return action.leadershipsAssessments
        case UNSET_LEADERSHIPS_ASSESSMENTS:
            return initialState
        case SET_LEADERSHIP_ASSESSMENT_STATUS:
            leadershipsAssessments.map(function (leadershipAssessment) {
                if (leadershipAssessment.leadership.id === action.leadershipAssessment.leadership.id &&
                    leadershipAssessment.assessment.id === action.leadershipAssessment.assessment.id) {
                    leadershipAssessment['status'] = action.status
                }
                return leadershipAssessment
            })
            return leadershipsAssessments
        default:
            return state
    }
}

export default leadershipsAssessments

export const getLeadershipsAssessments = state => state.leadershipsAssessments

export const calculateLeadershipsAssessments = createSelector(
    getAssessmentsBySelected,
    getCandidatesSelected,
    (assessmentsBySelected, leadershipsSelected) => {
        return leadershipAssessmentsArray(assessmentsBySelected, leadershipsSelected)
    }
)

export const calculateLeadershipAssessments = createSelector(
    getAssessmentsBySelected,
    getCandidateSelected,
    (assessmentsBySelected, leadership) => {
        return leadershipAssessmentsArray(assessmentsBySelected, [leadership])
    }
)

export function leadershipAssessmentsArray (assessments, leaderships) {
    let leadershipsAssessments = []
    Object.values(leaderships).forEach(function (leadership) {
        Object.values(assessments).forEach(function (assessment) {
            leadershipsAssessments.push({ leadership, assessment, status: 'Pending' })
        })
    })
    return leadershipsAssessments
}

export const getAssessmentsByStatus = createSelector(
    getLeadershipsAssessments,
    getOrganisationAssessments,
    (leadershipsAssessments, organisationAssessments) => {
        return organisationAssessments.map(function (assessment) {
            assessment['status'] = getStatus(assessment.assessment.id, leadershipsAssessments)
            return assessment
        })
    }
)

export function getStatus (assessmentId, leadershipsAssessments) {
    let status = 'available'
    leadershipsAssessments.forEach((leadershipAssessment) => {
        if (assessmentId === leadershipAssessment.assessment.id && leadershipAssessment.completedAt && !leadershipAssessment.deletedAt && !leadershipAssessment.cancelledAt) {
            status = 'completed'
        } else if (assessmentId === leadershipAssessment.assessment.id && !leadershipAssessment.deletedAt && !leadershipAssessment.cancelledAt) {
            status = 'sent'
        }
    })

    return status
}
