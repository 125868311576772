import { calculateCandidateAssessments, calculateCandidatesAssessments, expiresAt } from 'reducers/candidatesAssessmentsReducer'
import { createCandidateAssessment } from 'fetchs/candidateAssessmentFetch'

export const SET_CANDIDATES_ASSESSMENTS = 'SET_CANDIDATES_ASSESSMENTS'
export const UNSET_CANDIDATES_ASSESSMENTS = 'UNSET_CANDIDATES_ASSESSMENTS'
export const SET_CANDIDATE_ASSESSMENT_STATUS = 'SET_CANDIDATE_ASSESSMENT_STATUS'

export function updateCandidatesAssessments (expiresAt = null) {
  return function (dispatch, getState) {
    const candidatesAssessments = calculateCandidatesAssessments(getState())
    dispatch(sendCandidateAssessmentToFetch(candidatesAssessments, expiresAt))
  }
}

export function updateCandidateAssessments (expiresAt = null) {
  return function (dispatch, getState) {
    const candidatesAssessments = calculateCandidateAssessments(getState())
    dispatch(sendCandidateAssessmentToFetch(candidatesAssessments, expiresAt))
  }
}

function sendCandidateAssessmentToFetch (candidatesAssessments, expiresAt) {
  return function (dispatch) {
    dispatch(setCandidatesAssessments(candidatesAssessments),
      candidatesAssessments.forEach((candidateAssessment) => {
        dispatch(createCandidateAssessment(candidateAssessment, expiresAt))
      })
    )
  }
}

// ACTION CREATORS
export function setCandidatesAssessments (candidatesAssessments) {
  return {
    type: SET_CANDIDATES_ASSESSMENTS,
    candidatesAssessments
  }
}

export function unSetCandidatesAssessments () {
  return {
    type: UNSET_CANDIDATES_ASSESSMENTS,
  }
}

export function receiveCandidateAssessment (status, candidateAssessment) {
  return {
    type: SET_CANDIDATE_ASSESSMENT_STATUS,
    candidateAssessment,
    status
  }
}
