import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import Tabs from './Tabs'
import NewCandidatesForm from './Form'
import UploadCandidates from './UploadCandidates'
import TableLeaderSelect from 'containers/Table/LeaderSelect'
import Options from './Options'
import { createStructuredSelector } from 'reselect'
import { getModal } from 'reducers/modalReducer'
import { getAssessmentsBySelected } from 'reducers/organisationAssessmentsReducer'
import { unSetLeader } from 'actions/leaderAction'
import { getLeaderSelected } from 'reducers/leaderSelectedReducer'
import {addActiveTab, addAlertExpiry, replaceModal} from 'actions/uiAction'
import { getActiveTab } from 'reducers/activeTabReducer'
import Credits from "../../../components/Credits";
import { setUserOrganisationWithCredits } from '../../../actions/userOrganisationAction';
import { getUserOrganisation } from '../../../reducers/userOrganisationReducer'
import { getOrganisationAssessments } from '../../../reducers/organisationAssessmentsReducer'
import {setAssessmentsSelected} from "../../../actions/assessmentAction";
import {addModal} from "actions/uiAction";
import {updateCandidatesAssessments} from "../../../actions/candidateAssessmentAction";
import {listOrganisationAssessments} from "../../../fetchs/OrganisationAssessmentFetch";
import {getAssessmentsSelected} from "../../../reducers/assessmentsSelectedReducer";
import {listCandidates} from "../../../fetchs/candidateFetch";

class LeadershipAssessment extends Component {
    constructor(props) {
        super(props)

        this.props.onUnSetLeader()

        this.state = {
            credits: [],
        }
    }

    componentDidMount () {
        this.props.onListOrganisationAssessments()

        let candidateAssessment = null
        let organisationAssessments = this.props.organisationAssessments
        Object.keys(organisationAssessments).forEach(function(key) {
            let organisationAssessment = organisationAssessments[key];

            if (organisationAssessment.tokenType !== 'team' && organisationAssessment.assessment.slug === 'flowprofilernorm') {
                candidateAssessment = organisationAssessments[key]

            }
        });
        if (candidateAssessment) {
            const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
            assessmentsSelected[candidateAssessment.id] = true
            this.props.onSetAssessmentsSelected(assessmentsSelected)
        }
    }

    handleActiveTab = (activeTab) => {
        this.props.onAddActiveTab(activeTab)
    }

    handleUploadCandidates = () => {
        this.props.onAddActiveTab('Existing')
    }

    handleConfirm = () => {
        if (this.props.leaderSelected.id) {
            this.props.onAddModal('Create Reviewers')
        } else {
            this.props.onAddAlertExpiry('Please Select a Leader')
        }
    }

    render() {
        const { assessmentsBySelected, activeTab, organisationAssessments } = this.props

        let content, optionButton;
        switch (activeTab) {
            case 'New':
                content = <NewCandidatesForm newCandidates={true}/>
                optionButton = null
                break
            case 'Credit Balance':
                content = <Credits
                    credits={this.props.userOrganisation.credits}
                    organisationAssessments={organisationAssessments}
                    organisationName={this.props.userOrganisation.organisation.parent ? this.props.userOrganisation.organisation.parent.name : null}
                    organisationUrl={this.props.userOrganisation.organisation.parent ? this.props.userOrganisation.organisation.parent.url : null}
                />
                optionButton = null
                break
            default:
                content = <TableLeaderSelect modal={true}/>
                optionButton = <Options title={'Confirm'} onClick={this.handleConfirm}/>
                break
        }

        return (
            <section>
                {/*<div className="padding-top-md">*/}
                {/*    {assessmentsBySelected.length > 0 ? (*/}
                {/*        <CardGroup*/}
                {/*            cards={assessmentsBySelected}*/}
                {/*            noSelectionRequired*/}
                {/*        />*/}
                {/*    ) : (*/}
                {/*        <NoResults/>*/}
                {/*    )}*/}
                {/*</div>*/}
                <Tabs activeTab={activeTab} handleActiveTab={this.handleActiveTab}/>
                {content}
                {optionButton}
            </section>
        )
    }
}

LeadershipAssessment.propTypes = {
    modal: PropTypes.array.isRequired,
    assessmentsSelected: PropTypes.object.isRequired,
    assessmentsBySelected: PropTypes.arrayOf(PropTypes.object),
    leaderSelected: PropTypes.object,
    onAddModal: PropTypes.func.isRequired,
    onUnSetLeader: PropTypes.func.isRequired,
    onAddAlertExpiry: PropTypes.func.isRequired,
    onAddActiveTab: PropTypes.func.isRequired,
    onUpdateCandidatesAssessments: PropTypes.func.isRequired,
    organisationAssessments: PropTypes.arrayOf(PropTypes.object),
    onListOrganisationAssessments: PropTypes.func.isRequired,
    onSetAssessmentsSelected: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    onUnSetLeader: () => {
        dispatch(unSetLeader())
    },
    onAddAlertExpiry: (message) => {
        dispatch(addAlertExpiry('warning', message))
    },
    onAddActiveTab: (activeTab) => {
        dispatch(addActiveTab(activeTab))
    },
    onAddModal: (modal) => {
        dispatch(addModal(modal))
    },
    onUpdateCandidatesAssessments: () => {
        dispatch(updateCandidatesAssessments())
    },
    onListOrganisationAssessments: () => {
        dispatch(listOrganisationAssessments())
    },
    onSetAssessmentsSelected: (assessments) => {
        dispatch(setAssessmentsSelected(assessments))
    }
})

const mapStateToPropsSelector = createStructuredSelector({
    modal: getModal,
    assessmentsSelected: getAssessmentsSelected,
    assessmentsBySelected: getAssessmentsBySelected,
    leaderSelected: getLeaderSelected,
    activeTab: getActiveTab,
    userOrganisation: getUserOrganisation,
    organisationAssessments: getOrganisationAssessments
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(LeadershipAssessment)
