import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Form/Select'
import Text from 'components/Form/Text'
import Field from './Field'
import ChevronRight from 'assets/svg/components/ChevronRight'

const NewUser = ({ user, onTextChange, onRoleChange, roleOptions }) => (
  <div className="card card--stretch">
    <div className="grid">
      <Field required={true} input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.firstName}
                                          placeholder={'First Name'}
                                          itemName={'firstName'}/>}/>
      <Field required={true}
             input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.lastName}
                          placeholder={'Last Name'}
                          itemName={'lastName'}/>}/>
      <Field required={true}
             input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.primaryEmail}
                          placeholder={'Email'}
                          itemName={'primaryEmail'}/>}/>
      <Field required={true}
             input={<Select parentId={user.id} onItemChange={onRoleChange} selectedItem={user.role.id}
                            itemOptions={roleOptions} itemName={'Role'} required={true} />}/>
      <Field input={<button type={'submit'} className="button button--stretch button--active">
        <span className="padding-right-sm">Save</span>
        <i className="icon"><ChevronRight/></i>
      </button>}/>
    </div>
  </div>
)

NewUser.propTypes = {
  user: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  roleOptions: PropTypes.arrayOf(PropTypes.object),
}

export default NewUser
