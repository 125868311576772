import React from 'react'
import FlowProfilerLogo from 'assets/svg/components/FlowProfilerLogo'

const PrivacyPage = () => (
      <div className="logged-out-page">
        <div className="privacy">
          <div class="help__banner"><h1>Privacy Policy</h1></div>
          <h2 className="privacy__header">DATA PROTECTION POLICY</h2>
          <div className="privacy__content">
            <section>
              <h3>INTRODUCTION</h3>
              <p>The purpose of this document is to provide a concise policy regarding the data protection obligations of flowprofiler® Global Ltd. </p>
              <p>flowprofiler® Global Ltd is a data controller in respect of personal data of job applicants, employees, contractors, interns, apprentices and former employees, referred to as HR-related personal data.</p>
              <p>flowprofiler® Global Ltd is a data processor for Clients who are Data Controllers in respect of the personal data they collect. A Data Controller – Data Processor contract will be in place for each data controller that flowprofiler® Global Ltd processes data for.</p>
              <p>Employees/clients of flowprofiler® Global Ltd should refer to the guidance provided by the Information Commissioner’s Office (https://ico.org.uk) as well as seeking professional advice regarding best practice in this area.</p>
            </section>
            <section>
              <h3>RATIONALE</h3>
              <p>As a data controller and processor, flowprofiler® Global Ltd and its staff (hereafter referred-to collectively as flowprofiler® Global Ltd) must comply with the data protection rules set out in the relevant UK legislation. This Policy applies to all personal data collected, processed and stored by flowprofiler® Global Ltd in the course of its activities. </p>
              <p>We process personal information to enable us to provide training to our customers and clients; to promote our services, to maintain our own accounts and records, and to support and manage our employees.</p>
              <p>In its role as an employer, flowprofiler® Global Ltd may keep information relating to a staff member’s physical, physiological or mental well-being, as well as their economic, cultural or social identity.</p>
              <p>Personal data also include a combination of identification elements such as physical characteristics, pseudonyms, occupation, home address, etc.</p>
            </section>
            <section>
              <h3>SCOPE</h3>
              <p>The policy covers both personal and sensitive personal data held and processed in relation to its data subjects, and those of it’s Clients, by flowprofiler® Global Ltd. The policy applies equally to personal data held in manual and automated form. All personal and sensitive personal data will be treated with equal care by flowprofiler® Global Ltd. Both categories will be equally referred to as personal data in this policy, unless specifically stated otherwise.</p>
            </section>
            <section>
              <h3>DEFINITIONS</h3>
              <p>For the avoidance of doubt, and for consistency in terminology, the following definitions apply within this Policy.</p>
            </section>
            <section>
              <h3>DATA</h3>
              <p>This includes both automated and manual data. </p>
              <p>Automated data means data held on computer, or stored with the intention that it is processed on computer. </p>
              <p>Manual data means data that is processed as part of a relevant filing system, or which is stored with the intention that it forms part of a relevant filing system. </p>
            </section>
            <section>
              <h3>Personal Data</h3>
              <p>Information that relates to a living individual, who can be identified either directly from that data, or indirectly in conjunction with other data which is likely to come into the legitimate possession of flowprofiler® Global Ltd.</p>
            </section>
            <section>
              <h3>Sensitive Personal Data	</h3>
              <p>Sensitive personal data is personal data which relates to specific aspects of one’s identity or personality, and includes information relating to ethnic or racial identity, political or ideological beliefs, religious beliefs, trade union membership, mental or physical well-being, sexual orientation, or criminal record.</p>
            </section>
            <section>
              <h3>Data Controller</h3>
              <p>The legal entity responsible for the acquisition, processing and use of the personal data. </p>
              <p>In the context of this policy; flowprofiler® Global Ltd is the data controller in respect of personal data of job applicants, employees, contractors, interns, apprentices and former employees, referred to as HR-related personal data. </p>
              <p>flowprofiler® Global Ltd is a data processor for Clients who are Data Controllers in respect of personal data they collect.</p>
            </section>
            <section>
              <h3>Data Subject</h3>
              <p>A living individual who is the subject of the personal data, i.e. to whom the data relates either directly or indirectly. </p>
            </section>
            <section>
              <h3>Data Processor</h3>
              <p>flowprofiler® Global Ltd is a data processor for Clients who are data controllers in respect of the personal data. </p>
              <p>A person or entity who processes personal data on behalf of flowprofiler® Global Ltd on the basis of a formal, written contract, but who is not an employee of flowprofiler® Global Ltd.</p>
            </section>
            <section>
              <h3>Data Protection Officer	</h3>
              <p>flowprofiler® Global Ltd is not required to appoint a Data Protection Officer according to the ICO Guidelines, however they have appointed a suitable individual, Iain Chalmers, to monitor compliance with the appropriate data protection legislation, to deal with Subject Access Requests, and to respond to data protection queries from staff members and the general public.</p>
            </section>
            <section>
              <h3>flowprofiler® Global Ltd as a Data Controller & Data Processor</h3>
              <p>In the course of its daily organisational activities, flowprofiler® Global Ltd, processes and stores personal data in relation to living individuals. To that extent, flowprofiler® Global Ltd is a data processor and has obligations under the Data Protection legislation, which are reflected in this document. </p>
              <p>In accordance with UK Data Protection legislation, this data must be processed fairly and lawfully. </p>
              <p>flowprofiler® Global Ltd is committed to ensuring that all staff members have sufficient awareness of the legislation in order to be able to anticipate and identify a data protection issue, should one arise. In such circumstances, staff members must ensure that the Data Protection Officer (DPO) is informed, in order that appropriate corrective action is taken. </p>
            </section>
            <section>
              <h3>THIRD-PARTY PROCESSORS (WHERE APPLICABLE)</h3>
              <p>In the course of its role as data controller, flowprofiler® Global Ltd might engage a third-party service provider, or data processor, to process personal data on its behalf. </p>
              <p>In each case, a formal, written contract is in place with the processor, outlining their obligations in relation to the personal data, the security measures that they must have in place to protect the data, the specific purpose or purposes for which they are engaged, and the understanding that they will only process the data in compliance with the UK Data Protection legislation. </p>
              <p>The contract will also include reference to the fact that the data controller is entitled, from time to time, to audit or inspect the data management activities of the data processor, and to ensure that they remain compliant with the legislation, and with the terms of the contract.</p>
            </section>
            <section>
              <h3>THE EIGHT DATA PROTECTION PRINCIPLES</h3>
              <p>The following key principles are enshrined in UK legislation and are fundamental to flowprofiler® Global Ltd’s data protection policy.</p>
              <ol>
                <li>
                  <div><strong>Fair and Lawful</strong>: Personal data is processed fairly and lawfully:</div>
                  <ul>
                    <li>For data to be processed fairly, a data controller must:</li>
                    <li>Have legitimate grounds for collecting and using the personal data;</li>
                    <li>Not use the data in ways that have unjustified adverse effects on the individuals concerned;</li>
                    <li>Be transparent about the intention to use the data, and give individuals appropriate privacy notices when collecting their personal data;</li>
                    <li>Handle people’s personal data only in ways they would reasonably expect; and</li>
                    <li>Ensure they do not do anything unlawful with the data.</li>
                  </ul>
                  <div>flowprofiler® Global Ltd meet this obligation in the following way:</div>
                  <ul>
                    <li>Where possible, the informed consent of the data subject is sought before their data is processed;</li>
                    <li>Where it is not possible to seek consent, flowprofiler® Global Ltd ensure that collection of the data is justified under one of the other lawful processing conditions – legal obligation, contractual necessity, etc.;</li>
                    <li>Processing of the personal data is carried out only as part of flowprofiler® Global Ltd’s lawful activities, and it safeguards the rights and freedoms of the data subject;</li>
                    <li>The data subject’s data is not disclosed to a third party other than to a party contracted to flowprofiler® Global Ltd and operating on its behalf, or where flowprofiler® Global Ltd is required to do so by law.</li>
                  </ul>
                </li>
                <li>
                  <div><strong>Purposes</strong>: Personal data shall be obtained only for one or more specified and lawful purposes, and shall not be further processed in any manner incompatible with that purpose or those purposes:<br /> flowprofiler® Global Ltd meet this obligation in the following way:</div>
                  <ul>
                    <li>flowprofiler® Global Ltd obtain data for purposes which are specific, lawful and clearly stated. </li>
                    <li>A data subject has the right to question the purpose(s) for which flowprofiler® Global Ltd holds their data, and flowprofiler® Global Ltd is able to clearly state that purpose or purposes.</li>
                  </ul>
                </li>
                <li>
                  <div><strong>Adequacy</strong>: Personal data shall be adequate, relevant and not excessive in relation to the purpose or purposes for which they are processed <br />flowprofiler® Global Ltd meet this obligation in the following way:</div>
                  <ul>
                    <li>flowprofiler® Global Ltd ensures that the data it processes in relation to data subjects are relevant to the purposes for which the data are collected.</li>
                    <li>Data which are not relevant to such processing are not acquired or maintained.</li>
                  </ul>
                </li>
                <li>
                  <div><strong>Accuracy</strong>: Personal data shall be accurate and, where necessary, kept up to date. <br /> flowprofiler® Global Ltd meet this obligation in the following way</div>
                  <ul>
                    <li>Ensuring that administrative and IT validation processes are in place to conduct regular assessments of data accuracy;</li>
                    <li>Conducting periodic reviews and audits to ensure that relevant data is kept accurate and up-to-date. flowprofiler® Global Ltd conducts a review of sample data every six months to ensure accuracy; </li>
                    <li>Ensuring that staff contact details and details on next-of-kin are reviewed and updated every two years, or on an ‘ad hoc’ basis where staff members inform the office of such changes;</li>
                    <li>Conducting regular assessments in order to validate the need to keep certain personal data.</li>
                  </ul>
                </li>
                <li>
                  <div><strong>Retention</strong>: Personal data processed for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes<br/>As a data controller, flowprofiler® Global Ltd must:</div>
                  <ul>
                    <li>Review the length of time personal data is retained;</li>
                    <li>Consider the purpose or purposes for holding the information and in deciding whether (and for how long) to retain it;</li>
                    <li>Securely delete information that is no longer needed for this purpose or these purposes; and</li>
                    <li>Update, archive or securely delete information if it goes out of date.</li>
                  </ul>
                  <div>flowprofiler® Global Ltd meet this obligation in the following way:</div>
                  <ul>
                    <li>If data is being retained indefinitely, a justification is provided;</li>
                    <li>Once the respective retention period has elapsed, flowprofiler® Global Ltd undertakes to destroy, erase or otherwise put this data beyond use;</li>
                    <li>Data is destroyed as per the Data Destruction Policy in place at flowprofiler® Global Ltd;</li>
                    <li>Access to, and management of, staff and customer records is limited to those staff members who have appropriate authorisation and password access.</li>
                  </ul>
                </li>
                <li>
                  <div><strong>Rights</strong>: Personal data shall be processed in accordance with the rights of data subjects under this Act.<br />As a Data Controller, flowprofiler® Global Ltd has the following obligation:</div>
                  <ul>
                    <li>A right of access to a copy of the information comprised in their personal data; a right to object to processing that is likely to cause or is causing damage or distress;</li>
                    <li>A right to prevent processing for direct marketing;</li>
                    <li>A right to object to decisions being taken by automated means;</li>
                    <li>A right in certain circumstances to have inaccurate personal data rectified, blocked, erased or destroyed; and</li>
                    <li>A right to claim compensation for damages caused by a breach of the Act.</li>
                  </ul>
                  <div>flowprofiler® Global Ltd meet this obligation in the following way:</div>
                  <ul>
                    <li>A Subject Access Request procedure is in place;</li>
                    <li>A mechanism is in place to capture data subject preferences;</li>
                    <li>If using Direct Marketing, we ensure Opt-ins and Opt outs are as per current data protection legislation;</li>
                    <li>If using Profiling, we ensure the data subject is aware that they are being profiled and have the opportunity to object to such activity;</li>
                    <li>We have mechanisms in place to capture communication from data subjects that refer to amending their personal data;</li>
                    <li>We agree to pay in the instance where compensation has been awarded for breach of the Act.</li>
                  </ul>
                </li>
                <li>
                  <div><strong>Security</strong>: Appropriate technical and organisational measures shall be taken against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data.</div>
                  <ul>
                    <li>flowprofiler® Global Ltd meet this obligation in the following way:</li>
                    <li>flowprofiler® Global Ltd use a risk based approach to security of data. The level of security in place shall commensurate with the level of risk to security of the data; </li>
                    <li>flowprofiler® Global Ltd employ high standards of security in order to protect the personal data under its care;</li>
                    <li>flowprofiler® Global Ltd’s Password Policy and Data & Destruction Policies guarantee protection against unauthorised access to, or alteration, destruction or disclosure of any personal data held by flowprofiler® Global Ltd in its capacity as data controller;</li>
                    <li>In the event of a data security breach affecting the personal data being processed on behalf of the data controller, the relevant third-party processor notifies the data controller without undue delay;</li>
                    <li>Iain Chalmers of flowprofiler® Global Ltd is responsible for ensuring information security.</li>
                  </ul>
                </li>
                <li>
                  <div><strong>International</strong>: Personal data shall not be transferred to a country or territory outside the EEA unless that country or territory ensures an adequate level of protection for the rights and freedoms of data subjects in relation to the processing of personal data. <br/> flowprofiler® Global Ltd meet this obligation in the following way:</div>
                  <ul>
                    <li>Assess whether or not the data can be anonymized prior to transfer;</li>
                    <li>Map the process to clearly establish if the data transits through the non-EEA country or is the data actually processed in the non EEA country;</li>
                    <li>Ensure that there is no personal data whatsoever on the website;</li>
                    <li>Establish if the destination country is on the EU Commission’s list of countries or territories who provide adequate protection for the rights and freedoms of data subjects. Personal data may be shared with country’s on this list; </li>
                    <li>
                      <div>In any case, we undertake to map the transfer process to establish the risks to personal data that may arise. We undertake to mitigate those risks to an acceptable risk level prior to transfer by means of adequate safeguards:</div>
                      <ul>
                        <li>Adequate safeguards include Model Contract Clauses, Binding Corporate Rules, or other contractual arrangements;</li>
                        <li>Where “adequate safeguards” are established, the rights of data subjects continue to be protected even after their data has been transferred outside the EEA.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
            </section>
            <section>
              <h3>IMPLEMENTATION</h3>
              <p>As a data controller and processor, flowprofiler® Global Ltd ensures that any entity which they process personal data for, or processes data for them, does so in a manner compliant with the Data Protection legislation through a formal Data Processor Agreement. </p>
              <p>Regular audit trail monitoring will be done by the suitable individual to ensure compliance with this Agreement by any third-party entity which processes personal data on behalf of flowprofiler® Global Ltd. </p>
              <p>Failure of a data processor to manage flowprofiler® Global Ltd’s data in a compliant manner will be viewed as a breach of contract, and will be pursued through the courts. Failure of flowprofiler® Global Ltd’s staff to process personal data in compliance with this policy may result in disciplinary proceedings.</p>
            </section>

            <h2 className="privacy__header">flowprofiler® Global Ltd – Privacy Notice</h2>
            <section>
            <p>Nature of work - Psychometric Assessment & Training Supplier (Data Processor)</p>
            <p>Contact:</p>
            <div>
              flowprofiler® Global Ltd <br />
              Badgemore House <br />
              Badgemore Park <br />
              Henley-on-Thames <br />
              Oxfordshire <br />
              England <br />
              RG9 4NR
              <p>
              Email: <a href="mailto:hello@flowprofiler.com">hello@flowprofiler.com</a>
              </p>
            </div>
            </section>
            <section>
              <h3>Description of processing</h3>
              <p>The following is a broad description of the way flowprofiler® Global Ltd processes personal information</p>
            </section>
            <section>
              <h3>Reasons/purposes for processing information</h3>
              <p>We process personal information to enable us to provide training to our customers and clients; to promote our services, to maintain our own accounts and records and to support and manage our employees.</p>
              <p></p>
              <p>We are Publisher of multiple assessment tools and process data that the Client (the Data Controller) collect and control.</p>
            </section>
            <section>
              <h3>Type/classes of information processed</h3>
              <div>We process information relevant to the above reasons/purposes. This may include:</div>
              <ul>
                <li>personal details</li>
                <li>business activities of the person whose personal information we are processing</li>
                <li>training details</li>
                <li>goods and services</li>
              </ul>
            </section>
            <section>
              <h3>Who the information is processed about</h3>
              <div>We process personal information about:</div>
              <ul>
                <li>customers</li>
                <li>clients</li>
                <li>trainers</li>
                <li>prospective employees</li>
                <li>employees</li>
                <li>suppliers</li>
                <li>professional advisers and consultants</li>
                <li>complainants, enquirers</li>
              </ul>
            </section>
            <section>
              <h3>Who the information may be shared with</h3>
              <div>We sometimes need to share the personal information we process with the individual themself. Where this is necessary we are required to comply with all aspects of the Data Protection Act (DPA). What follows is a description of the types of organisations we may need to share some of the personal information we process with for one or more reasons.</div>
              <p>Where necessary or required we share information with:</p>
              <ul>
                <li>business associates and other professional advisers</li>
                <li>trainers</li>
                <li>other companies in the same group</li>
              </ul>
            </section>
            <section>
              <h3>Your rights</h3>
              <p>Important: if you have been asked to complete an assessment by a company or organisation, the Data Controller (this can be your employer, potential employer or educational establishment) is responsible for assisting you in exercising your rights. We recommend that you contact them.<br/>As flowprofiler® Global Ltd acts as Data Processor we cannot fulfil your request. We will pass the request to the company or organisation who are acting as the Data Controller.</p>
            </section>
            <section>
              <h3>Rights of access</h3>
              <p>You have the right to ask us for copies of your personal information. Please see our Subject Access Request Policy on our website <a rel="noopener noreferrer" href="https://flowprofiler.com" target="_blank">flowprofiler.com</a> or contact us via email: <a rel="noopener noreferrer" href="mailto:hello@flowprofiler.com">hello@flowprofiler.com</a></p>
            </section>
            <section>
              <h3>Right to rectification</h3>
              <p>You have the right to ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. Please contact us via email: <a rel="noopener noreferrer" href="mailto:hello@flowprofiler.com">hello@flowprofiler.com</a></p>
            </section>
            <section>
              <h3>Right to erasure</h3>
              <p>You have the right to ask us to erase your personal information in certain circumstances.  Please contact us via email <a rel="noopener noreferrer" href="mailto:hello@flowprofiler.com">hello@flowprofiler.com</a></p>
            </section>
            <section>
              <h3>Right to restriction of processing</h3>
              <p>You have the right to ask us to restrict the processing of your information in certain circumstances. Please contact us via email <a rel="noopener noreferrer" href="mailto:hello@flowprofiler.com">hello@flowprofiler.com</a>.</p>
              <p>You are not required to pay any charge for exercising your rights. We have one month to respond to you.</p>
              <p>Please also see our Data Protection Policy and Subject Access Request Policy for further details.</p>
              <p>We work to high standards when it comes to processing your personal information. If you have queries or concerns, please contact us at <a href="mailto:hello@flowprofiler.com">hello@flowprofiler.com</a> and we’ll respond.</p>
            </section>

            <h2 className="privacy__header">COOKIE POLICY – flowprofiler® Global Ltd</h2>
            <section>
              <h3>EFFECTIVE DATE: 12/2/2019</h3>
              <p>This Cookie Policy describes the different types of cookies and similar technologies that may be applied on the flowprofiler® Global Ltd Site and associated subdomains.</p>
              <p>Subdomains are as follows:</p>
              <ul>
                <li><a rel="noopener noreferrer" href="https://flowprofiler.com" target="_blank">flowprofiler.com</a></li>
              </ul>
              <p>flowprofiler® Global Ltd may change this Cookie Policy at any time. Please take a look at the “Effective Date” legend at the top of this page to see when this Cookie Policy was last revised. Any changes in this Cookie Policy will become effective when we make the revised Cookie Policy available on or through the Site.</p>
            </section>
            <section>
              <h3>WHAT IS A COOKIE?</h3>
              <p>Cookies are text files containing small amounts of information which are downloaded to your device when you visit a Site. Cookies are then sent back to the originating Site on each subsequent visit, or to another Site that recognises that cookie. Cookies are useful because they allow a Site to recognise a user’s device. You can find more information about cookies at:</p>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.allaboutcookies.org">www.allaboutcookies.org</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.youronlinechoices.eu">www.youronlinechoices.eu</a></li>
              </ul>
              <p>
              Cookies do lots of different jobs, like letting you navigate between pages efficiently remembering your preferences, and generally improve your website experience. They can also help to ensure that adverts you see online are more relevant to you and your interests.</p>
              <p>Most Sites you visit will use cookies in order to improve your user experience by enabling that Site to ‘remember’ you, either for the duration of your visit (using a ‘session cookie’) or for repeat visits (using a ‘persistent cookie’).</p>
              <p>Cookies may be set by the Site you are visiting (‘first party cookies’) or they may be set by other Sites who run content on the page you are viewing (‘third party cookies’).</p>
            </section>
            <section>
              <h3>COOKIES USED ON THIS SITE</h3>
              <p>flowprofiler® Global Ltd (“us”/”our”/”we”) uses first party and third party cookies. We use cookies in order to make this Site easier to use, to support the provision of information and functionality to you, as well as to provide us with information about how this Site is used so that we can make sure it is as up to date, relevant and error free as we can.</p>
              <p>Further information about the types of cookies that are used on this Site is set out in flowprofiler® Global Ltd’s Data Privacy Statement. To the extent any Personal Information is collected through cookies, the Data Privacy Statement applies and compliments this Cookie Policy</p>
              <p>By continuing to browse the Site you are agreeing to our use of cookies and the cookies notice at the top of your browser will disappear. If you block our use of cookies, you may be unable to access certain areas of our Site and certain functions and pages will not work in the usual way. Doing so may also affect our ability to update this Site to cater for user preferences and improve its performance.</p>
            </section>
            <section>
              <h3>STRICTLY NECESSARY COOKIES</h3>
              <p>Strictly necessary cookies are essential in order to enable you to move around the Site and use its features and/or services. Without these absolutely necessary cookies, the Site will not perform as smoothly for you as we would like it to and we may not be able to provide the Site with certain services or features.</p>
            </section>
            <section>
              <h3>PERFORMANCE COOKIES</h3>
              <p>Performance cookies collect information about how visitors use a Site; for instance which pages visitors go to most often, and if they get error messages from web pages. These cookies do not collect any information that identifies a visitor. The information that these cookies collect is aggregated and is therefore anonymous. It is only used to improve how a Site works.</p>
              <p>We use the following 3rd party Performance Cookies:</p>
              <ul>
                <li>Google Analytics</li>
              </ul>
              <p>Expiry: indefinite.</p>
            </section>
            <section>
              <h3>Functional Cookies</h3>
              <p>Functional cookies allow this Site to remember choices you make (such as your user name and log in details) and any customisations you make to Site pages during your visit in order to provide enhanced, personalised features. For instance, these cookies can be used to remember changes you have made to text size, fonts and other elements of web pages that can be customised. They may also be used to provide services which you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymous and they cannot track your browsing activity on other Sites.</p>
            </section>
            <section>
              <h3>TARGETING OR ADVERTISING COOKIES</h3>
              <p>Targeting or Advertising cookies are linked to services provided by third parties. The third party provides these services in return for recognising that you have visited our Site. These cookies collect information about your browsing habits on this Site in order to make advertising both on this Site and other Sites you subsequently visit relevant to you and your interests, to limit the number of times those adverts are served to you and to help measure the effectiveness of advertising campaigns.</p>
              <p>We do not use Targeting or Advertising Cookies.</p>
            </section>
            <section>
              <h3>MANAGING COOKIES IN YOUR BROWSER</h3>
              <p>In addition to the options provided above, you may refuse or accept cookies from the Site at any time by activating settings on your browser. Information about the procedure to follow in order to enable or disable cookies can be found on your Internet browser provider’s site via your help screen.</p>
              <p>Go to <a rel="noopener noreferrer" href="http://www.allaboutcookies.org/manage-cookies/" target="_blank">http://www.allaboutcookies.org/manage-cookies/</a> for procedures on how to view and disable cookies.</p>
              <p>Please be aware that if cookies are disabled, not all features of the Site may operate as intended.</p>
            </section>
            <section>
              <h3>HOW TO DELETE COOKIES</h3>
              <p>For further information on how to delete cookies we recommend that you read the information provided at following link: <a rel="noopener noreferrer" target="_blank" href="http://www.allaboutcookies.org/manage-cookies/clear-cookies-installed.html">http://www.allaboutcookies.org/manage-cookies/clear-cookies-installed.html</a></p>
            </section>
            <section>
              <h3>Further Information</h3>
              <p>You can find more information about cookies by visiting www.allaboutcookies.org to obtain detailed information on how to prevent cookies from being set on your particular browser.</p>
              <p>For further information about Google Analytics please see:</p>
              <p>Safeguarding your Data</p>
              <p>To opt out of being tracked by Google Analytics across all Sites, visit:</p>
              <p><a rel="noopener noreferrer" href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a></p>
              <p>If you would like further information about the cookies used in relation to YouTube videos, the YouTube privacy notice, including details about YouTube related cookies, is available at: <a rel="noopener noreferrer" href="https://www.google.co.uk/policies/privacy/" target="_blank">https://www.google.co.uk/policies/privacy/</a>.</p>
              <p>If you would like to find out more about advertising networks and affiliate programmes and how cookies are used for these purposes, please visit:</p>
              <p><a rel="noopener noreferrer" href="https://www.youronlinechoices.com">www.youronlinechoices.com</a></p>
              <p>This is an information portal which is run by the Internet Advertising Bureau (IAB), the trade association for digital advertising, and will provide you will all the information you need to know about online behavioural advertising and the choices available to you.</p>
            </section>
          </div>
        </div>
        <div className="panel__block margin-top-lg">
            <div className="text-right">
              <a href={`/default/user/login`}><p className="margin-bottom-none">Powered by <i
                className="logo"><FlowProfilerLogo/></i></p></a>
            </div>
            <span style={{ fontSize: "12px", display: 'block', width: '100%', textAlign: 'center' }}>flowprofiler® and associated marks are registered trademarks of flowprofiler® Global Ltd.</span>
          </div>
      </div>
)

export default PrivacyPage
