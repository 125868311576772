import { combineReducers } from 'redux'
import activeTab from './activeTabReducer'
import userAssessments from './userAssessmentsReducer'
import organisationAssessments from './organisationAssessmentsReducer'
import assessmentsSelected from './assessmentsSelectedReducer'
import modal from './modalReducer'
import sideBar from './sideBarReducer'
import date from './dateReducer'
import alert from './alertReducer'
import auth from './authReducer'
import candidates from './candidatesReducer'
import candidateSelected from './candidateSelectedReducer'
import candidatesSelected from './candidatesSelectedReducer'
import candidatesAssessments from './candidatesAssessmentsReducer'
import candidateAssessmentSelected from './candidateAssessmentSelectedReducer'
import candidatesAssessmentsSelected from './candidatesAssessmentsSelectedReducer'
import leadershipsAssessments from './leadershipsAssessmentsReducer'
import leadershipReport from './leadershipReportReducer'
import leadershipsAssessmentsSelected from './leadershipsAssessmentsSelectedReducer'
import leaderSelected from "./leaderSelectedReducer";
import leader from './leaderReducer'
import reviewers from "./reviewersReducer"
import client from './clientReducer'
import help from './helpReducer'
import reports from './reportsReducer'
import pages from './pagesReducer'
import roles from './rolesReducer'
import organisations from './organisationsReducer'
import organisationSelected from './organisationSelectedReducer'
import labels from './labelsReducer'
import labelSelected from './labelSelectedReducer'
import theme from './themeReducer'
import users from './usersReducer'
import menu from './menuReducer'
import user from './userReducer'
import userOrganisation from './userOrganisationReducer'
import userSelected from './userSelectedReducer'
import background from './backgroundReducer'
import confirmation from './confirmationReducer'
import userOrganisations from './userOrganisationsReducer'
import teamReport from './teamReportReducer'
import teamReportsSaved from './teamReportsSavedReducer'

export default combineReducers({
  activeTab,
  userAssessments,
  organisationAssessments,
  assessmentsSelected,
  modal,
  date,
  alert,
  auth,
  candidates,
  candidateSelected,
  candidatesSelected,
  candidatesAssessments,
  candidateAssessmentSelected,
  candidatesAssessmentsSelected,
  leadershipsAssessments,
  leadershipReport,
  leadershipsAssessmentsSelected,
  leaderSelected,
  leader,
  reviewers,
  client,
  help,
  reports,
  roles,
  pages,
  organisations,
  organisationSelected,
  labels,
  labelSelected,
  theme,
  users,
  menu,
  user,
  userSelected,
  userOrganisation,
  userOrganisations,
  sideBar,
  background,
  confirmation,
  teamReport,
  teamReportsSaved
})
