import { asyncFetch } from 'fetchs/apiFetch'
import { updateTokenError } from 'errors/tokenError'

// CREATE
export function transferToken (sender, recipient, type, specificity, updateUserCredit, updateSelectedCredit, total = false, issue = false) {
  return function (dispatch) {
    let data = {
      'sender': sender.namespace,
      'recipient': recipient.namespace,
      'type': type,
      'issue': issue,
    }
    if (specificity) {
      data['specificity'] = specificity
    }

    return asyncFetch(dispatch, 'POST', `tokens/transfer`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            dispatch(updateTokenError(response.customErrorCode, response.message))
            dispatch(updateUserCredit)
            dispatch(updateSelectedCredit)
          } else {
            if(total){
              dispatch(transferToken(sender, recipient, type, specificity, updateUserCredit, updateSelectedCredit, --total, issue))
            } else {
              dispatch(updateUserCredit)
              dispatch(updateSelectedCredit)
            }
          }
        })
  }
}

// READ

// UPDATE

// DELETE

// LIST
