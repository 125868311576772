import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Switch from "./Switch";

const SwitchControl = ({ onActiveChange, initialActiveState, label, id }) => {

    const [active, setActive] = useState(initialActiveState);

    return (
        <div className="app" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '200px', marginBottom: '20px' }}>
            <div>{label}</div>
            <Switch
                isOn={active}
                onColor="#18583DE5"
                handleToggle={() => {
                    setActive(!active)
                    onActiveChange(!active)
                }}
                id={id}
            />
        </div>
    )
}

SwitchControl.propTypes = {
    onActiveChange: PropTypes.func.isRequired,
    initialActiveState: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
}

export default SwitchControl
