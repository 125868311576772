import React from 'react'
import PropTypes from 'prop-types'

const NoResults = ({ colSpan }) => (
  <tbody>
  <tr className="table__group">
    <td/>
    <td colSpan={colSpan}>
      There are no results available
    </td>
  </tr>
  </tbody>
)

NoResults.propTypes = {
  colSpan: PropTypes.number.isRequired,
}

export default NoResults
