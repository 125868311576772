import React from 'react'
import PropTypes from 'prop-types'

class DateField extends React.Component {

    render () {
        return (
            <input className="input date-input" onClick={this.props.onClick} value={this.props.value} placeholder={this.props.placeholder} />
        )
    }
}

DateField.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string
};

export default DateField
