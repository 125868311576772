import React from 'react'
import PropTypes from 'prop-types'
import AssignCredit from './AssignCredit'
import SelectedAssessment from './SelectedAssessment'
import ChevronRight from 'assets/svg/components/ChevronRight'

const IssueSharedCredit = ({ selectedAssessments, assessmentSelect, sharedCredit, handleAssignCredit, handleChangeCredit, handleAssignAssessment, handleUnAssignAssessment, transferringCredits }) => (
  <div className="padding-top-md text-left separator">
    <div className="grid">
      <div className="grid__item w-6">
        <label className={'label'} htmlFor="firstName"><b
          className="colour-black">Shared Credit Assessments:</b> </label>
      </div>
      <AssignCredit currentCredit={sharedCredit} handleAssignCredit={handleAssignCredit} handleChangeCredit={handleChangeCredit} transferringCredits={transferringCredits} />
      <div className="grid__item w-12">
        {Object.values(selectedAssessments).map((assessment) => (
          <SelectedAssessment key={assessment.id} assessment={assessment} handleUnAssignAssessment={() => handleUnAssignAssessment(assessment)}/>
        ))}
      </div>
      <div className="grid__item w-6">
        {assessmentSelect}
      </div>
      <div className="grid__item w-6">
        <button onClick={handleAssignAssessment} className="button button--stretch button--active w-5">
          <span className="padding-right-sm">Assign assessment</span>
          <i className="icon"><ChevronRight/></i>
        </button>
      </div>
    </div>
  </div>
)

IssueSharedCredit.propTypes = {
  selectedAssessments: PropTypes.array.isRequired,
  // input: PropTypes.oneOfType([
  //   PropTypes.object,
  //   PropTypes.array
  // ]),
}

export default IssueSharedCredit
