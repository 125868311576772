import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { updateCandidatesAssessments } from 'actions/candidateAssessmentAction'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import TableCandidatesConfirm from 'containers/Table/CandidatesConfirm'
import Options from './Options'
import { createStructuredSelector } from 'reselect'
import { getAssessmentsBySelected } from 'reducers/organisationAssessmentsReducer'
import Field from "../../InputPage/Form/Field";
import DateWrapper from 'components/Form/DateWrapper'

class ModalCandidatesAssessmentsConfirm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expiresAt: ''
    }
  }

  handleConfirm = () => {
    this.props.onUpdateCandidatesAssessments(this.state.expiresAt)
    this.props.onAddModal('Individual Assessments')
  }

  handleDateChange = (date) => {
    if (date) {
      let dd = String(date.getDate()).padStart(2, '0');
      let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = date.getFullYear();
      let formattedDate = yyyy + '-' + mm + '-' + dd + ' 23:59:00';
      this.setState({ expiresAt: formattedDate })
    }
    else {
      this.setState({ expiresAt: '' })
    }
  }

  render () {
    const { assessmentsBySelected } = this.props

    return (
      <section>
        <div className="padding-top-md">
          {assessmentsBySelected.length > 0 ? (
            <CardGroup
              cards={assessmentsBySelected}
              noSelectionRequired
            />  
          ) : (
            <NoResults/>
          )}
        </div>
        <TableCandidatesConfirm/>
        <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', 'alignItems': 'flex-end'}}>
          <Field input={<DateWrapper onItemChange={this.handleDateChange}
                                 placeholder={'Expires on'}
                                 itemValue={this.state.expiresAt}
                    />}/>
          <Options title={'Confirm'} onClick={this.handleConfirm}/>
        </div>

      </section>
    )
  }
}

ModalCandidatesAssessmentsConfirm.propTypes = {
  assessmentsBySelected: PropTypes.arrayOf(PropTypes.object),
  onAddModal: PropTypes.func.isRequired,
  onUpdateCandidatesAssessments: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  assessmentsBySelected: getAssessmentsBySelected,
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateCandidatesAssessments: (expiresAt) => {
    dispatch(updateCandidatesAssessments(expiresAt))
  }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalCandidatesAssessmentsConfirm)
