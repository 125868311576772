import React from 'react'
import ChevronRight from 'assets/svg/components/ChevronRight'
import PropTypes from 'prop-types'

const Options = ({ title, onClick }) => (
    <div className="padding-top-lg text-right">
        <button className="button button--stretch button--active" onClick={onClick}>
            <span className="padding-right-sm">{title}</span>
            <i className="icon"><ChevronRight/></i>
        </button>
    </div>
)

Options.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default Options
