export const ADD_ALL_ASSESSMENT = 'ADD_ALL_ASSESSMENT'
export const SET_ASSESSMENTS_SELECTED = 'SET_ASSESSMENTS_SELECTED'
export const UNSET_ASSESSMENTS_SELECTED = 'UNSET_ASSESSMENTS_SELECTED'

export function receiveOrganisationAssessments (assessments) {
  return {
    type: ADD_ALL_ASSESSMENT,
    assessments
  }
}

export function setAssessmentsSelected (assessments) {
  return {
    type: SET_ASSESSMENTS_SELECTED,
    assessments
  }
}

export function unSetAssessmentsSelected () {
  return {
    type: UNSET_ASSESSMENTS_SELECTED,
  }
}

// export const SET_MODAL_ASSESSMENT = 'SET_MODAL_ASSESSMENT'
// export function setAssessmentsModal (assessments) {
//   return {
//     type: SET_MODAL_ASSESSMENT,
//     assessments
//   }
// }
