export const SET_REPORTS = 'SET_REPORTS'

export function receiveReports (reports) {
  return {
    type: SET_REPORTS,
    reports
  }
}






