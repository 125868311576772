import { SET_LEADERSHIP_REPORT } from 'actions/leadershipReportAction'

const initialState = null

const leadershipReport = (state = initialState, action) => {

    switch (action.type) {
        case SET_LEADERSHIP_REPORT:
            return action.leadershipReport
        default:
            return state
    }
}

export default leadershipReport

export const getLeadershipReport= state => state.leadershipReport
