import { ADD_USER_ASSESSMENTS } from 'actions/userAssessmentAction'

const initialState = []

const userAssessments = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_ASSESSMENTS:
      return action.assessments
    default:
      return state
  }
}

export default userAssessments

export const getUserAssessments = state => state.userAssessments

