import { ADD_USER_ORGANISATIONS } from 'actions/userAction'

const initialState = false

const userOrganisations = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_ORGANISATIONS:
      return action.organisations
    default:
      return state
  }
}

export default userOrganisations

export const getUserOrganisations = state => state.userOrganisations
