import { SET_HELP } from 'actions/helpAction'

const initialState = []

const help = (state = initialState, action) => {
  switch (action.type) {
    case SET_HELP:
      return action.help
    default:
      return state
  }
}

export default help

export const getHelp = state => state.help
