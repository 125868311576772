import React, { Component } from 'react'
import Search from 'components/Filter/Search'
import Select from 'components/Filter/Select'
import Reset from 'components/Filter/Reset'
import SelectOption from 'components/Filter/SelectOption'
import Date from 'components/Filter/Date'
import PropTypes from 'prop-types'

class Filter extends Component {
    render () {
        const {
            labels, searchValue, selectedLabel, selectedStatus, selectedInvited,
            onSearchChange, onLabelChange, onStatusChange, onInvitedChange, onReset
        } = this.props

        const labelOptions = []
        Object.values(labels).forEach((label) => {
            labelOptions.push(<SelectOption key={label.id} value={label.name} title={label.name}/>)
        })

        const statusOptions = []
        statusOptions.push(<SelectOption key={1} value={'Sent'} title={'Sent'}/>)
        statusOptions.push(<SelectOption key={2} value={'Deleted'} title={'Deleted'}/>)
        statusOptions.push(<SelectOption key={3} value={'Cancelled'} title={'Cancelled'}/>)
        statusOptions.push(<SelectOption key={4} value={'Completed'} title={'Completed'}/>)
        statusOptions.push(<SelectOption key={5} value={'Started'} title={'Started'}/>)

        return (
            <div className="grid--between grid--middle full-width">
                <Search onSearchChange={onSearchChange} searchValue={searchValue}/>
                <Select onItemChange={onLabelChange} selectedItem={selectedLabel} itemOptions={labelOptions}
                        itemName={'Label'}/>
                <Select onItemChange={onStatusChange} selectedItem={selectedStatus} itemOptions={statusOptions}
                        itemName={'Status'}/>
                <Date onItemChange={onInvitedChange} selectedItem={selectedInvited} placeholderText={'Invited Date'}/>
                <Reset onReset={onReset}/>
            </div>
        )
    }
}

Filter.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.object),
    searchValue: PropTypes.string.isRequired,
    selectedLabel: PropTypes.string.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onLabelChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    onInvitedChange: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
}

// TODO: proptype selectedInvited, why is date not working

export default Filter
