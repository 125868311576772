import { asyncFetch } from './apiFetch'
import { createCandidateError, updateCandidateError } from 'errors/candidateError'
import { receiveCandidate, receiveCandidates } from 'actions/candidateAction'
import { addActiveTab, addAlertExpiry, receivePages, unSetPages } from 'actions/uiAction'
import {unSetCandidatesAssessments, updateCandidatesAssessments} from 'actions/candidateAssessmentAction'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { getPages } from 'reducers/pagesReducer'
import {addCandidatesSelected} from "../actions/candidateSelectedAction";

// CREATE
export function createCandidate (candidate, updateTab = false) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())
    const data = {
      'firstName': candidate.firstName,
      'lastName': candidate.lastName,
      'email': candidate.email.trim(),
      'organisation': userOrganisation.namespace,
      'labels': candidate.labels,
    }

    return asyncFetch(dispatch, 'POST', `candidates`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            dispatch(createCandidateError(response.customErrorCode, candidate))
          } else {
            dispatch(receiveCandidate('Success', candidate.id, response))
            if(updateTab){
              dispatch(addActiveTab('Existing'))
            }
          }
        })
  }
}

export function createCandidateAndInvite (candidate, assessmentId, updateTab = false) {
    return function (dispatch, getState) {
        const userOrganisation = getUserOrganisation(getState())
        const data = {
            'firstName': candidate.firstName,
            'lastName': candidate.lastName,
            'email': candidate.email.trim(),
            'organisation': userOrganisation.namespace,
            'labels': candidate.labels,
            'o_assessment': assessmentId
        }

        return asyncFetch(dispatch, 'POST', `candidates`, data)
            .then(
                (response) => {
                    if (response && response.customErrorCode) {
                        dispatch(createCandidateError(response.customErrorCode, candidate))
                    } else {
                        dispatch(receiveCandidate('Success', candidate.id, response))
                        if(updateTab){
                            dispatch(addActiveTab('Existing'))
                        }
                    }
                })
    }
}

// READ

// UPDATE
export function updateCandidate (candidate) {
  return function (dispatch) {
    const data = {
      'firstName': candidate.firstName,
      'lastName': candidate.lastName,
      'email': candidate.email.trim(),
      'labels': candidate.labels,
    }

    return asyncFetch(dispatch, 'PATCH', `candidates/${candidate.id}`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            dispatch(updateCandidateError(response.customErrorCode, candidate))
          } else {
            dispatch(receiveCandidate('Success', candidate.id, response))
            dispatch(addAlertExpiry('success', 'Individual details updated successfully'))
          }
        })
  }
}

// ANONYMISE

export function anonymiseCandidate (candidate) {
  return function (dispatch) {

    return asyncFetch(dispatch, 'PATCH', `candidates/${candidate.id}/anonymise`)
        .then(
            (response) => {
              if (response && response.customErrorCode) {
                dispatch(updateCandidateError(response.customErrorCode, candidate))
              } else {
                dispatch(receiveCandidate('Success', candidate.id, response))
                dispatch(addAlertExpiry('success', 'Individual anonymised successfully'))
              }
            })
  }
}

// LIST
export function listCandidates (page, searchFilter = null, labelFilter = null, hasFlowprofiler = false) {
  return function (dispatch, getState) {
    dispatch(unSetPages())
    dispatch(unSetCandidatesAssessments())

    const userOrganisation = getUserOrganisation(getState())

    const organisationFilter = `&filters[]=and-organisation.namespace-eq-value-${userOrganisation.namespace}`
    const deletedAtFilter = '&filters[]=and-candidate.deletedAt-bool-value-false'

    let searchFilterUrl = ''
    if (searchFilter) {
      searchFilterUrl = `&filters[]=or-candidate.firstName-inci-value-${searchFilter}&filters[]=or-candidate.lastName-inci-value-${searchFilter}&filters[]=or-candidate.email-inci-value-${searchFilter}`
    }

    let labelFilterUrl = ''
    if (labelFilter) {
      labelFilterUrl = `&filters[]=and-label.name-inci-value-${labelFilter}`
    }

    const pages = getPages(getState())
    const offset = (page - 1) * pages.limit
    const paginationFilterUrl =  `&limit=${pages.limit}&offset=${offset}&count=true`
    const orderFilterUrl =  `&sorts[]=candidate.createdAt-desc`
    let hasFlowprofilerUrl = ''

      if (hasFlowprofiler) {
          hasFlowprofilerUrl = '&hasFlowprofiler=true'
      }

    return asyncFetch(dispatch, 'GET', `candidates?${organisationFilter}${deletedAtFilter}${searchFilterUrl}${labelFilterUrl}${paginationFilterUrl}${orderFilterUrl}${hasFlowprofilerUrl}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // assessmentsError(dispatch, response.customErrorCode)
          } else {
            dispatch(receiveCandidates(response.json))
            dispatch(receivePages(response.resultsTotal, page))
          }
        })
  }
}
