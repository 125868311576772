import {
    ADD_REVIEWER,
    SET_REVIEWERS,
    REMOVE_REVIEWER,
    UPDATE_REVIEWER_FIELD
} from 'actions/reviewerAction'
import {UPDATE_CANDIDATE_FIELD} from "../actions/candidateAction";

const initialState = []

const reviewers = (state = initialState, action) => {
    let newState = Object.assign([], state)
    switch (action.type) {
        case ADD_REVIEWER:
            newState.push(action.reviewer)
            return newState
        case SET_REVIEWERS:
            console.log(action.reviewers)
            newState = action.reviewers

            return newState
        case UPDATE_REVIEWER_FIELD:
            return newState.map(function (reviewer) {
                if (reviewer.id === action.reviewerId) {
                    reviewer[action.inputId] = action.value
                }
                return reviewer
            })
        case REMOVE_REVIEWER:
            return newState.filter(obj => {
                return obj.id !== action.reviewer.id
            })
        default:
            return state
    }
}

export default reviewers

export const getReviewers = state => state.reviewers
