import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CrossCircle from 'assets/svg/components/CrossCircle'
import Text from 'components/Form/Text'
import Field from './Field'
import MultiSelect from 'components/Form/MultiSelect'

class Inputs extends Component {

  render () {
    const { candidate, labels, onTextChange, onLabelChange, onDateChange, onDeleteCandidate } = this.props

    const labelOptions = []
    Object.values(labels).forEach((label) => {
      const option = { value: label.id, label: label.name }
      labelOptions.push(option)
    })
    
    return (
      <div className="card card--stretch" style={{ overflow: 'visible' }}>
        <div className="grid">
          <Field required={true}
                 input={<Text parentId={candidate.id} onItemChange={onTextChange} itemValue={candidate.firstName}
                              placeholder={'First Name'}
                              itemName={'firstName'}/>}/>
          <Field required={true}
                 input={<Text parentId={candidate.id} onItemChange={onTextChange} itemValue={candidate.lastName}
                              placeholder={'Last Name'}
                              itemName={'lastName'}/>}/>
          <Field required={true}
                 input={<Text parentId={candidate.id} onItemChange={onTextChange} itemValue={candidate.email}
                              placeholder={'Email'}
                              itemName={'email'}/>}/>
          <Field input={<MultiSelect parentId={candidate.id} onItemChange={onLabelChange} itemName={'Label'}
                                     itemOptions={labelOptions}/>}/>
          <div style={{float:"right"}}>
              <div className="card__close" onClick={() => onDeleteCandidate(candidate)}><CrossCircle/></div>
          </div>
        </div>
      </div>
    )
  }
}

Inputs.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object),
  candidate: PropTypes.object.isRequired,
  onDeleteCandidate: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onLabelChange: PropTypes.func.isRequired,
}

export default Inputs
