import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'

class Table extends Component {
  render () {
    const {
      labels,
      onClickLabel,
    } = this.props

    const headers = []
    let key = 0
    headers.push(<Header key={++key} header='Name'/>)

    let tableBody = <NoResults colSpan={2}/>
    const body = []

    if (labels.length) {
      Object.values(labels).forEach((label) => {

        const row = []
        row.push(<CellText header={'Name'} value={label.name} key={++key}/>)
        body.push(<Row onClick={() => onClickLabel(label)} key={++key} row={row}/>)
      })
      tableBody = (<tbody>{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

Table.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object),
  onClickLabel: PropTypes.func.isRequired,
}

export default Table
