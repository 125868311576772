import React from 'react'
import FlowProfilerLogo from '../../assets/svg/components/FlowProfilerLogo'

const Head = ({ user, logoImage, organisation }) => (
  <div className="sidebar__head">
    <div className="sidebar__logo">
      {/*{logoImage}*/}
      <FlowProfilerLogo/>
    </div>
    <div>
      {user &&
        <h6 className="sidebar__title margin-bottom-none">{`${user.firstName} ${user.lastName}`}</h6>
      }
      <span>{organisation}</span>
    </div>
  </div>
)

export default Head
