import React from 'react'
import Form from './Form'

const InputPage = () => (
  <section>
    <h2>Add New Individuals</h2>
    <div className="padding-bottom-lg">
      <Form/>
    </div>
  </section>
)

export default InputPage

