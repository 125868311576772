import React from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/lib/Creatable'

const MultiSelect = ({ parentId, itemOptions, onItemChange, selectedItems, itemName }) => (
  <CreatableSelect
    defaultValue={selectedItems}
    isClearable
    isMulti
    name="labels"
    placeholder={`Select ${itemName}...`}
    options={itemOptions}
    className="basic-multi-select"
    classNamePrefix="selects"
    onChange={(event) => onItemChange(event, parentId)}
  />
)

MultiSelect.propTypes = {
  onItemChange: PropTypes.func,
  parentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  itemOptions: PropTypes.array,
  selectedItems: PropTypes.array,
}

export default MultiSelect
