import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CellText from 'components/Table/CellText'
import CellCheckbox from 'components/Table/CellCheckbox'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import HeaderCheckbox from 'components/Table/HeaderCheckbox'
import NoResults from 'components/Table/NoResults'
import { formatLabels } from 'actions/labelAction'
import Slip from '../../../libs/slip'

class Table extends Component {

  componentDidMount() {
    if (this.props.enableListReordering) {
      const ol = document.getElementById('candidatelist')
      if (!ol) return
      ol.addEventListener('slip:beforereorder', (e) => {
        if (e.target.nodeName === 'INPUT') {
          e.preventDefault()
        }
      }, false)
      ol.addEventListener('slip:reorder', (e) => {
        e.target.parentNode.insertBefore(e.target, e.detail.insertBefore)
        this.props.onReorder(e.detail.originalIndex, e.detail.spliceIndex);
        return false
      }, false)
      new Slip(ol)
    }
  }

  render() {
    const {
      candidates, candidatesSelected, selectAll, modal,
      onClickCandidate, onSelectAllCandidates, onSelectCandidate
    } = this.props

    const headers = []
    let key = 0
    headers.push(<HeaderCheckbox key={++key} selectAll={selectAll} onSelectAllItems={onSelectAllCandidates}/>)
    headers.push(<Header key={++key} header='First'/>)
    headers.push(<Header key={++key} header='Last'/>)
    headers.push(<Header key={++key} header='Email'/>)
    headers.push(<Header key={++key} header='Label'/>)

    let tableBody = <NoResults colSpan={5}/>
    const body = []

    if (candidates.length) {
      Object.values(candidates).forEach((candidate) => {

        const isChecked = candidatesSelected.some(obj => {
          return obj.id === candidate.id
        })

        const row = []
        row.push(<CellCheckbox isChecked={isChecked} onChange={() => onSelectCandidate(candidate, isChecked)}
                               key={++key}/>)
        row.push(<CellText header={'First'} value={candidate.firstName} key={++key}/>)
        row.push(<CellText header={'Last'} value={candidate.lastName} key={++key}/>)
        row.push(<CellText header={'Email'} value={candidate.email} key={++key}/>)
        row.push(<CellText header={'Label'} value={formatLabels(candidate.labels)} key={++key}/>)
        if (modal) {
          body.push(<Row key={++key} row={row}/>)
        } else {
          body.push(<Row onClick={(event) => onClickCandidate(event, candidate)} key={++key} row={row}/>)
        }

      })
      tableBody = (<tbody id="candidatelist">{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

Table.propTypes = {
  candidatesSelected: PropTypes.arrayOf(PropTypes.object),
  candidates: PropTypes.arrayOf(PropTypes.object),
  selectAll: PropTypes.bool,
  modal: PropTypes.bool,
  onClickCandidate: PropTypes.func.isRequired,
  onSelectAllCandidates: PropTypes.func.isRequired,
  onSelectCandidate: PropTypes.func.isRequired
}

export default Table
