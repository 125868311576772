import { SET_SIDEBAR } from 'actions/uiAction'

const initialState = 'Send'

const sideBar = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      return action.sideBar
    default:
      return state
  }
}

export default sideBar

export const getSidebar = state => state.sideBar
