import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { getUser } from 'reducers/userReducer'
import NewUser from './NewUser'
import SelectOption from 'components/Form/SelectOption'
import TableUsersList from 'containers/Table/UsersList'
import { listOrganisationRoles } from 'fetchs/organisationRoleFetch'
import { createUser, listUsers } from 'fetchs/userFetch'
import { getRoles } from 'reducers/rolesReducer'
import { getUsers } from 'reducers/usersReducer'
import { getAlert } from 'reducers/alertReducer'
import { setUserSelected } from 'actions/userSelectedAction'
import { addModal } from 'actions/uiAction'
import Pagination from 'components/Pagination'
import {getUserOrganisation} from "../../reducers/userOrganisationReducer";

class UserPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      user: {
        firstName: '',
        lastName: '',
        primaryEmail: '',
        role: '',
        brandName: this.props.organisation.branding.name,
        fromEmail: this.props.user.primaryEmail,
        fromName: this.props.user.firstName + ' ' + this.props.user.lastName
      },
    }

    this.props.onListUsers(1)
      if (this.props.organisation) {
          this.props.onListRoles(this.props.organisation.namespace)
      }

  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.alert === 'success') {
      const user = {
        firstName: '',
        lastName: '',
        primaryEmail: '',
        role: ''
      }
      this.setState({ user })
    }
  }

  handleTextChange = (event, userId, field) => {
    const user = { ...this.state.user }
    user[field] = event.target.value

    this.setState({ user })
  }

  handleRoleChange = (event) => {
    const user = { ...this.state.user }
    user.role = this.props.roles.find(function (role) {
      return role.id === Number(event.target.value)
    })

    this.setState({ user })
  }

  handleConfirm = (event) => {
    event.preventDefault()
    this.props.onCreateUser(this.state.user)
  }

  handleClickUser = (user) => {
    this.props.onSetUserSelected(user)
    this.props.onAddModal('User')
  }

  handlePageChange = (page) => {
    this.props.onListUsers(page)
  }

  render () {
    const { roles } = this.props
    const { user } = this.state

    const roleOptions = []

    Object.values(roles).forEach((role) => {
      roleOptions.push(<SelectOption key={role.id} value={role.id} title={role.name}/>)
    })

    return (
      <section>
        <h2>Users</h2>
        <div className="padding-bottom-lg">
          <form className="form" onSubmit={this.handleConfirm}>
            <NewUser user={user} roleOptions={roleOptions}
                     onTextChange={this.handleTextChange} onRoleChange={this.handleRoleChange}
                     handleConfirm={this.handleConfirm}
            />
          </form>
          <TableUsersList onClickUser={this.handleClickUser}/>
          <Pagination onPageChange={this.handlePageChange}/>
        </div>
      </section>

    )
  }
}

UserPage.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  alert: PropTypes.object.isRequired,
  onCreateUser: PropTypes.func.isRequired,
  onListRoles: PropTypes.func.isRequired,
  onListUsers: PropTypes.func.isRequired,
  onSetUserSelected: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
    roles: getRoles,
    users: getUsers,
    alert: getAlert,
    user: getUser,
    organisation: getUserOrganisation,
})

const mapDispatchToProps = (dispatch) => ({
  onCreateUser: (user) => {
    dispatch(createUser(user))
  },
  onListRoles: (organisation) => {
    dispatch(listOrganisationRoles(organisation))
  },
  onListUsers: (page) => {
    dispatch(listUsers(page))
  },
  onSetUserSelected: (user) => {
    dispatch(setUserSelected(user))
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(UserPage)
