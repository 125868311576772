import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const Date = ({ selectedItem, onItemChange, placeholderText }) => (
  <div className="grid__item w-2-md">
    <div className="input-group">
      <DatePicker
        id="sentFrom"
        className="select"
        selected={selectedItem}
        onChange={onItemChange}
        placeholderText={placeholderText}
        dropdownMode="select"
        dateFormat="d/MM/yyyy"
      />
    </div>
  </div>
)

Date.propTypes = {
  onItemChange: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  placeholderText: PropTypes.string
}

export default Date
