import React from 'react'

const Search = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="15" cy="15" r="7"/>
      <path d="M20 20l4 4"/>
    </g>
  </svg>
)

export default Search
