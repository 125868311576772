import React from 'react'

const FlowProfilerLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="131.6" height="22.5">
    <path fill="#020000"
          d="M57.6 8c-.6-.6-1.8-1.4-3.6-1.4-.8 0-2.1.2-3.2 1.5V6.9h-3.3v15.3h3.3v-6.4c.3.5 1.2 1.5 3.2 1.5 1.3 0 2.4-.4 3.4-1.4 1-1 1.6-2.3 1.6-4.1.1-1.5-.5-2.9-1.4-3.8zM55 13.9c-.5.5-1.1.7-1.7.7-.7 0-1.4-.3-1.9-.8s-.7-1.2-.7-1.8c0-.6.2-1.3.8-1.9.3-.3.9-.7 1.8-.7.7 0 1.3.2 1.8.7.5.4.8 1.1.8 1.8-.1.7-.4 1.5-.9 2zM61.2 6.9h3.3v1.6c.3-.6.7-1.1 1.3-1.4.5-.3 1.1-.4 1.7-.4.4 0 .7 0 1.1.2l-.2 3.1c-.4-.2-.9-.4-1.5-.4-.7 0-1.2.2-1.6.7-.7.8-.8 1.9-.8 2.7v4.1h-3.3V6.9zM79.4 8.2c-.9-.9-2.4-1.6-4.4-1.6-2 0-3.5.7-4.4 1.6-1 1-1.5 2.5-1.5 3.8s.5 2.7 1.5 3.8c.9.9 2.5 1.6 4.4 1.6 2 0 3.5-.7 4.4-1.6 1-1 1.5-2.5 1.5-3.8s-.4-2.8-1.5-3.8zm-2.6 5.7c-.5.5-1.1.7-1.8.7-.9 0-1.5-.3-1.9-.7-.4-.4-.7-1-.7-1.9 0-.8.3-1.4.7-1.9s1.1-.7 1.8-.7 1.4.2 1.8.7.7 1.1.7 1.9c.2.8-.1 1.4-.6 1.9zM87 9.6v7.5h-3.3V9.6h-1.2V6.9h1.2v-2c0-1.1.1-2.4 1.1-3.5.7-.7 1.6-1.2 2.8-1.2.7 0 1.3.1 1.9.3v2.9c-.4-.2-.8-.4-1.2-.4-.5 0-.8.2-1 .4-.3.6-.3 1.3-.3 1.8v1.7h2.6v2.6H87zM93.8.7c1.1 0 1.9.8 1.9 1.9s-.8 1.9-1.9 1.9-1.9-.8-1.9-1.9.8-1.9 1.9-1.9zM92.1 6.9h3.3V17h-3.3zM98.3.5h3.3v16.6h-3.3zM114.5 12.2c0-1.2-.2-2.8-1.4-4.1-1.2-1.3-2.9-1.5-4-1.5s-2.7.2-3.9 1.5c-1 1-1.5 2.5-1.5 4 0 1.7.6 3 1.5 3.9 1.2 1.2 2.5 1.5 4 1.5 1.1 0 2.2-.2 3.2-.7.6-.4 1.5-1.1 2-2.5l-3.1-.3c-.3.6-.7.8-.7.8-.3.2-.8.3-1.3.3s-1.2-.1-1.7-.6c-.4-.5-.6-1.2-.6-1.7h7.6v-.6zm-7.4-1.6c.1-.5.3-.8.6-1.1.3-.3.8-.7 1.7-.7.7 0 1.2.2 1.5.6.5.5.6 1.1.7 1.2h-4.5zM116.6 6.9h3.3v1.6c.3-.6.7-1.1 1.3-1.4.5-.3 1.1-.4 1.7-.4.4 0 .7 0 1.1.2l-.2 3.1c-.4-.2-.9-.4-1.5-.4-.7 0-1.2.2-1.6.7-.7.8-.8 1.9-.8 2.7v4.1h-3.3V6.9z"/>
    <path fill="#A7A9AC"
          d="M5.9 9.6v7.5H2.6V9.6H1.5V6.9h1.2v-2c0-1.1.1-2.4 1.1-3.5C4.5.7 5.4.2 6.6.2c.7 0 1.3.1 1.9.3v2.9c-.4-.2-.9-.3-1.3-.3-.5 0-.8.2-1 .4-.3.5-.3 1.2-.3 1.7v1.7h2.6v2.6H5.9zM11.1.5h3.3v16.6h-3.3zM22.4 6.6c-2 0-3.5.7-4.4 1.6-1 1-1.5 2.5-1.5 3.8s.5 2.7 1.5 3.8c.9.9 2.5 1.6 4.4 1.6s3.5-.7 4.4-1.6c1-1 1.5-2.5 1.5-3.8s-.5-2.7-1.5-3.8c-.9-.9-2.4-1.6-4.4-1.6zm1.9 7.3c-.5.5-1.1.7-1.8.7-.9 0-1.5-.3-1.9-.7-.4-.4-.7-1-.7-1.9 0-.8.3-1.4.7-1.9s1.1-.7 1.8-.7 1.4.2 1.8.7.8 1.1.8 1.9c0 .8-.3 1.4-.7 1.9zM42.3 6.8v5.9c0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.5.3s-.4.1-.6.1c-.2 0-.4 0-.6-.1-.2-.1-.3-.2-.5-.3-.1-.1-.2-.3-.3-.5-.1-.2-.1-.4-.1-.6V6.8h-3v5.9c0 .2 0 .4-.1.6-.1.2-.2.3-.3.5-.1.1-.3.2-.5.3-.2.1-.4.1-.6.1-.2 0-.4 0-.6-.1-.2-.1-.3-.2-.5-.3-.1-.1-.2-.3-.3-.5-.1-.2-.1-.4-.1-.6V6.8h-3v5.9c0 .6.1 1.2.4 1.8.2.6.6 1 1 1.4.4.4.9.7 1.4 1 .5.2 1.1.4 1.8.4.6 0 1.1-.1 1.6-.3.5-.2 1-.5 1.4-.9.4.4.9.7 1.4.9.5.2 1.1.3 1.6.3.6 0 1.2-.1 1.8-.4.6-.2 1-.6 1.4-1 .4-.4.7-.9 1-1.4.2-.6.4-1.1.4-1.8V6.8h-3.2z"/>
    <path fill="#020000"
          d="M127.8 2.7c1.3 0 2.3 1 2.3 2.3s-1 2.3-2.3 2.3c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3zm0 4c1 0 1.7-.8 1.7-1.7 0-1-.8-1.7-1.7-1.7-1 0-1.7.8-1.7 1.7 0 1 .7 1.7 1.7 1.7zm.4-.6l-.4-.6h-.4v.6h-.4V3.8h1c.5 0 .8.4.8.8 0 .3-.1.6-.4.7l.4.7h-.6zm-.7-1.8v.6h.4c.2 0 .3-.2.3-.3 0-.2-.1-.3-.3-.3h-.4z"/>
  </svg>
)

export default FlowProfilerLogo
