import React from 'react'
import PropTypes from 'prop-types'

const Field = ({ input, required, label = '' }) => (
  <div style={{ position: 'relative', padding:'0 10px'}}>
    {label !== '' &&
      <label className="label">{label}</label>
    }
    {input}
    {required &&
    <p className="margin-top-xsm margin-bottom-none" style={{ 'paddingLeft': '12px', 'fontSize': '16px' }}>*Required</p>
    }
  </div>
)

Field.propTypes = {
  input: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  required: PropTypes.bool,
}

export default Field
