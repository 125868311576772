import React from 'react'

const Settings = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="22" cy="10" r="2"/>
      <path d="M8 10h12"/>
      <circle cx="16" cy="16" r="2"/>
      <path d="M8 16h6M18 16h6"/>
      <circle cx="10" cy="22" r="2"/>
      <path d="M12 22h12"/>
    </g>
  </svg>
)

export default Settings
