import { addConfirmation } from 'actions/uiAction'
import { updatePasswordError, updateUserPasswordError } from 'errors/userPasswordError'
import { asyncFetch } from './apiFetch'

// CREATE
export function createUserPassword (username, emailAddress = null) {
  let userSpec = username
  if (emailAddress) {
    userSpec = emailAddress.trim()
  }

  return function (dispatch) {
    const data = {
      'userSpec': userSpec
    }

    return asyncFetch(dispatch, 'POST', 'password/reset', data).then(
      (response) => {
        if (response && response.customErrorCode) {
          dispatch(updateUserPasswordError(response.customErrorCode))
        } else {
          dispatch(addConfirmation('passwordReset'))
        }
      })
  }
}

// READ

// UPDATE
export function updateUserPassword (username, passwordToken, password, passwordConfirm) {
  return function (dispatch) {
    const data = {
      'token': passwordToken,
      'password': password,
      'password_confirm': passwordConfirm
    }

    return asyncFetch(dispatch, 'POST', `users/${username}/password/change`, data).then(
      (response) => {
        if (response && response.customErrorCode) {
          dispatch(updatePasswordError(response))
        } else {
          dispatch(addConfirmation('passwordUpdate'))
        }
      })
  }
}

// DELETE

// LIST
