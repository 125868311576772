import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import Candidate from './Candidate';
import Assessment from './Assessment';
import Dropdown from '../../../components/Dropdown/Dropdown';
import ReportGroups from '../../../config/ReportGroups';

import { updateCandidate } from 'fetchs/candidateFetch';
import { reminderCandidateAssessment } from 'fetchs/candidateAssessmentFetch';
import { getLabels } from 'reducers/labelsReducer';
import { createLabel } from 'fetchs/labelFetch';
import { getCandidateAssessmentSelected } from 'reducers/candidateAssessmentSelectedReducer';
import { getUserOrganisation } from 'reducers/userOrganisationReducer'

import ChevronRight from 'assets/svg/components/ChevronRight';
import Field from "../../InputPage/Form/Field";
import DateWrapper from 'components/Form/DateWrapper'
import Options from "../CandidatesAssessmentsConfirm/Options";

class ModalCandidatesPageSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAssessmentTypeId: 0,
      selectedAssessmentTypeFullId: 0,
      expiresAt: '',
      copySuccess: false,
      copyClassicSuccess: false
    };
  }

  componentDidMount() {
    if (this.props.candidateAssessmentSelected.expiresAt) {
      this.setState({
        expiresAt: this.props.candidateAssessmentSelected.expiresAt.date
      })
      console.log(this.props.candidateAssessmentSelected.expiresAt.date)
    }
  }

  handleChangeOption = id => {
    this.setState({
      selectedAssessmentTypeId: id,
      copyClassicSuccess: false
    });
  };

  handleChangeFullOption = id => {
    this.setState({
      selectedAssessmentTypeFullId: id,
      copySuccess: false
    });
  };

  handleDateChange = (date) => {
    if (date) {
      let dd = String(date.getDate()).padStart(2, '0');
      let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = date.getFullYear();
      let formattedDate = yyyy + '-' + mm + '-' + dd + ' 23:59:00';
      this.setState({ expiresAt: formattedDate })
    }
    else {
      this.setState({ expiresAt: '' })
    }
  }
  
  handleResend = () => {
      this.props.onReminderCandidateAssessment(this.props.candidateAssessmentSelected, this.state.expiresAt)
  }

  copyLinkToClipboard = () => {
    const el = this.textArea
    el.select()
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }

  copyClassicLinkToClipboard = () => {
    const el = this.textAreaClassic
    el.select()
    document.execCommand("copy")
    this.setState({copyClassicSuccess: true})
  }

  render () {
    const {
      candidateAssessmentSelected,
      userOrganisation
    } = this.props;
    const { selectedAssessmentTypeId } = this.state;
    const { selectedAssessmentTypeFullId } = this.state;

    const activeReportGroups = []

    if (userOrganisation.organisation.developmentReportsActive) {
      activeReportGroups.push('development')
    }
    if (userOrganisation.organisation.managementReportsActive) {
      activeReportGroups.push('management')
    }
    if (userOrganisation.organisation.interviewReportsActive) {
      activeReportGroups.push('interview')
    }
    if (userOrganisation.organisation.candidateReportsActive) {
      activeReportGroups.push('candidate')
    }
    if (userOrganisation.organisation.leadershipReportsActive && candidateAssessmentSelected.assessment.slug === 'flowprofilernorm') {
      activeReportGroups.push('leadership')
    }
    if (userOrganisation.organisation.benchmarkReportsActive && candidateAssessmentSelected.assessment.slug === 'flowprofilernorm') {
      activeReportGroups.push('benchmark')
    }

    const ReportGroupsMap = activeReportGroups.map((group, idx) => ({ id: idx, name: group, label: group }));

    // fix for Candidate report type name change
    for (const [key, value] of ReportGroupsMap.entries()) {
      if (value.label === 'candidate') {
        value.label = 'Candidate/Personal'
      }
    }
    const selectedGroup = ReportGroupsMap.filter(group => group.id === selectedAssessmentTypeId)[0];
    const selectedGroupFull = ReportGroupsMap.filter(group => group.id === selectedAssessmentTypeFullId)[0];

    let status = 'Sent'
    if (candidateAssessmentSelected.deletedAt) {
      status = 'Deleted'
    } else if (candidateAssessmentSelected.cancelledAt) {
      status = 'Cancelled'
    } else if (candidateAssessmentSelected.completedAt) {
      status = 'Completed'
    } else if (candidateAssessmentSelected.startedAt) {
      status = 'Started'
    }

    return (
      <section>
        <div className={'separator'}>
          <h2 className="margin-bottom-none">Candidate Detail</h2>
          <Candidate candidate={candidateAssessmentSelected.candidate}/>
        </div>

        <div className={'separator'}>

          <h2 className="margin-bottom-none">Assessment</h2>
          <Assessment candidateAssessment={candidateAssessmentSelected}/>

          {status !== 'Completed' &&
            <div style={{
              'display': 'flex',
              'flex': 1,
              'flexDirection': 'row',
              'justifyContent': 'flex-start',
              'alignItems': 'flex-end'
            }}>
              <div style={{position: 'relative', padding: '0', width: '200px'}}>
                <DateWrapper onItemChange={this.handleDateChange}
                             placeholder={'Expires on'}
                             itemValue={this.state.expiresAt}
                />
              </div>
              <button className="button button--stretch button--info margin-left-md"
                      onClick={this.handleResend}>
                <span className="padding-right-sm">Resend Invitation</span>
                <i className="icon"><ChevronRight/></i>
              </button>
            </div>
          }

        </div>

        {status === 'Completed' &&
          <div>
            <h3 style={{'marginTop': '20px'}}>Full Report: All results</h3>


            <div className="padding-bottom-md" style={{'display': 'flex', 'flexWrap': 'wrap'}}>
              {ReportGroupsMap.length > 0 &&
                <div style={{'display': 'flex', 'justifyContent': 'flex-end', 'alignItems': 'flex-end'}}>
                      <Dropdown
                          options={ReportGroupsMap}
                          labelSelector="label"
                          handleChangeFunc={this.handleChangeFullOption}
                          selectedOptionId={selectedAssessmentTypeFullId}
                          modifierClass={"candidate-assessment-single"}
                      />
                    <a
                    className="button button--stretch button--active margin-left-md"
                    href={`${process.env.REACT_APP_API_URL}reports/full/${selectedGroupFull.name}/${candidateAssessmentSelected.id}`}
                    target={'_blank'}
                    >
                    <span className="padding-right-sm">Download Report</span>
                    <i className="icon"><ChevronRight/></i>
                    </a>
                    <button style={{'height':'48px'}} className={this.state.copySuccess ? "button button--stretch button--info margin-left-md" : "button button--stretch button--active margin-left-md"}
                    onClick={this.copyLinkToClipboard}>
                    <span className="padding-right-sm">Copy Report Link</span>
                    <i className="icon"><ChevronRight/></i>
                    </button>
                    <textarea
                    readOnly
                    style={{position: 'absolute', left: '-9999px', zIndex: '-9999'}}
                    ref={(textarea) => this.textArea = textarea}
                    value={`${process.env.REACT_APP_API_URL}reports/full/${selectedGroupFull.name}/${candidateAssessmentSelected.id}`}
                    />

                </div>
              }

            </div>

            {/*<h3 style={{'marginTop': '20px'}}>Classic Report: High / Low Scores</h3>*/}

            {/*<div className="padding-bottom-md" style={{'display': 'flex', 'flexWrap': 'wrap'}}>*/}
            {/*  <div style={{'display': 'flex', 'justifyContent': 'flex-end', 'alignItems': 'flex-end'}}>*/}
            {/*    <Dropdown*/}
            {/*      options={ReportGroupsMap}*/}
            {/*      labelSelector="label"*/}
            {/*      handleChangeFunc={this.handleChangeOption}*/}
            {/*      selectedOptionId={selectedAssessmentTypeId}*/}
            {/*      modifierClass={"candidate-assessment-single"}*/}
            {/*    />*/}
            {/*    <a*/}
            {/*      className="button button--stretch button--active margin-left-md"*/}
            {/*      href={`${process.env.REACT_APP_API_URL}reports/${selectedGroup.name}/${candidateAssessmentSelected.id}`}*/}
            {/*      target={'_blank'}*/}
            {/*    >*/}
            {/*      <span className="padding-right-sm">Download Report</span>*/}
            {/*      <i className="icon"><ChevronRight/></i>*/}
            {/*    </a>*/}
            {/*    <button style={{'height':'48px'}} className={this.state.copyClassicSuccess ? "button button--stretch button--info margin-left-md" : "button button--stretch button--active margin-left-md"}*/}
            {/*            onClick={this.copyClassicLinkToClipboard}>*/}
            {/*      <span className="padding-right-sm">Copy Report Link</span>*/}
            {/*      <i className="icon"><ChevronRight/></i>*/}
            {/*    </button>*/}
            {/*    <textarea*/}
            {/*        readOnly*/}
            {/*        style={{position: 'absolute', left: '-9999px', zIndex: '-9999'}}*/}
            {/*        ref={(textarea) => this.textAreaClassic = textarea}*/}
            {/*        value={`${process.env.REACT_APP_API_URL}reports/${selectedGroup.name}/${candidateAssessmentSelected.id}`}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>
        }

      </section>
    )
  }
}

ModalCandidatesPageSingle.propTypes = {
  candidateAssessmentSelected: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.object),
  onUpdateCandidate: PropTypes.func.isRequired,
  onReminderCandidateAssessment: PropTypes.func.isRequired,
  onCreateLabel: PropTypes.func.isRequired
}

const mapStateToPropsSelector = createStructuredSelector({
  candidateAssessmentSelected: getCandidateAssessmentSelected,
  userOrganisation: getUserOrganisation,
  labels: getLabels
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateCandidate: (candidate) => {
    dispatch(updateCandidate(candidate))
  },
  onReminderCandidateAssessment: (candidate, expiresAt) => {
    dispatch(reminderCandidateAssessment(candidate, expiresAt))
  },
  onCreateLabel: (label) => {
    dispatch(createLabel(label))
  },
});

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalCandidatesPageSingle)
