import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createUserPassword } from 'fetchs/userPasswordFetch'
import Form from './Form'
import Confirmation from './Confirmation'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getConfirmation } from 'reducers/confirmationReducer'

class PasswordResetPage extends Component {
  onSubmitForm = (event) => {
    event.preventDefault()
    this.props.onCreateUserPassword(event.target.email.value)
  }

  render () {
    const { confirmation, branding } = this.props

    if (confirmation === 'passwordReset') {
      return (<Confirmation branding={branding}/>)
    } else {
      return (<Form onSubmitForm={this.onSubmitForm} branding={branding}/>)
    }
  }
}

PasswordResetPage.propTypes = {
  branding: PropTypes.string.isRequired,
  confirmation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  onCreateUserPassword: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  confirmation: getConfirmation
})

const mapDispatchToProps = (dispatch) => ({
  onCreateUserPassword: (email) => {
    dispatch(createUserPassword(email))
  }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(PasswordResetPage)
