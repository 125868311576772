import { ADD_BACKGROUND, REMOVE_BACKGROUND } from '../actions/backgroundAction'

const initialState = false

const background = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BACKGROUND:
      return true
    case REMOVE_BACKGROUND:
      return initialState
    default:
      return state
  }
}

export default background

export const getBackground = state => state.background
