import { addAlertExpiry } from 'actions/uiAction'
// CREATE

// READ
export const readBrandError = (errorCode) => {
  return function (dispatch) {
    switch (errorCode) {
      case 404:
        // No primary email
        dispatch(addAlertExpiry('warning', 'There is no organisation matching this domain, please login and a list of your assigned organisations will be displayed.'))
        break
      default:
    }
  }
}
// UPDATE

// DELETE

// LIST
