import { asyncFetch } from './apiFetch'
import { readBrandError } from 'errors/brandError'
import { setUserOrganisationBrand } from 'actions/userOrganisationAction'

// CREATE

// READ
export function readBrand (organisation) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'GET', `branding/${organisation}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            dispatch(readBrandError(response.customErrorCode))
            dispatch(setUserOrganisationBrand({ organisation: false, name: 'default' }))
          } else {
            dispatch(setUserOrganisationBrand(response))
          }
        })
  }
}

// UPDATE
export function updateBrand (organisationSelected) {
  return function (dispatch) {

    const data = {
      'name': organisationSelected.branding.name,
      // 'bgcolour': organisation.type,
    }
    return asyncFetch(dispatch, 'PATCH', `organisations/${organisationSelected.organisation.namespace}/branding`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            dispatch(readBrandError(response.customErrorCode))
          } else {
            // dispatch(addOrganisationSelectedBranding(response))
          }
        })
  }
}

// DELETE

// LIST
