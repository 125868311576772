import {
  ADD_USER_ORGANISATION_CREDITS,
  // REMOVE_USER_ORGANISATION_CREDITS,
  SET_USER_ORGANISATION,
  SET_USER_ORGANISATION_BRAND,
  // UNSET_USER_ORGANISATION
} from 'actions/userOrganisationAction'

const initialState = {
  namespace: 'default',
  organisation: {},
  assessments: [],
  credits: false,
  branding: {}
}

const userOrganisation = (state = initialState, action) => {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case SET_USER_ORGANISATION:
      newState['organisation'] = action.organisation
      break
      // return newState
    case ADD_USER_ORGANISATION_CREDITS:
      newState['credits'] = action.credits
      break
      // return newState
    case SET_USER_ORGANISATION_BRAND:
      newState['branding'] = action.branding
      newState['namespace'] = action.branding.organisation
      break
      // return newState
    // case UNSET_USER_ORGANISATION:
    //   return initialState
    // case REMOVE_USER_ORGANISATION_CREDITS:
    //   newState['credits'] = false
    //   return newState
    // case SET_USER_ORGANISATION_NAMES:
    //   newState['branding'] = action.branding
    //   newState['namespace'] = action.branding.organisation
    //   return newState
    default:
      break
      // return state
  }
  localStorage.setItem('userOrganisation', JSON.stringify(newState))
  return newState
}

export default userOrganisation

export const getUserOrganisation = state => state.userOrganisation

