import {createCandidate, createCandidateAndInvite, updateCandidate, anonymiseCandidate, listCandidates} from 'fetchs/candidateFetch'
import { getCandidates } from 'reducers/candidatesReducer'
import { getLabels } from 'reducers/labelsReducer'

export const SET_CANDIDATES = 'SET_CANDIDATES'
export const UNSET_CANDIDATES = 'UNSET_CANDIDATES'
export const ADD_CANDIDATE = 'ADD_CANDIDATE'
export const ADD_CANDIDATES = 'ADD_CANDIDATES'
export const REMOVE_CANDIDATE = 'REMOVE_CANDIDATE'
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE'
export const UPDATE_CANDIDATE_FIELD = 'UPDATE_CANDIDATE_FIELD'

export function createCandidates () {
  return function (dispatch, getState) {
    const candidates = getCandidates(getState())
    const labels = getLabels(getState())
    let candidatesCounter = candidates.length
    let updateTab = false;

    candidates.forEach((candidate) => {

      candidate.labels = validateLabel(labels, candidate.labels)

      if(!--candidatesCounter){
        updateTab =  true
      }

      dispatch(createCandidate(candidate, updateTab))
    })

  }
}

export function createCandidatesAndInvite (assessmentId) {
  return function (dispatch, getState) {
    const candidates = getCandidates(getState())
    const labels = getLabels(getState())
    let candidatesCounter = candidates.length
    let updateTab = false;

    candidates.forEach((candidate) => {

      candidate.labels = validateLabel(labels, candidate.labels)

      if(!--candidatesCounter){
        updateTab =  true
      }

      dispatch(createCandidateAndInvite(candidate, assessmentId, updateTab))
    })

  }
}

export function updateCandidateWithLabel (candidate) {
  return function (dispatch, getState) {
    const labels = getLabels(getState())
    candidate.labels = validateLabel(labels, candidate.labels)
    dispatch(updateCandidate(candidate))
  }
}

export function deleteCandidate (candidate) {
  return function (dispatch) {
    dispatch(anonymiseCandidate(candidate))
  }
}

function validateLabel(labels, candidateLabels){
  const validatedLabels = []
  candidateLabels.forEach((candidateLabel) => {
    const exists = labels.find(function (label) {
      return candidateLabel === label.id
    })

    if (!exists) {
      const existsByName = labels.find(function (label) {
        return candidateLabel === label.name
      })
      if (existsByName) {
        validatedLabels.push(existsByName.id)
      }
    } else {
      validatedLabels.push(exists.id)
    }
  })
  return validatedLabels
}

export function addCandidate (candidate) {
  return {
    type: ADD_CANDIDATE,
    candidate
  }
}

export function receiveCandidate (status, candidateId, candidate) {
  return {
    type: UPDATE_CANDIDATE,
    status,
    candidateId,
    candidate,
  }
}

export function updateCandidateField (candidateId, inputId, value) {
  return {
    type: UPDATE_CANDIDATE_FIELD,
    candidateId,
    inputId,
    value
  }
}

export function removeCandidate (candidate) {
  return {
    type: REMOVE_CANDIDATE,
    candidate
  }
}

export function receiveCandidates (candidates) {
  return {
    type: SET_CANDIDATES,
    candidates
  }
}

export function addCandidates (candidates) {
  return {
    type: ADD_CANDIDATES,
    candidates
  }
}

export function unSetCandidates () {
  return {
    type: UNSET_CANDIDATES,
  }
}
