import React, { PureComponent } from 'react';
import { object } from 'prop-types';
import { Link } from 'react-router-dom'

class HelpQuestionItem extends PureComponent {
  render() {
    const { fields } = this.props;

    if (fields === undefined) {
      return (
          false
      )
    }
    const subsectionSlug = fields.subsectionSlug.fields.slug;

    return (
        <Link to={`/default/user/help/${subsectionSlug}/${fields.slug}/`}>{fields.title}</Link>
    );
  }
}

HelpQuestionItem.propTypes = {
  fields: object.isRequired
};

export default HelpQuestionItem;
