import { SET_USER } from 'actions/userAction'

const initialState = false

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user
    default:
      return state
  }
}

export default user

export const getUser = state => state.user
