import React from 'react'
import PropTypes from 'prop-types'
import AddButton from 'assets/svg/components/Add'

const Add = ({ onAddCandidate }) => (
  <div className="padding-top-md padding-bottom-md">
    <span onClick={onAddCandidate}><AddButton/></span>
  </div>
)

Add.propTypes = {
  onAddCandidate: PropTypes.func.isRequired,
}

export default Add
