import { asyncFetch } from './apiFetch'
import { authenticateError } from 'errors/authenticateError'
import { applyToken } from 'actions/authenticateAction'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { addAlertExpiry } from 'actions/uiAction'
// import { readUser } from 'fetchs/userFetch'

// CREATE
export function createAuthentication (username, password, branding = false) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())
    const data = {
      'username': username,
      'password': password,
    }
    let namespace = false
    if (userOrganisation.namespace && userOrganisation.namespace !== 'default') {
      namespace = userOrganisation.namespace
      data['organisation'] = namespace
      data['branding'] = userOrganisation.branding.name
    }

    return asyncFetch(dispatch, 'POST', 'authenticate', data).then(
      (response) => {
        if (response && response.customErrorCode) {
          dispatch(authenticateError(response.customErrorCode))
        } else if(response) {
          dispatch(applyToken(response.token, namespace, branding))
        } else {
          dispatch(addAlertExpiry('warning', `Network error`, false))
        }
      }).catch(e => console.log(e))
  }
}

// READ

// UPDATE
export function updateAuthentication (organisation = false, branding = false) {
  return function (dispatch, getState) {
    if (!organisation) {
      const userOrganisation = getUserOrganisation(getState())
      organisation = userOrganisation.namespace
      branding = userOrganisation.branding.name
    }
    const data = {'organisation': organisation}

    return asyncFetch(dispatch, 'POST', 'token/refresh', data).then(
      (response) => {
        if (response && response.customErrorCode) {
            // we shouldn't be showing this error to the user
          //dispatch(authenticateError(response.customErrorCode))
        } else if(response && response.token && organisation && branding) {
          dispatch(applyToken(response.token, organisation, branding))
        } else {
          //dispatch(addAlertExpiry('warning', `Network error`, false))
        }
      })
  }
}

// DELETE

// LIST

