import React from 'react'
import { Link } from 'react-router-dom'
import FlowProfilerLogo from 'assets/svg/components/FlowProfilerLogo'
const Copy = () => (
  <div className="panel__block margin-top-lg">
    <div className="grid grid--between text-left padding-bottom-sm">
      <div className="grid__item w-5">
        <a className="padding-right-xsm" href="mailto:hello@flowprofiler.com" target="_blank" rel="noopener noreferrer">Contact</a>
        <span className="padding-right-xsm">|</span>
        <Link target="_blank" to={`/default/user/privacy`}>Privacy</Link>
      </div>
      <div className="grid__item w-6">
        <div className="text-right">
          <a href={`/default/user/login`}><p className="margin-bottom-none">Powered by <i
            className="logo"><FlowProfilerLogo/></i></p></a>
        </div>
      </div>
    </div>
    <span style={{ fontSize: "12px" }}>flowprofiler® and associated marks are registered trademarks of flowprofiler® Global Ltd.</span>
  </div>
)

export default Copy
