import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { createAuthentication } from 'fetchs/authenticationFetch'
import { removeConfirmation } from 'actions/uiAction'
import ChevronRight from 'assets/svg/components/ChevronRight'

class LoginPage extends Component {
  componentDidMount () {
    this.props.onRemoveConfirmation()
  }

  onSubmitForm = (event) => {
    event.preventDefault()
    this.props.onCreateAuthentication(event.target.email.value, event.target.password.value, this.props.branding)
  }

  render () {
    const { branding, userOrganisations } = this.props

    if (userOrganisations && branding === 'default') {
      return <Redirect to={`/default/user/organisations`}/>
    } else {
      return (
        <form className="form" onSubmit={this.onSubmitForm}>
          <h2 className="form__head">Login</h2>
          <div className="form__group" >
            <label className="label" style={{float:"left"}} htmlFor="emailLogin">Email Address or Username</label>
            <input autoComplete="email" className="input" name="email" type="text" id="emailLogin" required />
          </div>
          <div className="form__group">
            <div className="grid grid--flush grid--between">
              <div className="padding-right-sm">
                <label className="label label--bare" htmlFor="passwordLogin">Password</label>
              </div>
              <Link className="colour-active" to={`/${branding}/user/password/reset`}><b>Forgotten Password</b></Link>
            </div>
            <div className="padding-top-xsm">
              <input autoComplete="current-password" type="password" className="input" name="password" id="passwordLogin" required/>
            </div>
          </div>
          <button className="button button--stretch button--active">
            <span>Go</span>
            <i className="icon"><ChevronRight/></i>
          </button>
        </form>
      )
    }
  }
}

LoginPage.propTypes = {
  branding: PropTypes.string.isRequired,
  userOrganisations: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  onCreateAuthentication: PropTypes.func.isRequired,
  onRemoveConfirmation: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({})

const mapDispatchToProps = (dispatch) => ({
  onCreateAuthentication: (email, password, branding) => {
    dispatch(createAuthentication(email, password, branding))
  },
  onRemoveConfirmation: () => {
    dispatch(removeConfirmation())
  }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(LoginPage)
