import React, { Component } from 'react'
import Tab from './Tab'
import PropTypes from 'prop-types'

class Tabs extends Component {
  render () {
    const { handleActiveTab, activeTab } = this.props
    return (
      <div className="tabs">
        <Tab activeTab={activeTab} handleActiveTab={handleActiveTab} title={'Available'}/>
        <Tab activeTab={activeTab} handleActiveTab={handleActiveTab} title={'Sent'}/>
        <Tab activeTab={activeTab} handleActiveTab={handleActiveTab} title={'Completed'}/>
      </div>
    )
  }
}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  handleActiveTab: PropTypes.func.isRequired,
}

export default Tabs
