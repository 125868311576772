import { SET_REPORTS } from 'actions/reportAction'

const initialState = []

const reports = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORTS:
      return action.reports
    default:
      return state
  }
}

export default reports

export const getReports = state => state.reports
