import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import Logo from './Logo'
import Copy from './Copy'


const DualView = ({content, modifier}) => (
  <div>
    <div className={classNames('panel', {
      [`panel--${modifier}`]: modifier
    })}>
      <Logo/>
      <div className="panel__item panel__item--center">
        {content}
        <Copy/>
      </div>
    </div>
  </div>
)

DualView.propTypes = {
  modifier: string
}

DualView.defaultProps = {
  modifier: undefined
};

export default DualView

