import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Form/Select'
import Field from './Field'

const User = ({ user, onTextChange, onRoleChange, onStatusChange, roleOptions }) => (
  <div className="padding-top-md text-left">
    <div className="grid">
      <Field itemLabel={'First Name'}
             input={user.firstName}/>
      <Field itemLabel={'Last Name'}
             input={user.lastName}/>
      <Field itemLabel={'Role'}
             input={<Select parentId={user.id} onItemChange={onRoleChange} selectedItem={user.role ? user.role.id : ''}
                            itemOptions={roleOptions} itemName={'Role'}/>}/>
    </div>
  </div>
)

User.propTypes = {
  user: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onRoleChange: PropTypes.func.isRequired,
  roleOptions: PropTypes.array.isRequired,
}

export default User
