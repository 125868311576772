import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getCandidates } from 'reducers/candidatesReducer'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import {formatLabels} from 'actions/labelAction'

class InputPageStatus extends Component {
  render () {
    const { candidates } = this.props

    const headers = []
    let key = 0
    headers.push(<Header key={++key} header='First'/>)
    headers.push(<Header key={++key} header='Last'/>)
    headers.push(<Header key={++key} header='Email'/>)
    headers.push(<Header key={++key} header='Labels'/>)
    headers.push(<Header key={++key} header='Status'/>)

    let tableBody = <NoResults colSpan={6}/>
    const body = []

    if (candidates.length) {
      Object.values(candidates).forEach((candidate) => {
        const row = []
        row.push(<CellText header={'First'} value={candidate.firstName} key={++key}/>)
        row.push(<CellText header={'Last'} value={candidate.lastName} key={++key}/>)
        row.push(<CellText header={'Email'} value={candidate.email} key={++key}/>)
        row.push(<CellText header={'Label'} value={formatLabels(candidate.labels)} key={++key}/>)
        row.push(<CellText header={'Status'} value={candidate.status} key={++key}/>)
        body.push(<Row key={++key} row={row}/>)
      })
      tableBody = (<tbody>{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

InputPageStatus.propTypes = {
  candidates: PropTypes.array.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  candidates: getCandidates
})

export default connect(mapStateToPropsSelector, null)(InputPageStatus)
