import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginPage from 'containers/UserLoginPage'
import PasswordResetPage from 'containers/UserPasswordResetPage'
import PasswordUpdatePage from 'containers/UserPasswordUpdatePage'
import UserAssessmentNotFoundPage from 'containers/UserAssessmentNotFoundPage'
import UserAssessmentExpired from 'containers/UserAssessmentExpiredPage'
import Logo from './Logo'
import PropTypes from 'prop-types'
import Copy from './Copy'
import CopyNoContact from './CopyNoContact'

class LoginView extends Component {
  render () {
    const { branding, userOrganisations } = this.props
    const {pathname} = this.props.location;

    return (
      <div>
        <div className="panel">
          <Logo/>
          <div className="panel__item panel__item--center">
          <Switch>
            <Route path={`/${branding}/user/login`} render={(props) => (
              <LoginPage {...props} branding={branding} userOrganisations={userOrganisations}/>
            )}/>
            <Route path={`/${branding}/user/password/reset`} render={(props) => (
              <PasswordResetPage {...props} branding={branding}/>
            )}/>
            <Route path={`/${branding}/user/password/update/:username/:token`} render={(props) => (
              <PasswordUpdatePage {...props} branding={branding}/>
            )}/>
            <Route path={`/${branding}/user/assessment/404`} render={(props) => (
              <UserAssessmentNotFoundPage {...props} message={'Assessment not found'}/>
            )}/>
            <Route path={`/${branding}/user/assessment/expired`} render={(props) => (
                <UserAssessmentExpired {...props} message={'Assessment expired'}/>
            )}/>
            <Route path={`/${branding}/user/*`} render={(props) => (
              <UserAssessmentNotFoundPage {...props} message={'Page not found'}/>
            )}/>
          </Switch>
            {pathname == `/${branding}/user/assessment/expired` ? (
                <CopyNoContact/>
              ) : pathname == `/${branding}/user/login` && branding !== 'default' ? (
                <CopyNoContact/>
            ) : (
                <Copy/>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

LoginView.propTypes = {
  branding: PropTypes.string.isRequired,
  userOrganisations: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
}

export default LoginView
