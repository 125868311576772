import { SET_ROLES } from 'actions/roleAction'

const initialState = []

const roles = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROLES:
      return action.roles
    default:
      return state
  }
}

export default roles

export const getRoles = state => state.roles
