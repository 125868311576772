import React from 'react'
import PropTypes from 'prop-types'

const CellCheckbox = ({ isChecked, onChange }) => (
  <td className="table__data table__checkbox">
    <input type='checkbox' checked={isChecked} onChange={onChange}/>
  </td>
)

CellCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default CellCheckbox
