import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Filter from './Filter'
import Table from './Table'
import { addModal } from 'actions/uiAction'
import { listLabels } from 'fetchs/labelFetch'
import { listOrganisationCandidatesAssessments } from 'fetchs/organisationCandidateAssessmentFetch'
import { addBackground, removeBackground } from 'actions/backgroundAction'
import { createStructuredSelector } from 'reselect'
import { getCandidatesAssessments } from 'reducers/candidatesAssessmentsReducer'
import { getLabels } from 'reducers/labelsReducer'
import { setCandidateAssessmentSelected } from 'actions/candidateAssessmentSelectedAction'
import Pagination from 'components/Pagination'
import {readOrganisation} from "../../fetchs/organisationFetch";
import {getUserOrganisation} from "../../reducers/userOrganisationReducer";

class CandidatesAssessmentsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchValue: '',
      lastSearch: 0,
      minimumSearch: false,
      selectedLabel: '',
      selectedStatus: '',
      selectedInvited: null,
      selectAll: false,
    }
    this.props.onListOrganisationCandidatesAssessments(true, 1)
  }

  componentDidMount () {
    this.props.onListLabels()
    this.props.onAddBackground()
    this.props.onReadOrganisation(this.props.userOrganisation.namespace)
  }

  componentWillUnmount () {
    this.props.onRemoveBackground()
  }

  handleSearchChange = (event) => {
    const searchValue = event.target.value
    const date = new Date()
    const timestamp = date.getTime()
    const second = 1000
    this.setState({ searchValue, lastSearch: timestamp })

    setTimeout(() => {
      if (this.state.searchValue.length > 2 && this.state.lastSearch + (second) > timestamp) {
        this.setState({ minimumSearch: true })
        this.props.onListOrganisationCandidatesAssessments(false, 1, this.state.searchValue, this.state.selectedLabel, this.state.selectedStatus, this.state.selectedInvited)
      } else if (this.state.minimumSearch) {
        this.setState({ minimumSearch: false })
        this.props.onListOrganisationCandidatesAssessments(false, 1, '', this.state.selectedLabel, this.state.selectedStatus, this.state.selectedInvited)
      }
    }, second)
  }

  handleLabelChange = (event) => {
    this.setState({ selectedLabel: event.target.value })
    this.props.onListOrganisationCandidatesAssessments(false, 1, this.state.searchValue, event.target.value, this.state.selectedStatus, this.state.selectedInvited)
  }

  handleStatusChange = (event) => {
    this.setState({ selectedStatus: event.target.value })
    this.props.onListOrganisationCandidatesAssessments(false, 1, this.state.searchValue, this.state.selectedLabel, event.target.value, this.state.selectedInvited)
  }

  handleClickCandidatesAssessments = (candidateAssessment) => {
    this.props.onSetCandidateAssessmentSelected(candidateAssessment)
    this.props.onAddModal('Individual Assessment')
  }

  handleInvitedChange = (date) => {
    this.setState({ selectedInvited: date })
    this.props.onListOrganisationCandidatesAssessments(false, 1, this.state.searchValue, this.state.selectedLabel, this.state.selectedStatus, date)
  }

  handleReset = () => {
    this.setState({ page: 1, searchValue: '', selectedLabel: '', selectedStatus: '', selectedInvited: null })
    this.props.onListOrganisationCandidatesAssessments(false, 1)
  }

  handlePageChange = (page) => {
    this.props.onListOrganisationCandidatesAssessments(false, page, this.state.searchValue, this.state.selectedLabel, this.state.selectedStatus, this.state.selectedInvited)
  }

  render () {
    const { candidatesAssessments, labels } = this.props
    const { searchValue, selectedLabel, selectedStatus, selectedInvited } = this.state

    return (
      <section>
        <h2>Assessments</h2>
        <Filter labels={labels} searchValue={searchValue}
                selectedLabel={selectedLabel} selectedStatus={selectedStatus} selectedInvited={selectedInvited}
                onSearchChange={this.handleSearchChange}
                onLabelChange={this.handleLabelChange} onStatusChange={this.handleStatusChange}
                onInvitedChange={this.handleInvitedChange} onReset={this.handleReset}/>

        <Table candidatesAssessments={candidatesAssessments}
               onClickCandidatesAssessments={this.handleClickCandidatesAssessments}/>
        <Pagination onPageChange={this.handlePageChange}/>
      </section>
    )
  }
}

CandidatesAssessmentsPage.propTypes = {
  organisationAssessments: PropTypes.arrayOf(PropTypes.object),
  candidatesAssessments: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  onListOrganisationCandidatesAssessments: PropTypes.func.isRequired,
  onListLabels: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onAddBackground: PropTypes.func.isRequired,
  onRemoveBackground: PropTypes.func.isRequired,
  onSetCandidateAssessmentSelected: PropTypes.func.isRequired,
  onReadOrganisation: PropTypes.func.isRequired,
  userOrganisation: PropTypes.object.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  candidatesAssessments: getCandidatesAssessments,
  labels: getLabels,
  userOrganisation: getUserOrganisation,
})

const mapDispatchToProps = (dispatch) => ({
  onListOrganisationCandidatesAssessments: (reset, page, searchFilter, labelFilter, statusFilter, invitedFilter) => {
    dispatch(listOrganisationCandidatesAssessments(reset, page, searchFilter, labelFilter, statusFilter, invitedFilter))
  },
  onListLabels: () => {
    dispatch(listLabels())
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
  onAddBackground: () => {
    dispatch(addBackground())
  },
  onRemoveBackground: () => {
    dispatch(removeBackground())
  },
  onSetCandidateAssessmentSelected: (candidateAssessment) => {
    dispatch(setCandidateAssessmentSelected(candidateAssessment))
  },
  onReadOrganisation: (organisation) => {
    dispatch(readOrganisation(organisation))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(CandidatesAssessmentsPage)
