import React from 'react'
import ChevronRight from 'assets/svg/components/ChevronRight'
import PropTypes from 'prop-types'

const Options = ({ handleConfirm }) => (
  <div className="grid__item w-12">
    <div className="padding-top-sm">
      <button onClick={handleConfirm} className="button button--stretch button--active">
        <span className="padding-right-sm">Save</span>
        <i className="icon"><ChevronRight/></i>
      </button>
    </div>
  </div>
)

Options.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
}

export default Options
