import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/Form/Text'
import Field from './Field'
import ChevronRight from 'assets/svg/components/ChevronRight'

const UserInfo = ({ user, onTextChange }) => (
    <div className="card card--stretch">
        <div className="grid">
            <Field required={false}
                   label={'New password (minimum length of 8, mixed case, includes at least 1 number and 1 special character)'} input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.password} secureTextEntry="true"
                                                       placeholder={'New Password'}
                                                       required={false}
                                                       type={'password'}
                                                       autocomplete={'new-password'}
                                                       itemName={'password'}/>}/>
            <Field required={false}
                   label={'Confirm new password'} input={<Text parentId={user.id} onItemChange={onTextChange} itemValue={user.passwordConfirm}
                                                               placeholder={'Confirm New Password'}
                                                               required={false}
                                                               type={'password'}
                                                               autocomplete={'new-password'}
                                                               itemName={'passwordConfirm'}/>}/>
            <Field input={<button type={'submit'} className="button button--stretch button--active">
                <span className="padding-right-sm">Update</span>
                <i className="icon"><ChevronRight/></i>
            </button>}/>
        </div>
    </div>
)

UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
    onTextChange: PropTypes.func.isRequired,
}

export default UserInfo
