import React from 'react'

const ChevronRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11.6" height="20">
    <path fill="none" stroke="#FFF" strokeWidth="1.738" strokeLinecap="round" strokeLinejoin="round"
          d="M3.5 14.6L8.1 10 3.5 5.4"/>
  </svg>
)

export default ChevronRight
