import React from 'react'

const Logo = ({logoImageUrl, onImageChange, onSaveImage}) => (
  <div>
    <h5>Company Logo:</h5>
    <div className="padding-bottom-md">
      <div className="padding-bottom-xsm"><img alt={'no logo'} height="100" src={logoImageUrl}/></div>
    </div>
    <form onSubmit={(event) => onSaveImage('logo', event)}>
      <input className="fileInput"
             type="file"
             onChange={(event) => onImageChange('logo', event)} />
      <button type={'submit'} className="button button--stretch button--active">Update</button>
    </form>
  </div>
)

export default Logo
