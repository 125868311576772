import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import Add from 'containers/InputPage/Form/Add'
import Inputs from 'containers/InputPage/Form/Inputs'
import Buttons from 'containers/InputPage/Form/Buttons'
import {
    addCandidate,
    createCandidates,
    createCandidatesAndInvite,
    removeCandidate,
    unSetCandidates,
    updateCandidateField
} from 'actions/candidateAction'
import {addAlertExpiry, addModal, replaceModal} from 'actions/uiAction'
import { createLabel, listLabels } from 'fetchs/labelFetch'
import { getCandidates } from 'reducers/candidatesReducer'
import { getLabels } from 'reducers/labelsReducer'
import {addCandidatesSelected} from "../../../../actions/candidateSelectedAction";
import {updateCandidatesAssessments} from "../../../../actions/candidateAssessmentAction";
import {listCandidates} from "../../../../fetchs/candidateFetch";
import {getOrganisationAssessments} from "../../../../reducers/organisationAssessmentsReducer";

class Form extends Component {
    constructor (props) {
        super(props)

        this.state = {
            idCounter: 0,
        }

        this.props.onUnSetCandidates()
        this.props.onListLabels()
    }

    componentDidMount () {
        this.handleAddCandidate()
    }

    handleTextChange = (event, candidateId, itemName) => {
        this.props.onUpdateCandidate(candidateId, itemName, event.target.value)
    }

    handleLabelChange = (newValue, candidateId) => {
        const labels = []
        newValue.forEach((label) => {
            if (label.__isNew__) {
                this.props.onCreateLabel(label.value)
            }
            labels.push(label.value)
        })
        this.props.onUpdateCandidate(candidateId, 'labels', labels)
    }

    handleAddCandidate = () => {

        // brute force clear candidates from previous input
        if (this.props.candidates && this.props.candidates.length > 0) {
            Object.values(this.props.candidates).forEach((candidate) => {
                if (candidate.status !== 'Pending') {
                    this.props.onRemoveCandidate(candidate)
                }
            })
        }

        const idCounter = this.state.idCounter + 1
        const candidate = {
            id: idCounter,
            status: 'Pending',
            firstName: '',
            lastName: '',
            email: '',
            labels: []
        }
        this.props.onAddCandidate(candidate)

        this.setState({ idCounter })
    }

    handleDeleteCandidate = (candidate) => {
        this.props.onRemoveCandidate(candidate)
    }

    handleConfirm = async (event) => {
        event.preventDefault()

        let candidateAssessment = null
        let organisationAssessments = this.props.organisationAssessments
        let candidateAssessments = []
        Object.keys(organisationAssessments).forEach(function(key) {
            let organisationAssessment = organisationAssessments[key];

            if (organisationAssessment.tokenType !== 'team' && organisationAssessment.assessment.slug === 'flowprofilernorm') {
                candidateAssessments.push(organisationAssessments[key])
            }
        });

        if (candidateAssessments.length > 0) {
            for (let i = 0; i < candidateAssessments.length; i++) {
                this.props.onCreateCandidatesAndInvite(candidateAssessments[i].id)
            }
        }
        else {
            this.props.onAddAlertExpiry('You do not have any credits for the flowprofiler®. Please contact your supplier.')
        }

        if(!this.props.newCandidates){
            this.props.onAddModal('Individual Input')
        }
    }

    render () {
        const { candidates, labels } = this.props

        const inputs = []
        Object.values(candidates).forEach((candidate) => {
            if (candidate.status === 'Pending') {
                inputs.push(<Inputs key={candidate.id} labels={labels} candidate={candidate}
                                    onTextChange={this.handleTextChange}
                                    onLabelChange={this.handleLabelChange}
                                    onDeleteCandidate={this.handleDeleteCandidate}/>)
            }
        })

        return (
            <form className="form" onSubmit={this.handleConfirm}>
                {inputs}
                <Add onAddCandidate={this.handleAddCandidate}/>
                <Buttons/>
            </form>
        )
    }
}

Form.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.object),
    candidates: PropTypes.arrayOf(PropTypes.object),
    onCreateCandidates: PropTypes.func.isRequired,
    onCreateCandidatesAndInvite: PropTypes.func.isRequired,
    onListLabels: PropTypes.func.isRequired,
    onUpdateCandidate: PropTypes.func.isRequired,
    onAddCandidate: PropTypes.func.isRequired,
    onRemoveCandidate: PropTypes.func.isRequired,
    onUnSetCandidates: PropTypes.func.isRequired,
    onAddModal: PropTypes.func.isRequired,
    onCreateLabel: PropTypes.func.isRequired,
    onNewCandidates: PropTypes.func,
    onAddCandidatesSelected: PropTypes.func.isRequired,
    onUpdateCandidatesAssessments: PropTypes.func.isRequired,
    organisationAssessments: PropTypes.arrayOf(PropTypes.object),
    onAddAlertExpiry: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
    labels: getLabels,
    candidates: getCandidates,
    organisationAssessments: getOrganisationAssessments
})

const mapDispatchToProps = (dispatch) => ({
    onCreateCandidates: () => {
        dispatch(createCandidates())
    },
    onCreateCandidatesAndInvite: (assessmentId) => {
        dispatch(createCandidatesAndInvite(assessmentId))
    },
    onListLabels: () => {
        dispatch(listLabels())
    },
    onUpdateCandidate: (candidateId, inputId, value) => {
        dispatch(updateCandidateField(candidateId, inputId, value))
    },
    onAddCandidate: (candidate) => {
        dispatch(addCandidate(candidate))
    },
    onRemoveCandidate: (candidate) => {
        dispatch(removeCandidate(candidate))
    },
    onUnSetCandidates: () => {
        dispatch(unSetCandidates())
    },
    onAddModal: (modal) => {
        dispatch(replaceModal(modal))
    },
    onCreateLabel: (label) => {
        dispatch(createLabel(label))
    },
    onAddCandidatesSelected: (candidate) => {
        dispatch(addCandidatesSelected(candidate))
    },
    onUpdateCandidatesAssessments: () => {
        dispatch(updateCandidatesAssessments())
    },
    onAddAlertExpiry: (message) => {
        dispatch(addAlertExpiry('warning', message))
    },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Form)
