import { receiveCandidateAssessment } from 'actions/candidateAssessmentAction'

export const candidateAssessmentError = (errorCode, candidateAssessment) => {
  return function (dispatch) {
    switch (errorCode) {
      case 4009002:
        // 'No credit available. There were not enough shared tokens available to complete this request. Please contact your administrator.'
        dispatch(receiveCandidateAssessment('No credit', candidateAssessment))
        break
      case 4009003:
        // 'No credit available. There were not enough basic tokens available to complete this request. Please contact your administrator.'
        dispatch(receiveCandidateAssessment('No credit', candidateAssessment))
        break
      case 409:
        // 'Similar assessment for given candidate already exists.'
        dispatch(receiveCandidateAssessment('Duplicate', candidateAssessment))
        break
      case 4009001:
        // 'Assessment has been disabled. Please contact your administrator.'
        dispatch(receiveCandidateAssessment('Assessment Disabled', candidateAssessment))
        break
      default:
    }
  }
}
