import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CrossCircle from 'assets/svg/components/CrossCircle'
import Text from 'components/Form/Text'
import Field from '../../../InputPage/Form/Field'
import MultiSelect from 'components/Form/MultiSelect'
import Dropdown from "../../../../components/Dropdown/Dropdown";
import Select from "./Select";
import SelectOption from "../../../../components/Filter/SelectOption";

class Inputs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedRelationship: 0
        };
    }

    handleChangeRelationshipOption = id => {
        this.setState({
            selectedRelationship: id
        });
    };

    render () {
        const { reviewer, onTextChange, onDeleteReviewer, onRelationshipChange } = this.props

        const relationshipOptions = []
        relationshipOptions.push(<SelectOption key={1} value={'Manager'} title={'Manager'}/>)
        relationshipOptions.push(<SelectOption key={2} value={'Peer'} title={'Peer'}/>)
        relationshipOptions.push(<SelectOption key={3} value={'Report'} title={'Report'}/>)
        relationshipOptions.push(<SelectOption key={4} value={'Other'} title={'Other'}/>)

        const { selectedRelationship } = this.state;

        console.log(reviewer)

        return (
            <div className="card card--stretch" style={{ overflow: 'visible' }}>
                <div className="grid">
                    <Field required={true}
                           input={<Text parentId={reviewer.id} onItemChange={onTextChange} itemValue={reviewer.firstName}
                                        placeholder={'First Name'}
                                        itemName={'firstName'}/>}/>
                    <Field required={true}
                           input={<Text parentId={reviewer.id} onItemChange={onTextChange} itemValue={reviewer.lastName}
                                        placeholder={'Last Name'}
                                        itemName={'lastName'}/>}/>
                    <Field required={true}
                           input={<Text parentId={reviewer.id} onItemChange={onTextChange} itemValue={reviewer.email}
                                        placeholder={'Email'}
                                        itemName={'email'}/>}/>

                    <Field required={true} itemLabel={'Relationship'}
                           input={<Select parentId={reviewer.id} onItemChange={(event) => onRelationshipChange(event, reviewer)} itemOptions={relationshipOptions}
                                          selectedItem={reviewer.relationship}
                                          itemName={'relationship'}
                                          required={true}/>}/>



                    {/*<Field input={<MultiSelect parentId={candidate.id} onItemChange={onLabelChange} itemName={'Label'}*/}
                    {/*                           itemOptions={labelOptions}/>}/>*/}
                    <div style={{float:"right"}}>
                        <div className="card__close" onClick={() => onDeleteReviewer(reviewer)}><CrossCircle/></div>
                    </div>
                </div>
            </div>
        )
    }
}

Inputs.propTypes = {
    reviewer: PropTypes.object.isRequired,
    onDeleteReviewer: PropTypes.func.isRequired,
    onTextChange: PropTypes.func.isRequired,
    onRelationshipChange: PropTypes.func.isRequired,
}

export default Inputs
