import React from 'react'

const Add = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="63" height="63">
    <circle fill="#005A6D" cx="31.5" cy="31.5" r="30.8"/>
    <g fill="none" stroke="#FFF" strokeWidth="1.307" strokeMiterlimit="10">
      <path d="M31.5 21.9v19.3M21.9 31.5h19.3"/>
    </g>
  </svg>
)

export default Add
