import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { addActiveTab, addAlertExpiry, addModal, removeModal } from 'actions/uiAction'
import { listOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import { updateUserPreferences } from 'actions/userAction'

import { setAssessmentsSelected } from 'actions/assessmentAction'
import CardGroup from 'components/CardGroup'
import ButtonGroup from './ButtonGroup'
import { createStructuredSelector } from 'reselect'
import { setTeamReport } from 'actions/teamReportAction'
import { getOrganisationAssessments } from 'reducers/organisationAssessmentsReducer'
import { getAssessmentsSelected } from 'reducers/assessmentsSelectedReducer'
import { getUser } from 'reducers/userReducer'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { getTeamReportsSaved } from '../../reducers/teamReportsSavedReducer'
import { readOrganisation } from 'fetchs/organisationFetch'
import { listTeamReportsSaved } from '../../fetchs/organisationCandidateAssessmentFetch'
import Table from "./Table";
import fp from '../../assets/fp.png';

class Assessments extends Component {
    constructor (props) {
        super(props)
    }

    componentDidMount () {
        // also calls listOrganisationAssessments
        this.props.onListOrganisationAssessments(this.props.userOrganisation)
        this.props.onReadOrganisation(this.props.userOrganisation.namespace)
        this.props.onListTeamReportsSaved()
    }

    // componentDidUpdate(nextProps){
    //     if (nextProps.organisationAssessments !== this.props.organisationAssessments){
    //         this.setState({
    //             organisationAssessments:this.props.organisationAssessments
    //         });
    //     }
    // }

    handleAddAssessment = (assessmentItem) => {
        console.log(assessmentItem)
        const assessmentsSelected = {}
        assessmentsSelected[assessmentItem.id] = true
        this.props.onSetAssessmentsSelected(assessmentsSelected)
    }

    handleRemoveAssessment = (assessmentItem) => {
        const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
        delete assessmentsSelected[assessmentItem.id]
        this.props.onSetAssessmentsSelected(assessmentsSelected)
    }

    handleGoClick = () => {
        if (Object.keys(this.props.assessmentsSelected).length) {
            this.props.onSetTeamReport(null)
            this.props.onAddActiveTab('New')
            this.props.onAddModal('Select Team Members')
        } else {
            this.props.onAddAlertExpiry('Please Select an Assessment')
        }
    }

    handleClickReport = (event, teamReport) => {
        this.props.onSetTeamReport(teamReport)
        this.props.onAddModal('Download Team Report')
    }

    render () {
        const { assessmentsSelected, user, organisationAssessments, userOrganisation,  userOrganisation: { namespace } } = this.props;

        let orgAssessments = []
        //let hasFlowProfiler = false
        // remove flowprofiler from assessment options
        Object.keys(organisationAssessments).forEach(function(key) {
            let organisationAssessment = organisationAssessments[key];

            if (organisationAssessment.assessment.slug !== 'flowprofilernorm' && organisationAssessment.assessment.slug !== 'leadershipflow360') {
                //delete organisationAssessments[key]
                orgAssessments.push(organisationAssessments[key])

            }
        });

        const { teamReports } = this.props

        if (userOrganisation.organisation && userOrganisation.organisation.teamReportsActive) {
            return (
                <div>
                    <h2>Create Team Report</h2>
                    {orgAssessments.length > 0 &&
                    <div>
                        <CardGroup
                            cards={orgAssessments}
                            assessmentsSelected={assessmentsSelected}
                            handleRemoveAssessment={this.handleRemoveAssessment}
                            handleAddAssessment={this.handleAddAssessment}
                            tilePreference={user.preferences && user.preferences[namespace] ? user.preferences[namespace].tiles : null}
                        />
                        <ButtonGroup
                            handleGoClick={this.handleGoClick}
                        />
                    </div>
                    }

                    <h3>Saved team reports</h3>
                    <Table teamReports={teamReports} onClickReport={this.handleClickReport}
                           modal={true}/>
                </div>
            )
        }
        else if (userOrganisation.organisation.parent) {
            return (
                <div style={{display: 'flex', alignItems: 'center', margin: 'auto', marginTop: '20%', maxWidth: '300px' }}><img style={{marginRight: '10px'}} src={fp} alt="flowprofiler®" width="100px" height="100px" /><span>Please <a href={userOrganisation.organisation.parent.url} target="_blank" style={{textDecoration: 'underline'}}>click here</a> to contact<br/> your flowprofiler® Partner<br/> and activate <strong>Teams</strong></span></div>
            )
        }
        else {
            // the organisation data is not vailable after reloading the page
            return (
                <div></div>
            )
        }

    }
}

Assessments.propTypes = {
    assessmentsSelected: PropTypes.object.isRequired,
    organisationAssessments: PropTypes.arrayOf(PropTypes.object),
    onListOrganisationAssessments: PropTypes.func.isRequired,
    onAddModal: PropTypes.func.isRequired,
    onSetAssessmentsSelected: PropTypes.func.isRequired,
    onAddAlertExpiry: PropTypes.func.isRequired,
    onReadOrganisation: PropTypes.func.isRequired,
    onListTeamReportsSaved: PropTypes.func.isRequired,
    onSetTeamReport: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
    organisationAssessments: getOrganisationAssessments,
    assessmentsSelected: getAssessmentsSelected,
    user: getUser,
    userOrganisation: getUserOrganisation,
    teamReports: getTeamReportsSaved
})

const mapDispatchToProps = (dispatch) => ({
    onListOrganisationAssessments: () => {
        dispatch(listOrganisationAssessments())
    },
    onUpdateUserPreferences: preferenceData => {
        dispatch(updateUserPreferences(preferenceData))
    },
    onAddModal: (modal) => {
        dispatch(removeModal())
        dispatch(addModal(modal))
    },
    onSetAssessmentsSelected: (assessments) => {
        dispatch(setAssessmentsSelected(assessments))
    },
    onAddAlertExpiry: (message) => {
        dispatch(addAlertExpiry('warning', message))
    },
    onAddActiveTab: (activeTab) => {
        dispatch(addActiveTab(activeTab))
    },
    onReadOrganisation: (organisation) => {
        dispatch(readOrganisation(organisation))
    },
    onListTeamReportsSaved: () => {
        dispatch(listTeamReportsSaved())
    },
    onSetTeamReport: (teamReport) => {
        dispatch(setTeamReport(teamReport))
    },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Assessments)
