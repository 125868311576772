import { asyncFetch } from './apiFetch'
import { receiveOrganisationAssessments, unSetAssessmentsSelected } from 'actions/assessmentAction'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { addOrganisationSelectedAssessments } from 'actions/organisationSelectedAction'
// import { organisationAssessmentsError } from 'errors/assessmentError'

// CREATE
export function createOrganisationAssessments (organisation, assessment, tokenType) {
  return function (dispatch) {
    const data = {
      'assessment': assessment,
      'tokenType': tokenType
    }
    return asyncFetch(dispatch, 'POST', `organisations/${organisation.namespace}/assessments`, data).then(
      (response) => {
        if (response && response.customErrorCode) {
          // organisationAssessmentsError(dispatch, response.customErrorCode)
        } else {
          dispatch(listOrganisationAssessments(organisation, addOrganisationSelectedAssessments))
        }
      })
  }
}
// READ

// UPDATE

// DELETE
export function deleteOrganisationAssessments (organisation, assessment) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'DELETE', `organisations/${organisation.namespace}/assessments/${assessment.id}`).then(
      (response) => {
        if (response && response.customErrorCode) {
          // organisationAssessmentsError(dispatch, response.customErrorCode)
        } else {
          dispatch(listOrganisationAssessments(organisation, addOrganisationSelectedAssessments))
        }
      })
  }
}
// LIST
export function listOrganisationAssessments (organisation = false, responseMethod = receiveOrganisationAssessments) {
  const deletedAtFilter = '&filters[]=and-assessment.deletedAt-bool-value-false'
  const disabledFilter = '&filters[]=and-o_assessment.tokenType-ninci-value-disabled'

  return function (dispatch, getState) {
      //dispatch(receiveOrganisationAssessments([]))
    dispatch(unSetAssessmentsSelected())

    if (!organisation) {
      organisation = getUserOrganisation(getState())
    }

    return asyncFetch(dispatch, 'GET', `organisations/${organisation.namespace}/assessments?${deletedAtFilter}${disabledFilter}`).then(
      (response) => {
        if (response && response.customErrorCode) {
          // organisationAssessmentsError(dispatch, response.customErrorCode)
        } else {
            console.log(response.json)
          dispatch(responseMethod(response.json))
        }
      })
  }
}

// REORDER
//
export function reorderOrganisationAssessments(orderId) {
  let organisation = {namespace: 99999999}; // TEMP
  let assessment = {id: 99999999}; // TEMP
  return function(dispatch) {
    return asyncFetch(dispatch, 'POST', `organisations/${organisation.namespace}/assessments/${assessment.id}`, {order:orderId}).then(
      (response) => {
        if (response && response.customErrorCode) {
          // TODO
        } else {
          // TODO
        }
      })
  }
}
