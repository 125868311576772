import {
  ADD_CANDIDATE,
  ADD_CANDIDATES,
  REMOVE_CANDIDATE,
  SET_CANDIDATES,
  UNSET_CANDIDATES,
  UPDATE_CANDIDATE,
  UPDATE_CANDIDATE_FIELD
} from 'actions/candidateAction'

const initialState = []

const candidates = (state = initialState, action) => {
  let newState = Object.assign([], state)
  switch (action.type) {
    case SET_CANDIDATES:
      return action.candidates
    case ADD_CANDIDATES:
      newState.push(action.candidates)
      return newState
    case UNSET_CANDIDATES:
      return initialState
    case ADD_CANDIDATE:
      newState.push(action.candidate)
      return newState
    case UPDATE_CANDIDATE:
      return newState.map(function (candidate) {
        if (candidate.id === action.candidateId) {
          candidate = action.candidate
          candidate['status'] = action.status
        }
        return candidate
      })
    case UPDATE_CANDIDATE_FIELD:
      return newState.map(function (candidate) {
        if (candidate.id === action.candidateId) {
          candidate[action.inputId] = action.value
        }
        return candidate
      })
    case REMOVE_CANDIDATE:
      return newState.filter(obj => {
        return obj.id !== action.candidate.id
      })
    default:
      return state
  }
}

export default candidates

export const getCandidates = state => state.candidates
