import { asyncFetch } from './apiFetch'
import { receiveUserOrganisations } from 'actions/userAction'

// CREATE

// READ

// UPDATE

// DELETE

// LIST

export function listUserOrganisations () {
  return function (dispatch, getState) {
    return asyncFetch(dispatch, 'GET', `user/organisations`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // organisationAssessmentsError(dispatch, response.customErrorCode)
          } else {
            dispatch(receiveUserOrganisations(response.json))
          }
        })
  }
}
