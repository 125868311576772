import { SET_LABEL_SELECTED } from 'actions/labelAction'

const initialState = {}

const labelSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_LABEL_SELECTED:
      return action.label
    default:
      return state
  }
}

export default labelSelected

export const getLabelSelected = state => state.labelSelected
