import React from 'react'

const CrossCircle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24.7" height="24.7">
    <circle fill="none" stroke="#777" strokeWidth="1.085" strokeMiterlimit="10" cx="12.4" cy="12.4" r="10.4"/>
    <g fill="none" stroke="#777" strokeWidth="1.085" strokeMiterlimit="10">
      <path d="M7.8 8l9 8.9M7.8 16.8l9-9"/>
    </g>
  </svg>
)

export default CrossCircle
