import React from 'react'

const Help = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32">
    <g fill="none" stroke="#B1B2B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="16" cy="16" r="8"/>
      <path d="M16 18c1.7 0 3-1.3 3-3s-1.3-3-3-3-3 1.3-3 3M16 18v2"/>
    </g>
  </svg>
)

export default Help
