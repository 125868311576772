import { SET_LABELS } from 'actions/labelAction'
import { UPDATE_LABEL } from 'actions/labelAction'

const initialState = []

const labels = (state = initialState, action) => {
  let newState = Object.assign([], state)
  switch (action.type) {
    case SET_LABELS:
      return action.labels
    case UPDATE_LABEL:
      return newState.map(function (label) {
        if (label.id === action.label.id) {
          label = action.label
        }
        return label
      })
    default:
      return state
  }
}

export default labels

export const getLabels = state => state.labels
