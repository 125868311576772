import React from 'react'
import PropTypes from 'prop-types'

const Tab = ({ activeTab, title, handleActiveTab }) => (
  <div
    className={activeTab === title ? 'tabs__item tabs__item--active' : 'tabs__item'}
    onClick={() => handleActiveTab(title)}>
    {title}
  </div>
)

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleActiveTab: PropTypes.func.isRequired,
}

export default Tab
