import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import Go from './Go'
import Candidate from './Candidate'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import Tabs from './Tabs'
import { addAlertExpiry } from 'actions/uiAction'
import { updateCandidateWithLabel, deleteCandidate } from 'actions/candidateAction'
import { listOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import { updateCandidateAssessments, } from 'actions/candidateAssessmentAction'
import { setAssessmentsSelected } from 'actions/assessmentAction'
import { getAssessmentsSelected } from 'reducers/assessmentsSelectedReducer'
import { getCandidateSelected } from 'reducers/candidateSelectedReducer'
import { getAssessmentsByStatus } from 'reducers/candidatesAssessmentsReducer'
import { getLabels } from 'reducers/labelsReducer'
import { createLabel } from 'fetchs/labelFetch'
import { readCandidateAssessment } from 'fetchs/candidateAssessmentFetch'
import Field from "../../InputPage/Form/Field";
import DateWrapper from 'components/Form/DateWrapper'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

class ModalCandidatesPageSingle extends Component {
  constructor (props) {
    super(props)

    this.state = {
      expiresAt: '',
      open: false
    }

    const candidate = { ...this.props.candidateSelected }
    const candidateLabels = this.props.candidateSelected.labels

    candidate.labels = this.props.labels.reduce(function (filtered, label) {
      const exists = candidateLabels.find(function(candidateLabel) {
        return candidateLabel.id === label.id;
      });
      if(exists){
        filtered.push(label.id)
      }

      return filtered
    }, [])

    this.state = {
      candidate,
      activeTab: 'Available',
    }

    this.props.onReadCandidateAssessment(this.props.candidateSelected)
    this.props.onListOrganisationAssessments()
  }

  handleAddAssessment = (assessmentItem) => {
    const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
    assessmentsSelected[assessmentItem.id] = true
    this.props.onSetAssessmentsSelected(assessmentsSelected)
  }

  handleRemoveAssessment = (assessmentItem) => {
    const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
    delete assessmentsSelected[assessmentItem.id]
    this.props.onSetAssessmentsSelected(assessmentsSelected)
  }

  handleConfirm = () => {
    if (Object.keys(this.props.assessmentsSelected).length) {
        this.props.onUpdateCandidateAssessments(this.state.expiresAt)
        this.props.onAddModal('Individual Assessments')
    } else {
        this.props.onAddAlertExpiry('Please Select an Assessment')
    }
  }

  handleDateChange = (date) => {
    if (date) {
      let dd = String(date.getDate()).padStart(2, '0');
      let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = date.getFullYear();
      let formattedDate = yyyy + '-' + mm + '-' + dd + ' 23:59:00';
      this.setState({ expiresAt: formattedDate })
    }
    else {
      this.setState({ expiresAt: '' })
    }
  }

  handleUpdateCandidate = (event) => {
    event.preventDefault()
    this.props.onUpdateCandidate(this.state.candidate)
  }

  handleDeleteCandidate = (event) => {
    event.preventDefault()
    this.props.onDeleteCandidate(this.state.candidate)
  }

  handleAnonymiseCandidate = (event) => {
    event.preventDefault()
    this.setState({ open: true })
  }

  handleConfirmAnonymise = (event) => {
    event.preventDefault()
    this.setState({ open: false })
    this.props.onDeleteCandidate(this.state.candidate)
    this.setState({ candidate: {firstName: '---', lastName: '---', email: '---'} })
  }

  handleCancelAnonymise = (event) => {
    event.preventDefault()
    this.setState({ open: false })
  }

  handleTextChange = (event, candidateId, field) => {
    const candidate = { ...this.state.candidate }
    candidate[field] = event.target.value

    this.setState({ candidate })
  }

  handleLabelChange = (newValue) => {
    const candidate = { ...this.state.candidate }
    candidate.labels = []

    newValue.forEach((label) => {
      if (label.__isNew__) {
        this.props.onCreateLabel(label.value)
      }
      candidate.labels.push(label.value)
    })

    this.setState({ candidate })
  }

  handleActiveTab = (activeTab) => {
    this.setState({ activeTab })
  }

  render () {
    const { assessmentsByStatus, assessmentsSelected, labels } = this.props
    const { candidate, activeTab } = this.state
    const labelOptions = []
    const selectedLabels = []

    if (candidate.labels) {
      Object.values(labels).forEach((label) => {
        const option = { value: label.id, label: label.name, parentId: candidate.id }
        labelOptions.push(option)
        if (candidate.labels.includes(label.id)) {
          selectedLabels.push(option)
        }
      })
    }


    const availableCards = []
    const completedCards = []
    const sentCards = []

    assessmentsByStatus.forEach(assessment => {
      switch (assessment.status) {
        case 'available':
          availableCards.push(assessment)
          break
        case 'completed':
          completedCards.push(assessment)
          break
        case 'sent':
          sentCards.push(assessment)
          break
        default:
          break
      }
    });

    return (
      <section>
        {Object.keys(candidate).length && (
          <form
            className="form"
            onSubmit={this.handleUpdateCandidate}
          >
            <Candidate
              candidate={candidate}
              onTextChange={this.handleTextChange}
              onLabelChange={this.handleLabelChange}
              labelOptions={labelOptions} selectedLabels={selectedLabels}
            />
          </form>
        )
        }
        {candidate.firstName != '---' && (
        <button style={{marginTop: '20px'}} className="button red button--stretch button--active" onClick={this.handleAnonymiseCandidate}>
          <span className="padding-right-sm">Anonymise</span>
        </button>
        )}
        {/*<AlertDialog />*/}
        <div>
          <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Anonymise candidate?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Would you like to anonymise this individual's personal information? It cannot be restored.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCancelAnonymise} color="primary">
                No
              </Button>
              <Button onClick={this.handleConfirmAnonymise} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="padding-top-md">
          <Tabs
            activeTab={this.state.activeTab}
            handleActiveTab={this.handleActiveTab}
          />
          {activeTab === 'Available' && (
            <React.Fragment>
              {availableCards.length > 0 ? (
                <CardGroup
                  cards={availableCards}
                  assessmentsSelected={assessmentsSelected}
                  handleRemoveAssessment={this.handleRemoveAssessment}
                  handleAddAssessment={this.handleAddAssessment}
                />                
              ) : (
                <NoResults/>
              )}
            </React.Fragment>
          )}
          {activeTab === 'Completed' && (
            <React.Fragment>
              {completedCards.length > 0 ? (
                <CardGroup
                  cards={completedCards}
                  assessmentsSelected={assessmentsSelected}
                  handleRemoveAssessment={this.handleRemoveAssessment}
                  handleAddAssessment={this.handleAddAssessment}
                />                
              ) : (
                <NoResults/>
              )}
            </React.Fragment>
          )}          
          {activeTab === 'Sent' && (
            <React.Fragment>
              {sentCards.length > 0 ? (
                <CardGroup
                  cards={sentCards}
                  assessmentsSelected={assessmentsSelected}
                  handleRemoveAssessment={this.handleRemoveAssessment}
                  handleAddAssessment={this.handleAddAssessment}
                />                
              ) : (
                <NoResults/>
              )}
            </React.Fragment>
          )}
        </div>
        {activeTab === 'Available' &&
        <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'flex-end', 'alignItems': 'flex-end'}}>
          <Field input={<DateWrapper onItemChange={this.handleDateChange}
                                 placeholder={'Expires on'}
                                 itemValue={this.state.expiresAt}
                    />}/>
          <div>
            <Go handleConfirm={this.handleConfirm}/>
          </div>
        </div>

        }

      </section>
    )
  }
}

ModalCandidatesPageSingle.propTypes = {
  assessmentsByStatus: PropTypes.arrayOf(PropTypes.object),
  candidateSelected: PropTypes.object.isRequired,
  assessmentsSelected: PropTypes.object.isRequired,
  labels: PropTypes.arrayOf(PropTypes.object),
  onListOrganisationAssessments: PropTypes.func.isRequired,
  onSetAssessmentsSelected: PropTypes.func.isRequired,
  onUpdateCandidateAssessments: PropTypes.func.isRequired,
  onUpdateCandidate: PropTypes.func.isRequired,
  onCreateLabel: PropTypes.func.isRequired,
  onReadCandidateAssessment: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  assessmentsByStatus: getAssessmentsByStatus,
  assessmentsSelected: getAssessmentsSelected,
  candidateSelected: getCandidateSelected,
  labels: getLabels,
})

const mapDispatchToProps = (dispatch) => ({
  onListOrganisationAssessments: () => {
    dispatch(listOrganisationAssessments())
  },
  onSetAssessmentsSelected: (assessments) => {
    dispatch(setAssessmentsSelected(assessments))
  },
  onUpdateCandidateAssessments: (expiresAt) => {
    dispatch(updateCandidateAssessments(expiresAt))
  },
  onUpdateCandidate: (candidate) => {
    dispatch(updateCandidateWithLabel(candidate))
  },
  onDeleteCandidate: (candidate) => {
    dispatch(deleteCandidate(candidate))
  },
  onCreateLabel: (label) => {
    dispatch(createLabel(label))
  },
  onReadCandidateAssessment: (candidate) => {
    dispatch(readCandidateAssessment(candidate))
  },
  onAddAlertExpiry: (message) => {
    dispatch(addAlertExpiry('warning', message))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalCandidatesPageSingle)
