import React, { Component } from 'react'
import { addModal, removeCurrentModal, removeModal } from 'actions/uiAction'
import { connect } from 'react-redux'
import Cross from 'assets/svg/components/Cross'
import TeamReportsPage from './TeamReportsPage'
import TeamReportsDownload from './TeamReportsDownload'
import TeamReportsEdit from './TeamReportsEdit'
import AssessmentsPageSingle from './AssessmentsPageSingle'
import CandidatesPageMultiple from './CandidatesPageMultiple'
import CandidatesPageSingle from './CandidatesPageSingle'
import OrganisationsPageSingle from './OrganisationsPageSingle'
import UserPageSingle from './UserPageSingle'
import LabelPage from './LabelPage'
import CandidatesAssessmentsStatus from './CandidatesAssessmentsStatus'
import CandidatesAssessmentsConfirm from './CandidatesAssessmentsConfirm'
import CandidateAssessmentSingle from './CandidateAssessmentSingle'
import LeadershipAssessment from './LeadershipAssessment'
import LeadershipAssessmentStatus from './LeadershipAssessmentStatus'
import LeadershipAssessmentReviewers from './LeadershipAssessmentReviewers'
import LeadershipAssessmentConfirm from './LeadershipAssessmentConfirm'
import LeadershipAssessmentEdit from './LeadershipAssessmentEdit'
import { createStructuredSelector } from 'reselect'
import { getModal } from 'reducers/modalReducer'
import InputPageStatus from './InputPageStatus'
import PropTypes from 'prop-types'

class Modal extends Component {
  render () {
    const { modal, onRemoveModal, onRemoveCurrentModal, onAddModal } = this.props
    // TODO WTF? why not just use modal string value?
    const currentModal = Object.assign([], modal).pop()
    let modalContent = null

    switch (currentModal) {
      case 'Select Individuals':
        modalContent = <AssessmentsPageSingle onAddModal={onAddModal}/>
        break
      case 'Individual':
        modalContent = <CandidatesPageSingle onAddModal={onAddModal}/>
        break
      case 'Organisation':
        modalContent = <OrganisationsPageSingle onAddModal={onAddModal}/>
        break
      case 'Individuals':
        modalContent = <CandidatesPageMultiple onAddModal={onAddModal}/>
        break
      case 'User':
        modalContent = <UserPageSingle onAddModal={onAddModal}/>
        break
      case 'Label':
        modalContent = <LabelPage/>
        break
      case 'Individual Assessments':
        modalContent = <CandidatesAssessmentsStatus/>
        break
      case 'Individual Assessment':
        modalContent = <CandidateAssessmentSingle />
        break
      case 'Individual Confirm':
        modalContent = <CandidatesAssessmentsConfirm onAddModal={onAddModal}/>
        break
      case 'Individual Input':
        modalContent = <InputPageStatus onAddModal={onAddModal}/>
        break
      case 'Select Team Members':
        modalContent = <TeamReportsPage onAddModal={onAddModal}/>
        break
      case 'Download Team Report':
        modalContent = <TeamReportsDownload onAddModal={onAddModal}/>
        break
      case 'Edit Team Report':
        modalContent = <TeamReportsEdit onAddModal={onAddModal}/>
        break
      case 'Select Leader':
        modalContent = <LeadershipAssessment onAddModal={onAddModal}/>
        break
      case 'Status':
        modalContent = <LeadershipAssessmentStatus onAddModal={onAddModal}/>
        break
      case 'Create Reviewers':
        modalContent = <LeadershipAssessmentReviewers onAddModal={onAddModal}/>
        break
      case 'Confirm':
        modalContent = <LeadershipAssessmentConfirm onAddModal={onAddModal}/>
        break
      case 'Edit Leadership Assessment':
        modalContent = <LeadershipAssessmentEdit onAddModal={onAddModal}/>
        break
      default:
        modalContent = null
    }

    return (
      <div>
        <div className="overlay" onClick={() => onRemoveModal()}/>
        <div className="modal">
          <div className="grid grid--middle">
            <div className="grid__item w-6">
              {!['Create Reviewers', 'Status', 'Edit Leadership Assessment'].includes(currentModal) && <h2 className="margin-bottom-none">{currentModal}</h2>}
            </div>
            <div className="grid__item w-6">
              <div className="text-right">
                <i className="icon icon--component" onClick={onRemoveCurrentModal}><Cross/></i>
              </div>
            </div>
          </div>
          {modalContent}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  modal: PropTypes.array.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onRemoveModal: PropTypes.func.isRequired,
  onRemoveCurrentModal: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  onRemoveCurrentModal: () => {
    dispatch(removeCurrentModal())
  },
  onRemoveModal: () => {
    dispatch(removeModal())
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
})

const mapStateToPropsSelector = createStructuredSelector({
  modal: getModal
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Modal)
