import {
    ADD_CANDIDATES_ASSESSMENTS_SELECTED,
    REMOVE_CANDIDATES_ASSESSMENTS_SELECTED,
    UNSET_CANDIDATES_ASSESSMENTS_SELECTED,
    SET_CANDIDATES_ASSESSMENTS_SELECTED
} from 'actions/candidatesAssessmentsSelectedAction'

const initialState = []

const candidatesAssessmentsSelected = (state = initialState, action) => {
    const newState = Object.assign([], state)
    switch (action.type) {
        case SET_CANDIDATES_ASSESSMENTS_SELECTED:
            return action.candidatesAssessments
        case UNSET_CANDIDATES_ASSESSMENTS_SELECTED:
            return initialState
        case ADD_CANDIDATES_ASSESSMENTS_SELECTED:
            var notExist = newState.some(obj => {
                return obj.id === action.candidateAssessment.id
            })

            if (!notExist) {
                newState.push(action.candidateAssessment)
            }

            return newState
        case REMOVE_CANDIDATES_ASSESSMENTS_SELECTED:
            return newState.filter(obj => {
                return obj.id !== action.candidateAssessment.id
            })
        default:
            return state
    }
}

export default candidatesAssessmentsSelected

export const getCandidatesAssessmentsSelected = state => state.candidatesAssessmentsSelected
