import React, {Component} from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import Filter from "../LeadershipAssessmentPage/Filter";
import Table from "../LeadershipAssessmentPage/Table";
import Pagination from "../../components/Pagination";
import PropTypes from "prop-types";
import {getLeadershipsAssessments} from "../../reducers/leadershipsAssessmentsReducer";
import {getLabels} from "../../reducers/labelsReducer";
import {listOrganisationLeadershipsAssessments} from "../../fetchs/organisationLeadershipAssessmentFetch";
import {listLabels} from "../../fetchs/labelFetch";
import {addModal} from "../../actions/uiAction";
import {addBackground, removeBackground} from "../../actions/backgroundAction";
import {setLeadershipReport} from "../../actions/leadershipReportAction";
import ButtonGroup from "../AssessmentPage/ButtonGroup";
import {listOrganisationAssessments} from "../../fetchs/OrganisationAssessmentFetch";
import {getOrganisationAssessments} from "../../reducers/organisationAssessmentsReducer";
import {setAssessmentsSelected} from "../../actions/assessmentAction";
import fp from "../../assets/fp.png";
import {getUserOrganisation} from "../../reducers/userOrganisationReducer";
import {readOrganisation} from "../../fetchs/organisationFetch";

class LeadershipAssessmentsPage extends Component {
    constructor (props) {
        super(props)

        this.props.onListOrganisationAssessments();

        this.state = {
            organisationAssessments:props.organisationAssessments
        }

        this.state = {
            searchValue: '',
            lastSearch: 0,
            minimumSearch: false,
            selectedLabel: '',
            selectedStatus: '',
            selectedInvited: null,
            selectAll: false,
        }
        this.props.onListOrganisationLeadershipsAssessments(true, 1)
    }

    // componentDidMount () {
    //     this.props.onListLabels()
    //     this.props.onAddBackground()
    // }

    componentDidMount () {
        this.props.onReadOrganisation(this.props.userOrganisation.namespace)

        if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.from === 'send') {
            this.props.onAddModal('Select Leader')
        }
    }

    componentDidUpdate(prevProps, prevState, snapShot){
        if (prevProps.organisationAssessments !== this.props.organisationAssessments){
            this.setState({
                organisationAssessments:this.props.organisationAssessments
            });
        }
    }

    componentWillUnmount () {
        this.props.onRemoveBackground()
    }

    handleSearchChange = (event) => {
        const searchValue = event.target.value
        const date = new Date()
        const timestamp = date.getTime()
        const second = 1000
        this.setState({ searchValue, lastSearch: timestamp })

        setTimeout(() => {
            if (this.state.searchValue.length > 2 && this.state.lastSearch + (second) > timestamp) {
                this.setState({ minimumSearch: true })
                this.props.onListOrganisationLeadershipsAssessments(false, 1, this.state.searchValue, this.state.selectedLabel, this.state.selectedStatus, this.state.selectedInvited)
            } else if (this.state.minimumSearch) {
                this.setState({ minimumSearch: false })
                this.props.onListOrganisationLeadershipsAssessments(false, 1, '', this.state.selectedLabel, this.state.selectedStatus, this.state.selectedInvited)
            }
        }, second)
    }

    handleLabelChange = (event) => {
        this.setState({ selectedLabel: event.target.value })
        this.props.onListOrganisationLeadershipsAssessments(false, 1, this.state.searchValue, event.target.value, this.state.selectedStatus, this.state.selectedInvited)
    }

    handleStatusChange = (event) => {
        this.setState({ selectedStatus: event.target.value })
        this.props.onListOrganisationLeadershipsAssessments(false, 1, this.state.searchValue, this.state.selectedLabel, event.target.value, this.state.selectedInvited)
    }

    handleClickLeadershipsAssessments = (leadershipAssessment) => {
        this.props.onSetLeadershipReport(leadershipAssessment)
        this.props.onAddModal('Status')
    }

    handleInvitedChange = (date) => {
        this.setState({ selectedInvited: date })
        this.props.onListOrganisationLeadershipsAssessments(false, 1, this.state.searchValue, this.state.selectedLabel, this.state.selectedStatus, date)
    }

    handleReset = () => {
        this.setState({ page: 1, searchValue: '', selectedLabel: '', selectedStatus: '', selectedInvited: null })
        this.props.onListOrganisationLeadershipsAssessments(false, 1)
    }

    handlePageChange = (page) => {
        this.props.onListOrganisationLeadershipsAssessments(false, page, this.state.searchValue, this.state.selectedLabel, this.state.selectedStatus, this.state.selectedInvited)
    }

    handleAddNew = () => {
        this.props.onAddModal('Select Leader')
    }

    // handleAddAssessment = (assessmentItem) => {
    //     const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
    //     assessmentsSelected[assessmentItem.id] = true
    //     this.props.onSetAssessmentsSelected(assessmentsSelected)
    // }

    render () {

        const { organisationAssessments } = this.state;
        const { leadershipAssessments, labels , userOrganisation} = this.props
        const { searchValue, selectedLabel, selectedStatus, selectedInvited } = this.state

        const orgAssessments = [];

        // we need to remove the assessments that have a token type of team, as these are only needed for the team reports
        if (organisationAssessments) {
            Object.keys(organisationAssessments).forEach(function(key) {
                let organisationAssessment = organisationAssessments[key];

                if (organisationAssessment.tokenType !== 'team') {
                    orgAssessments.push(organisationAssessments[key])
                }
            });
        }

        if (userOrganisation.organisation && userOrganisation.organisation.threeSixtyReportsActive) {
            return (
                <section>
                    {/*<h2>Send new</h2>*/}
                    {/*<div>*/}
                    {/*    <ButtonGroup*/}
                    {/*        handleGoClick={this.handleAddNew}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <h2>Manage existing</h2>
                    <Filter labels={labels} searchValue={searchValue}
                            selectedLabel={selectedLabel} selectedStatus={selectedStatus}
                            selectedInvited={selectedInvited}
                            onSearchChange={this.handleSearchChange}
                            onLabelChange={this.handleLabelChange} onStatusChange={this.handleStatusChange}
                            onInvitedChange={this.handleInvitedChange} onReset={this.handleReset}/>

                    <Table leadershipAssessments={leadershipAssessments}
                           onClickLeadershipAssessment={this.handleClickLeadershipsAssessments}/>
                    <Pagination onPageChange={this.handlePageChange}/>
                </section>
            )
        }
        else if (userOrganisation.organisation.parent) {
            return (
                <div style={{display: 'flex', alignItems: 'center', margin: 'auto', marginTop: '20%', maxWidth: '300px' }}><img style={{marginRight: '10px'}} src={fp} alt="flowprofiler®" width="100px" height="100px" /><span>Please <a href={userOrganisation.organisation.parent.url} target="_blank" style={{textDecoration: 'underline'}}>click here</a> to contact<br/> your flowprofiler® Partner<br/> and activate <strong>360</strong></span></div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    }
}

LeadershipAssessmentsPage.propTypes = {
    leadershipAssessments: PropTypes.array.isRequired,
    onSetAssessmentsSelected: PropTypes.func.isRequired,
    labels: PropTypes.array.isRequired,
    onListOrganisationLeadershipsAssessments: PropTypes.func.isRequired,
    onListOrganisationAssessments: PropTypes.func.isRequired,
    onListLabels: PropTypes.func.isRequired,
    onAddModal: PropTypes.func.isRequired,
    onAddBackground: PropTypes.func.isRequired,
    onRemoveBackground: PropTypes.func.isRequired,
    onSetLeadershipReport: PropTypes.func.isRequired,
    onReadOrganisation: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
    leadershipAssessments: getLeadershipsAssessments,
    organisationAssessments: getOrganisationAssessments,
    labels: getLabels,
    userOrganisation: getUserOrganisation,
})

const mapDispatchToProps = (dispatch) => ({
    onListOrganisationLeadershipsAssessments: (reset, page, searchFilter, labelFilter, statusFilter, invitedFilter) => {
        dispatch(listOrganisationLeadershipsAssessments(reset, page, searchFilter, labelFilter, statusFilter, invitedFilter))
    },
    onListOrganisationAssessments: () => {
        dispatch(listOrganisationAssessments())
    },
    onSetAssessmentsSelected: (assessments) => {
        dispatch(setAssessmentsSelected(assessments))
    },
    onListLabels: () => {
        dispatch(listLabels())
    },
    onAddModal: (modal) => {
        dispatch(addModal(modal))
    },
    onAddBackground: () => {
        dispatch(addBackground())
    },
    onRemoveBackground: () => {
        dispatch(removeBackground())
    },
    onSetLeadershipReport: (leadershipAssessment) => {
        dispatch(setLeadershipReport(leadershipAssessment))
    },
    onReadOrganisation: (organisation) => {
        dispatch(readOrganisation(organisation))
    },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(LeadershipAssessmentsPage)
