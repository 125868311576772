import {
    ADD_LEADER,
} from 'actions/leaderAction'

const initialState = []

const leader = (state = initialState, action) => {
    let newState = Object.assign([], state)
    switch (action.type) {
        case ADD_LEADER:
            newState.push(action.leader)
            return newState
        default:
            return state
    }
}

export default leader

export const getLeader = state => state.leader
