import React from 'react'
import SidebarItem from './Item'
import Send from 'assets/svg/components/Send'
import Input from 'assets/svg/components/Input'
import Manage from 'assets/svg/components/Manage'
import Individual from 'assets/svg/components/Individual'
import Team from 'assets/svg/components/Team'
import ThreeSixty from 'assets/svg/components/ThreeSixty'
import Note from 'assets/svg/components/Note'
import Settings from 'assets/svg/components/Settings'

const GroupTop = () => (
  <div>
    <div className="sidebar__group border-bottom">
      <h5 className="sidebar__title margin-bottom-xsm">Invite</h5>
      <ul className="list-bare">
        <SidebarItem name={'Send'} link={`send`} icon={<Send/>}/>
        <SidebarItem name={'Input'} link={`input`} icon={<Input/>}/>
      </ul>
    </div>
    <div className="sidebar__group border-bottom">
      <h5 className="sidebar__title margin-bottom-xsm">Manage</h5>
      <ul className="list-bare">
        <SidebarItem name={'Assessments'} link={`assessments`} icon={<Note/>}/>
        <SidebarItem name={'Individuals'} link={`individuals`} icon={<Individual/>}/>
        <SidebarItem name={'Teams'} link={`teams`} icon={<Team/>}/>
        <SidebarItem name={'360'} link={`360`} icon={<ThreeSixty/>}/>
        <SidebarItem name={'Organisations'} link={`organisations`} icon={<Manage/>}/>
        <SidebarItem name={'Labels'} link={`labels`} icon={<Settings/>}/>
      </ul>
    </div>
  </div>
)

export default GroupTop
