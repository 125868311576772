import { calculateLeadershipAssessments, calculateLeadershipsAssessments, expiresAt } from 'reducers/leadershipsAssessmentsReducer'
import { createLeadershipAssessment, updateLeadershipAssessmentReviewersFetch } from 'fetchs/leadershipAssessmentFetch'
import {listOrganisationLeadershipsAssessments} from "../fetchs/organisationLeadershipAssessmentFetch";

export const SET_LEADERSHIPS_ASSESSMENTS = 'SET_LEADERSHIPS_ASSESSMENTS'
export const UNSET_LEADERSHIPS_ASSESSMENTS = 'UNSET_LEADERSHIPS_ASSESSMENTS'
export const SET_LEADERSHIP_ASSESSMENT_STATUS = 'SET_LEADERSHIP_ASSESSMENT_STATUS'

export function updateLeadershipsAssessments (expiresAt = null) {
    return function (dispatch, getState) {
        const leadershipsAssessments = calculateLeadershipsAssessments(getState())
        dispatch(sendLeadershipAssessmentToFetch(leadershipsAssessments, expiresAt))
    }
}

export function updateLeadershipAssessments (expiresAt = null) {
    return function (dispatch, getState) {
        const leadershipsAssessments = calculateLeadershipAssessments(getState())
        dispatch(sendLeadershipAssessmentToFetch(leadershipsAssessments, expiresAt))
    }
}

function sendLeadershipAssessmentToFetch (leadershipsAssessments, expiresAt) {
    return function (dispatch) {
        dispatch(setLeadershipsAssessments(leadershipsAssessments),
            leadershipsAssessments.forEach((leadershipAssessment) => {
                dispatch(createLeadershipAssessment(leadershipAssessment, expiresAt))
            })
        )
    }
}

export function updateLeadershipAssessmentReviewers (leadershipsAssessment, reviewers) {
    return function (dispatch) {
        dispatch(sendLeadershipAssessmentReviewersToFetch(leadershipsAssessment, reviewers))
    }
}

function sendLeadershipAssessmentReviewersToFetch (leadershipsAssessment, reviewers) {
    return function (dispatch) {
        dispatch(updateLeadershipAssessmentReviewersFetch(leadershipsAssessment, reviewers))
    }
}

// ACTION CREATORS
export function setLeadershipsAssessments (leadershipsAssessments) {
    return {
        type: SET_LEADERSHIPS_ASSESSMENTS,
        leadershipsAssessments
    }
}

export function unSetLeadershipsAssessments () {
    return {
        type: UNSET_LEADERSHIPS_ASSESSMENTS,
    }
}

export function receiveLeadershipAssessment (status, leadershipAssessment) {
    return {
        type: SET_LEADERSHIP_ASSESSMENT_STATUS,
        leadershipAssessment,
        status
    }
}
