import { SET_CLIENT } from 'actions/clientAction'

const initialState = []

const client = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT:
      return action.client
    default:
      return state
  }
}

export default client

export const getClient = state => state.client
