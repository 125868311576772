import { readOrganisationCredit } from 'fetchs/organisationCreditFetch'
import { listOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import { readOrganisationBrand } from 'fetchs/organisationBrandFetch'
import { readBrand } from 'fetchs/brandFetch'
import { getUserOrganisation} from 'reducers/userOrganisationReducer'
import { transferToken } from 'fetchs/tokenFetch'
import { addOrganisationCredits } from 'actions/organisationSelectedAction'

export const SET_USER_ORGANISATION = 'SET_USER_ORGANISATION'
export const ADD_USER_ORGANISATION_CREDITS = 'ADD_USER_ORGANISATION_CREDITS'
export const SET_USER_ORGANISATION_BRAND = 'SET_USER_ORGANISATION_BRAND'

export function setUserOrganisationWithCredits (organisation) {
  return function (dispatch) {
    dispatch(listOrganisationAssessments())
    dispatch(readOrganisationCredit(organisation, addUserOrganisationCredits))
    dispatch(setUserOrganisation(organisation))
  }
}

export function getUserOrganisationBrand (organisation, updateAuth) {
  return function (dispatch) {
    if (organisation !== 'default' && updateAuth) {
      dispatch(readOrganisationBrand(organisation, setUserOrganisationBrand, updateAuth))
    } else if (organisation !== 'default') {
      dispatch(readBrand(organisation))
    } else {
      dispatch(setUserOrganisationBrand({ organisation: 'default', name: 'default' }))
    }
  }
}

export function assignTokens (total, direction, selectedOrganisation, type, specificity) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())

    const updateUserCredit = readOrganisationCredit(userOrganisation, addUserOrganisationCredits)
    const updateSelectedCredit = readOrganisationCredit(selectedOrganisation, addOrganisationCredits)
    let sender = userOrganisation;
    let recipient = selectedOrganisation;
    let issue = false

    if(direction === 'minus'){
       sender = selectedOrganisation;
       recipient = userOrganisation;
    } else if(userOrganisation.organisation.type === 'owner') {
      issue = true
    }

    dispatch(transferToken(sender, recipient, type, specificity, updateUserCredit, updateSelectedCredit, --total, issue))
  }
}

export function setUserOrganisationBrand (branding) {
  return {
    type: SET_USER_ORGANISATION_BRAND,
    branding
  }
}

export function setUserOrganisation (organisation) {
  return {
    type: SET_USER_ORGANISATION,
    organisation
  }
}



export function addUserOrganisationCredits (credits) {
  return {
    type: ADD_USER_ORGANISATION_CREDITS,
    credits
  }
}
