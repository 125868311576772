import {
    ADD_LEADERSHIPS_ASSESSMENTS_SELECTED,
    REMOVE_LEADERSHIPS_ASSESSMENTS_SELECTED,
    UNSET_LEADERSHIPS_ASSESSMENTS_SELECTED,
    SET_LEADERSHIPS_ASSESSMENTS_SELECTED
} from 'actions/leadershipsAssessmentsSelectedAction'

const initialState = []

const leadershipsAssessmentsSelected = (state = initialState, action) => {
    const newState = Object.assign([], state)
    switch (action.type) {
        case SET_LEADERSHIPS_ASSESSMENTS_SELECTED:
            return action.leadershipsAssessments
        case UNSET_LEADERSHIPS_ASSESSMENTS_SELECTED:
            return initialState
        case ADD_LEADERSHIPS_ASSESSMENTS_SELECTED:
            var notExist = newState.some(obj => {
                return obj.id === action.leadershipAssessment.id
            })

            if (!notExist) {
                newState.push(action.leadershipAssessment)
            }

            return newState
        case REMOVE_LEADERSHIPS_ASSESSMENTS_SELECTED:
            return newState.filter(obj => {
                return obj.id !== action.leadershipAssessment.id
            })
        default:
            return state
    }
}

export default leadershipsAssessmentsSelected

export const getLeadershipsAssessmentsSelected = state => state.leadershipsAssessmentsSelected
