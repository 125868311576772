import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TableCandidatesSelect from 'containers/Table/CandidatesSelect'
import { addAlertExpiry, addModal } from 'actions/uiAction'
import { createStructuredSelector } from 'reselect'
import { getCandidatesSelected } from 'reducers/candidatesSelectedReducer'
import { unSetCandidates } from 'actions/candidateAction'
import { unSetCandidatesSelected } from 'actions/candidateSelectedAction'
import ChevronRight from 'assets/svg/components/ChevronRight'

class CandidatesPage extends Component {
  componentWillMount () {
    this.props.onUnSetCandidates()
    this.props.onUnSetCandidatesSelected()
  }

  handleGoClick = () => {
    if (this.props.candidatesSelected.length) {
      this.props.onAddModal('Individuals')
    } else {
      this.props.onAddAlertExpiry('Please Select a Candidate')
    }
  }

  render () {
    return (
      <section>
        <h2>Individuals</h2>
        <TableCandidatesSelect/>
        <div className="text-right">
          <button onClick={this.handleGoClick} className="button button--stretch button--active margin-left-sm">
            <span className="padding-right-sm">Go</span>
            <i className="icon"><ChevronRight/></i>
          </button>
        </div>
      </section>
    )
  }
}

CandidatesPage.propTypes = {
  candidatesSelected: PropTypes.arrayOf(PropTypes.object),
  onAddModal: PropTypes.func.isRequired,
  onUnSetCandidates: PropTypes.func.isRequired,
  onUnSetCandidatesSelected: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
  onUnSetCandidates: () => {
    dispatch(unSetCandidates())
  },
  onUnSetCandidatesSelected: () => {
    dispatch(unSetCandidatesSelected())
  },
  onAddAlertExpiry: (message) => {
    dispatch(addAlertExpiry('warning', message))
  },
})

const mapStateToPropsSelector = createStructuredSelector({
  candidatesSelected: getCandidatesSelected
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(CandidatesPage)
