import { REMOVE_CONFIRMATION, SET_CONFIRMATION } from 'actions/uiAction'

const initialState = false

const confirmation = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIRMATION:
      return action.confirmation
    case REMOVE_CONFIRMATION:
      return initialState
    default:
      return state
  }
}

export default confirmation

export const getConfirmation = state => state.confirmation
