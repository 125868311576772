

export const REMOVE_REVIEWER = 'REMOVE_REVIEWER'
export const ADD_REVIEWER = 'ADD_REVIEWER'

export const SET_REVIEWERS = 'SET_REVIEWERS'

export const UPDATE_REVIEWER_FIELD = 'UPDATE_REVIEWER_FIELD'

export function addReviewer (reviewer) {
    return {
        type: ADD_REVIEWER,
        reviewer
    }
}

export function setReviewers (reviewers) {
    return {
        type: SET_REVIEWERS,
        reviewers
    }
}

export function removeReviewer (reviewer) {
    return {
        type: REMOVE_REVIEWER,
        reviewer
    }
}

export function updateReviewerField (reviewerId, inputId, value) {
    return {
        type: UPDATE_REVIEWER_FIELD,
        reviewerId,
        inputId,
        value
    }
}
