import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {addActiveTab, addAlertExpiry, addModal, setSideBar} from 'actions/uiAction'
import { listOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import { updateUserPreferences } from 'actions/userAction'

import { setAssessmentsSelected } from 'actions/assessmentAction'
import CardGroup from 'components/CardGroup'
import ButtonGroup from './ButtonGroup'
import { createStructuredSelector } from 'reselect'
import { getOrganisationAssessments } from 'reducers/organisationAssessmentsReducer'
import { getAssessmentsSelected } from 'reducers/assessmentsSelectedReducer'
import { getUser } from 'reducers/userReducer'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { readOrganisation } from 'fetchs/organisationFetch'

class Assessments extends Component {
  constructor (props) {
    super(props)
    this.props.onListOrganisationAssessments();

    this.state = {
      organisationAssessments:props.organisationAssessments
    }
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount () {
    this.props.onReadOrganisation(this.props.userOrganisation.namespace)
  }

  componentDidUpdate(nextProps){
    if (nextProps.organisationAssessments !== this.props.organisationAssessments){
      this.setState({
        organisationAssessments:this.props.organisationAssessments
      });
    }
  }

  onDragStart(index) {
    this.indexA = index;
  }
  onDragOver(index) {
    this.indexB = index;
  }
  onDragEnd() {
    let arr = [...this.state.organisationAssessments];
    let temp = arr[this.indexB];
    arr[this.indexB] = arr[this.indexA];
    arr[this.indexA] = temp;
    this.setState({
      organisationAssessments:arr
    });
  }


  handleAddAssessment = (assessmentItem) => {

    if (assessmentItem.assessment.slug === 'leadershipflow360') {

      this.props.history.push('/' + this.props.userOrganisation.branding.name + '/360', { from: 'send' })
      this.props.onSetSideBar('360')

    }
    else {
      const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
      assessmentsSelected[assessmentItem.id] = true
      this.props.onSetAssessmentsSelected(assessmentsSelected)
    }

  }

  handleRemoveAssessment = (assessmentItem) => {
    const assessmentsSelected = Object.assign({}, this.props.assessmentsSelected)
    delete assessmentsSelected[assessmentItem.id]
    this.props.onSetAssessmentsSelected(assessmentsSelected)
  }

  handleGoClick = () => {
    if (Object.keys(this.props.assessmentsSelected).length) {
      this.props.onAddActiveTab('New')
      this.props.onAddModal('Select Individuals')
    } else {
      this.props.onAddAlertExpiry('Please Select an Assessment')
    }
  }
  updatedTilePreferences = preferenceData => {
    let preferences;
    if (this.props.user.preferences) {
      preferences = {...this.props.user.preferences};
      preferences[this.props.userOrganisation.namespace] = preferenceData;
    } else {
      preferences = { [this.props.userOrganisation.namespace]: preferenceData }
    }

    this.props.onUpdateUserPreferences(preferences);
  };

  render () {
    const { organisationAssessments } = this.state;
    const { assessmentsSelected, user, userOrganisation: { namespace } } = this.props;

    const orgAssessments = [];

    // we need to remove the assessments that have a token type of team, as these are only needed for the team reports
    Object.keys(organisationAssessments).forEach(function(key) {
      let organisationAssessment = organisationAssessments[key];

      //  && organisationAssessment.assessment.slug !== 'leadershipflow360'
      if (organisationAssessment.tokenType !== 'team') {
        orgAssessments.push(organisationAssessments[key])
      }

      console.log(orgAssessments)
    });

    return (
      <div>
        <h2>Select Assessments</h2>
          {orgAssessments.length > 0 && <CardGroup
            cards={orgAssessments}
            assessmentsSelected={assessmentsSelected}
            handleRemoveAssessment={this.handleRemoveAssessment}
            handleAddAssessment={this.handleAddAssessment}
            isSortable
            updatedTilePreferences={this.updatedTilePreferences}
            tilePreference={user.preferences && user.preferences[namespace] ? user.preferences[namespace].tiles : null}
          />}
        <ButtonGroup
          handleGoClick={this.handleGoClick}
        />
      </div>
    )
  }
}

Assessments.propTypes = {
  assessmentsSelected: PropTypes.object.isRequired,
  organisationAssessments: PropTypes.arrayOf(PropTypes.object),
  onListOrganisationAssessments: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onSetAssessmentsSelected: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
  onReadOrganisation: PropTypes.func.isRequired,
  onSetSideBar: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  organisationAssessments: getOrganisationAssessments,
  assessmentsSelected: getAssessmentsSelected,
  user: getUser,
  userOrganisation: getUserOrganisation,
})

const mapDispatchToProps = (dispatch) => ({
  onListOrganisationAssessments: () => {
    dispatch(listOrganisationAssessments())
  },
  onUpdateUserPreferences: preferenceData => {
    dispatch(updateUserPreferences(preferenceData))
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
  onSetAssessmentsSelected: (assessments) => {
    dispatch(setAssessmentsSelected(assessments))
  },
  onAddAlertExpiry: (message) => {
    dispatch(addAlertExpiry('warning', message))
  },
  onAddActiveTab: (activeTab) => {
    dispatch(addActiveTab(activeTab))
  },
  onReadOrganisation: (organisation) => {
    dispatch(readOrganisation(organisation))
  },
  onSetSideBar: (name) => {
    dispatch(setSideBar(name))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Assessments)
