import React, { Component } from 'react'
import Tab from './Tab'
import PropTypes from 'prop-types'

class Tabs extends Component {
    render () {
        const { handleActiveTab, activeTab } = this.props
        return (
            <div className="tabs">
                <Tab activeTab={activeTab} handleActiveTab={handleActiveTab} title={'New'}/>
                <Tab activeTab={activeTab} handleActiveTab={handleActiveTab} title={'Existing'}/>
                <Tab activeTab={activeTab} handleActiveTab={handleActiveTab} title={'Credit Balance'}/>
            </div>
        )
    }
}

Tabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
    handleActiveTab: PropTypes.func.isRequired,
}

export default Tabs
