export const ADD_MODAL = 'ADD_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const REMOVE_CURRENT_MODAL = 'REMOVE_CURRENT_MODAL'
export const REPLACE_MODAL = 'REPLACE_MODAL'
export const ADD_ALERT = 'ADD_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const SET_CONFIRMATION = 'SET_CONFIRMATION'
export const REMOVE_CONFIRMATION = 'REMOVE_CONFIRMATION'
export const SET_SIDEBAR = 'SET_SIDEBAR'
export const OPEN_MENU = 'OPEN_MENU'
export const CLOSE_MENU = 'CLOSE_MENU'
export const RECEIVE_PAGES = 'RECEIVE_PAGES'
export const UNSET_PAGES = 'UNSET_PAGES'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

export function addActiveTab (activeTab) {
  return {
    type: SET_ACTIVE_TAB,
    activeTab
  }
}

export function addModal (modal) {
  return {
    type: ADD_MODAL,
    modal
  }
}

export function removeModal () {
  return {
    type: REMOVE_MODAL
  }
}

export function removeCurrentModal () {
  return {
    type: REMOVE_CURRENT_MODAL
  }
}

export function replaceModal (modal) {
  return {
    type: REPLACE_MODAL,
    modal
  }
}

export function addAlertExpiry (alertType, message, expiresAt = true) {
  const date = new Date()
  const timestamp = date.getTime()
  const second = 1000

  expiresAt = timestamp + (30 * second)

  // if (expiresAt && alertType !== 'danger') {
  //   expiresAt = timestamp + (30 * second)
  // }

  return addAlert(alertType, message, expiresAt)
}

export function addAlert (alertType, message, expiresAt) {
  return {
    type: ADD_ALERT,
    alertType,
    message,
    expiresAt
  }
}

export function removeAlert () {
  return {
    type: REMOVE_ALERT
  }
}

export function addConfirmation (confirmation) {
  return {
    type: SET_CONFIRMATION,
    confirmation
  }
}

export function removeConfirmation (confirmation) {
  return {
    type: REMOVE_CONFIRMATION,
    confirmation
  }
}

export function setSideBar (sideBar) {
  return {
    type: SET_SIDEBAR,
    sideBar
  }
}

export function openMenu () {
  return {
    type: OPEN_MENU
  }
}

export function closeMenu () {
  return {
    type: CLOSE_MENU
  }
}

export function receivePages (total, page) {
  return {
    type: RECEIVE_PAGES,
    total,
    page
  }
}

export function unSetPages () {
  return {
    type: UNSET_PAGES,
  }
}
