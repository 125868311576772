import React from 'react'

let unlimited = false
let shared = false

const Credits = ({credits, organisationAssessments, organisationName, organisationUrl}) => (
    <div>
        <table className="table">
            <thead className="show-md">
            <tr>
                <th></th>
                <th>Description</th>
                <th>Available Credits</th>
                <th>Assessment(s)</th>
            </tr>
            </thead>
            { credits === false &&
            <tbody>
            <tr className="table__group">
                <td colSpan="4" style={{textAlign: 'center'}}>
                    <span>Loading results...</span>
                </td>
            </tr>
            </tbody>
            }
            { credits !== false &&
            <tbody>
            {unlimited = false}
            {organisationAssessments.map((d, k) => {
                if (d.tokenType === 'unlimited') {
                    unlimited = true
                }

            })}
            {unlimited === true &&
                <tr className="table__group">
                    <td>
                        <span>Unlimited</span>
                    </td>
                    <td>
                        <span>Unlimited use of assessments</span>
                    </td>
                    <td>
                            <span>Unlimited</span>
                    </td>
                    <td>
                        <ul style={{listStyleType: 'none',marginBottom: 0}}>
                            {organisationAssessments.map((d, k) => {
                                if (d.tokenType === 'unlimited') {
                                    return <li key={k}>{d.assessment.name}</li>
                                }

                            })}
                        </ul>
                    </td>
                </tr>
            }
            {shared = false}
            {organisationAssessments.map((d, k) => {
                if (d.tokenType === 'shared') {
                    shared = true
                }

            })}
            {(credits['shared'] !== 0 || shared === true) &&
                <tr className="table__group">
                    <td>
                        <span>Shared</span>
                    </td>
                    <td>
                        <span>Share credits between assessments</span>
                    </td>
                    <td>
                        <span>{ credits['shared'] ? credits['shared'].toString() : 0 }</span>
                    </td>
                    <td>
                        <ul style={{listStyleType:'none',marginBottom: 0}}>
                            {organisationAssessments.map((d,k) => {
                                if (d.tokenType === 'shared') {
                                    return <li key={k}>{d.assessment.name}</li>
                                }

                            })}
                        </ul>
                    </td>
                </tr>
            }
            {organisationAssessments.map((d,k) => {
                if (d.tokenType === 'specific') {
                    if (d.assessment.name === 'resilienceflow\u00ae') {
                        return <tr key={k} className="table__group">
                            <td>
                                <span>Type-Specific</span>
                            </td>
                            <td>
                                <span>Credits for assessment</span>
                            </td>
                            <td>
                                <span>{ credits['resilienceflow\u00ae'] ? credits['resilienceflow\u00ae'].toString() : 0 }</span>
                            </td>
                            <td>
                                <span>resilienceflow®</span>
                            </td>
                        </tr>
                    }
                    else if (d.assessment.name === 'eqflow\u00ae') {
                        return <tr key={k} className="table__group">
                            <td>
                                <span>Type-Specific</span>
                            </td>
                            <td>
                                <span>Credits for assessment</span>
                            </td>
                            <td>
                                <span>{ credits['eqflow\u00ae'] ? credits['eqflow\u00ae'].toString() : 0 }</span>
                            </td>
                            <td>
                                <span>eqflow®</span>
                            </td>
                        </tr>
                    }
                    else if (d.assessment.name === 'motivationflow\u00ae') {
                        return <tr key={k} className="table__group">
                            <td>
                                <span>Type-Specific</span>
                            </td>
                            <td>
                                <span>Credits for assessment</span>
                            </td>
                            <td>
                                <span>{ credits['motivationflow\u00ae'] ? credits['motivationflow\u00ae'].toString() : 0 }</span>
                            </td>
                            <td>
                                <span>motivationflow®</span>
                            </td>
                        </tr>
                    }
                    else if (d.assessment.name === 'the flowprofiler\u00ae') {
                        return <tr key={k} className="table__group">
                            <td>
                                <span>Type-Specific</span>
                            </td>
                            <td>
                                <span>Credits for assessment</span>
                            </td>
                            <td>
                                <span>{ credits['the flowprofiler\u00ae'] ? credits['the flowprofiler\u00ae'].toString() : 0 }</span>
                            </td>
                            <td>
                                <span>the flowprofiler®</span>
                            </td>
                        </tr>
                    }
                }

            })}
            </tbody>
            }

        </table>
        { organisationName && organisationUrl &&
        <p className="margin-top-md">To order credits contact <a className="colour-active" href={organisationUrl} target="_blank">{organisationName}.</a></p>
        }
    </div>
)

export default Credits
