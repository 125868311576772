import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import TableCandidatesSelect from 'containers/Table/CandidatesSelect'
import { createStructuredSelector } from 'reselect'
import {
    candidatesAssessmentsSelectAll,
    unSetCandidatesAssessmentsSelected,
    addCandidatesAssessmentsSelected,
    removeCandidatesAssessmentsSelected
} from 'actions/candidatesAssessmentsSelectedAction'
import { getModal } from 'reducers/modalReducer'
import { getAssessmentsBySelected } from 'reducers/organisationAssessmentsReducer'
import { unSetCandidates } from 'actions/candidateAction'
import { getCandidatesSelected } from 'reducers/candidatesSelectedReducer'
import { addActiveTab, addAlertExpiry } from 'actions/uiAction'
import { getActiveTab } from 'reducers/activeTabReducer'
import { getLabels } from 'reducers/labelsReducer'
import { listLabels } from 'fetchs/labelFetch'
import { setCandidatesAssessments } from '../../../actions/candidateAssessmentAction'
import { getUserOrganisation } from '../../../reducers/userOrganisationReducer'
import { getOrganisationAssessments } from '../../../reducers/organisationAssessmentsReducer'
import { listOrganisationCandidatesAssessmentsByAssessment, createTeamReport, updateTeamReport, readTeamReport } from '../../../fetchs/organisationCandidateAssessmentFetch'
import { getCandidatesAssessments } from '../../../reducers/candidatesAssessmentsReducer'
import { getCandidatesAssessmentsSelected } from '../../../reducers/candidatesAssessmentsSelectedReducer';
import Filter from "./Filter";
import Table from '../../Table/TeamReportSelect/Table'
import Pagination from 'components/Pagination'
import ButtonGroup from "../../TeamReportPage/ButtonGroup";
import Field from "./Field";
import Text from 'components/Form/Text'
import Checkbox from 'components/Form/Checkbox'
import ChevronRight from 'assets/svg/components/ChevronRight'
import { getTeamReport } from '../../../reducers/teamReportReducer';
import { replaceModal } from 'actions/uiAction'

class ModalTeamReportsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            lastSearch: 0,
            minimumSearch: false,
            selectedLabel: '',
            selectedStatus: '',
            selectedInvited: null,
            selectAll: false,
            teamName: '',
            isAnonymise: false
        }
    }

    componentDidMount () {


        const {
            assessmentsBySelected,
            teamReport
        } = this.props;

        console.log(teamReport)

        if (teamReport && teamReport.assessment) {
            this.props.onSetCandidatesAssessments()
            //this.props.onClearCandidatesAssessmentsSelected()

            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, teamReport.assessment.id, '', '')

            // gets related candidateAssessments and saves to candidateAssessmentsSelected
            this.props.onReadTeamReport(teamReport)
            this.setState({
                isAnonymise: teamReport.anonymise ? true : false,
                teamName: teamReport.name
            })
        }
        else if (assessmentsBySelected && assessmentsBySelected.length) {
            let assessmentId = assessmentsBySelected[0].assessment.id

            if (assessmentId) {
                this.props.onSetCandidatesAssessments()
                //this.props.onClearCandidatesAssessmentsSelected()
                this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, assessmentId, '', '')
            }
        }


        this.props.onListLabels()
    }

    // componentDidUpdate (prevProps, prevState, snapshot) {
    //
    //     let assessmentId = this.props.assessmentsBySelected[0].assessment.id
    //
    //     if (assessmentId && assessmentId !== prevProps.assessmentsBySelected[0].assessment.id) {
    //         this.props.onSetCandidatesAssessments()
    //         this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, assessmentId, '', '')
    //     }
    // }

    handleSearchChange = (event) => {
        const searchValue = event.target.value
        const date = new Date()
        const timestamp = date.getTime()
        const second = 1000
        this.setState({ searchValue, lastSearch: timestamp })

        const {
            teamReport
        } = this.props;

        setTimeout(() => {
            if (this.state.searchValue.length > 2 && this.state.lastSearch + (second) > timestamp) {
                this.setState({ minimumSearch: true })
                if (teamReport && teamReport.assessment) {
                    this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, teamReport.assessment.id, this.state.searchValue, this.state.selectedLabel)
                }
            } else if (this.state.minimumSearch) {
                this.setState({ minimumSearch: false })
                if (teamReport && teamReport.assessment) {
                    this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, teamReport.assessment.id, '', this.state.selectedLabel)
                }
            }
        }, second)
    }

    handleLabelChange = (event) => {
        this.setState({ selectedLabel: event.target.value })
        this.props.onSetCandidatesAssessments()
        this.props.onClearCandidatesAssessmentsSelected()

        const {
            teamReport
        } = this.props;

        if (teamReport && teamReport.assessment) {
            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, teamReport.assessment.id, this.state.searchValue, event.target.value)
        }
        else if (this.props.assessmentsBySelected.length > 0) {
            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, this.props.assessmentsBySelected[0].assessment.id, this.state.searchValue, event.target.value)
        }
    }

    handleReset = () => {
        this.setState({ page: 1, searchValue: '', selectedLabel: '', selectedStatus: '', selectedInvited: null })
        this.props.onSetCandidatesAssessments()
        this.props.onClearCandidatesAssessmentsSelected()

        const {
            teamReport
        } = this.props;

        if (teamReport && teamReport.assessment) {
            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, teamReport.assessment.id, '', '')
        }
        else if (this.props.assessmentsBySelected.length > 0) {
            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, 1, this.props.assessmentsBySelected[0].assessment.id, '', '')
        }
    }

    handlePageChange = (page) => {
        const {
            teamReport
        } = this.props;

        if (teamReport && teamReport.assessment) {
            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, page, teamReport.assessment.id, this.state.searchValue, this.state.selectedLabel)
        }
        else if (this.props.assessmentsBySelected.length > 0) {
            this.props.onListOrganisationCandidatesAssessmentsByAssessment(false, page, this.props.assessmentsBySelected[0].assessment.id, this.state.searchValue, this.state.selectedLabel)
        }
    }

    handleClickCandidateAssessment = (candidateAssessment) => {
        console.log(candidateAssessment)
    }

    handleSelectAllCandidateAssessments = () => {
        this.props.onCandidatesAssessmentsSelectAll(!this.state.selectAll)
        this.setState({ selectAll: !this.state.selectAll })
    }

    handleSelectCandidateAssessment = (candidateAssessment, isChecked) => {
        console.log(candidateAssessment)
        if (isChecked) {
            this.props.onRemoveCandidatesAssessmentsSelected(candidateAssessment)
            this.setState({ selectAll: false })
        } else {
            this.props.onAddCandidatesAssessmentsSelected(candidateAssessment)
        }
    }

    handleGoClick = () => {
        if (Object.keys(this.props.candidatesAssessmentsSelected).length) {
            console.log(this.props.candidatesAssessmentsSelected)
        } else {
            this.props.onAddAlertExpiry('Please Select some candidate assessments')
        }
    }

    handleTextChange = (event, organisationId, field) => {
        this.setState({ teamName: event.target.value })
    }

    handleConfirm = () => {
        if (this.props.assessmentsBySelected) {
            let teamReport = this.props.teamReport

            if (this.state.teamName === '') {
                this.props.onAddAlertExpiry('Please enter a team name')
            }
            else if (Object.keys(this.props.candidatesAssessmentsSelected).length) {
                this.props.onUpdateTeamReport(teamReport, this.state.teamName, this.state.isAnonymise)
                this.props.onAddActiveTab('New')
                this.props.onAddModal('Download Team Report')
            } else {
                this.props.onAddAlertExpiry('Please Select some candidate assessments')
            }
        }

    }

    onSelectAnonymise = () => {
        let anonymise = this.state.isAnonymise
        this.setState({ isAnonymise: anonymise ? false : true })
    }

    render() {
        // const { assessmentsBySelected, activeTab, organisationAssessments } = this.props
        //
        // let content, optionButton;
        // content = <TableCandidatesSelect modal={true}/>
        // optionButton = <Options title={'Confirm'} onClick={this.handleConfirm}/>



        const { candidatesAssessments, candidatesAssessmentsSelected, labels, teamReport } = this.props
        const { searchValue, selectedLabel, selectedStatus, selectedInvited, selectAll, isAnonymise, teamName } = this.state

        return (
            <section>
                <h2>{ teamName }</h2>

                <Filter labels={labels} searchValue={searchValue}
                        selectedLabel={selectedLabel} selectedStatus={selectedStatus} selectedInvited={selectedInvited}
                        onSearchChange={this.handleSearchChange}
                        onLabelChange={this.handleLabelChange} onStatusChange={this.handleStatusChange}
                        onInvitedChange={this.handleInvitedChange} onReset={this.handleReset}/>

                {/*<Table candidatesAssessments={candidatesAssessments} modal={true}/>*/}
                <Table candidatesAssessments={candidatesAssessments} candidatesAssessmentsSelected={candidatesAssessmentsSelected} selectAll={selectAll}
                       modal={true}
                       enableListReordering={true}
                       onReorder={this.handleReorder}
                       onClickCandidateAssessment={this.handleClickCandidateAssessment}
                       onSelectAllCandidateAssessments={this.handleSelectAllCandidateAssessments}
                       onSelectCandidateAssessment={this.handleSelectCandidateAssessment}/>

                <div className="grid--center grid--middle full-width">
                    <Pagination onPageChange={this.handlePageChange}/>
                </div>
                <div className="grid--between grid--middle full-width">
                    <div className="grid__item w-3-md">
                        <Checkbox isChecked={isAnonymise} onChange={() => this.onSelectAnonymise(isAnonymise)} /> Anonymise
                    </div>

                    <Field required={true} input={<Text onItemChange={this.handleTextChange} placeholder={'Team Name*'}
                                                        itemName={'name'} itemValue={teamName}/>}/>

                    <Field input={<button type={'submit'} className="button button--stretch button--active" onClick={this.handleConfirm}>
                        <span className="padding-right-sm">Save</span>
                        <i className="icon"><ChevronRight/></i>
                    </button>}/>
                </div>
            </section>
        )
    }
}

ModalTeamReportsPage.propTypes = {
    modal: PropTypes.array.isRequired,
    assessmentsBySelected: PropTypes.arrayOf(PropTypes.object),
    candidatesAssessmentsSelected: PropTypes.arrayOf(PropTypes.object),
    onAddModal: PropTypes.func.isRequired,
    onUnSetCandidates: PropTypes.func.isRequired,
    onAddAlertExpiry: PropTypes.func.isRequired,
    candidatesAssessments: PropTypes.array.isRequired,
    onListOrganisationCandidatesAssessmentsByAssessment: PropTypes.func.isRequired,
    labels: PropTypes.array.isRequired,
    onListLabels: PropTypes.func.isRequired,
    onCandidatesAssessmentsSelectAll: PropTypes.func.isRequired,
    onSetCandidatesAssessments: PropTypes.func.isRequired,
    onReadTeamReport: PropTypes.func.isRequired,
    onClearCandidatesAssessmentsSelected: PropTypes.func.isRequired,
    onRemoveCandidatesAssessmentsSelected: PropTypes.func.isRequired,
    onAddCandidatesAssessmentsSelected: PropTypes.func.isRequired,
    onCreateTeamReport: PropTypes.func.isRequired,
    onUpdateTeamReport: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
    onUnSetCandidates: () => {
        dispatch(unSetCandidates())
    },
    onAddAlertExpiry: (message) => {
        dispatch(addAlertExpiry('warning', message))
    },
    onAddActiveTab: (activeTab) => {
        dispatch(addActiveTab(activeTab))
    },
    onListOrganisationCandidatesAssessmentsByAssessment: (reset, page, assessment, searchFilter, labelFilter) => {
        dispatch(listOrganisationCandidatesAssessmentsByAssessment(reset, page, assessment, searchFilter, labelFilter))
    },
    onListLabels: () => {
        dispatch(listLabels())
    },
    onCandidatesAssessmentsSelectAll: (set) => {
        dispatch(candidatesAssessmentsSelectAll(set))
    },
    onClearCandidatesAssessmentsSelected: () => {
        dispatch(unSetCandidatesAssessmentsSelected())
    },
    onRemoveCandidatesAssessmentsSelected: (candidateAssessment) => {
        dispatch(removeCandidatesAssessmentsSelected(candidateAssessment))
    },
    onAddCandidatesAssessmentsSelected: (candidateAssessment) => {
        dispatch(addCandidatesAssessmentsSelected(candidateAssessment))
    },
    onCreateTeamReport: (teamName, reportType, anonymise) => {
        dispatch(createTeamReport(teamName, reportType, anonymise))
    },
    onUpdateTeamReport: (teamName, reportType, anonymise) => {
        dispatch(updateTeamReport(teamName, reportType, anonymise))
    },
    onSetCandidatesAssessments: () => {
        dispatch(setCandidatesAssessments([]))
    },
    onReadTeamReport: (teamReport) => {
        dispatch(readTeamReport(teamReport))
    },
    onAddModal: (modal) => {
        dispatch(replaceModal(modal))
    },
})

const mapStateToPropsSelector = createStructuredSelector({
    modal: getModal,
    candidatesAssessmentsSelected: getCandidatesAssessmentsSelected,
    assessmentsBySelected: getAssessmentsBySelected,
    candidatesSelected: getCandidatesSelected,
    activeTab: getActiveTab,
    userOrganisation: getUserOrganisation,
    organisationAssessments: getOrganisationAssessments,
    candidatesAssessments: getCandidatesAssessments,
    labels: getLabels,
    teamReport: getTeamReport
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalTeamReportsPage)
