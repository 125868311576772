import { getLeadershipsAssessments } from 'reducers/leadershipsAssessmentsReducer'
import {SET_LEADERSHIP_SELECTED} from "./candidateSelectedAction";

export const UNSET_LEADERSHIPS_ASSESSMENTS_SELECTED = 'UNSET_LEADERSHIPS_ASSESSMENTS_SELECTED'
export const REMOVE_LEADERSHIPS_ASSESSMENTS_SELECTED = 'REMOVE_LEADERSHIPS_ASSESSMENTS_SELECTED'
export const ADD_LEADERSHIPS_ASSESSMENTS_SELECTED = 'ADD_LEADERSHIPS_ASSESSMENTS_SELECTED'
export const SET_LEADERSHIPS_ASSESSMENTS_SELECTED = 'SET_LEADERSHIPS_ASSESSMENTS_SELECTED'

export function leadershipsAssessmentsSelectAll (set) {
    return function (dispatch, getState) {
        const leadershipsAssessments = getLeadershipsAssessments(getState())
        Object.values(leadershipsAssessments).forEach((leadershipsAssessment) => {
            if (set) {
                dispatch(addLeadershipsAssessmentsSelected(leadershipsAssessment))
            } else {
                dispatch(removeLeadershipsAssessmentsSelected(leadershipsAssessment))
            }
        })
    }
}

export function setLeadershipsAssessmentsSelected (leadershipsAssessments) {
    return {
        type: SET_LEADERSHIPS_ASSESSMENTS_SELECTED,
        leadershipsAssessments
    }
}

export function unSetLeadershipsAssessmentsSelected () {
    return {
        type: UNSET_LEADERSHIPS_ASSESSMENTS_SELECTED,
    }
}

export function removeLeadershipsAssessmentsSelected (leadershipAssessment) {
    return {
        type: REMOVE_LEADERSHIPS_ASSESSMENTS_SELECTED,
        leadershipAssessment
    }
}

export function addLeadershipsAssessmentsSelected (leadershipAssessment) {
    console.log(leadershipAssessment)
    return {
        type: ADD_LEADERSHIPS_ASSESSMENTS_SELECTED,
        leadershipAssessment
    }
}
