import { asyncFetch } from './apiFetch'
import { receiveOrganisations } from 'actions/organisationAction'
import { setUserOrganisationWithCredits } from 'actions/userOrganisationAction'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { addAlertExpiry } from 'actions/uiAction'
import { receivePages, unSetPages } from 'actions/uiAction'
import { getPages } from 'reducers/pagesReducer'
import { updateOrganisationBrand } from 'fetchs/organisationBrandFetch'

// CREATE
export function createOrganisation (organisation) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())
    const namespace = organisation.name.replace(/[^0-9a-zA-Z]/g, '').toLowerCase();
    const data = {
        'name': organisation.name,
        'namespace': namespace,
        'type': organisation.type,
        'parent': userOrganisation.organisation.id,
        'url': organisation.url !== '' ? organisation.url : null
    }
    return asyncFetch(dispatch, 'POST', `organisations`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(updateOrganisationError(response.customErrorCode, organisation))
          } else {
            dispatch(updateOrganisationBrand(organisation.branding, namespace))
            dispatch(listOrganisationDescendants(1))
            dispatch(addAlertExpiry('success', 'Organisation created successfully'))
          }
        })
  }
}
// READ
export function readOrganisation (namespace) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'GET', `organisations/${namespace}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(updateOrganisationError(response.customErrorCode, organisation))
          } else {
            dispatch(setUserOrganisationWithCredits(response))
          }
        })
  }
}

// UPDATE
export function updateOrganisation (organisation) {
  return function (dispatch) {
    let data = {
        'name': organisation.name,
        'type': organisation.type,
        'teamReportsActive': organisation.teamReportsActive,
        'threeSixtyReportsActive': organisation.threeSixtyReportsActive,
        'leadershipReportsActive': organisation.leadershipReportsActive,
        'benchmarkReportsActive': organisation.benchmarkReportsActive,
        'developmentReportsActive': organisation.developmentReportsActive,
        'managementReportsActive': organisation.managementReportsActive,
        'interviewReportsActive': organisation.interviewReportsActive,
        'candidateReportsActive': organisation.candidateReportsActive,
        'url': organisation.url ? organisation.url : '',
        'expiresAt': organisation.expiresAt ? organisation.expiresAt : null
    }

    // if (organisation.url !== '') {
    //     data = { ...data, url: organisation.url};
    // }

    return asyncFetch(dispatch, 'PATCH', `organisations/${organisation.namespace}`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(updateOrganisationError(response.customErrorCode, organisation))
          } else {
            dispatch(listOrganisationDescendants(1))
            dispatch(addAlertExpiry('success', 'Organisation details updated successfully'))
          }
        })
  }
}

// DELETE

// LIST
export function listOrganisationDescendants (page) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())

    const pages = getPages(getState())
    const offset = (page - 1) * pages.limit
    const paginationFilterUrl =  `&limit=${pages.limit}&offset=${offset}&count=true`

    return asyncFetch(dispatch, 'GET', `organisations/${userOrganisation.namespace}/descendants?${paginationFilterUrl}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // organisationAssessmentsError(dispatch, response.customErrorCode)
          } else {
            dispatch(receiveOrganisations(response.json))
            dispatch(receivePages(response.resultsTotal, page))
          }
        })
  }
}

export function listOrganisations (page, searchFilter = null, typeFilter = null) {
  return function (dispatch, getState) {
    dispatch(unSetPages())

    let searchFilterUrl = ''
    if (searchFilter) {
      searchFilterUrl = `&filters[]=and-organisation.name-inci-value-${searchFilter}`
    }

    let typeFilterUrl = ''
    if (typeFilter) {
      typeFilterUrl = `&filters[]=and-organisation.type-inci-value-${typeFilter}`
    }

    return asyncFetch(dispatch, 'GET', `organisations?${searchFilterUrl}${typeFilterUrl}`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // organisationAssessmentsError(dispatch, response.customErrorCode)
          } else {
            dispatch(receiveOrganisations(response))
          }
        })
  }
}
