import React from 'react'
import PropTypes from 'prop-types'

const Text = ({ parentId, onItemChange, itemValue, itemName, placeholder, required = true, type = 'text', autocomplete = 'on', disabled = false }) => (
  <input className="margin-bottom-none input" type={type} placeholder={placeholder} value={itemValue} autoComplete={autocomplete}
         name={itemName} onChange={(event) => onItemChange(event, parentId, itemName)} required={required} disabled={disabled}/>
)

Text.propTypes = {
  onItemChange: PropTypes.func.isRequired,
  itemValue: PropTypes.string,
  itemName: PropTypes.string.isRequired,
}

export default Text
