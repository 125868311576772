import React from 'react'
import PropTypes from 'prop-types'

const CellLink = ({ header, onClick, value }) => (
  <td className="table__data">
    <span className="title hide-md">{header}</span>
    <span onClick={onClick} href="#0">{value}</span>
  </td>
)

CellLink.propTypes = {
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default CellLink
