import React from 'react'
import FlowProfilerLogoWhite from 'assets/svg/components/FlowProfilerLogoWhite'

const Logo = () => (
  <div className="panel__item">
    <div className="panel__icon">
      <FlowProfilerLogoWhite/>
    </div>
  </div>
)

export default Logo
