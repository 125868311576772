export const SET_LABELS = 'SET_LABELS'
export const SET_LABEL_SELECTED = 'SET_LABEL_SELECTED'
export const UPDATE_LABEL = 'UPDATE_LABEL'

export function formatLabels(candidateLabels){
  const labels = []
  if(candidateLabels){
    Object.values(candidateLabels).forEach((label) => {
      labels.push(label.name)
    })
  }
  return labels.join(', ')
}

export function receiveLabels (labels) {
  return {
    type: SET_LABELS,
    labels
  }
}

export function receiveLabel (label) {
  return {
    type: UPDATE_LABEL,
    label,
  }
}

export function setLabelSelected (label) {
  return {
    type: SET_LABEL_SELECTED,
    label
  }
}
