import { asyncFetch } from './apiFetch'
import { readBrandError } from 'errors/brandError'
import { updateAuthentication } from 'fetchs/authenticationFetch'
import { addAlertExpiry } from 'actions/uiAction'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
// import { addOrganisationSelectedBranding } from 'actions/organisationSelectedAction'

// CREATE

// READ
export function readOrganisationBrand (organisation, responseMethod, updateAuth = false) {
  return function (dispatch) {
    return asyncFetch(dispatch, 'GET', `organisations/${organisation}/branding`)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            dispatch(readBrandError(response.customErrorCode))
            dispatch(responseMethod({ organisation: false, name: 'default' }))
          } else {
            if(updateAuth){
              dispatch(updateAuthentication(organisation, response.name))
            }
            dispatch(responseMethod(response))
          }
        })
  }
}

// UPDATE
export function updateOrganisationBrand (branding, namespace) {
  return function (dispatch) {

    const data = {
      'name': branding,
    }
    return asyncFetch(dispatch, 'PATCH', `organisations/${namespace}/branding`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            dispatch(readBrandError(response.customErrorCode))
          } else {
            // dispatch(addOrganisationSelectedBranding(response))
          }
        })
  }
}

export function updateOrganisationLogo (assetData) {

  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())
    const data = {
      'logo': assetData.id
    }
    return asyncFetch(dispatch, 'PATCH', `organisations/${userOrganisation.namespace}/branding`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(createUserError(response.customErrorCode, user))
          } else {
            dispatch(addAlertExpiry('success', 'Logo image updated successfully'))
            window.location.reload()
          }
        })
  }
}

// DELETE

// LIST
