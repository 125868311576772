import React from 'react'
import PropTypes from 'prop-types'

const SelectOption = ({ value, title }) => (
  <option value={value}>{title}</option>
)

SelectOption.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  title: PropTypes.string.isRequired,
}

export default SelectOption
