import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCandidatesAssessments } from 'reducers/candidatesAssessmentsReducer'
import { createCandidateAssessment } from 'fetchs/candidateAssessmentFetch'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import {formatLabels} from 'actions/labelAction'
import Moment from 'react-moment';

import { createStructuredSelector } from 'reselect'

class Table extends Component {
  render () {
    const { candidatesAssessments, onClickCandidatesAssessments } = this.props

    const headers = []
    let key = 0
    headers.push(<Header key={++key} header='First'/>)
    headers.push(<Header key={++key} header='Last'/>)
    headers.push(<Header key={++key} header='Assessment'/>)
    headers.push(<Header key={++key} header='Label'/>)
    headers.push(<Header key={++key} header='Status'/>)
    headers.push(<Header key={++key} header='Invited'/>)
    headers.push(<Header key={++key} header='Expires'/>)

    let tableBody = <NoResults colSpan={6}/>
    const body = []

    if (candidatesAssessments.length) {
      candidatesAssessments.forEach((candidateAssessment) => {

        const fullDate = candidateAssessment.createdAt ? <Moment format="DD/MM/YYYY" date={candidateAssessment.createdAt.date} /> : null

        let expires = '';
        if (candidateAssessment.expiresAt) {
          expires = <Moment format="DD/MM/YYYY" date={candidateAssessment.expiresAt.date} />
        }
        const expiresAt = expires;


        let status = 'Sent'
        if (candidateAssessment.deletedAt) {
          status = 'Deleted'
        } else if (candidateAssessment.cancelledAt) {
          status = 'Cancelled'
        } else if (candidateAssessment.completedAt) {
          status = 'Completed'
        } else if (candidateAssessment.startedAt) {
          status = 'Started'
        }

        const row = []
        row.push(<CellText header={'First'} value={candidateAssessment.candidate.firstName} key={++key}/>)
        row.push(<CellText header={'Last'} value={candidateAssessment.candidate.lastName} key={++key}/>)
        row.push(<CellText header={'Assessment'} value={candidateAssessment.assessment.name} key={++key}/>)
        row.push(<CellText header={'Label'} value={formatLabels(candidateAssessment.candidate.labels)} key={++key}/>)
        row.push(<CellText header={'Status'} value={status} key={++key}/>)
        row.push(<CellText header={'Invited'} value={fullDate} key={++key}/>)
        row.push(<CellText header={'Expires'} value={expiresAt} key={++key}/>)
        body.push(<Row onClick={() => onClickCandidatesAssessments(candidateAssessment)} key={++key} row={row}/>)
      })
      tableBody = (<tbody>{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

Table.propTypes = {
  candidatesAssessments: PropTypes.arrayOf(PropTypes.object),
  onCreateCandidateAssessment: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  candidatesAssessments: getCandidatesAssessments
})

const mapDispatchToProps = (dispatch) => ({
  onCreateCandidateAssessment: (candidateAssessment) => {
    dispatch(createCandidateAssessment(candidateAssessment))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Table)
