import React from 'react'
import PropTypes from 'prop-types'

const CellText = ({ header, value }) => (
  <td className="table__data">
    {/* <span className="title hide-md">{header}</span> */}
    <span>{value}</span>
  </td>
)

CellText.propTypes = {
  header: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
  ])
}

export default CellText
