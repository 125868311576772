import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUsers } from 'reducers/usersReducer'
import { addModal } from 'actions/uiAction'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import Moment from 'react-moment';

class TableUsersList extends Component {

  render () {
    const { users, onClickUser } = this.props

    const headers = []
    let key = 0
    headers.push(<Header key={++key} header='First'/>)
    headers.push(<Header key={++key} header='Last'/>)
    headers.push(<Header key={++key} header='Email'/>)
    headers.push(<Header key={++key} header='Role'/>)
    headers.push(<Header key={++key} header='Last Login'/>)
    headers.push(<Header key={++key} header='Status'/>)

    let tableBody = <NoResults colSpan={6}/>
    const body = []

    if (users.length) {
      const organisationName = this.props.userOrganisation.namespace
      Object.values(users).forEach((user) => {
        if (user.status !== 'Pending') {

          let role = null
          if (user.groups[organisationName] && user.groups[organisationName][0]) {
            role = user.groups[organisationName][0].name
          }

          let fullDate = null
          if (user.lastLoginAt) {
            fullDate = <Moment format="DD/MM/YYYY" date={user.lastLoginAt.date} />;
          }

          let status = 'Deactivated'
          if (user.isActive) {
            status = 'Activated'
          }

          const row = []
          row.push(<CellText header={'First'} value={user.firstName} key={++key}/>)
          row.push(<CellText header={'Last'} value={user.lastName} key={++key}/>)
          row.push(<CellText header={'Email'} value={user.primaryEmail} key={++key}/>)
          row.push(<CellText header={'Role'} value={role} key={++key}/>)
          row.push(<CellText header={'Last Login'} value={fullDate} key={++key}/>)
          row.push(<CellText header={'Status'} value={status} key={++key}/>)
          body.push(<Row onClick={() => onClickUser(user)} key={++key} row={row}/>)
        }
      })
      tableBody = (<tbody>{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

TableUsersList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  userOrganisation: PropTypes.object.isRequired,
  onAddModal: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  users: getUsers,
  userOrganisation: getUserOrganisation,
})

const mapDispatchToProps = (dispatch) => ({
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(TableUsersList)
