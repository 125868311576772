import React from 'react'
import PropTypes from 'prop-types'

const Select = ({ selectedItem, onItemChange, itemOptions, itemName, required = true }) => (
    <div className="input-group">
        <select className="select" value={selectedItem} onChange={onItemChange} required={required}>
            <option value="">Select {itemName}...</option>
            {itemOptions}
        </select>
    </div>
)

Select.propTypes = {
    onItemChange: PropTypes.func.isRequired,
    selectedItem: PropTypes.string,
    itemOptions: PropTypes.array,
    itemName: PropTypes.string,
}

export default Select
