import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import DateField from './DateField'

const DateWrapper = ({ onItemChange, itemValue = '', placeholder = 'Expires on' }) => (
    <DatePicker
        customInput={<DateField />}
        id="sentFrom"
        className="select"
        selected={itemValue !== '' && new Date(itemValue)}
        onChange={(date) => onItemChange(date)}
        dropdownMode="select"
        dateFormat="d/MM/yyyy"
        placeholderText={placeholder}
    />
)

DateWrapper.propTypes = {
    onItemChange: PropTypes.func.isRequired,
    itemValue: PropTypes.string
}

export default DateWrapper
