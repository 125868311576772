import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Confirmation = ({ branding }) => (
  <section>
    <h2 className="form__head">Reset Password</h2>
    <p>Please check your email for a link to reset your password</p>
    <div className="form__group">
      <div className="grid grid--flush grid--between">
        <Link className="colour-active" to={`/${branding}/user/login`}><b>Back to Login</b></Link>
      </div>
    </div>
  </section>
)

Confirmation.propTypes = {
  branding: PropTypes.string.isRequired,
}

export default Confirmation
