import React, { Component } from 'react'
import Bars from 'assets/svg/components/Bars'
import { connect } from 'react-redux'
import { openMenu } from 'actions/uiAction'
import { createStructuredSelector } from 'reselect'

class Menu extends Component {
  render () {
    return (
      <div className="menu" onClick={() => this.props.onOpenMenu()}>
        <Bars/>
      </div>
    )
  }
}

const mapStateToPropsSelector = createStructuredSelector({})

const mapDispatchToProps = (dispatch) => ({
  onOpenMenu: () => {
    dispatch(openMenu())
  }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Menu)
