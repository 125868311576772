import React from 'react'
import PropTypes from 'prop-types'
import AddButton from 'assets/svg/components/Add'

const Add = ({ onAddReviewer }) => (
    <div className="padding-top-md padding-bottom-md">
        <span onClick={onAddReviewer}><AddButton/></span>
    </div>
)

Add.propTypes = {
    onAddReviewer: PropTypes.func.isRequired,
}

export default Add
