import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCandidatesAssessments } from 'reducers/candidatesAssessmentsReducer'
import { createCandidateAssessment } from 'fetchs/candidateAssessmentFetch'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import {formatLabels} from 'actions/labelAction'
import Moment from 'react-moment';

import { createStructuredSelector } from 'reselect'
import Options from "../LeadershipAssessmentConfirm/Options";
import ChevronRight from "../../../assets/svg/components/ChevronRight";
import {
    createLeadershipAssessment,
    reminderLeadershipAssessmentReview
} from "../../../fetchs/leadershipAssessmentFetch";

class Table extends Component {

    constructor(props) {
        super(props)
    }

    render () {
        const { reviewers, onResendReview, onResendAllReviews } = this.props

        const headers = []
        let key = 0
        headers.push(<Header key={++key} header='First'/>)
        headers.push(<Header key={++key} header='Last'/>)
        headers.push(<Header key={++key} header='Email'/>)
        headers.push(<Header key={++key} header='Relationship'/>)
        headers.push(<Header key={++key} header='Completed'/>)
        headers.push(<Header key={++key} header=''/>)

        let tableBody = <tbody>
        <tr className="table__group">
            <td/>
            <td colSpan="3">
                Loading reviewers
            </td>
        </tr>
        </tbody>
        const body = []

        if (reviewers) {

            if (reviewers.length) {

                reviewers.forEach((review) => {

                    const completedAt = review.completedAt ? <Moment format="DD/MM/YYYY" date={review.completedAt.date} /> : ''

                    const row = []
                    row.push(<CellText header={'First'} value={review.firstName} key={++key}/>)
                    row.push(<CellText header={'Last'} value={review.lastName} key={++key}/>)
                    row.push(<CellText header={'Email'} value={review.email} key={++key}/>)
                    row.push(<CellText header={'Relationship'} value={review.relationship} key={++key}/>)
                    row.push(<CellText header={'Completed'} value={completedAt} key={++key}/>)
                    if (!review.completedAt) {
                        row.push(<td key={++key}><button className="button button--active" onClick={() => onResendReview(review)}>
                            <span className="padding-right-sm">Resend</span>
                            <i className="icon"><ChevronRight/></i>
                        </button></td>)
                    }
                    else {
                        row.push(<td key={++key}></td>)
                    }
                    body.push(<Row key={++key} row={row}/>)
                })

                const lastRow = []
                lastRow.push(<td key={++key}></td>)
                lastRow.push(<td key={++key}></td>)
                lastRow.push(<td key={++key}></td>)
                lastRow.push(<td key={++key}></td>)
                lastRow.push(<td key={++key}></td>)
                lastRow.push(<td key={++key}><button className="button button--active" onClick={() => onResendAllReviews()}>
                    <span className="padding-right-sm">Resend all</span>
                    <i className="icon"><ChevronRight/></i>
                </button></td>)
                body.push(<Row key={++key} row={lastRow}/>)

                tableBody = (<tbody>{body}</tbody>)
            }
            else {
                tableBody = <NoResults colSpan={3}/>
            }

        }

        return (
            <div className="padding-top-md">
                <table className="table">
                    <Head headers={headers}/>
                    {tableBody}
                </table>
            </div>
        )
    }
}

Table.propTypes = {
    reviewers: PropTypes.arrayOf(PropTypes.object),
    onResendReview: PropTypes.func.isRequired,
    onResendAllReviews: PropTypes.func.isRequired,
}

export default Table
