import React from 'react'

const User = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="21.8">
    <path
      d="M18.8 17.7c0 1-.3 1.8-.9 2.4-.6.6-1.5.9-2.5.9H4.1c-1 0-1.9-.3-2.5-.9-.6-.6-.9-1.4-.9-2.4v-1.3c0-.4.1-.9.2-1.4.1-.5.2-1 .3-1.4s.3-.8.6-1.3c.2-.4.5-.8.8-1 .3-.3.7-.5 1.1-.7.4-.2.9-.3 1.4-.3.1 0 .3.1.6.3l.9.6c.4.3.8.5 1.4.6.6.2 1.2.3 1.7.3.6 0 1.2-.1 1.7-.3.6-.2 1-.4 1.4-.6.3-.2.7-.4.9-.6.3-.2.5-.3.6-.3.5 0 1 .1 1.4.3.4.2.8.4 1.1.7.3.3.6.6.8 1 .2.4.4.8.6 1.3.1.4.3.9.3 1.4.1.5.2 1 .2 1.4.1.4.1.8.1 1.3z"/>
    <path
      d="M14.7 5.7c0 1.4-.5 2.5-1.4 3.5s-2.1 1.4-3.5 1.4-2.5-.5-3.5-1.4-1.5-2.1-1.5-3.5.5-2.5 1.4-3.5S8.3.8 9.7.8s2.5.5 3.5 1.4 1.5 2.1 1.5 3.5z"/>
  </svg>
)

export default User
