import React from 'react'
import PropTypes from 'prop-types'

const Field = ({ itemLabel, input, required }) => (
  <div className="grid__item w-6-md">
    <div className="grid grid--middle">
      <div className="grid__item w-12">
        {/*<label className={required ? 'label label--required' : 'label'} htmlFor="firstName"><b*/}
          {/*className="colour-black">{itemLabel}:</b> </label>*/}
        {required ? '*' : ''}<label className="label" htmlFor="firstName"><b
          className="colour-black">{itemLabel}:</b> </label>
      </div>
      <div className="grid__item">
        {input}
      </div>
    </div>
  </div>
)

Field.propTypes = {
  itemLabel: PropTypes.string.isRequired,
  input: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
}

export default Field
