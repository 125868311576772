import { ADD_ALL_ASSESSMENT } from 'actions/assessmentAction'
import { getAssessmentsSelected } from './assessmentsSelectedReducer'
import {
 getOrganisationSelected
} from './organisationSelectedReducer'
import { createSelector } from 'reselect'

const initialState = []

const organisationAssessments = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALL_ASSESSMENT:
      return action.assessments
    default:
      return state
  }
}
export default organisationAssessments

export const getOrganisationAssessments = state => state.organisationAssessments

export const getAssessmentsBySelected = createSelector(
  getAssessmentsSelected,
  getOrganisationAssessments,
  (assessmentsSelected, organisationAssessments) => {
    return organisationAssessments.filter(function (assessment) {
      return assessment.id in assessmentsSelected
    })
  }
)

export const getUserAssessmentsBySharedToken = createSelector(
  getOrganisationAssessments,
  getOrganisationSelected,
  (organisationAssessments, organisation) => {
    return filterAssessments(organisationAssessments, organisation, ['shared', 'unlimited'])
  }
)

export const getUserAssessmentsBySpecificToken = createSelector(
  getOrganisationAssessments,
  getOrganisationSelected,
  (organisationAssessments, organisation) => {
    return filterAssessments(organisationAssessments, organisation, ['shared', 'specific', 'unlimited'])
  }
)

export const getUserAssessmentsByUnlimitedToken = createSelector(
  getOrganisationAssessments,
  getOrganisationSelected,
  (organisationAssessments, organisation) => {
    return filterAssessments(organisationAssessments, organisation, ['unlimited'])
  }
)

function filterAssessments (organisationAssessments, organisation, tokenTypes) {
  // console.group('assess')
  // console.log(organisationAssessments)
  // console.log(organisation)
  // console.log(tokenTypes)
  // console.groupEnd()
  return organisationAssessments.filter(function (organisationAssessment) {
    let isAssigned = false
    if(organisation.assessments){
      isAssigned = organisation.assessments.some(assignedAssessment => {
          // we don't want to check against team token types
          if (assignedAssessment.tokenType !== 'team') {
              return assignedAssessment.assessment.id === organisationAssessment.assessment.id
          }
            else {
                return false
          }
      })
    }

    return (tokenTypes.includes(organisationAssessment.tokenType) && !isAssigned)
  })
}
