import React from 'react'
import PropTypes from 'prop-types'

const Field = ({ itemLabel, input, required }) => (
  <div className="grid__item w-6-md">
    <div className="grid grid--middle">
      <div className="grid__item w-12">
        <label className={required ? 'label label--required' : 'label'} htmlFor="firstName">
          <span className="colour-black">{itemLabel}: </span>
        </label>
        <span className=""> {input}</span>
      </div>
    </div>
  </div>
)

Field.propTypes = {
  itemLabel: PropTypes.string.isRequired,
  input: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default Field
