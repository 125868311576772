import React, { Component } from 'react'
import { connect } from 'react-redux'
import { removeToken } from 'actions/authenticateAction'
import FlowProfilerLogo from 'assets/svg/components/FlowProfilerLogo'
import Cross from 'assets/svg/components/Cross'
import { closeMenu } from 'actions/uiAction'

import Head from './Head'
import GroupTop from './GroupTop'
import GroupBottom from './GroupBottom'
import { createStructuredSelector } from 'reselect'
import { getMenu } from 'reducers/menuReducer'
import PropTypes from 'prop-types'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { getUser } from 'reducers/userReducer'

class Sidebar extends Component {
  render () {
    const { menu, onLogOut, onCloseMenu, userOrganisation, user } = this.props

    let logoImage = <span className="icon-flowprofiler-logo"></span>
    if(userOrganisation.branding.logo){
      logoImage = <img alt={'no logo'} height="50" src={userOrganisation.branding.logo.assetUrl}/>
    }

    return (
      <div className={menu ? 'sidebar sidebar--menu' : 'sidebar'}>
        <div className="menu-close" onClick={() => onCloseMenu()}>
          <i className="icon"><Cross/></i>
        </div>
        <div className="sidebar__body">
          <Head user={user} logoImage={logoImage} organisation={(userOrganisation.organisation.name ? userOrganisation.organisation.name : '')}/>
          <GroupTop/>
          <GroupBottom onLogOut={onLogOut}/>
          <p className="sidebar__logo_container">Powered by <i className="logo"><FlowProfilerLogo/></i></p>
        </div>
      </div>
    )
  }
}

Sidebar.propTypes = {
  menu: PropTypes.bool.isRequired,
  onLogOut: PropTypes.func.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  menu: getMenu,
  userOrganisation: getUserOrganisation,
  user: getUser
})

const mapDispatchToProps = (dispatch) => ({
  onLogOut: () => {
    dispatch(removeToken())
  },
  onCloseMenu: () => {
    dispatch(closeMenu())
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Sidebar)
