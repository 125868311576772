import React, { PureComponent } from 'react';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import { array, string, number, func } from 'prop-types';

import ArrowDown from 'assets/svg/components/ArrowDown'
import DropdownItem from './DropdownItem';

class Dropdown extends PureComponent {
  constructor() {
    super();
    this.state = {
      active: false
    };
  }

  toggleActive = () => {
    this.setState({
      active: !this.state.active
    });
  };

  handleClickOutside = () => {
    this.setState({
      active: false
    });
  };

  handleChangeOption = id => {
    this.props.handleChangeFunc(id);
    this.setState({
      active: false
    })
  };

  render() {
    const {
      options,
      labelSelector,
      selectedOptionId,
      modifierClass
    } = this.props;

    const { active } = this.state;

    const selectedOption = options.filter(option => option.id === selectedOptionId)[0];

    return (
      <div className={classNames('dropdown', {
        'dropdown--active': active,
        [`dropdown--${modifierClass}`]: modifierClass
      })}>
        <div className="dropdown__selected-option">
          <button onClick={this.toggleActive}>
            <i className="icon">
              <ArrowDown color="#005a6d" />
            </i>
            {selectedOption[labelSelector]}
          </button>
        </div>
        <div className="dropdown__options">
          {options.map(option =>
            <DropdownItem
              key={option.id}
              option={option}
              handleChangeOption={this.handleChangeOption}
              labelSelector={labelSelector}
            />
          )}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: array.isRequired,
  labelSelector: string.isRequired,
  selectedOptionId:number.isRequired,
  modifierClass: string,
  handleChangeFunc: func.isRequired
};

Dropdown.defaultProps = {
  modifierClass: undefined
};

export default enhanceWithClickOutside(Dropdown);