import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PropTypes from 'prop-types'
import { getLabelSelected } from 'reducers/labelSelectedReducer'
import { updateLabel, deleteLabel } from 'fetchs/labelFetch'
import Save from './Save'
import Remove from './Remove'
import Text from 'components/Form/Text'
import { removeModal } from 'actions/uiAction'

class ModalLabelPage extends Component {
  constructor (props) {
    super(props)

    const label = { ...this.props.labelSelected }

    this.state = {
      label,
    }
  }

  handleTextChange = (event, labelId, field) => {
    const label = { ...this.state.label }
    label[field] = event.target.value

    this.setState({ label })
  }

  handleRemove = () => {
    this.props.onDeleteLabel(this.state.label)
    this.props.onRemoveModal()
  }

  handleConfirm = (event) => {
    event.preventDefault()
    this.props.onUpdateLabel(this.state.label)
  }

  render () {
    const { label } = this.state

    return (
      <div className="padding-top-md text-left">
        <div className="grid">
          <form className="form" onSubmit={this.handleConfirm}>
            <div className="grid__item w-6-md">
              <div className="grid grid--middle">
                <div className="grid__item w-12">
                  <label className="label" htmlFor="firstName"><b
                    className="colour-black">Name:</b> </label>
                </div>
                <div className="grid__item">
                  <Text parentId={label.id} onItemChange={this.handleTextChange} itemValue={label.name}
                        itemLabel={'Name'} itemName={'name'}/>
                </div>
              </div>
            </div>
            <div className="grid__item w-6-md">
              <div className="grid grid--middle">
                <div className="grid__item w-12">
                  <Remove onRemove={() => this.handleRemove(label.id)}/>
                  <Save/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

ModalLabelPage.propTypes = {
  labelSelected: PropTypes.object.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  labelSelected: getLabelSelected,
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateLabel: (label) => {
    dispatch(updateLabel(label))
  },
  onDeleteLabel: (label) => {
    dispatch(deleteLabel(label))
  },
  onRemoveModal: () => {
    dispatch(removeModal())
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalLabelPage)
