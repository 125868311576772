import React, { PureComponent } from 'react';
import { string, func } from 'prop-types';

class Cta extends PureComponent {
  render() {
    const { text, onClick } = this.props;
    return (
      <button onClick={onClick} className="cta">
        {text}
      </button>
    );
  }
}

Cta.propTypes = {
  text: string.isRequired,
  onClick: func.isRequired
};

export default Cta;