import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getCandidatesAssessments } from 'reducers/candidatesAssessmentsReducer'
import { createCandidateAssessment } from 'fetchs/candidateAssessmentFetch'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import {formatLabels} from 'actions/labelAction'
import Moment from 'react-moment';

import { createStructuredSelector } from 'reselect'

class Table extends Component {
    render () {
        const { teamReports, onClickReport } = this.props

        const headers = []
        let key = 0
        headers.push(<Header key={++key} header='Name'/>)
        headers.push(<Header key={++key} header='Report type'/>)
        headers.push(<Header key={++key} header='Created at'/>)

        let tableBody = <tbody>
        <tr className="table__group">
            <td/>
            <td colSpan="3">
                Loading saved team reports
            </td>
        </tr>
        </tbody>
        const body = []

        if (teamReports) {


            if (teamReports.length > 0) {
                teamReports.forEach((teamReport) => {

                    const createdAt = teamReport.createdAt ? <Moment format="DD/MM/YYYY" date={teamReport.createdAt.date} /> : null

                    const row = []
                    row.push(<CellText header={'First'} value={teamReport.name} key={++key}/>)
                    row.push(<CellText header={'Last'} value={teamReport.reportType} key={++key}/>)
                    row.push(<CellText header={'Created at'} value={createdAt} key={++key}/>)
                    body.push(<Row onClick={(event) => onClickReport(event, teamReport)} key={++key} row={row}/>)
                })
                tableBody = (<tbody>{body}</tbody>)
            }
            else {
                tableBody = <NoResults colSpan={3}/>
            }


        }

        return (
            <div>
                <table className="table">
                    <Head headers={headers}/>
                    {tableBody}
                </table>
            </div>
        )
    }
}

Table.propTypes = {
    teamReports: PropTypes.arrayOf(PropTypes.object),
    onClickReport: PropTypes.func.isRequired
}

export default connect()(Table)
