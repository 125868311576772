import { readOrganisationCredit } from 'fetchs/organisationCreditFetch'
import { listOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import { readOrganisationBrand, updateOrganisationBrand } from 'fetchs/organisationBrandFetch'
import { updateOrganisation } from 'fetchs/organisationFetch'

export const SET_ORGANISATION_SELECTED = 'SET_ORGANISATION_SELECTED'
export const ADD_ORGANISATION_CREDITS = 'ADD_ORGANISATION_CREDITS'
export const ADD_ORGANISATION_ASSESSMENTS = 'ADD_ORGANISATION_ASSESSMENTS'
export const ADD_ORGANISATION_BRANDING = 'ADD_ORGANISATION_BRANDING'
export const ORGANISATION_UPDATED_CREDITS = 'ORGANISATION_UPDATED_CREDITS'


export function setOrganisationSelectedWithCreditsBranding (organisation) {
  return function (dispatch) {
    dispatch(setOrganisationSelected(organisation))
    dispatch(listOrganisationAssessments(organisation, addOrganisationSelectedAssessments))
    dispatch(readOrganisationCredit(organisation, addOrganisationCredits))
    dispatch(readOrganisationBrand(organisation.namespace, addOrganisationSelectedBranding))
  }
}

export function updateOrganisationSelected (organisationSelected) {
    return function (dispatch) {
      dispatch(updateOrganisationBrand(organisationSelected.branding.name, organisationSelected.organisation.namespace))
      dispatch(updateOrganisation(organisationSelected.organisation))
    }
}


export function addOrganisationSelectedBranding (branding) {
  return {
    type: ADD_ORGANISATION_BRANDING,
    branding
  }
}

export function addOrganisationSelectedAssessments (assessments) {
  return {
    type: ADD_ORGANISATION_ASSESSMENTS,
    assessments
  }
}

export function setOrganisationSelected (organisation) {
  return {
    type: SET_ORGANISATION_SELECTED,
    organisation
  }
}


export function addOrganisationCredits (credits) {
  return {
    type: ADD_ORGANISATION_CREDITS,
    credits
  }
}

export function organisationUpdatedCredits () {
  return {
    type: ORGANISATION_UPDATED_CREDITS
  }
}
