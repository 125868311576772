import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

import SearchIcon from 'assets/svg/components/Search'

class Search extends PureComponent {
constructor() {
  super();
  this.state = {
    focused: false
  }
}
onBlur = () => {
  this.setState({
    focused: false
  });
}
onFocus = () => {
  this.setState({
    focused: true
  });
}
render() {
  const { focused } = this.state;
  const { searchValue, onSearchChange } = this.props;

  return (
    <div className="grid__item w-2-md">
      <div className={classNames("input-group", {
        ['input-group--search-focused']: focused
      })}>
        <input onFocus={this.onFocus} onBlur={this.onBlur} onChange={onSearchChange} className="input-search" type="text" placeholder="Search..."
              value={searchValue}/>
        <button className="input-button">{!focused && <SearchIcon/>}</button>
      </div>
    </div>
  );
  }
}

Search.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
}

export default Search
