import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import Tabs from './Tabs'
import NewCandidatesForm from 'containers/InputPage/Form'
import UploadCandidates from './UploadCandidates'
import TableCandidatesSelect from 'containers/Table/CandidatesSelect'
import Options from './Options'
import { createStructuredSelector } from 'reselect'
import { getModal } from 'reducers/modalReducer'
import { getAssessmentsBySelected } from 'reducers/organisationAssessmentsReducer'
import { unSetCandidates } from 'actions/candidateAction'
import { getCandidatesSelected } from 'reducers/candidatesSelectedReducer'
import { addActiveTab, addAlertExpiry } from 'actions/uiAction'
import { getActiveTab } from 'reducers/activeTabReducer'
import Credits from "../../../components/Credits";
import { setUserOrganisationWithCredits } from '../../../actions/userOrganisationAction';
import { getUserOrganisation } from '../../../reducers/userOrganisationReducer'
import { getOrganisationAssessments } from '../../../reducers/organisationAssessmentsReducer'
import {getLeaderSelected} from "../../../reducers/leaderSelectedReducer";
import NewReviewersForm from 'containers/Modal/LeadershipAssessmentReviewers/Form'

class LeadershipAssessmentReviewers extends Component {
    constructor(props) {
        super(props)

        // this.props.onUnSetCandidates()

        // this.state = {
        //     credits: [],
        // }
    }

    handleConfirm = () => {
        if (this.props.candidatesSelected.length) {
            this.props.onAddModal('Leadership Assessment Confirm')
        } else {
            // this.props.onAddAlertExpiry('Please Select a Candidate')
        }
    }

    render() {
        const { assessmentsBySelected, activeTab, organisationAssessments } = this.props

        let content, optionButton;
        content = <NewReviewersForm onAddReviewer={this.handleAddReviewer} />
        optionButton = null

        return (
            <section>
                {/*<div className="padding-top-md">*/}
                {/*    {assessmentsBySelected.length > 0 ? (*/}
                {/*        <CardGroup*/}
                {/*            cards={assessmentsBySelected}*/}
                {/*            noSelectionRequired*/}
                {/*        />*/}
                {/*    ) : (*/}
                {/*        <NoResults/>*/}
                {/*    )}*/}
                {/*</div>*/}
                <h2>Leader: { this.props.leaderSelected.firstName } { this.props.leaderSelected.lastName }</h2>
                {/*<div>*/}
                {/*    <table className="table">*/}
                {/*        <tbody>*/}
                {/*            <tr>*/}
                {/*                <td><strong>{ this.props.leaderSelected.firstName }</strong></td>*/}
                {/*                <td><strong>{ this.props.leaderSelected.lastName }</strong></td>*/}
                {/*                <td><strong>{ this.props.leaderSelected.email }</strong></td>*/}
                {/*            </tr>*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</div>*/}
                <h2>Create Reviewers:</h2>
                {content}
                {optionButton}
            </section>
        )
    }
}

LeadershipAssessmentReviewers.propTypes = {
    modal: PropTypes.array.isRequired,
    assessmentsBySelected: PropTypes.arrayOf(PropTypes.object),
    leaderSelected: PropTypes.object,
    onAddModal: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({

})

const mapStateToPropsSelector = createStructuredSelector({
    modal: getModal,
    assessmentsBySelected: getAssessmentsBySelected,
    userOrganisation: getUserOrganisation,
    organisationAssessments: getOrganisationAssessments,
    leaderSelected: getLeaderSelected,
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(LeadershipAssessmentReviewers)
