import { SET_ASSESSMENTS_SELECTED, UNSET_ASSESSMENTS_SELECTED } from 'actions/assessmentAction'

const initialState = {}

const assessmentsSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSESSMENTS_SELECTED:
      return action.assessments
    case UNSET_ASSESSMENTS_SELECTED:
      return initialState
    default:
      return state
  }
}

export default assessmentsSelected

export const getAssessmentsSelected = state => state.assessmentsSelected
