import {
  ADD_CANDIDATES_SELECTED,
  REMOVE_CANDIDATES_SELECTED,
  UNSET_CANDIDATES_SELECTED
} from 'actions/candidateSelectedAction'

const initialState = []

const candidatesSelected = (state = initialState, action) => {
  const newState = Object.assign([], state)
  switch (action.type) {
    case UNSET_CANDIDATES_SELECTED:
      return initialState
    case ADD_CANDIDATES_SELECTED:
      var notExist = newState.some(obj => {
        return obj.id === action.candidate.id
      })

      if (!notExist) {
        newState.push(action.candidate)
      }

      return newState
    case REMOVE_CANDIDATES_SELECTED:
      return newState.filter(obj => {
        return obj.id !== action.candidate.id
      })
    default:
      return state
  }
}

export default candidatesSelected

export const getCandidatesSelected = state => state.candidatesSelected
