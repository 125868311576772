import React from 'react'
import PropTypes from 'prop-types'

const Row = ({ row, onClick }) => (
  <tr onClick={onClick} className="table__group">{row}</tr>
)

Row.propTypes = {
  row: PropTypes.array.isRequired
}

export default Row
