import {
  SET_CANDIDATE_ASSESSMENT_STATUS,
  SET_CANDIDATES_ASSESSMENTS,
  UNSET_CANDIDATES_ASSESSMENTS
} from 'actions/candidateAssessmentAction'
import { getOrganisationAssessments, getAssessmentsBySelected } from './organisationAssessmentsReducer'
import { getCandidatesSelected } from './candidatesSelectedReducer'
import { getCandidateSelected } from './candidateSelectedReducer'
import { createSelector } from 'reselect'

const initialState = []

const candidatesAssessments = (state = initialState, action) => {
  const candidatesAssessments = Object.assign([], state)
  switch (action.type) {
    case SET_CANDIDATES_ASSESSMENTS:
      return action.candidatesAssessments
    case UNSET_CANDIDATES_ASSESSMENTS:
      return initialState
    case SET_CANDIDATE_ASSESSMENT_STATUS:
      candidatesAssessments.map(function (candidateAssessment) {
        if (candidateAssessment.candidate.id === action.candidateAssessment.candidate.id &&
          candidateAssessment.assessment.id === action.candidateAssessment.assessment.id) {
          candidateAssessment['status'] = action.status
        }
        return candidateAssessment
      })
      return candidatesAssessments
    default:
      return state
  }
}

export default candidatesAssessments

export const getCandidatesAssessments = state => state.candidatesAssessments

export const calculateCandidatesAssessments = createSelector(
  getAssessmentsBySelected,
  getCandidatesSelected,
  (assessmentsBySelected, candidatesSelected) => {
    return candidateAssessmentsArray(assessmentsBySelected, candidatesSelected)
  }
)

export const calculateCandidateAssessments = createSelector(
  getAssessmentsBySelected,
  getCandidateSelected,
  (assessmentsBySelected, candidate) => {
    return candidateAssessmentsArray(assessmentsBySelected, [candidate])
  }
)

export function candidateAssessmentsArray (assessments, candidates) {
  let candidatesAssessments = []
  Object.values(candidates).forEach(function (candidate) {
    Object.values(assessments).forEach(function (assessment) {
      candidatesAssessments.push({ candidate, assessment, status: 'Pending' })
    })
  })
  return candidatesAssessments
}

export const getAssessmentsByStatus = createSelector(
  getCandidatesAssessments,
  getOrganisationAssessments,
  (candidatesAssessments, organisationAssessments) => {
    return organisationAssessments.map(function (assessment) {
      assessment['status'] = getStatus(assessment.assessment.id, candidatesAssessments)
      return assessment
    })
  }
)

export function getStatus (assessmentId, candidatesAssessments) {
  let status = 'available'
  candidatesAssessments.forEach((candidateAssessment) => {
    if (assessmentId === candidateAssessment.assessment.id && candidateAssessment.completedAt && !candidateAssessment.deletedAt && !candidateAssessment.cancelledAt) {
      status = 'completed'
    } else if (assessmentId === candidateAssessment.assessment.id && !candidateAssessment.deletedAt && !candidateAssessment.cancelledAt) {
      status = 'sent'
    }
  })

  return status
}
