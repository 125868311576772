import React, { Component } from 'react'
import Table from './Table'
import Filter from './Filter'
import { connect } from 'react-redux'
import { listCandidates } from 'fetchs/candidateFetch'
import { addModal } from 'actions/uiAction'
import { listLabels } from 'fetchs/labelFetch'
import { reorderOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch.js'
import { addBackground, removeBackground } from 'actions/backgroundAction'
import {
  addCandidatesSelected,
  candidatesSelectAll,
  removeCandidatesSelected,
  setCandidateSelected, unSetCandidatesSelected,
} from 'actions/candidateSelectedAction'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getCandidatesSelected } from 'reducers/candidatesSelectedReducer'
import { getCandidates } from 'reducers/candidatesReducer'
import { getLabels } from 'reducers/labelsReducer'
import Pagination from 'components/Pagination'

class TableCandidatesSelect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchValue: '',
      lastSearch: 0,
      minimumSearch: false,
      selectedLabel: '',
      selectAll: false,
    }
  }

  handleSelectAllCandidates = () => {
    this.props.onCandidatesSelectAll(!this.state.selectAll)
    this.setState({ selectAll: !this.state.selectAll })
  }

  handleSelectCandidate = (candidate, isChecked) => {
    if (isChecked) {
      this.props.onRemoveCandidatesSelected(candidate)
      this.setState({ selectAll: false })
    } else {
      this.props.onAddCandidatesSelected(candidate)
    }
  }

  componentDidMount () {
    this.props.onListCandidates(1)
    this.props.onListLabels()
    this.props.onAddBackground()
    this.props.onUnSetCandidatesSelected()
  }

  componentWillUnmount () {
    this.props.onRemoveBackground()
  }

  handleSearchChange = (event) => {
    const searchValue = event.target.value
    const date = new Date()
    const timestamp = date.getTime()
    const second = 1000
    this.setState({ searchValue, lastSearch: timestamp })

    setTimeout(() => {
      if (this.state.searchValue.length > 2 && this.state.lastSearch + (second) > timestamp) {
        this.setState({ minimumSearch: true })
        this.props.onListCandidates(1, this.state.searchValue, this.state.selectedLabel)
      } else if (this.state.minimumSearch) {
        this.setState({ minimumSearch: false })
        this.props.onListCandidates(1, '', this.state.selectedLabel)
      }
    }, second)
  }

  handleLabelChange = (event) => {
    this.setState({ selectedLabel: event.target.value })
    this.props.onListCandidates(1, this.state.searchValue, event.target.value)
  }

  handleClickCandidate = (event, candidate) => {
    if(event.target.type !== 'checkbox'){
      this.props.onSetCandidateSelected(candidate)
      this.props.onAddModal('Individual')
    }
  }

  handleReset = () => {
    this.setState({ searchValue: '', selectedLabel: '' })
    this.props.onListCandidates(1)
  }

  handlePageChange = (page) => {
    this.props.onListCandidates(page, this.state.searchValue, this.state.selectedLabel)
  }

  handleReorder = (fromIndex, toIndex) => {
    reorderOrganisationAssessments(fromIndex);
    reorderOrganisationAssessments(toIndex);
  }

  render () {
    const { candidates, labels, candidatesSelected, modal } = this.props
    const { searchValue, selectedLabel, selectAll } = this.state

    return (
      <section>
        <Filter labels={labels} searchValue={searchValue}
                selectedLabel={selectedLabel}
                onSearchChange={this.handleSearchChange}
                onLabelChange={this.handleLabelChange} onReset={this.handleReset}/>
        <div className="padding-top-md">
          <Table candidates={candidates} candidatesSelected={candidatesSelected} selectAll={selectAll}
                 modal={modal}
                 enableListReordering={true}
                 onReorder={this.handleReorder}
                 onClickCandidate={this.handleClickCandidate}
                 onSelectAllCandidates={this.handleSelectAllCandidates}
                 onSelectCandidate={this.handleSelectCandidate}/>
        </div>
        <Pagination onPageChange={this.handlePageChange}/>
      </section>
    )
  }
}

TableCandidatesSelect.propTypes = {
  candidatesSelected: PropTypes.arrayOf(PropTypes.object),
  candidates: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.arrayOf(PropTypes.object),
  modal: PropTypes.bool,
  onListCandidates: PropTypes.func.isRequired,
  onListLabels: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onAddBackground: PropTypes.func.isRequired,
  onRemoveBackground: PropTypes.func.isRequired,
  onCandidatesSelectAll: PropTypes.func.isRequired,
  onSetCandidateSelected: PropTypes.func.isRequired,
  onRemoveCandidatesSelected: PropTypes.func.isRequired,
  onAddCandidatesSelected: PropTypes.func.isRequired,
  onUnSetCandidatesSelected: PropTypes.func.isRequired,
}
const mapStateToPropsSelector = createStructuredSelector({
  candidatesSelected: getCandidatesSelected,
  candidates: getCandidates,
  labels: getLabels
})

const mapDispatchToProps = (dispatch) => ({
  onListCandidates: (page, searchFilter, labelFilter) => {
    dispatch(listCandidates(page, searchFilter, labelFilter))
  },
  onListLabels: () => {
    dispatch(listLabels(false))
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
  onAddBackground: () => {
    dispatch(addBackground())
  },
  onRemoveBackground: () => {
    dispatch(removeBackground())
  },
  onCandidatesSelectAll: (set) => {
    dispatch(candidatesSelectAll(set))
  },
  onSetCandidateSelected: (candidateSelected) => {
    dispatch(setCandidateSelected(candidateSelected))
  },
  onRemoveCandidatesSelected: (candidate) => {
    dispatch(removeCandidatesSelected(candidate))
  },
  onAddCandidatesSelected: (candidate) => {
    dispatch(addCandidatesSelected(candidate))
  },
  onUnSetCandidatesSelected: () => {
    dispatch(unSetCandidatesSelected())
  }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(TableCandidatesSelect)
