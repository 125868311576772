import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getReviewers } from 'reducers/reviewersReducer'
import { removeReviewer } from 'actions/reviewerAction'
import { addModal } from 'actions/uiAction'
import CellLink from 'components/Table/CellLink'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import {formatLabels} from 'actions/labelAction'

class TableReviewersConfirm extends Component {
    handleRemoveReviewer = (reviewer) => {
        this.props.onRemoveReviewersSelected(reviewer)
    }

    handleClickReviewer = (reviewer) => {
        this.props.onSetReviewerSelected(reviewer)
        this.props.onAddModal('Individual')
    }

    render () {
        const { reviewers } = this.props

        let reviewerList = reviewers

        const headers = []
        let key = 0
        headers.push(<Header key={++key} header='First'/>)
        headers.push(<Header key={++key} header='Last'/>)
        headers.push(<Header key={++key} header='Email'/>)
        headers.push(<Header key={++key} header='Relationship'/>)
        headers.push(<Header key={++key} header='Remove'/>)

        let tableBody = <NoResults colSpan={5}/>
        const body = []

        if (Object.values(reviewerList).length) {
            Object.values(reviewerList).forEach((reviewer) => {

                const row = []
                row.push(<CellText header={'First'} value={reviewer.firstName} key={++key}/>)
                row.push(<CellText header={'Last'} value={reviewer.lastName} key={++key}/>)
                row.push(<CellText header={'Email'} value={reviewer.email} key={++key}/>)
                row.push(<CellText header={'Relationship'} value={reviewer.relationship} key={++key}/>)
                // row.push(<CellText header={'Label'} value={formatLabels(reviewer.labels)} key={++key}/>)
                if (reviewers.length) {
                    row.push(<CellLink header={'Remove'} onClick={() => this.handleRemoveReviewer(reviewer)} value={'Remove'}
                                       key={++key}/>)
                }
                body.push(<Row key={++key} row={row}/>)
            })
            tableBody = (<tbody>{body}</tbody>)
        }

        return (
            <div className="padding-top-md">
                <table className="table">
                    <Head headers={headers}/>
                    {tableBody}
                </table>
            </div>
        )
    }
}

TableReviewersConfirm.propTypes = {
    reviewers: PropTypes.arrayOf(PropTypes.object),
    onRemoveReviewersSelected: PropTypes.func.isRequired,
    onAddModal: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
    reviewers: getReviewers,
})

const mapDispatchToProps = (dispatch) => ({
    onRemoveReviewersSelected: (reviewer) => {
        dispatch(removeReviewer(reviewer))
    },
    onAddModal: (modal) => {
        dispatch(addModal(modal))
    },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(TableReviewersConfirm)
