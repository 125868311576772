import { asyncFetch } from './apiFetch'
import { receiveRoles } from 'actions/roleAction'

// CREATE

// READ

// UPDATE

// DELETE

// LIST

export function listOrganisationRoles (organisation) {
    return function (dispatch) {
        return asyncFetch(dispatch, 'GET', `organisations/${organisation}/groups`)
            .then(
                (response) => {
                    if (response && response.customErrorCode) {
                        // organisationAssessmentsError(dispatch, response.customErrorCode)
                    } else {
                        console.log(response.json)
                        dispatch(receiveRoles(response.json))
                    }
                })
    }
}
