import {
    ADD_LEADER_SELECTED,
    UNSET_LEADER_SELECTED
} from 'actions/leaderSelectedAction'

const initialState = {}

const leaderSelected = (state = initialState, action) => {
    let newState = Object.assign({}, state)
    switch (action.type) {
        case UNSET_LEADER_SELECTED:
            return initialState
        case ADD_LEADER_SELECTED:
            var notExist = newState.id === action.candidate.id

            if (!notExist) {
                newState = action.candidate
            }

            return newState
        default:
            return state
    }
}

export default leaderSelected

export const getLeaderSelected = state => state.leaderSelected
