import { addAlertExpiry } from 'actions/uiAction'
// CREATE
export const authenticateError = (errorCode) => {
  return function (dispatch) {
    switch (errorCode) {
      case 4010001:
        // Invalid Credentials
        dispatch(addAlertExpiry('warning', 'Incorrect email, username or password'))
        break
      case 4010003:
        // Invalid Credentials
        dispatch(addAlertExpiry('warning', 'It looks like your account has expired. Please contact your supplier to reinstate access.'))
        break
      default:
    }
  }
}

// READ

// UPDATE

// DELETE

// LIST
