import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import NoResults from 'components/NoResults'
import CardGroup from 'components/CardGroup'
import Tabs from './Tabs'
import NewCandidatesForm from 'containers/InputPage/Form'
import UploadCandidates from './UploadCandidates'
import TableCandidatesSelect from 'containers/Table/CandidatesSelect'
import Options from './Options'
import { createStructuredSelector } from 'reselect'
import { getModal } from 'reducers/modalReducer'
import { getAssessmentsBySelected } from 'reducers/organisationAssessmentsReducer'
import { unSetCandidates } from 'actions/candidateAction'
import { getCandidatesSelected } from 'reducers/candidatesSelectedReducer'
import { addActiveTab, addAlertExpiry } from 'actions/uiAction'
import { getActiveTab } from 'reducers/activeTabReducer'
import Credits from "../../../components/Credits";
import { setUserOrganisationWithCredits } from '../../../actions/userOrganisationAction';
import { getUserOrganisation } from '../../../reducers/userOrganisationReducer'
import { getOrganisationAssessments } from '../../../reducers/organisationAssessmentsReducer'

class ModalAssessmentsPageSingle extends Component {
  constructor(props) {
    super(props)

    this.props.onUnSetCandidates()

    this.state = {
      credits: [],
    }
  }

  handleActiveTab = (activeTab) => {
    this.props.onAddActiveTab(activeTab)
  }

  handleUploadCandidates = () => {
    this.props.onAddActiveTab('Existing')
  }

  handleConfirm = () => {
    if (this.props.candidatesSelected.length) {
      this.props.onAddModal('Individual Confirm')
    } else {
      this.props.onAddAlertExpiry('Please Select a Candidate')
    }
  }

  render() {
    const { assessmentsBySelected, activeTab, organisationAssessments } = this.props

    let content, optionButton;
    switch (activeTab) {
      case 'New':
        content = <NewCandidatesForm newCandidates={true}/>
        optionButton = null
        break
      case 'Upload':
        content = <UploadCandidates/>
        optionButton = <Options title={'Upload'} onClick={this.handleUploadCandidates}/>
        break
      case 'Credit Balance':
        content = <Credits
            credits={this.props.userOrganisation.credits}
            organisationAssessments={organisationAssessments}
            organisationName={this.props.userOrganisation.organisation.parent ? this.props.userOrganisation.organisation.parent.name : null}
            organisationUrl={this.props.userOrganisation.organisation.parent ? this.props.userOrganisation.organisation.parent.url : null}
        />
        optionButton = null
        break
      default:
        content = <TableCandidatesSelect modal={true}/>
        optionButton = <Options title={'Confirm'} onClick={this.handleConfirm}/>
        break
    }

    return (
      <section>
        <div className="padding-top-md">
          {assessmentsBySelected.length > 0 ? (
            <CardGroup
              cards={assessmentsBySelected}
              noSelectionRequired
            />  
          ) : (
            <NoResults/>
          )}
        </div>
        <Tabs activeTab={activeTab} handleActiveTab={this.handleActiveTab}/>
        {content}
        {optionButton}
      </section>
    )
  }
}

ModalAssessmentsPageSingle.propTypes = {
  modal: PropTypes.array.isRequired,
  assessmentsBySelected: PropTypes.arrayOf(PropTypes.object),
  candidatesSelected: PropTypes.arrayOf(PropTypes.object),
  onAddModal: PropTypes.func.isRequired,
  onUnSetCandidates: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  onUnSetCandidates: () => {
    dispatch(unSetCandidates())
  },
  onAddAlertExpiry: (message) => {
    dispatch(addAlertExpiry('warning', message))
  },
    onAddActiveTab: (activeTab) => {
    dispatch(addActiveTab(activeTab))
  },
})

const mapStateToPropsSelector = createStructuredSelector({
  modal: getModal,
  assessmentsBySelected: getAssessmentsBySelected,
  candidatesSelected: getCandidatesSelected,
  activeTab: getActiveTab,
  userOrganisation: getUserOrganisation,
  organisationAssessments: getOrganisationAssessments
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalAssessmentsPageSingle)
