import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/Form/Text'
import MultiSelect from 'components/Form/MultiSelect'
import Save from './Save'
import Field from './Field'

const Candidate = ({ candidate, onTextChange, onLabelChange, labelOptions, selectedLabels }) => (
  <div className="padding-top-md text-left">
    <div className="grid">
      <Field itemLabel={'First Name'} required={true}
             input={<Text parentId={candidate.id} onItemChange={onTextChange} itemValue={candidate.firstName}
                          itemLabel={'First Name'} itemName={'firstName'}/>}/>
      <Field itemLabel={'Last Name'} required={true}
             input={<Text parentId={candidate.id} onItemChange={onTextChange} itemValue={candidate.lastName}
                          itemLabel={'Last Name'} itemName={'lastName'}/>}/>
      <Field itemLabel={'Email'} required={true}
             input={<Text parentId={candidate.id} onItemChange={onTextChange} itemValue={candidate.email}
                          itemLabel={'Email'} itemName={'email'}/>}/>
      <Field itemLabel={'Labels'}
             input={<MultiSelect parentId={candidate.id} onItemChange={onLabelChange}
                                 itemOptions={labelOptions} selectedItems={selectedLabels} itemName={'Label'}/>}/>

      <div className="grid__item w-6-md">
        <div className="grid grid--middle">
          <div className="grid__item w-12">
            <label className="label" htmlFor="firstName"><b
                className="colour-black">Actions:</b> </label>
          </div>
          <div className="grid__item">
            <Save/>
          </div>
        </div>
      </div>
    </div>
  </div>
)

Candidate.propTypes = {
  candidate: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  labelOptions: PropTypes.array.isRequired,
}

export default Candidate
