import React from 'react'
import PropTypes from 'prop-types'

const HeaderCheckbox = ({ selectAll, onSelectAllItems }) => (
  <th className="table__checkbox"><input type="checkbox" checked={selectAll} onChange={() => onSelectAllItems()}/></th>
)

HeaderCheckbox.propTypes = {
  selectAll: PropTypes.bool.isRequired,
  onSelectAllItems: PropTypes.func.isRequired
}

export default HeaderCheckbox
