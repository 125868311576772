import React from 'react'
import User from 'assets/svg/components/User'

const Privacy = () => (
  <div>
    <h2>Joe Bloggs</h2>
    <div className="padding-bottom-sm">
      <div className="list-group">
        <div className="list-group__item">
          <User/>
        </div>
        <div className="list-group__item">
          <b className="colour-black">Manager</b>
        </div>
      </div>
    </div>
    <div className="list-group">
      <div className="list-group__item">
        <User/>
      </div>
      <div className="list-group__item">
        <ul className="list-bare">
          <li><b className="colour-black">e:</b> <a href="mailto:joebloggs@recruit.com">joebloggs@recruit.com</a></li>
          <li><b className="colour-black">p:</b> <a href="tel:4407123456789">4407123456789</a></li>
          <li><b className="colour-black">a:</b> 123 Somewhere rd, London, SE1 1LDN</li>
        </ul>
      </div>
    </div>
  </div>
)

export default Privacy
