import {
  SET_CANDIDATE_ASSESSMENT_SELECTED,
  // UNSET_CANDIDATE_ASSESSMENT_SELECTED
} from 'actions/candidateAssessmentSelectedAction'

const initialState = false
const candidateAssessmentSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANDIDATE_ASSESSMENT_SELECTED:
      return action.candidateAssessment
    // case UNSET_CANDIDATE_ASSESSMENT_SELECTED:
    //   return action.candidateAssessment
    default:
      return state
  }
}

export default candidateAssessmentSelected

export const getCandidateAssessmentSelected = state => state.candidateAssessmentSelected

