import { SET_THEME } from 'actions/themeAction'

const initialState = []

const theme = (state = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
      return action.theme
    default:
      return state
  }
}

export default theme

export const getTheme = state => state.theme
