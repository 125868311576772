import { ADD_ALERT, REMOVE_ALERT } from '../actions/uiAction'

const initialState = {
  alertType: false,
  message: false,
  expiresAt: false
}

const alert = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT:
      return { type: action.alertType, message: action.message, expiresAt: action.expiresAt }
    case REMOVE_ALERT:
      return initialState
    default:
      return state
  }
}

export default alert

export const getAlert = state => state.alert

