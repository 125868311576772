import React from 'react'
import PropTypes from 'prop-types'

const Reset = ({ onReset }) => (
  <div className="grid__item w-2-md">
        <button className="button button--info" onClick={onReset}>Reset</button>
  </div>
)

Reset.propTypes = {
  onReset: PropTypes.func.isRequired,
}

export default Reset
