import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getSidebar } from 'reducers/sideBarReducer'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import { closeMenu, setSideBar } from 'actions/uiAction'

class Item extends Component {

  handleItemClick = (name) => {
    this.props.onCloseMenu()
    this.props.onSetSideBar(name)
  }

  render () {
    const { name, icon, link, userOrganisation, sideBar, dual, targetBlank } = this.props

    let url = `/${userOrganisation.branding.name}/${link}`
    if(dual){
      url = `/${link}`
    }

    const extraProps = {};
    if (targetBlank) extraProps.target = "_blank";

    return (
      <li className={sideBar === name ? 'active' : ''} >
        <Link  {...extraProps} to={url} onClick={() => this.handleItemClick(name)}>
          <i className="icon">{icon}</i>
          <span className="padding-left-xsm">{name}</span>
        </Link>
      </li>
    )
  }
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  sideBar: PropTypes.string,
  onCloseMenu: PropTypes.func.isRequired,
  onSetSideBar: PropTypes.func.isRequired,
  targetBlank: PropTypes.bool
}

Item.defaultProps = {
  targetBlank: false
};

const mapStateToPropsSelector = createStructuredSelector({
  sideBar: getSidebar,
  userOrganisation: getUserOrganisation
})

const mapDispatchToProps = (dispatch) => ({
  onCloseMenu: () => {
    dispatch(closeMenu())
  },
  onSetSideBar: (name) => {
    dispatch(setSideBar(name))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(Item)
