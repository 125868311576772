import React from 'react'
import NotFound from 'assets/svg/components/NotFound'
import PropTypes from 'prop-types'

const UserAssessmentNotFoundPage = ({ message }) => (
    <div className="form">
      <h2 className="form__head">{message}</h2>
      <NotFound/>
    </div>
)

UserAssessmentNotFoundPage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default UserAssessmentNotFoundPage
