import { receiveLeadershipAssessment, setLeadershipsAssessments } from 'actions/leadershipAssessmentAction'
import { leadershipAssessmentError } from 'errors/leadershipAssessmentError'
import { asyncFetch } from './apiFetch'
import {addAlertExpiry, removeModal} from 'actions/uiAction'
import {listOrganisationLeadershipsAssessments} from "./organisationLeadershipAssessmentFetch";

// CREATE
export function createLeadershipAssessment ( organisationAssessment, leader, reviewers) {
    return function (dispatch) {

        let data = {
            'organisation_assessment': organisationAssessment.id,
            'reviewers': reviewers
        }
        // if (expiresAt && expiresAt != '') {
        //     data = {
        //         'o_assessment': leadershipAssessment.assessment.id,
        //         'expiresAt': expiresAt
        //     }
        // }

        return asyncFetch(dispatch, 'POST', `leaderships/${leader.id}/assessments`, data).then(
            (response) => {
                if (response && response.customErrorCode) {
                    dispatch(leadershipAssessmentError(response.customErrorCode, leader))
                } else {
                    dispatch(removeModal('Confirm'))
                    dispatch(addAlertExpiry('success', '360 created successfully'))
                    dispatch(listOrganisationLeadershipsAssessments(true, 1))
                }
            })
    }
}

export function updateLeadershipAssessmentReviewersFetch (leadershipsAssessment, reviewers) {
    return function (dispatch) {

        let data = {
            'leadership_assessment': leadershipsAssessment.id,
            'reviewers': reviewers
        }

        return asyncFetch(dispatch, 'PATCH', `leaderships/assessments/${leadershipsAssessment.id}/reviews`, data).then(
            (response) => {
                if (response && response.customErrorCode) {
                    dispatch(leadershipAssessmentError(response.customErrorCode, leadershipsAssessment.candidate))
                } else {
                    dispatch(listOrganisationLeadershipsAssessments(true, 1))
                    dispatch(removeModal('Edit Leadership Assessment'))
                    dispatch(addAlertExpiry('success', 'Leadership assessment updated successfully'))
                    // dispatch(receiveLeadershipAssessment('Sent', response))
                }
            })
    }
}

export function reminderLeadershipAssessmentReview (leadershipAssessmentReview) {
    return function (dispatch) {

        let data = {}

        return asyncFetch(dispatch, 'POST', `leaderships/assessments/resend-review/${leadershipAssessmentReview.id}`, data).then(
            (response) => {
                console.log(response)
                if (response && response.customErrorCode === 403) {
                    dispatch(addAlertExpiry('warning', response.message))
                }
                else if (response && response.customErrorCode) {
                    // dispatch(leadershipAssessmentError(response.customErrorCode, response))
                } else {
                    dispatch(addAlertExpiry('success', 'Reminder Sent'))
                    // dispatch(setLeadershipsAssessments(response))
                }
            })
    }
}

export function reminderLeadershipAssessmentReviews (leadershipAssessment) {
    return function (dispatch) {

        let data = {}

        return asyncFetch(dispatch, 'POST', `leaderships/assessments/${leadershipAssessment.id}/resend-all-reviews`, data).then(
            (response) => {
                console.log(response)
                if (response && response.customErrorCode === 403) {
                    dispatch(addAlertExpiry('warning', response.message))
                }
                else if (response && response.customErrorCode) {
                    // dispatch(leadershipAssessmentError(response.customErrorCode, response))
                } else {
                    dispatch(addAlertExpiry('success', 'Reminders Sent'))
                    // dispatch(setLeadershipsAssessments(response))
                }
            })
    }
}

export function reminderLeadershipAssessment (leadershipAssessment, expiresAt = null) {
    return function (dispatch) {

        let data = {}
        if (expiresAt && expiresAt != '') {
            data = {
                'expiresAt': expiresAt
            }
        }

        return asyncFetch(dispatch, 'POST', `leaderships/${leadershipAssessment.leadership.id}/assessments/${leadershipAssessment.id}/reminders`, data).then(
            (response) => {
                console.log(response)
                if (response && response.customErrorCode === 403) {
                    dispatch(addAlertExpiry('warning', response.message))
                }
                else if (response && response.customErrorCode) {
                    // dispatch(leadershipAssessmentError(response.customErrorCode, response))
                } else {
                    dispatch(addAlertExpiry('success', 'Reminder Sent'))
                    // dispatch(setLeadershipsAssessments(response))
                }
            })
    }
}

// READ

// UPDATE

// DELETE

// LIST
