import { addAlertExpiry } from 'actions/uiAction'

export const updateTokenError = (errorCode, message) => {
  return function (dispatch) {
    switch (errorCode) {
      case 4009004:
        dispatch(addAlertExpiry('warning', 'No credit available. There were not enough basic tokens available to complete transfer request. Please contact your administrator.'))
        break
      default:
    }
  }
}


