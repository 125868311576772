import { SET_ORGANISATIONS } from 'actions/organisationAction'

const initialState = []

const organisations = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANISATIONS:
      return action.organisations
    default:
      return state
  }
}

export default organisations

export const getOrganisations = state => state.organisations
