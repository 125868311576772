import React, { Component } from 'react'
import Table from './Table'
import Filter from './Filter'
import { connect } from 'react-redux'
import { listCandidates } from 'fetchs/candidateFetch'
import { addModal } from 'actions/uiAction'
import { listLabels } from 'fetchs/labelFetch'
import { reorderOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch.js'
import { addBackground, removeBackground } from 'actions/backgroundAction'
import {
    addLeaderSelected,
    setLeaderSelected,
    unSetLeaderSelected,
} from 'actions/leaderSelectedAction'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getLeaderSelected } from 'reducers/leaderSelectedReducer'
import { getLeader } from 'reducers/leaderReducer'
import { getLabels } from 'reducers/labelsReducer'
import Pagination from 'components/Pagination'
import { getCandidates } from 'reducers/candidatesReducer'
import {
    addCandidatesSelected,
    removeCandidatesSelected,
    unSetCandidatesSelected
} from "../../../actions/candidateSelectedAction";

class TableLeaderSelect extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchValue: '',
            lastSearch: 0,
            minimumSearch: false,
            selectedLabel: '',
            selectAll: false,
        }
    }

    handleSelectLeader = async (candidate, isChecked) => {
        if (isChecked) {
            this.props.onRemoveLeaderSelected(candidate)
            // this.props.onRemoveCandidatesSelected(candidate)
            this.setState({ selectAll: false })
        } else {
            await this.props.onUnSetCandidatesSelected()
            this.props.onAddLeaderSelected(candidate)
            // this.props.onAddCandidatesSelected(candidate)
        }
    }

    handleClickLeader = (event, candidate) => {
        if(event.target.type !== 'checkbox'){
            this.props.onSetLeaderSelected(candidate)
            this.props.onAddModal('Individual')
        }
    }

    componentDidMount () {
        this.props.onListCandidates(1)
        this.props.onRemoveLeaderSelected()
        this.props.onUnSetCandidatesSelected()
    }

    handleSearchChange = (event) => {
        const searchValue = event.target.value
        const date = new Date()
        const timestamp = date.getTime()
        const second = 1000
        this.setState({ searchValue, lastSearch: timestamp })

        setTimeout(() => {
            if (this.state.searchValue.length > 2 && this.state.lastSearch + (second) > timestamp) {
                this.setState({ minimumSearch: true })
                this.props.onListCandidates(1, this.state.searchValue, this.state.selectedLabel)
            } else if (this.state.minimumSearch) {
                this.setState({ minimumSearch: false })
                this.props.onListCandidates(1, '', this.state.selectedLabel)
            }
        }, second)
    }

    handleLabelChange = (event) => {
        this.setState({ selectedLabel: event.target.value })
        this.props.onListCandidates(1, this.state.searchValue, event.target.value)
    }

    handleClickCandidate = (event, candidate) => {
        if(event.target.type !== 'checkbox'){
            this.props.onSetLeaderSelected(candidate)
            this.props.onAddModal('Individual')
        }
    }

    handleReset = () => {
        this.setState({ searchValue: '', selectedLabel: '' })
        this.props.onListCandidates(1)
    }

    handlePageChange = (page) => {
        this.props.onListCandidates(page, this.state.searchValue, this.state.selectedLabel)
    }

    handleReorder = (fromIndex, toIndex) => {
        reorderOrganisationAssessments(fromIndex);
        reorderOrganisationAssessments(toIndex);
    }

    render () {
        const { candidates, labels, leaderSelected, modal } = this.props
        const { searchValue, selectedLabel, selectAll } = this.state

        const candidatesList = [];

        // we need to remove the assessments that have a token type of team, as these are only needed for the team reports
        Object.keys(candidates).forEach(function(key) {
            let candidateItem = candidates[key];

            if (candidateItem.hasFlowprofiler) {
                candidatesList.push(candidates[key])
            }

            console.log(candidatesList)
        });

        return (
            <section>
                <Filter labels={labels} searchValue={searchValue}
                        selectedLabel={selectedLabel}
                        onSearchChange={this.handleSearchChange}
                        onLabelChange={this.handleLabelChange} onReset={this.handleReset}/>
                <div className="padding-top-md">
                    <Table candidates={candidatesList} leaderSelected={leaderSelected} selectAll={selectAll}
                           modal={modal}
                           enableListReordering={true}
                           onReorder={this.handleReorder}
                           onClickLeader={this.handleClickLeader}
                           onSelectLeader={this.handleSelectLeader}/>
                </div>
                <Pagination onPageChange={this.handlePageChange}/>
            </section>
        )
    }
}

TableLeaderSelect.propTypes = {
    leaderSelected: PropTypes.object,
    leader: PropTypes.arrayOf(PropTypes.object),
    candidates: PropTypes.arrayOf(PropTypes.object),
    labels: PropTypes.arrayOf(PropTypes.object),
    modal: PropTypes.bool,
    onListCandidates: PropTypes.func.isRequired,
    onListLabels: PropTypes.func.isRequired,
    onAddModal: PropTypes.func.isRequired,
    onSetLeaderSelected: PropTypes.func.isRequired,
    onRemoveLeaderSelected: PropTypes.func.isRequired,
    onAddLeaderSelected: PropTypes.func.isRequired,
    onRemoveCandidatesSelected: PropTypes.func.isRequired,
    onAddCandidatesSelected: PropTypes.func.isRequired,
    onUnSetCandidatesSelected: PropTypes.func.isRequired,
}
const mapStateToPropsSelector = createStructuredSelector({
    leaderSelected: getLeaderSelected,
    leader: getLeader,
    candidates: getCandidates,
    labels: getLabels
})

const mapDispatchToProps = (dispatch) => ({
    onListCandidates: (page, searchFilter, labelFilter) => {
        dispatch(listCandidates(page, searchFilter, labelFilter, true))
    },
    onListLabels: () => {
        dispatch(listLabels(false))
    },
    onAddModal: (modal) => {
        dispatch(addModal(modal))
    },
    onSetLeaderSelected: (candidateSelected) => {
        dispatch(setLeaderSelected(candidateSelected))
    },
    onRemoveLeaderSelected: () => {
        dispatch(unSetLeaderSelected())
    },
    onAddLeaderSelected: (candidate) => {
        dispatch(addLeaderSelected(candidate))
    },
    onRemoveCandidatesSelected: (candidate) => {
        dispatch(removeCandidatesSelected(candidate))
    },
    onAddCandidatesSelected: (candidate) => {
        dispatch(addCandidatesSelected(candidate))
    },
    onUnSetCandidatesSelected: () => {
        dispatch(unSetCandidatesSelected())
    }
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(TableLeaderSelect)
