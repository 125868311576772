import { SET_TEAM_REPORT } from 'actions/teamReportAction'

const initialState = null

const teamReport = (state = initialState, action) => {

    switch (action.type) {
        case SET_TEAM_REPORT:
            return action.teamReport
        default:
            return state
    }
}

export default teamReport

export const getTeamReport = state => state.teamReport
