import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import Organisation from './Organisation'
import Reports from './Reports'
import { addModal } from 'actions/uiAction'
import { organisationUpdatedCredits, updateOrganisationSelected } from 'actions/organisationSelectedAction'
import { assignTokens } from 'actions/userOrganisationAction'
import {
  getAssessmentsBySharedToken,
  getAssessmentsBySpecificToken,
  getAssessmentsByUnlimitedToken,
  getOrganisationSelected
} from 'reducers/organisationSelectedReducer'
import { createLabel } from 'fetchs/labelFetch'
import SelectOption from 'components/Form/SelectOption'
import Select from 'components/Form/Select'
import CreditBalance from './CreditBalance'
import IssueSharedCredit from './IssueCredit/IssueSharedCredit'
import IssueSpecificCredit from './IssueCredit/IssueSpecificCredit'
import IssueUnlimitedCredit from './IssueCredit/IssueUnlimitedCredit'
import {
  getUserAssessmentsBySharedToken,
  getUserAssessmentsBySpecificToken,
  getUserAssessmentsByUnlimitedToken
} from 'reducers/organisationAssessmentsReducer'
import { createOrganisationAssessments, deleteOrganisationAssessments } from 'fetchs/OrganisationAssessmentFetch'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'
import Credits from "../../../components/Credits";
import {getOrganisationAssessments} from "../../../reducers/organisationAssessmentsReducer";
import Field from "./Field";
import ChevronRight from "../../../assets/svg/components/ChevronRight";
import AssignCredit from "./IssueCredit/AssignCredit";
import DateWrapper from "../../../components/Form/DateWrapper";
import Options from "./Options";

class ModalOrganisationsPageSingle extends Component {
  constructor (props) {
    super(props)

    this.state = {
      organisationSelected: this.props.organisationSelected,
      types: [],
      sharedAssessments: [],
      specificAssessments: [],
      unlimitedAssessments: [],
      sharedCredit: 0,
      specificCredit: {},
      transferringCredits: false,
      selectedSharedAssessment: '',
      selectedSpecificAssessment: '',
      selectedUnlimitedAssessment: '',
      flowProfilerSelected: '',
      eqFlowSelected: '',
      motivationFlowSelected: '',
      resilienceFlowSelected: '',
      flowProfiler360Selected: '',
      flowProfilerCredits: 0,
      eqFlowCredits: 0,
      motivationFlowCredits: 0,
      resilienceFlowCredits: 0,
      flowProfiler360Credits: 0,
      expiresAt: '',
    }
  }

  componentDidMount() {
    if (this.props.organisationSelected.organisation.expiresAt) {
      this.setState({
        expiresAt: this.props.organisationSelected.organisation.expiresAt.date
      })
      console.log(this.props.organisationSelected.organisation.expiresAt.date)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {

    if(Object.keys(this.props.organisationSelected.branding).length && !Object.keys(this.state.organisationSelected.branding).length){
      this.setState({organisationSelected: this.props.organisationSelected})
    }

    const organisationCredits = { ...this.props.organisationSelected.credits }
    if(organisationCredits && this.props.organisationSelected.updatedCredits){
      this.props.onOrganisationUpdatedCredits()
        delete organisationCredits['unlimited']

        if (!isNaN(organisationCredits['shared'])) {
          const sharedCredit = organisationCredits['shared'].toString()
          delete organisationCredits['shared']
          this.setState({sharedCredit, specificCredit: organisationCredits, transferringCredits: false})
        }
    }
  }

  handleUpdateOrganisation = async () => {
    const organisationSelected = { ...this.state.organisationSelected }
    organisationSelected.organisation['expiresAt'] = this.state.expiresAt
    await this.setState({ organisationSelected })

    this.props.onUpdateOrganisationSelected(this.state.organisationSelected)
  }

  handleFlowProfilerChange = (event) => {
    this.setState({ ['flowProfilerSelected']: event.target.value })
  }

  handleFlowProfilerCredits = (event) => {
    this.setState({ ['flowProfilerCredits']: event.target.value })
    this.handleChangeCreditFlowProfiler(event)
  }

  handleAssignCreditsFlowProfiler = (event) => {
    console.log(event)
  }

  handleChangeCreditFlowProfiler = (event) => {
    if (this.state.flowProfilerSelected === 'shared') {
      this.handleChangeSharedCredit(event)
    }
    else if (this.state.flowProfilerSelected === 'specific') {
      this.handleChangeSpecificCredit(event, 'flowprofiler')
    }
  }

  handleTextChange = (event, organisationId, field) => {
    const organisationSelected = { ...this.state.organisationSelected }
    if(field === 'name'){
      organisationSelected.organisation['name'] = event.target.value
    }
    else if (field === 'url') {
      organisationSelected.organisation['url'] = event.target.value
    }
    else if (field === 'brand'){
      organisationSelected.branding['name'] = event.target.value
    }

    this.setState({ organisationSelected })
  }

  handleTypeChange = (event) => {
    const organisationSelected = { ...this.state.organisationSelected }
    organisationSelected.organisation.type = event.target.value

    this.setState({ organisationSelected })
  }

  handleDateChange = (date) => {

    const organisationSelected = { ...this.state.organisationSelected }
    if (date) {
      let dd = String(date.getDate()).padStart(2, '0');
      let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = date.getFullYear();
      let formattedDate = yyyy + '-' + mm + '-' + dd + ' 23:59:00';
      this.setState({ expiresAt: formattedDate })
      // organisationSelected.organisation['expiresAt'] = formattedDate
      this.setState({ expiresAt: formattedDate })

      // this.setState({ organisationSelected })
    }
    else {
      // organisationSelected.organisation['expiresAt'] = ''
      this.setState({ expiresAt: '' })

      // this.setState({ organisationSelected })
    }
  }

  handleTeamReportsActiveChange = (value) => {
    const organisationSelected = { ...this.state.organisationSelected }
    organisationSelected.organisation.teamReportsActive = value

    this.setState({ organisationSelected })

    this.props.onUpdateOrganisationSelected(this.state.organisationSelected)
  }

  handle360ReportsActiveChange = (value) => {
    const organisationSelected = { ...this.state.organisationSelected }
    organisationSelected.organisation.threeSixtyReportsActive = value

    this.setState({ organisationSelected })

    this.props.onUpdateOrganisationSelected(this.state.organisationSelected)
  }

  handleLeadershipReportsActiveChange = (value) => {
    const organisationSelected = { ...this.state.organisationSelected }
    organisationSelected.organisation.leadershipReportsActive = value

    this.setState({ organisationSelected })

    this.props.onUpdateOrganisationSelected(this.state.organisationSelected)
  }

  handleBenchmarkReportsActiveChange = (value) => {
    const organisationSelected = { ...this.state.organisationSelected }
    organisationSelected.organisation.benchmarkReportsActive = value

    this.setState({ organisationSelected })

    this.props.onUpdateOrganisationSelected(this.state.organisationSelected)
  }

  handleReportsActiveChange = (reportType, value) => {

    const organisationSelected = { ...this.state.organisationSelected }
    if (reportType === 'development') {
      organisationSelected.organisation.developmentReportsActive = value
    }
    else if (reportType === 'management') {
      organisationSelected.organisation.managementReportsActive = value
    }
    else if (reportType === 'interview') {
      organisationSelected.organisation.interviewReportsActive = value
    }
    else if (reportType === 'candidate') {
      organisationSelected.organisation.candidateReportsActive = value
    }
    else if (reportType === 'benchmark') {
      organisationSelected.organisation.benchmarkReportsActive = value
    }
    else if (reportType === 'leadership') {
      organisationSelected.organisation.leadershipReportsActive = value
    }
    else if (reportType === 'threeSixty') {
      organisationSelected.organisation.threeSixtyReportsActive = value
    }
    else if (reportType === 'team') {
      organisationSelected.organisation.teamReportsActive = value
    }

    this.setState({ organisationSelected })

    this.props.onUpdateOrganisationSelected(this.state.organisationSelected)
  }

  handleAssessmentChange = (event, selectedAssessment) => {
    this.setState({ [selectedAssessment]: event.target.value })
  }

  handleAssignAssessment = (selectedAssessment, tokenType) => {
    this.props.onCreateOrganisationAssessments(this.state.organisationSelected.organisation, this.state[selectedAssessment], tokenType)
  }

  handleUnAssignAssessment = (selectedAssessment) => {
    this.props.onDeleteOrganisationAssessments(this.state.organisationSelected.organisation, selectedAssessment)
  }

  handleChangeSharedCredit = (event) => {
    this.setState({ sharedCredit: event.target.value })
  }

  handleAssignSharedCredit = () => {
    const organisationCredits = { ...this.props.organisationSelected.credits }
    const total = this.state.sharedCredit - organisationCredits['shared'].toString()
    let direction = 'plus'
    if(total < 0 ){
      direction = 'minus'
    }

    if(total !== 0) {
      this.props.onAssignTokens(Math.abs(total), direction, this.state.organisationSelected.organisation, 'shared')
      this.setState({ transferringCredits: true })
    }
  }

  handleChangeSpecificCredit = (event, assessmentName) => {
    const amount = event.target.value
    this.setState(prevState => {
      return {specificCredit: {...prevState.specificCredit, [assessmentName]: amount}};
    });
  }

  handleAssignSpecificCredit = (assessmentName) => {
    const organisationCredits = { ...this.props.organisationSelected.credits }
    delete organisationCredits['unlimited']
    delete organisationCredits['shared']

    const total = this.state.specificCredit[assessmentName] - (organisationCredits[assessmentName] ? organisationCredits[assessmentName] : 0)

    let direction = 'plus'
    if(total < 0 ){
      direction = 'minus'
    }
    
    if(total !== 0){
      this.props.onAssignTokens(Math.abs(total), direction, this.state.organisationSelected.organisation, 'specific', assessmentName)
      this.setState({transferringCredits: true})
    }

  }

  creditBalance = (userCredits) => {
    let sharedCredit = null
    const unlimitedCredit = userCredits['unlimited']
    delete userCredits['unlimited']

    if (!isNaN(userCredits['shared'])) {
      sharedCredit = userCredits['shared'].toString()
      delete userCredits['shared']
    }

    return <CreditBalance unlimited={unlimitedCredit} shared={sharedCredit} assessmentSpecific={userCredits}/>
  }

  render () {
    const { organisationSelected, organisationAssessments, userOrganisation, userAssessmentsSpecific, userAssessmentsShared, userAssessmentsUnlimited, assessmentsSpecific, assessmentsShared, assessmentsUnlimited } = this.props
    const { selectedSharedAssessment, selectedSpecificAssessment, selectedUnlimitedAssessment, sharedCredit, specificCredit, transferringCredits, flowProfilerSelected, flowProfilerCredits } = this.state

    const teamReportsActiveOptions = []
    const threeSixtyReportsActiveOptions = []

    teamReportsActiveOptions.push(<SelectOption key={1} value={'true'} title={'On'}/>)
    teamReportsActiveOptions.push(<SelectOption key={2} value={'false'} title={'Off'}/>)

    threeSixtyReportsActiveOptions.push(<SelectOption key={1} value={'true'} title={'On'}/>)
    threeSixtyReportsActiveOptions.push(<SelectOption key={2} value={'false'} title={'Off'}/>)

    const typeOptions = []
    
    let orgType = userOrganisation.organisation.type;

    if (orgType === 'owner') {
      typeOptions.push(<SelectOption key={1} value={'owner'} title={'Owner'}/>)
    }
    if (['owner'].includes(orgType)) {
      typeOptions.push(<SelectOption key={2} value={'partner'} title={'Partner'}/>)
    }
    if (['owner', 'partner'].includes(orgType)) {
      typeOptions.push(<SelectOption key={3} value={'subpartner'} title={'Sub-Partner'}/>)
    }
    if (['owner', 'partner', 'subpartner'].includes(orgType)) {
      typeOptions.push(<SelectOption key={4} value={'client'} title={'Client'}/>)
    }

    const userCredits = { ...userOrganisation.credits }
    let creditBalance = null
    if (userCredits && userOrganisation.organisation.type !== 'owner') {
      creditBalance = this.creditBalance(userCredits)
    }

    const flowProfilerOptions = []
    const eqFlowOptions = []
    const motivationFlowOptions = []
    const resilienceFlowOptions = []
    const flowProfiler360Options = []

    const specificOptions = []
    userAssessmentsSpecific.forEach((assessment) => {
      specificOptions.push(<SelectOption key={assessment.id} value={assessment.assessment.id}
                                           title={assessment.assessment.name}/>)

      if (assessment.assessment.slug === 'flowprofilernorm') {
        flowProfilerOptions.push(<SelectOption key={1} value={'specific'} title={'Specific'}/>)
      }

      if (assessment.assessment.slug === 'eqnorm') {
        eqFlowOptions.push(<SelectOption key={1} value={'specific'} title={'Specific'}/>)
      }

      if (assessment.assessment.slug === 'motnorm') {
        motivationFlowOptions.push(<SelectOption key={1} value={'specific'} title={'Specific'}/>)
      }

      if (assessment.assessment.slug === 'resnorm') {
        resilienceFlowOptions.push(<SelectOption key={1} value={'specific'} title={'Specific'}/>)
      }

      if (assessment.assessment.slug === 'leadershipflow360') {
        flowProfiler360Options.push(<SelectOption key={1} value={'specific'} title={'Specific'}/>)
      }
    })

    const unlimitedOptions = []

    userAssessmentsUnlimited.forEach((assessment) => {
      unlimitedOptions.push(<SelectOption key={assessment.id} value={assessment.assessment.id}
                                         title={assessment.assessment.name}/>)
    })

    const sharedOptions = []
    userAssessmentsShared.forEach((assessment) => {
      sharedOptions.push(<SelectOption key={assessment.id} value={assessment.assessment.id}
                                       title={assessment.assessment.name}/>)
    })

    return (
      <section>
        {Object.keys(organisationSelected.organisation).length &&
        <Organisation organisationSelected={organisationSelected} typeOptions={typeOptions} teamReportsActiveOptions={teamReportsActiveOptions} threeSixtyReportsActiveOptions={threeSixtyReportsActiveOptions}
                      onTextChange={this.handleTextChange}
                      onTypeChange={this.handleTypeChange}
                      onUpdateOrganisation={this.handleUpdateOrganisation}/>
        }

        <div style={{display: 'flex'}}>

        <div style={{position: 'relative', padding: '0', width: '200px'}}>
          <div className="grid__item" style={{paddingLeft: 0}}>
            <div className="grid grid--middle">
              <div className="grid__item w-12">
                <label className={'label label--bare'}><b
                    className="colour-black" style={{marginBottom: '0'}}>Expires on:</b> </label>
              </div>

              <div className="grid__item" style={{paddingTop: '12px'}}>
                <DateWrapper onItemChange={this.handleDateChange}
                             placeholder={'Expires on'}
                             itemValue={this.state.expiresAt}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid__item w-12-md text-right" style={{paddingTop: 20}}>
          <Options handleConfirm={this.handleUpdateOrganisation}/>
        </div>

        </div>

        <h2 className="padding-top-md">{userOrganisation.organisation.name} Credit Balance</h2>
        <Credits
            credits={userOrganisation.credits}
            organisationAssessments={organisationAssessments}
            organisationName={userOrganisation.organisation.parent ? userOrganisation.organisation.parent.name : null}
            organisationUrl={userOrganisation.organisation.parent ? userOrganisation.organisation.parent.url : null}
        />

        <h2 className="padding-top-md">{organisationSelected.organisation.name} Credit Balance</h2>
        <Credits
            credits={organisationSelected.credits}
            organisationAssessments={organisationSelected.assessments}
        />

        <h2 className="padding-top-md">Issue Credits</h2>
        {!(userOrganisation.organisation.type === 'partner' && organisationSelected.organisation.type === 'subpartner') && userOrganisation.organisation.type !== 'subpartner' &&
        <IssueUnlimitedCredit
            selectedAssessments={assessmentsUnlimited}
            assessmentSelect={<Select parentId={''}
                                      onItemChange={(event) => this.handleAssessmentChange(event, 'selectedUnlimitedAssessment')}
                                      selectedItem={selectedUnlimitedAssessment}
                                      itemOptions={unlimitedOptions} itemName={'Assessment'}/>}
            handleAssignAssessment={() => this.handleAssignAssessment('selectedUnlimitedAssessment', 'unlimited')}
            handleUnAssignAssessment={this.handleUnAssignAssessment}
        />
        }
        <IssueSharedCredit
        selectedAssessments={assessmentsShared}
        assessmentSelect={<Select parentId={''}
        onItemChange={(event) => this.handleAssessmentChange(event, 'selectedSharedAssessment')}
        selectedItem={selectedSharedAssessment}
        itemOptions={sharedOptions} itemName={'Assessment'}/>}
        sharedCredit={sharedCredit}
        handleChangeCredit={this.handleChangeSharedCredit}
        handleAssignCredit={this.handleAssignSharedCredit}
        handleAssignAssessment={() => this.handleAssignAssessment('selectedSharedAssessment', 'shared')}
        handleUnAssignAssessment={this.handleUnAssignAssessment}
        transferringCredits={transferringCredits}
        />
        <IssueSpecificCredit
        selectedAssessments={assessmentsSpecific}
        assessmentSelect={<Select parentId={''}
        onItemChange={(event) => this.handleAssessmentChange(event, 'selectedSpecificAssessment')}
        selectedItem={selectedSpecificAssessment}
        itemOptions={specificOptions} itemName={'Assessment'}/>}
        assessmentSpecificCredits={specificCredit}
        handleChangeCredit={this.handleChangeSpecificCredit}
        handleAssignCredit={this.handleAssignSpecificCredit}
        handleAssignAssessment={() => this.handleAssignAssessment('selectedSpecificAssessment', 'specific')}
        handleUnAssignAssessment={this.handleUnAssignAssessment}
        transferringCredits={transferringCredits}
        />

        {/*<h2 className="padding-top-md">Assessments and Credits</h2>*/}
        {/*<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '20px', alignItems: 'center' }}>*/}
        {/*  <div style={{width: '200px'}}>flowprofiler®</div>*/}
        {/*  <div style={{width: '200px'}}>*/}
        {/*    <Select parentId={organisationSelected.organisation.id} onItemChange={this.handleFlowProfilerChange} selectedItem={flowProfilerSelected}*/}
        {/*            itemOptions={flowProfilerOptions} itemName={'Type'}/>*/}
        {/*  </div>*/}

        {/*  <input style={{width: '200px', height: '40px'}} type="number" value={flowProfilerCredits} onChange={(event) => this.handleFlowProfilerCredits(event)}/>*/}

        {/*  <button onClick={this.handleAssignCreditsFlowProfiler} className="button button--stretch button--active w-5" style={{width: '200px'}}>*/}
        {/*    <span className="padding-right-sm">Update</span>*/}
        {/*    <i className="icon"><ChevronRight/></i>*/}
        {/*  </button>*/}
        {/*</div>*/}

        {/*<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '20px', alignItems: 'center' }}>*/}
        {/*  <div style={{width: '200px'}}>eqflow®</div>*/}
        {/*  <div style={{width: '200px'}}>*/}
        {/*    <Select parentId={organisationSelected.organisation.id} onItemChange={this.handleFlowProfilerChange} selectedItem={organisationSelected.organisation.type}*/}
        {/*            itemOptions={eqFlowOptions} itemName={'Type'}/>*/}
        {/*  </div>*/}

        {/*  <input style={{width: '200px', height: '40px'}} type="number" value={1} onChange={(event) => console.log(event)}/>*/}

        {/*  <button onClick={this.handleAssignCreditsFlowProfiler} className="button button--stretch button--active w-5" style={{width: '200px'}}>*/}
        {/*    <span className="padding-right-sm">Update</span>*/}
        {/*    <i className="icon"><ChevronRight/></i>*/}
        {/*  </button>*/}
        {/*</div>*/}

        {/*<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '20px', alignItems: 'center' }}>*/}
        {/*  <div style={{width: '200px'}}>motivationflow®</div>*/}
        {/*  <div style={{width: '200px'}}>*/}
        {/*    <Select parentId={organisationSelected.organisation.id} onItemChange={this.handleFlowProfilerChange} selectedItem={organisationSelected.organisation.type}*/}
        {/*            itemOptions={motivationFlowOptions} itemName={'Type'}/>*/}
        {/*  </div>*/}

        {/*  <input style={{width: '200px', height: '40px'}} type="number" value={1} onChange={(event) => console.log(event)}/>*/}

        {/*  <button onClick={this.handleAssignCreditsFlowProfiler} className="button button--stretch button--active w-5" style={{width: '200px'}}>*/}
        {/*    <span className="padding-right-sm">Update</span>*/}
        {/*    <i className="icon"><ChevronRight/></i>*/}
        {/*  </button>*/}
        {/*</div>*/}

        {/*<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '20px', alignItems: 'center' }}>*/}
        {/*  <div style={{width: '200px'}}>resilienceflow®</div>*/}
        {/*  <div style={{width: '200px'}}>*/}
        {/*    <Select parentId={organisationSelected.organisation.id} onItemChange={this.handleFlowProfilerChange} selectedItem={organisationSelected.organisation.type}*/}
        {/*            itemOptions={resilienceFlowOptions} itemName={'Type'}/>*/}
        {/*  </div>*/}

        {/*  <input style={{width: '200px', height: '40px'}} type="number" value={1} onChange={(event) => this.handleFlowProfilerCredits(event)}/>*/}

        {/*  <button onClick={this.handleAssignCreditsFlowProfiler} className="button button--stretch button--active w-5" style={{width: '200px'}}>*/}
        {/*    <span className="padding-right-sm">Update</span>*/}
        {/*    <i className="icon"><ChevronRight/></i>*/}
        {/*  </button>*/}
        {/*</div>*/}

        <h2 className="padding-top-md">Access</h2>
        <Reports organisationSelected={organisationSelected} userOrganisation={userOrganisation} typeOptions={typeOptions} teamReportsActiveOptions={teamReportsActiveOptions} threeSixtyReportsActiveOptions={threeSixtyReportsActiveOptions}
                      onTextChange={this.handleTextChange}
                      onTypeChange={this.handleTypeChange}
                      onReportsActiveChange={this.handleReportsActiveChange}
                      onUpdateOrganisation={this.handleUpdateOrganisation}/>

      </section>
    )
  }
}

ModalOrganisationsPageSingle.propTypes = {
  organisationSelected: PropTypes.object.isRequired,
  userAssessmentsSpecific: PropTypes.arrayOf(PropTypes.object),
  userAssessmentsShared: PropTypes.arrayOf(PropTypes.object),
  userAssessmentsUnlimited: PropTypes.arrayOf(PropTypes.object),
  assessmentsSpecific: PropTypes.arrayOf(PropTypes.object),
  assessmentsShared: PropTypes.arrayOf(PropTypes.object),
  assessmentsUnlimited: PropTypes.arrayOf(PropTypes.object),
  userOrganisation: PropTypes.object.isRequired,
  onUpdateOrganisationSelected: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onCreateLabel: PropTypes.func.isRequired,
  onAssignTokens: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  organisationSelected: getOrganisationSelected,
  userAssessmentsSpecific: getUserAssessmentsBySpecificToken,
  userAssessmentsShared: getUserAssessmentsBySharedToken,
  userAssessmentsUnlimited: getUserAssessmentsByUnlimitedToken,
  assessmentsSpecific: getAssessmentsBySpecificToken,
  assessmentsShared: getAssessmentsBySharedToken,
  assessmentsUnlimited: getAssessmentsByUnlimitedToken,
  userOrganisation: getUserOrganisation,
  organisationAssessments: getOrganisationAssessments
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateOrganisationSelected: (organisationSelected) => {
    dispatch(updateOrganisationSelected(organisationSelected))
  },
  onOrganisationUpdatedCredits: () => {
    dispatch(organisationUpdatedCredits())
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
  onCreateLabel: (label) => {
    dispatch(createLabel(label))
  },
  onAssignTokens: (total, direction, selectedOrganisation, type, specificity) => {
    dispatch(assignTokens(total, direction, selectedOrganisation, type, specificity))
  },
  onCreateOrganisationAssessments: (organisation, assessment, tokenType) => {
    dispatch(createOrganisationAssessments(organisation, assessment, tokenType))
  },
  onDeleteOrganisationAssessments: (organisation, assessment) => {
    dispatch(deleteOrganisationAssessments(organisation, assessment))
  },
})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalOrganisationsPageSingle)
