import { SET_USER_SELECTED } from 'actions/userSelectedAction'

const initialState = {}

const userSelected = (state = initialState, action) => {
  switch (action.type) {
    // case UNSET_USER_SELECTED:
    //   return initialState
    case SET_USER_SELECTED:
      return action.user
    default:
      return state
  }
}

export default userSelected

export const getUserSelected = state => state.userSelected
