import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Form/Select'
import Text from 'components/Form/Text'
import Field from './Field'
import ChevronRight from 'assets/svg/components/ChevronRight'

const NewOrganisation = ({ organisation, onTextChange, onTypeChange, typeOptions }) => (
  <div className="card card--stretch">
    <div className="grid">
      <Field required={true} input={<Text parentId={organisation.id} onItemChange={onTextChange} itemValue={organisation.name}
                                          placeholder={'Organisation Name*'}
                                          itemName={'name'}/>}/>
      <Field required={true}
             input={<Text parentId={organisation.id} onItemChange={onTextChange} itemValue={organisation.branding}
                          placeholder={'Short Name*'}
                          itemName={'branding'}/>}/>

      <Field input={<button type={'submit'} className="button button--stretch button--active">
        <span className="padding-right-sm">Save</span>
        <i className="icon"><ChevronRight/></i>
      </button>}/>


    </div>
    <div className="grid" style={{marginTop: '0'}}>
      <Field required={false} input={<Text required={false} parentId={organisation.id} onItemChange={onTextChange} itemValue={organisation.url}
                          placeholder={'Partner URL'}
                          itemName={'url'}/>}/>

      <Field input={<Select parentId={organisation.id} onItemChange={onTypeChange} selectedItem={organisation.type}
                            itemOptions={typeOptions} itemName={'Type'}/>}/>
    </div>
  </div>
)

NewOrganisation.propTypes = {
  organisation: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  typeOptions: PropTypes.arrayOf(PropTypes.object),
}

export default NewOrganisation
