import { getCandidates } from 'reducers/candidatesReducer'

export const SET_CANDIDATE_SELECTED = 'SET_CANDIDATE_SELECTED'
export const UNSET_CANDIDATE_SELECTED = 'UNSET_CANDIDATE_SELECTED'
export const UNSET_CANDIDATES_SELECTED = 'UNSET_CANDIDATES_SELECTED'
export const REMOVE_CANDIDATES_SELECTED = 'REMOVE_CANDIDATES_SELECTED'
export const ADD_CANDIDATES_SELECTED = 'ADD_CANDIDATES_SELECTED'

export function candidatesSelectAll (set) {
  return function (dispatch, getState) {
    const candidates = getCandidates(getState())
    Object.values(candidates).forEach((candidate) => {
      if (set) {
        dispatch(addCandidatesSelected(candidate))
      } else {
        dispatch(removeCandidatesSelected(candidate))
      }
    })
  }
}

export function setCandidateSelected (candidate) {
  return {
    type: SET_CANDIDATE_SELECTED,
    candidate
  }
}

export function unSetCandidateSelected () {
  return {
    type: UNSET_CANDIDATE_SELECTED,
  }
}

export function unSetCandidatesSelected () {
  return {
    type: UNSET_CANDIDATES_SELECTED,
  }
}

export function removeCandidatesSelected (candidate) {
  return {
    type: REMOVE_CANDIDATES_SELECTED,
    candidate
  }
}

export function addCandidatesSelected (candidate) {
  return {
    type: ADD_CANDIDATES_SELECTED,
    candidate
  }
}
