import { asyncFetch } from './apiFetch'
import { addAlertExpiry } from 'actions/uiAction'
import { listUsers } from 'fetchs/userFetch'
import { getUserOrganisation } from 'reducers/userOrganisationReducer'

// CREATE
export function createUserPermission (user) {
  return function (dispatch, getState) {
    const userOrganisation = getUserOrganisation(getState())
    const data = {
      'group': user.role.id,
      'user': user.username ? user.username : user.primaryEmail.trim(),
      'cascade': true,
    }

    return asyncFetch(dispatch, 'POST', `organisations/${userOrganisation.namespace}/permissions/grant`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(createUserError(response.customErrorCode, user))
          } else {
            dispatch(listUsers())
          }
        })
  }
}

// READ

// UPDATE

// DELETE
export function deleteUserPermission (user) {
  return function (dispatch, getState) {

    const userOrganisation = getUserOrganisation(getState())
    const data = {
      'group': user.role.id,
      'user': user.username ? user.username : user.primaryEmail.trim(),
      'cascade': true,
    }

    return asyncFetch(dispatch, 'DELETE', `organisations/${userOrganisation.namespace}/permissions/revoke`, data)
      .then(
        (response) => {
          if (response && response.customErrorCode) {
            // dispatch(createUserError(response.customErrorCode, user))
          } else {
            dispatch(listUsers())
            dispatch(addAlertExpiry('success', 'User added successfully'))
          }
        })
  }
}

// LIST
