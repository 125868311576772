import React, { Component } from 'react'
import { connect } from 'react-redux'
import TableCandidatesAssessmentsStatus from 'containers/Table/CandidatesAssessmentsStatus'
import { createStructuredSelector } from 'reselect'
import ChevronRight from 'assets/svg/components/ChevronRight'
import { removeModal } from 'actions/uiAction'
import { unSetCandidatesSelected } from 'actions/candidateSelectedAction'
import { unSetAssessmentsSelected } from 'actions/assessmentAction'
import PropTypes from 'prop-types'

class ModalCandidatesAssessmentsStatus extends Component {
  handleFinish = () => {
    this.props.onUnSetCandidatesSelected()
    this.props.onUnSetAssessmentsSelected()
    this.props.onRemoveModal()
  }

  render () {
    return (
      <section>
        <div className="text-right">
          <button className="button button--stretch button--active margin-left-md margin-bottom-sm"
                  onClick={this.handleFinish}>
            <span className="padding-right-sm">Finish</span>
            <i className="icon"><ChevronRight/></i>
          </button>
        </div>
        <TableCandidatesAssessmentsStatus/>
      </section>
    )
  }
}

ModalCandidatesAssessmentsStatus.propTypes = {
  onRemoveModal: PropTypes.func.isRequired,
  onUnSetCandidatesSelected: PropTypes.func.isRequired,
  onUnSetAssessmentsSelected: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  onRemoveModal: () => {
    dispatch(removeModal())
  },
  onUnSetCandidatesSelected: () => {
    dispatch(unSetCandidatesSelected())
  },
  onUnSetAssessmentsSelected: () => {
    dispatch(unSetAssessmentsSelected())
  },
})

const mapStateToPropsSelector = createStructuredSelector({})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(ModalCandidatesAssessmentsStatus)
