import React from 'react'
import { Link } from 'react-router-dom'
import ChevronRight from 'assets/svg/components/ChevronRight'
import PropTypes from 'prop-types'

const Form = ({ branding, onSubmitForm, }) => (
  <form className="form" onSubmit={onSubmitForm}>
    <h2 className="form__head">Reset Password</h2>
    <div className="form__group">
      <label className="label" htmlFor="emailLogin">Email Address or Username</label>
      <input autoComplete="email" className="input" name="email" type="text" id="emailLogin" required/>
    </div>
    <div className="form__group">
      <div className="grid grid--flush grid--between">
        <Link className="colour-active" to={`/${branding}/user/login`}><b>Back to Login</b></Link>
      </div>
    </div>
    <button className="button button--stretch button--active">
      <span>Go</span>
      <i className="icon"><ChevronRight/></i>
    </button>
  </form>
)

Form.propTypes = {
  branding: PropTypes.string.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
}

export default Form
