import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CellText from 'components/Table/CellText'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import NoResults from 'components/Table/NoResults'

class Table extends Component {
  render () {
    const {
      organisations,
      onClickOrganisation
    } = this.props

    const headers = []
    let key = 0
    headers.push(<Header key={++key} header='Name'/>)
    headers.push(<Header key={++key} header='Type'/>)

    let tableBody = <NoResults colSpan={2}/>
    const body = []

    if (organisations.length) {
      Object.values(organisations).forEach((organisation) => {
        const row = []
        row.push(<CellText header={'Name'} value={organisation.name} key={++key}/>)
        row.push(<CellText header={'Type'} value={organisation.type} key={++key}/>)
        body.push(<Row onClick={() => onClickOrganisation(organisation)} key={++key} row={row}/>)
      })
      tableBody = (<tbody>{body}</tbody>)
    }

    return (
      <div className="padding-top-md">
        <table className="table">
          <Head headers={headers}/>
          {tableBody}
        </table>
      </div>
    )
  }
}

Table.propTypes = {
  organisations: PropTypes.arrayOf(PropTypes.object),
  onClickOrganisation: PropTypes.func.isRequired,
}

export default Table
