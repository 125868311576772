import React from 'react'
import ChevronRight from 'assets/svg/components/ChevronRight'
import PropTypes from 'prop-types'

const Options = ({ handleConfirm }) => (
  <div className="padding-top-lg text-right">
    <button onClick={handleConfirm} className="button button--stretch button--active">
      <span className="padding-right-sm">Go</span>
      <i className="icon"><ChevronRight/></i>
    </button>
  </div>
)

Options.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
}

export default Options
