import React from 'react'

const NotFound = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150.6" height="115.2">
    <path fill="#005A6D" d="M149.2 27.9H1.4V11.3C1.4 5.7 6 1.1 11.6 1.1H139c5.6 0 10.2 4.6 10.2 10.2v16.6z"/>
    <path fill="#FFF"
          d="M134.5 19.1H85.2c-2 0-3.7-1.7-3.7-3.7v-1.8c0-2 1.7-3.7 3.7-3.7h49.2c2 0 3.7 1.7 3.7 3.7v1.8c.1 2-1.6 3.7-3.6 3.7z"/>
    <circle fill="#FFF" cx="17" cy="14.5" r="4.6"/>
    <circle fill="#FFF" cx="30.3" cy="14.5" r="4.6"/>
    <circle fill="#FFF" cx="43.6" cy="14.5" r="4.6"/>
    <g>
      <path fill="#005A6D" d="M1.4 34.6h147.8v69.3c0 5.6-4.6 10.2-10.2 10.2H11.6c-5.6 0-10.2-4.6-10.2-10.2V34.6z"/>
    </g>
    <g fill="#FFF">
      <path
        d="M55.4 77.7H44.1v-4L53.5 59h6.9v14.3h3v4.4h-3v4.9h-5v-4.9zM49 73.3h6.4v-9.8L49 73.3zM75.3 58.6c6.7 0 9.7 6.1 9.7 12.2S82 83 75.3 83c-6.7 0-9.7-6.1-9.7-12.2-.1-6.1 2.9-12.2 9.7-12.2zm0 4.5c-3.4 0-4.7 3.4-4.7 7.7 0 4.3 1.3 7.7 4.7 7.7 3.4 0 4.6-3.4 4.6-7.7 0-4.4-1.3-7.7-4.6-7.7zM98.4 77.7H87.1v-4L96.5 59h6.9v14.3h3v4.4h-3v4.9h-5v-4.9zM92 73.3h6.4v-9.8L92 73.3z"/>
    </g>
  </svg>
)

export default NotFound
