import React, {PureComponent} from 'react';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect';
import { Route, Switch, Link } from 'react-router-dom';
import { string } from 'prop-types';

import { getUserOrganisation } from 'reducers/userOrganisationReducer';
import fetchContent from '../../../fetchs/contentfulFetch';
import HelpTopicItem from './components/HelpTopicItem';
import HelpQuestionItem from './components/HelpQuestionItem';
import HelpPage from './components/HelpPage';
import FlowProfilerLogo from 'assets/svg/components/FlowProfilerLogo'

class Help extends PureComponent {
  constructor() {
    super();
    this.state = {
      baseContent: []
    };
  }
  componentWillMount() {
    fetchContent({
      'sys.id': 'fJe173w6Ib0rmrpeoet4q',
      include: 2
    })
      .then(resp => {
        this.setState({
          baseContent: resp.items[0].fields
        });
      })
      .catch(() => {
        this.setState({
          contentfulAPIerror: true
        });
      })
  }
  render() {
    const { baseContent, contentfulAPIerror } = this.state;
    const { location: { pathname } } = this.props;

    const splitPathname = pathname.split('/');

    const pathAfterHelp =
      splitPathname
        .slice(splitPathname.indexOf('help'), splitPathname.length)
        .filter(path => path !== '/' && path !== '');

    return (  
      <div className="logged-out-page">
        <div className="help">
          <div className="help__close">
            <button onClick={() => window.close()}>Close help page</button>
          </div>
          <div className="help__banner"><h1>flowprofiler® Help Center</h1></div>
          <div className="help__inner">
            {<div className="help__crumbs">
              <div className="help__crumbs__sect">
                <Link to={`/default/user/${pathAfterHelp[0]}`}>
                  <span className="padding-left-xsm">{pathAfterHelp[0]}</span>
                </Link>
                <span>&nbsp;&nbsp;&nbsp;/</span>
              </div>
              {pathAfterHelp.length > 1 && (
                <div className="help__crumbs__sect">
                  <Link to={`/default/user/${pathAfterHelp[0]}/${pathAfterHelp[1]}/`}>
                    <span className="padding-left-xsm">{pathAfterHelp[1]}</span>
                  </Link>
                  <span>&nbsp;&nbsp;&nbsp;/</span>
                </div>  
              )}
              {pathAfterHelp.length > 2 && (
              <div className="help__crumbs__sect">
                <Link to={`/default/user/${pathAfterHelp[0]}/${pathAfterHelp[1]}/${pathAfterHelp[2]}`}>
                  <span className="padding-left-xsm">{pathAfterHelp[2]}</span>
                </Link>
              </div>   
              )}    
            </div>}
            {baseContent.length === 0 ? (
              contentfulAPIerror ? (
                <div className="help__error">There was an error loading this content</div>
              ) : (
                <div className="help__loading">loading...</div>
              )
            ) : (
              <div className="help__content">
                <Switch>
                  <Route exact path={`/default/user/help/`} render={(props) => (
                    <React.Fragment>
                      <div className="help__topics">
                        <h2>Help Center Topics</h2>
                        {baseContent.helpCenterTopics.map(topic => (
                          <HelpTopicItem key={topic.sys.id} {...topic} />
                        ))}
                      </div>
                      <div className="help__questions">
                        <h2>Common Questions</h2>
                        {baseContent.questions.map(question => (
                          <HelpQuestionItem key={question.sys.id} {...question} />
                        ))}
                      </div> 
                    </React.Fragment>                  
                  )}/>                
                  <Route exact path={`/default/user/help/:slug`} render={(props) => {
                    const subSection =
                      baseContent.helpCenterTopics
                        .filter(topic => topic.fields.slug.fields.slug === props.match.params.slug)[0];

                    return (
                      <div className="help__section">
                        <h3>{subSection.fields.title}</h3>
                        <div className="help__section__content">
                          {subSection.fields.page.map(link => (
                            <HelpQuestionItem key={link.sys.id} {...link} />
                          ))}
                        </div>
                      </div>                    
                    )
                  }}/>
                  <Route exact path={`/default/user/help/:slug/:slug`} render={(props) => <HelpPage {...props} /> } />                
                </Switch>
              </div>
            )}
          </div>
          <div className="panel__block margin-top-lg">
            <div className="text-right">
              <a href={`/default/user/login`}><p className="margin-bottom-none">Powered by <i
                className="logo"><FlowProfilerLogo/></i></p></a>
            </div>
            <span style={{ fontSize: "12px" }}>flowprofiler® and associated marks are registered trademarks of flowprofiler® Global Ltd.</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToPropsSelector = createStructuredSelector({
  userOrganisation: getUserOrganisation
})

Help.protoTypes = {
  location: string.isRequired
};

export default connect(mapStateToPropsSelector, {})(Help)
