import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Form/Select'
import Text from 'components/Form/Text'
import Options from './Options'
import Field from './Field'
import DateWrapper from "../../../components/Form/DateWrapper";

const Organisation = ({ organisationSelected, onTextChange, onTypeChange, onUpdateOrganisation, typeOptions }) => (
  <div className="padding-top-md text-left">
    <div className="grid">
      <Field itemLabel={'Name'}
             input={<Text parentId={organisationSelected.organisation.id} onItemChange={onTextChange} itemValue={organisationSelected.organisation.name}
                          itemLabel={'Name'} itemName={'name'}/>}/>
      <Field itemLabel={'Short Name'}
      input={<Text parentId={organisationSelected.organisation.id} onItemChange={onTextChange} itemValue={(organisationSelected.branding.name ? organisationSelected.branding.name: '')}
      itemLabel={'Short Name'} itemName={'brand'}/>}/>

      <Field required={false} itemLabel={'Partner URL'}
             input={<Text required={false} parentId={organisationSelected.organisation.id} onItemChange={onTextChange} itemValue={(organisationSelected.organisation.url ? organisationSelected.organisation.url: '')}
                          itemLabel={'Partner URL'} itemName={'url'}/>}/>

      <Field itemLabel={'Type'}
             input={<Select parentId={organisationSelected.organisation.id} onItemChange={onTypeChange} selectedItem={organisationSelected.organisation.type}
                            itemOptions={typeOptions} itemName={'Type'}/>}/>



    </div>

  </div>
)

Organisation.propTypes = {
  organisationSelected: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onUpdateOrganisation: PropTypes.func.isRequired,
  typeOptions: PropTypes.arrayOf(PropTypes.object),
}

export default Organisation
