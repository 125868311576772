import { SET_ACTIVE_TAB } from 'actions/uiAction'

const initialState = 'New'

const activeTab = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.activeTab
    default:
      return state
  }
}

export default activeTab

export const getActiveTab = state => state.activeTab
