import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Form/Select'
import Text from 'components/Form/Text'
import Options from './Options'
import Field from './Field'
import Switch from "./Switch";
import SwitchControl from "./SwitchControl";

const Reports = ({ organisationSelected, userOrganisation, onReportsActiveChange }) => {

    return (
        <div className="padding-top-md text-left" style={{display: 'flex', flexDirection: 'row', justifyContent: 'start'}}>
            <div style={{width: '400px'}}>
                {userOrganisation.organisation.developmentReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.developmentReportsActive} label="Development" id="development-reports" onActiveChange={(e) => onReportsActiveChange("development", e)} />
                }
                {userOrganisation.organisation.managementReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.managementReportsActive} label="Management" id="management-reports" onActiveChange={(e) => onReportsActiveChange("management", e)} />
                }
                {userOrganisation.organisation.interviewReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.interviewReportsActive} label="Interview" id="interview-reports" onActiveChange={(e) => onReportsActiveChange("interview", e)} />

                }
                {userOrganisation.organisation.candidateReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.candidateReportsActive} label="Candidate" id="candidate-reports" onActiveChange={(e) => onReportsActiveChange("candidate", e)} />
                }
            </div>
            <div>
                {userOrganisation.organisation.leadershipReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.leadershipReportsActive} label="Leadership" id="leadership-reports" onActiveChange={(e) => onReportsActiveChange("leadership", e)} />
                }
                {userOrganisation.organisation.benchmarkReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.benchmarkReportsActive} label="Benchmark" id="benchmark-reports" onActiveChange={(e) => onReportsActiveChange("benchmark", e)} />
                }
                {userOrganisation.organisation.teamReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.teamReportsActive} label="Teams" id="team-reports" onActiveChange={(e) => onReportsActiveChange("team", e)} />
                }
                {userOrganisation.organisation.threeSixtyReportsActive &&
                    <SwitchControl initialActiveState={organisationSelected.organisation.threeSixtyReportsActive} label="360" id="three-sixty-reports" onActiveChange={(e) => onReportsActiveChange("threeSixty", e)} />
                }
            </div>

        </div>
    )
}

Reports.propTypes = {
    organisationSelected: PropTypes.object.isRequired,
    userOrganisation: PropTypes.object.isRequired,
    onReportsActiveChange: PropTypes.func.isRequired
}

export default Reports
