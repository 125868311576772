import React, { Component } from 'react'
import { createStructuredSelector } from 'reselect'
import { getPages } from 'reducers/pagesReducer'
import { connect } from 'react-redux'
import PaginationModule from "react-js-pagination";

class Pagination extends Component {
  render () {
    const { pages, onPageChange } = this.props
    return (
      <section>
        {pages.total > pages.limit &&
        <PaginationModule
          activePage={pages.current}
          itemsCountPerPage={pages.limit}
          totalItemsCount={Number(pages.total)}
          pageRangeDisplayed={pages.range}
          onChange={onPageChange}
        />
        }
      </section>

    )
  }
}

const mapStateToPropsSelector = createStructuredSelector({
  pages: getPages,
})

export default connect(mapStateToPropsSelector, null)(Pagination)
