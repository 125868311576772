import React from 'react'
import BalanceSharedCredit from './BalanceSharedCredit'
import BalanceAssessmentCredit from './BalanceAssessmentCredit'
import BalanceUnlimitedCredit from './BalanceUnlimitedCredit'
import Field from '../Field'

const Index = ({ unlimited, shared, assessmentSpecific }) => (
  <div className={'separator'}>
    <h2>My Company Credit Balance</h2>
    <div className="padding-top-md text-left">
      <div className="grid">
        {shared &&
        <Field itemLabel={'Shared Credits'}
               input={<BalanceSharedCredit shared={shared}/>}/>
        }
        {assessmentSpecific &&
        <Field itemLabel={'Type Specific'}
               input={<BalanceAssessmentCredit assessmentSpecific={assessmentSpecific}/>}/>
        }
        {unlimited &&
        <Field itemLabel={'Unlimited'}
               input={<BalanceUnlimitedCredit unlimited={unlimited}/>}/>
        }
      </div>
    </div>
  </div>
)

Index.propTypes = {
  // organisation: PropTypes.object.isRequired,
  // onTextChange: PropTypes.func.isRequired,
  // onTypeChange: PropTypes.func.isRequired,
  // onUpdateOrganisation: PropTypes.func.isRequired,
  // typeOptions: PropTypes.array.isRequired,
}

export default Index
