import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CellText from 'components/Table/CellText'
import CellCheckbox from 'components/Table/CellCheckbox'
import Row from 'components/Table/Row'
import Header from 'components/Table/Header'
import Head from 'components/Table/Head'
import HeaderCheckbox from 'components/Table/HeaderCheckbox'
import NoResults from 'components/Table/NoResults'
import { formatLabels } from 'actions/labelAction'
import Slip from '../../../libs/slip'
import Moment from "react-moment";

class Table extends Component {

    componentDidMount() {
        if (this.props.enableListReordering) {
            const ol = document.getElementById('candidatelist')
            if (!ol) return
            ol.addEventListener('slip:beforereorder', (e) => {
                if (e.target.nodeName === 'INPUT') {
                    e.preventDefault()
                }
            }, false)
            ol.addEventListener('slip:reorder', (e) => {
                e.target.parentNode.insertBefore(e.target, e.detail.insertBefore)
                this.props.onReorder(e.detail.originalIndex, e.detail.spliceIndex);
                return false
            }, false)
            new Slip(ol)
        }
    }

    render() {
        const {
            candidatesAssessments, candidatesAssessmentsSelected, selectAll, modal,
            onClickCandidatesAssessments, onSelectAllCandidateAssessments, onSelectCandidateAssessment
        } = this.props

        const headers = []
        let key = 0
        headers.push(<HeaderCheckbox key={++key} selectAll={selectAll} onSelectAllItems={onSelectAllCandidateAssessments}/>)
        headers.push(<Header key={++key} header='First'/>)
        headers.push(<Header key={++key} header='Last'/>)
        headers.push(<Header key={++key} header='Completed'/>)
        headers.push(<Header key={++key} header='Assessment'/>)
        headers.push(<Header key={++key} header='Label'/>)

        let tableBody = <NoResults colSpan={5}/>
        const body = []

        if (candidatesAssessments.length) {
            Object.values(candidatesAssessments).forEach((candidateAssessment) => {

                const isChecked = candidatesAssessmentsSelected.some(obj => {
                    return obj.id === candidateAssessment.id
                })

                const completedAt = <Moment format="DD/MM/YYYY" date={candidateAssessment.completedAt.date} />

                const row = []
                row.push(<CellCheckbox isChecked={isChecked} onChange={() => onSelectCandidateAssessment(candidateAssessment, isChecked)}
                                       key={++key}/>)
                row.push(<CellText header={'First'} value={candidateAssessment.candidate.firstName} key={++key}/>)
                row.push(<CellText header={'Last'} value={candidateAssessment.candidate.lastName} key={++key}/>)
                row.push(<CellText header={'Completed'} value={completedAt} key={++key}/>)
                row.push(<CellText header={'Assessment'} value={candidateAssessment.assessment.name} key={++key}/>)
                row.push(<CellText header={'Label'} value={formatLabels(candidateAssessment.candidate.labels)} key={++key}/>)
                if (modal) {
                    body.push(<Row key={++key} row={row}/>)
                } else {
                    body.push(<Row onClick={(event) => onClickCandidatesAssessments(event, candidateAssessment)} key={++key} row={row}/>)
                }

            })
            tableBody = (<tbody id="candidatelist">{body}</tbody>)
        }

        return (
            <div className="padding-top-md">
                <table className="table">
                    <Head headers={headers}/>
                    {tableBody}
                </table>
            </div>
        )
    }
}

Table.propTypes = {
    candidatesAssessmentsSelected: PropTypes.arrayOf(PropTypes.object),
    candidatesAssessments: PropTypes.arrayOf(PropTypes.object),
    selectAll: PropTypes.bool,
    modal: PropTypes.bool,
    onClickCandidateAssessment: PropTypes.func.isRequired,
    onSelectAllCandidateAssessments: PropTypes.func.isRequired,
    onSelectCandidateAssessment: PropTypes.func.isRequired,
    candidateAssessments: PropTypes.arrayOf(PropTypes.object),
}

export default Table
