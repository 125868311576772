import React from 'react'
import classNames from 'classnames';

import Tick from 'assets/svg/components/Tick'
import EQflow from 'assets/svg/eqflow-button-teal.svg'
import Mflow from 'assets/svg/mflow.svg'
import Rflow from 'assets/svg/rflow.svg'
import FLOWP from 'assets/svg/flowp-grey.svg'
import LFLOW from 'assets/360-icon.png'

const Card = ({ assessmentItem, active, handleClick, modal, modifierClass = undefined, sortable }) => (
  <div
    key={checkId(assessmentItem)}
    className={classNames(`card card--${modifierClass} ${active}`, {
      'card--default': modal,
      [`card--${modifierClass}`]: modifierClass
    })}
    onClick={handleClick}
  > 
    {modifierClass === 'eqflow' && <img className="card__report-icon" src={EQflow} alt="eqflow" />}
    {modifierClass === 'motivationflow' && <img className="card__report-icon" src={Mflow} alt="motivationflow" />}
    {modifierClass === 'resilienceflow' && <img className="card__report-icon" src={Rflow} alt="resilienceflow" />}
    {modifierClass === 'the flowprofiler' && <img className="card__report-icon" src={FLOWP} alt="flowprofiler" />}
    {modifierClass === 'leadershipflow 360' && <img className="card__report-icon" src={LFLOW} alt="leadershipflow" />}
    {displayTick(active)}
    {displayLogo(assessmentItem)}
    <p className="card__title">{assessmentItem.assessment.name}</p>
  </div>
)

function displayTick (active) {
  if (active) {
    return (
      <div className="card__icon">
        <Tick/>
      </div>
    )
  }
}

function checkId (assessmentItem) {
  let id = assessmentItem.id
  if (assessmentItem.o_assessment) {
    id = assessmentItem.o_assessment
  }
  return id
}

function displayLogo (assessmentItem) {
  let logo = null
  switch (assessmentItem.assessment.slug.toLowerCase()) {
    case 'eqflow':
      logo = (<span className="icon-eqflow-button">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                </span>)
      break
    case 'motivationflow':
      logo = (<span className="icon-mflow-button">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
              </span>)
      break
    case 'resilienceflow':
      logo = (<span className="icon-rflow-button">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
                <span className="path5"></span>
                <span className="path6"></span>
              </span>)
      break
    default:
      logo = (<span className="icon-flowprofiler-button"></span>)
      break

  }

  return logo
}

Card.defaultProps = {
  sortable: false
};

export default Card
