import React, { Component } from 'react'
import Table from './Table'
import Filter from './Filter'
import { connect } from 'react-redux'
import { listLabels } from 'fetchs/labelFetch'
import { addAlertExpiry, addModal } from 'actions/uiAction'
import { addBackground, removeBackground } from 'actions/backgroundAction'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { getLabels } from 'reducers/labelsReducer'
import Pagination from 'components/Pagination'
import { setLabelSelected } from 'actions/labelAction'

class LabelsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchValue: '',
      lastSearch: 0,
      minimumSearch: false,
    }
  }

  handleSearchChange = (event) => {
    const searchValue = event.target.value
    const date = new Date()
    const timestamp = date.getTime()
    const second = 1000
    this.setState({ searchValue, lastSearch: timestamp })

    setTimeout(() => {
      if (this.state.searchValue.length > 2 && this.state.lastSearch + (second) > timestamp) {
        this.setState({ minimumSearch: true })
        this.props.onListLabels(1, this.state.searchValue)
      } else if (this.state.minimumSearch) {
        this.setState({ minimumSearch: false })
        this.props.onListLabels(1, '')
      }
    }, second)
  }

  handleClickLabel = (label) => {
    this.props.onSetLabelSelected(label)
    this.props.onAddModal('Label')
  }

  componentDidMount () {
    this.props.onListLabels(1)
    this.props.onAddBackground()
  }

  componentWillUnmount () {
    this.props.onRemoveBackground()
  }

  handleReset = () => {
    this.setState({ searchValue: '' })
    this.props.onListLabels(1)
  }

  handlePageChange = (page) => {
    this.props.onListLabels(page, this.state.searchValue)
  }

  render () {
    const { labels } = this.props
    const { searchValue } = this.state

    return (
      <section>
        <h2>Labels</h2>
        <Filter labels={labels} searchValue={searchValue}
                onSearchChange={this.handleSearchChange} onLabelChange={this.handleLabelChange}
                onReset={this.handleReset}/>
        <Table labels={labels}
               onClickLabel={this.handleClickLabel} />
        <Pagination onPageChange={this.handlePageChange}/>
      </section>
    )
  }
}

LabelsPage.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object),
  onListLabels: PropTypes.func.isRequired,
  onAddModal: PropTypes.func.isRequired,
  onAddBackground: PropTypes.func.isRequired,
  onRemoveBackground: PropTypes.func.isRequired,
  onSetLabelSelected: PropTypes.func.isRequired,
  onAddAlertExpiry: PropTypes.func.isRequired,
}

const mapStateToPropsSelector = createStructuredSelector({
  labels: getLabels,
})

const mapDispatchToProps = (dispatch) => ({
  onListLabels: (page, searchFilter) => {
    dispatch(listLabels(page, searchFilter))
  },
  onAddModal: (modal) => {
    dispatch(addModal(modal))
  },
  onAddBackground: () => {
    dispatch(addBackground())
  },
  onRemoveBackground: () => {
    dispatch(removeBackground())
  },
  onAddAlertExpiry: (message) => {
    dispatch(addAlertExpiry('warning', message))
  },
  onSetLabelSelected: (label) => {
    dispatch(setLabelSelected(label))
  },

})

export default connect(mapStateToPropsSelector, mapDispatchToProps)(LabelsPage)
