import { CLOSE_MENU, OPEN_MENU } from 'actions/uiAction'

const initialState = false

const menu = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return true
    case CLOSE_MENU:
      return initialState
    default:
      return state
  }
}

export default menu

export const getMenu = state => state.menu
