import { getCandidatesAssessments } from 'reducers/candidatesAssessmentsReducer'
import {SET_CANDIDATE_SELECTED} from "./candidateSelectedAction";

export const UNSET_CANDIDATES_ASSESSMENTS_SELECTED = 'UNSET_CANDIDATES_ASSESSMENTS_SELECTED'
export const REMOVE_CANDIDATES_ASSESSMENTS_SELECTED = 'REMOVE_CANDIDATES_ASSESSMENTS_SELECTED'
export const ADD_CANDIDATES_ASSESSMENTS_SELECTED = 'ADD_CANDIDATES_ASSESSMENTS_SELECTED'
export const SET_CANDIDATES_ASSESSMENTS_SELECTED = 'SET_CANDIDATES_ASSESSMENTS_SELECTED'

export function candidatesAssessmentsSelectAll (set) {
    return function (dispatch, getState) {
        const candidatesAssessments = getCandidatesAssessments(getState())
        Object.values(candidatesAssessments).forEach((candidatesAssessment) => {
            if (set) {
                dispatch(addCandidatesAssessmentsSelected(candidatesAssessment))
            } else {
                dispatch(removeCandidatesAssessmentsSelected(candidatesAssessment))
            }
        })
    }
}

export function setCandidatesAssessmentsSelected (candidatesAssessments) {
    return {
        type: SET_CANDIDATES_ASSESSMENTS_SELECTED,
        candidatesAssessments
    }
}

export function unSetCandidatesAssessmentsSelected () {
    return {
        type: UNSET_CANDIDATES_ASSESSMENTS_SELECTED,
    }
}

export function removeCandidatesAssessmentsSelected (candidateAssessment) {
    return {
        type: REMOVE_CANDIDATES_ASSESSMENTS_SELECTED,
        candidateAssessment
    }
}

export function addCandidatesAssessmentsSelected (candidateAssessment) {
    console.log(candidateAssessment)
    return {
        type: ADD_CANDIDATES_ASSESSMENTS_SELECTED,
        candidateAssessment
    }
}
