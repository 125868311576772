import React from 'react'
import PropTypes from 'prop-types'

const Field = ({ itemLabel, input, required }) => (
  <div className="grid__item w-6-md">
    <div className="grid grid--middle">
      { itemLabel &&
        <div className="grid__item w-12">
          <label className={required ? 'label label--bare label--required' : 'label label--bare'} htmlFor="firstName"><b
              className="colour-black" style={{marginBottom: '0'}}>{itemLabel}:</b> </label>
        </div>
      }

      <div className="grid__item" style={{paddingTop: '12px'}}>
        {input}
      </div>
    </div>
  </div>
)

Field.propTypes = {
  itemLabel: PropTypes.string,
  input: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  required: PropTypes.bool,
}

export default Field
