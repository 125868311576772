import { CURRENT_DATE } from '../actions/dateAction'

const initialState = {
  currentDate: null
}

const date = (state = initialState, action) => {
  switch (action.type) {
    case 'START_DATE':
      return { ...state, currentDate: action.startDate }
    case CURRENT_DATE:
      return { ...state, currentDate: action.date }
    default:
      return state
  }
}

export default date

export const getDate = state => state.date
